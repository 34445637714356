import * as React from "react";
import "./Tabs.scss";
import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { RequestUtils } from '../../serverUtils/requests';
import { useHistory } from 'react-router-dom';
import { divScroller } from "../Form/Form";
import {default as ScrollLeft} from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import {default as ScrollRight} from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

export const Tabs = React.forwardRef(({ tabs = [], sections = [], index = 0 }, ref) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(index);
    const [isScrollLeft, setIsScrollLeft] = useState(false);
    const [isScrollRight, setIsScrollRight] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const divRef = useRef();
    const setActiveTabByName = (url) => {
        let i = tabs.filter(t => t).findIndex(t => t.url === url);
        setActiveTab(i);
        scrollTabToView(url);
    }
    const scrollTabToView = url => {
        // tabRefs[url]?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    
    useEffect(() => {
        if (!isNaN(activeTab) && tabs && tabs[activeTab]) {
            RequestUtils.insertURLParam('tab', tabs[activeTab].url, history);
        }
    }, [activeTab]);

    const tabRefs = {};
    React.useImperativeHandle(ref, () => ({
        setActiveTabByName,
        getActiveTabUrl: () => tabs[activeTab] && tabs[activeTab].url,
        refresh: () => setRefresh(!refresh),
    }));

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            if (divRef.current) {
                let scrollLeftMax = divRef?.current.scrollWidth-divRef?.current.clientWidth;
                setIsScrollLeft(scrollLeftMax > 0);
                setIsScrollRight(scrollLeftMax > 0);
            }
        });
    
        observer.observe(divRef.current);
    
        return () => {
          observer.disconnect();
        };
    }, []);
    useEffect(() => {
        setRefresh(!refresh);
    }, [tabs]);

    useEffect(() => setActiveTab(index), [index]);
    // console.log('active tab: ', activeTab);
    return <div className="Tabs">
        <div className="tabsWrapper" ref={divRef} 
            onScroll={e => {
                const {scrollLeft, scrollWidth, clientWidth} = e.target;
                setIsScrollLeft(scrollLeft > 0);
                setIsScrollRight(scrollLeft < scrollWidth-clientWidth);
            }}
            onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
            <ScrollLeft className={`scroll-left ${isScrollLeft? 'show':''}`}/>
            {tabs.filter(t => t).map((tab, i) => {
                tabRefs[tab.url] = React.createRef();
                return <div ref={tabRefs[tab.url]} key={i} className={classNames('tab', { active: i === activeTab })}
                    onClick={e => {
                        e.stopPropagation();
                        if (tab.url) {
                            // RequestUtils.insertURLParam("tab", tab.url, history, true);
                            scrollTabToView(tab.url);
                        }
                        setActiveTab(i);
                    }}>{tab.html}</div>
            })}
            <ScrollRight className={`scroll-right ${isScrollRight? 'show':''}`}/>
        </div>

        <div className="tabSection">
            {sections.map((s, i) => <div key={i} style={{display: i === activeTab? '':'none'}}>{s}</div>)}
        </div>
    </div>
});
