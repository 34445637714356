import './CartsPage.scss';

import { IonPage, IonContent, IonSpinner, useIonViewWillEnter } from "@ionic/react";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CartPage from './CartPage';
import { useStore } from "../../Store";
import UserModel from '../../serverUtils/models/UserModel';
import { STATUS } from '../../components/Bracket/Bracket';
import { PAYMENT } from '../../components/Form/PaymentInfos';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Badge, Link, ThemeProvider } from '@mui/material';
import { default as RemoveIcon } from '@mui/icons-material/HighlightOff';
import Theme from '../../components/FormInput/Theme';
import {default as CollapseIcon} from '@mui/icons-material/UnfoldLessDouble';
import {default as ExpandIcon} from '@mui/icons-material/UnfoldMoreDouble';
import Utils from "../../serverUtils/Utils";
import { RequestUtils } from "../../serverUtils/requests";
import { getBackHistory } from "../../components/Form/Form";


export default function CartsPage() {
    const history = useHistory();
    const session = useStore(state => state.session);
    const browsingHistory = useStore(state => state.browsingHistory);

    const [selected, setSelected] = useState();
    const [deleting, setDeleting] = useState();
    
    const init = () => {
        if (!session) {
            return;
        }
        let carts = session.getCarts();
        let params = RequestUtils.getURLParameters();
        if (params.ref) {
            let c = carts.find(c => c.ref === params.ref);
            if (c){
                return setSelected(c);
            }
        }
        if (carts.length === 1) {
            setSelected(carts[0]);
        } else {
            setSelected(null);
        }
    }

    useEffect(() => {
        init();
    }, [session]);

    useIonViewWillEnter(init, [session]);
    return (
        <IonPage className="CartsPage">
            <ThemeProvider theme={Theme}>
                {session && session.getCarts && session.getCarts() &&
                <IonContent>
                    {browsingHistory.length>0 && <Link href={`${getBackHistory(browsingHistory, '/carts')}`} >Back</Link>}
                    <h1>My Carts <Badge color='primary' style={{ marginLeft: 20 }}
                            badgeContent={session.getCarts().length} /></h1>
                        {session.getCarts().length === 0 ? 'Nothing in carts' :
                        session.getCarts().map((c, i) => {
                            let cartRef = React.createRef(null);
                            let isSelected = c === selected;
                            let business = c.getBusiness && c.getBusiness() || {};
                            return <Accordion key={i} expanded={isSelected}>
                                <AccordionSummary onClick={(e) => {
                                    let isSelect = selected === c;
                                    RequestUtils.insertURLParam('ref', !isSelect && c.ref, history);
                                    return  isSelect? setSelected(null) : setSelected(c);
                                }}>
                                    <h2 className="cart-header">
                                        {/* {c.ref_display ?
                                            <Link href={`/${c.ref_type}s/${c.ref}`}>
                                                {c.ref_display}
                                            </Link> :
                                            <span>{c.getBusiness().name}</span>
                                        }  */}
                                        {c === selected? <CollapseIcon /> : <ExpandIcon />}
                                        <span id="cart_id">{(business?.name) || c.ref_display}</span>
                                        {business?.dates?.start_date && <span className="tournament-date">@{Utils.formatDate(business.dates.start_date)}</span>}
                                        <RemoveIcon className="RemoveIcon" 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDeleting(c.id);
                                                cartRef?.current.deleteCart(c);
                                            }}/>
                                        {deleting===c.id && <IonSpinner name="circles" className="spinner"/>}
                                    </h2>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <CartPage ref={cartRef}
                                        cart={c}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                </IonContent>}
            </ThemeProvider>
        </IonPage>
    )
}

function GIHCart({session, setSession, setShow, setSelected}) {
    //test paypal buyer account phando-buyer@comcast.net:mypaypalpass1
    //gih test account sb-yfisb11878941@business.example.com:12345678:ASKKAZIR7sZe-sVMHEPj8lqOhR1Y2onxwhv91RO94ONqur7VJxP8jRYlAGuiE7HYPPa5nmpEPpXP9S3C
    //phanConsultant phando-facilitator@comcast.net: client ID:AS-wwg3RWPmurWwFSJF2yLaHkZiIr2juPUTE6FCrw8gg3u8sFr5TH6mkz8aYKnYMRpeXTiczfwSJovOi

    const [gihSubscription, setGihSubscription] = useState();
    const [subsType, setSubsType] = useState();
    const [message, setMessage] = useState();
    useEffect(() => {
        UserModel.getGIHSubscription()
            .then(s => {
                let gihsubs = JSON.parse(s);
                let mode = document.location.hostname==='localhost'? 'sandbox':'live';
                if (gihsubs.subs) {
                    setSubsType(gihsubs.subs[mode][0]);
                    setGihSubscription({
                        subs: gihsubs.subs[mode],
                        payment: gihsubs[mode]
                    });
                }
            });
    }, [session]);

    const getCart = () => {
        if (!gihSubscription || !subsType) {
            return;
        }
        setSelected(false);
        const {subs, payment} = gihSubscription;
        let sub = subs.find(s => s.type===subsType.type);
        return {
            items: [{
                sku: `gih_membership_fee_${sub.id}`,
                description: `G0IH membership fee (${sub.type}).`,
                quantity: 1,
                unit_price: sub.amount,
                sub_total: subs.amount,
                item_type: sub.type,
            }],
            payment,
            getBusiness: () => ({
                name: 'GIH',
                payment_info : [{
                    payment_type: PAYMENT.paypal.value,
                    location_id: payment.clientId,
                    account: payment.account,
                    label: PAYMENT.paypal.label,
                }],
                waiver: 'This a subscription that you are registering for recurrent charge will be made to your account via PayPal.'
            })
        }
    }

    const onSubmit = async transaction => {
        setMessage('');
        let cart = getCart();
        return UserModel.gihSubscription({payment_type: cart.item_type, transaction})
                .then(result => {
                    if (result && !result.error){
                        setMessage('Successfully subscribed.');
                        let item = cart.items[0];
                        let sub = {
                            status: STATUS.Active,
                            start_date: new Date().getTime(),
                            end_date: moment().add(item.quantity, item.item_type),
                            recurrence: item.item_type,
                            amount: item.amount,
                            paid_date: new Date().getTime()
                        }
                        session.gih.push(sub);
                        setSession({...session});
                    }else {
                        setMessage('Error subscribing. Please try again.');
                    }
                });
    }

    return <div className="charge-detail" key={0}>
        <Alert severity="info">
            {message ? message : `To participate in a tournament from GIH.  
                        Your membership subscription must be active.  
                        Please select a payment option from the below 
                        options and pay for your subscription before 
                        continuing your registration.`}
        </Alert>
                <h3>Subscription Options</h3>
                {gihSubscription && gihSubscription.subs.map((s, i) => {
                    return <div key={i} className="item">
                                <input name="payment_select" type="radio" 
                                    checked={subsType.type===s.type} 
                                    onClick={() => {
                                        setSubsType({type: s.type, amount: s.amount, id: s.id});
                                        }} />
                                <span className="description">{s.amount} USD / {s.type}</span>
                        </div>;
                })}
                {getCart() && 
                    <CartPage session={session}
                        setSession={setSession}
                        cart={getCart()}
                        refresh={() => setShow(true)}
                        disableDeleteCart={true}
                        onSubmitOverride={onSubmit}
                        selected={true}
                        setSelected={setSelected}
                            />}               
            </div>
            
}