import { IonItem, IonLabel, IonList, IonMenuToggle } from "@ionic/react";
import "../../App.scss";
import { pages } from "./pages";
import { useStore } from "../../Store";

const Navigation = () => {
    const session = useStore(state => state.session);
    const createNavItem = (navItem) => {
        return <IonItem color="tertiary" key={navItem.name} button routerLink={navItem.path} lines="none" className={`navItem`}>
            <img className="icon" src={`assets/icon/${navItem.icon}.svg`} />
            <IonLabel className={`navItemLabel`}>{navItem.name}</IonLabel>
        </IonItem>
    }

    return <IonList className={`navList`}>
        <IonMenuToggle autoHide={false}>
            { pages.filter(p => {
                if ( p.mainNav ) {
                    if (p.session && !session) {
                        return false;
                    }else if (p.name === 'Login' && session) {
                        return false;
                    }
                    return true;
                }
                return false;
            }).map(p => createNavItem(p)) }
        </IonMenuToggle>
    </IonList>;
}

export default Navigation;
