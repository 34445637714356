import { FormControlLabel, ThemeProvider } from "@mui/material";
import {Checkbox} from "@mui/material";
import Theme from "./Theme";
import { useEffect, useState } from "react";

export default function CheckboxFormInput({value, name, label, register, isRequired, errors, onChange, setValue, isReadOnly}) {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(value);
        setValue && setValue(name, value);
    }, [value]);
    const handleOnChange = e => {
        let v = e.target.checked;
        onChange && onChange(v);
        setChecked(v);
        setValue && setValue(name, v);
    };
    let _register = {};
    if (register) {
        _register = {...register(name, { 
                            isRequired, 
                            onChange: handleOnChange
                        })};
    }else {
        _register = {onChange: handleOnChange};
    }
    return (
        <ThemeProvider theme={Theme}>
            <FormControlLabel className="CheckboxFormInput"
                control={
                    <Checkbox disabled={isReadOnly? true:false}
                        onClick={e => e.stopPropagation()}
                        checked={checked? true:false}
                        {..._register}
                        error={!!errors || ''}
                        helpertext={errors?.message} /> }
                label={label} />
        </ThemeProvider>
    )
}