import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage, useIonViewWillEnter
} from "@ionic/react";
import { useStore } from "../../Store";
import classNames from "classnames";
import pageStyles from "../Page.module.scss";
import GymInformationForm from "../../components/GymForm/GymInformationForm";
import GymModel from "../../serverUtils/models/GymModel";
import UserModel from "../../serverUtils/models/UserModel";
import GymMemberships from "../GymPage/GymMemberships";
import { RequestUtils } from "../../serverUtils/requests";
import GymStoreItems from "./GymStoreItems";
import { Link } from "@mui/material";
import { Tabs } from "../../components/Tabs/Tabs";
import { ENTITY } from "../../serverUtils/Models";

const TABS = [
  { html: "Information", url: "information" },
  { html: "Memberships", url: "memberships" },
  { html: "Transactions", url: 'transactions' },
  { html: "Store Items", url: "store_items" },
];

const GymEditPage = (props) => {
  const session = useStore((state) => state.session);
  const [gym, setGym] = useState(null);
  const [tabs, setTabs] = useState(TABS);
  const [sectionIndex, setSectionIndex] = useState();
  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(TABS.findIndex(t => t.url === params.tab));
    }
  }, []);

  const getData = async (id) => {
    if (!id) {
      return;
    }
    if (id === "add") {
      setTabs(TABS.slice(0, 1));
      setSectionIndex(0);
      return setGym(UserModel.addSessionAsAdmin(session));
    }
    let gym = await GymModel.getGym(id);
    if (gym) {
      UserModel.isAdmin(session, ENTITY.gym, gym.id) && setGym(gym);
    }
  };

  let urlId = RequestUtils.getURLId("gyms");
  useEffect(() => {
    if (urlId === 'add') {
      setGym(null);
    }
  }, [urlId]);

  useEffect(() => {
    if (session) {
      getData(urlId);
    }
  }, [session]);

  useIonViewWillEnter(async () => {
    session && getData(RequestUtils.getURLId("gyms"));
  }, [session]);

  return (
    <IonPage>
      <IonHeader>
        {/*<IonToolbar>*/}
        {/*    <IonButtons slot="start">*/}
        {/*        <IonBackButton defaultHref="home" />*/}
        {/*    </IonButtons>*/}
        {/*    <History />*/}
        {/*</IonToolbar>*/}
      </IonHeader>
      <IonContent className={classNames(pageStyles.pageContent)}>
        <h1 id="gym_title" className="display-font">
          {props.match.params.id ? "Edit" : "New"} Gym
        </h1>
        {gym && gym.id && <Link href={`/gyms/${gym && gym.id}`}>{gym && gym.name}</Link>}
        <div className={classNames(pageStyles.pageContent)}>
          <Tabs
            index={sectionIndex}
            tabs={tabs}
            sections={[
              <GymInformationForm gym={gym} />,
              <GymMemberships gym={gym} session={session} />,
              <div>Transactions</div>,
              <GymStoreItems gym={gym} />,

            ]}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GymEditPage;
