import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage, useIonViewWillEnter
} from "@ionic/react";
import SwipableTabs from "../../components/SwipableTabs/SwipableTabs";
import classNames from "classnames";
import pageStyles from "../Page.module.scss";
import UserModel from "../../serverUtils/models/UserModel";
import Profile from "../../components/MembershipForm/Profile";
import { MembershipSchema } from "../../serverUtils/Models";
import { RequestUtils } from "../../serverUtils/requests";
import { useStore } from "../../Store";
import { Link, ThemeProvider } from "@mui/material";
import Theme from "../../components/FormInput/Theme";

const sections = [
  {
    name: "Profile",
    component: (data, props) => <Profile membership={data} props={props} />,
  },
  {
    name: "Friends",
    component: (data) => <div>Friends</div>,
  },
  {
    name: "Images",

    component: (data) => <div>Images</div>,
  },
];

const UserEditPage = (props) => {
  const history = useHistory();
  const session = useStore((state) => state.session);
  const [data, setData] = useState(null);
  let urlId = RequestUtils.getURLId("users");
  useEffect(() => {
    if (urlId === 'add') {
      setData(null);
    }
  }, [urlId]);
  useEffect(() => {
    session && getData(urlId);
  }, [session]);

  const getData = async (id) => {
    if (!id) {
      return setData({ ...MembershipSchema().model });
    }
    if (id === "add") {
      return setData({});
    }
    let user = await UserModel.getUser(id);
    setData(user);
  };

  useIonViewWillEnter(() => {
    session && getData(urlId);
  }, [session]);

  return (
    <IonPage>
      <ThemeProvider theme={Theme} >
        <IonHeader>
          {/*<IonToolbar>*/}
          {/*    <IonButtons slot="start">*/}
          {/*        <IonBackButton defaultHref="home" />*/}
          {/*    </IonButtons>*/}
          {/*    <History />*/}
          {/*</IonToolbar>*/}
        </IonHeader>
        <IonContent className={classNames("ion-padding", pageStyles.pageContent)}>
          <h1 id="user_add" className="display-font" >
            {props.match.params.id ? "Edit" : "New"} User
          </h1>
          {data && data.id &&
            <Link href={`/users/${data.id}`}>
              {UserModel.getMembershipName(data)}
            </Link>}
          <div className={classNames("ion-padding", pageStyles.pageContent)}>
            <SwipableTabs
              sections={data && data.id ? sections : [sections[0]]}
              data={data} setData={setData}
              props={props}
            />
          </div>
        </IonContent>
      </ThemeProvider>
    </IonPage>
  );
};

export default UserEditPage;
