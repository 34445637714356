import { IonButton } from "@ionic/react";
import moment from "moment";
import { useState } from "react";
import UserModel from "../../serverUtils/models/UserModel";
import AutocompleteTextFormInput from "../FormInput/AutocompleteTextFormInput";
import { STATUS } from "./Form";
import AlertPane from "../FormInput/AlertPane";
import "./AddEntityMember.scss";

export default function AddEntityMember({
        entity, 
        session, 
        memberships, 
        newMember, 
        setNewMember,
        setMemberships,
        setIsAdd,
        model}) {
    const [message, setMessage] = useState();

    const doAdd = async () => {
        if (!newMember) {
            return;
        }
        const {membership_type} = entity;
        let ms = membership_type && membership_type.length>0 && membership_type[0];
        let data = {
            registered_date: new Date().getTime(),
            update_by: session.id,
            membership_type: (ms && ms.membership_type) || '',
            period: {
                start_date: new Date().getTime(),
                end_date: moment().add((ms && ms.duration) || 1, (ms && ms.duration_type) || 'years'),
            },
            since: new Date().getTime(),
            id: entity.id,
            note: `add by ${UserModel.getMembershipName(session)}`,
            status: STATUS.Active
        };
        let result = await model.updateMembership({id: entity.id, member_id: newMember.id, data});
        if (result && result.id){
            let m = await UserModel.getUser(result.id);
            setMemberships([...memberships, m]);
            setIsAdd(false);
        }else {
            setMessage('error: Error updating membership.');
        }
    }

    return (
        <div className="AddEntityMember">
            <AlertPane message={message} />
            <AutocompleteTextFormInput
                label={`Select a member`}
                placeholder={`Search a member`}
                onChange={o => {
                    setMessage('');
                    if (!memberships.find(m => m.id===o.id)) {
                        return setNewMember(o);
                    }
                    setMessage('warning: Member already exist.');
                }}
                options={[]}
                optionsCallback={async search_string => {
                    let response = await UserModel.searchUser(search_string);
                    if (response){
                        return Object.values(response)
                                    .map(o => ({id: o.id, label:o.display})) || [];
                    }
                }} 
            />
            <div className="buttons">
                <IonButton onClick={doAdd}>Add</IonButton>
                <IonButton onClick={() => setIsAdd(false)}>Cancel</IonButton>
            </div>
            
        </div>
    );
}