import {IonContent, IonHeader, IonPage, IonSearchbar} from "@ionic/react";
import { isKeyEnter } from "../../components/Form/Form";

const SearchPage = () => {
    return <IonPage>
        <IonHeader>
            <IonSearchbar
                onIonInput={e => {
                    if (isKeyEnter(e)) {
                        console.log('Enter key pressed!');
                        // Add your code here to handle the Enter key press
                    }
                }}></IonSearchbar>
        </IonHeader>
        <IonContent>

        </IonContent>
    </IonPage>
}

export default SearchPage;