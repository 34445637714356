import { IonButton } from "@ionic/react";
import { TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SelectFormInput from "../FormInput/SelectFormInput";
import { STATUS } from "./Form";
import AlertPane from "../FormInput/AlertPane";
import "./MembershipInfo.scss";
import DateFormInput1 from "../FormInput/DateFormInput1";
import TextAreaFormInput from "../FormInput/TextAreaFormInput";

export default function MembershipInfo({info, onUpdate}) {
    const {control} = useForm();
    const [data, setData] = useState();
    const [message, setMessage] = useState();
    useEffect(() => {
        setData(info);
    }, [info]);

    const update = (value) => {
        let d = {...data, ...value};
        setData({...d});
    }
    
    const getStatus = () => {
        return data.status || 'P';
    }

    return !data? '' :
        <div className="MembershipInfo">
            <AlertPane message={message} />
            <div className="fields">
                <div className="status">
                    <SelectFormInput options={STATUS} label="Status" name="status"
                        value={getStatus()}
                        onChange={v => update({status: v})}
                    />
                    {/* <SelectFormInput options={[]} label="Membership Type" name="membership_type"
                        value={data.membership_level || ''}
                        onChange={v => update({membership_level: v})}
                    /> */}
                </div>
                <TextAreaFormInput
                    label="Notes"
                    value={data?.notes || ''}
                    name={`note`}
                    placeholder="add notes here...."
                    onChange={note => update({note})}
                />
            </div>
            <IonButton onClick={() => {
                setMessage('');
                onUpdate && onUpdate(data)
                .then(result => {
                    if (result && result.id){
                        setMessage('sucess: Successfully update membership.')
                    }else {
                        setMessage('error: Error updating membership.');
                    }
                });
                
            }}>Save</IonButton>
        </div>;
}