import { STATUS, hasTableFormChange, includesBlankOption, reloadPage } from "../Form/Form";
import { useRef, useState, useEffect } from "react";
import { RequestLeague, RequestUtils } from "../../serverUtils/requests";
import {
  Checkbox,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow, Tooltip
} from "@mui/material";
import TableFormInput, { ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/AlertPane";
import { GRAPPLING_TYPES } from "./Divisions";
import "../Form/Form.scss";
import MultiSelectFormInput, { includesAllOption } from "../FormInput/MultiSelectFormInput";
import { SelectGender, SelectGrapplingType } from "./WeightClasses";
import { CodeInput } from "./AgeGroups";
import Utils from "../../serverUtils/Utils";
import { RankSchema } from "../../serverUtils/Models";

export default function Ranks({ league }) {
  const [message, setMessage] = useState("");
  return (
    <div className={`Ranks sub_form`}>
      <AlertPane message={message} setMessage={setMessage} timeOut={5000}/>
      <RankTable league={league} setMessage={setMessage} />
    </div>
  );
}

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Rank Name",
    align: "left"
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    align: "left",
  },
  {
    id: "gi_ranks",
    disablePadding: false,
    label: "BJJ Belts",
  },
  {
    id: "grappling_types",
    disablePadding: false,
    label: "Grappling Types",
  },
  {
    id: "genders",
    disablePadding: false,
    label: "Genders",
  },
];

const alignCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const CRC_Fields = Utils.listObjectKeys(RankSchema().model);
const RankTable = ({ league, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const checkCRCMessageRef = useRef();
  const checkCRCMessageRef1 = useRef();
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const checkCRC = () => {
    checkCRCMessageRef.current.innerHTML = '';
    checkCRCMessageRef1.current.innerHTML = '';
    if (hasTableFormChange(league.getRanks(), CRC_Fields)) {
      checkCRCMessageRef.current.innerHTML = 'Data has changed';
      checkCRCMessageRef1.current.innerHTML = 'Data has changed';
      toolbarButtonsRef.current.setIndicator({save: 'yellow'});
      toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
    }else {
      toolbarButtonsRef.current.setIndicator({save: ''});
      toolbarButtonsRef1.current.setIndicator({save: ''});
    }
  }
  useEffect(() => league.getRanks && league.getRanks().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields))), []);

  const save = async () => {
    setMessage("");
    let selects = league.getRanks();
    if (selects.length === 0) {
      return setMessage("info: Nothing selected.");
    }
    if (selects.find((s) => !s.name || !s.code)) {
      return setMessage("error: Rank, code and status must be filled.");
    }
    for (let r of selects) {
      r.status = r.status || 'A';
      r.grappling_types = !r.grappling_types || r.grappling_types.length===0? ['gi']:r.grappling_types;
      r.genders = !r.genders || r.genders.length===0? ['M']:r.genders;

      let response;
      if (!r.id.startsWith("-1")) {
        response = await RequestLeague.updateLeagueRankRequest(r);
      } else {
        r.league = league.id;
        response = await RequestLeague.addLeagueRankRequest(r);
      }
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage("error: Updating rank " + r.name);
      } else if (!r.id) {
        r.id = response.id;
      }
      r.crc = Utils.getCRC(r, CRC_Fields);
    }
    tableRef.current.setSelected([]);
    setMessage("success: Successfully updated server.");
    checkCRC();
  };

  const deleteSelected = async () => {
    setMessage("");
    let selects = league
      .getRanks()
      .filter((r) => tableRef.current.selected.includes(r.id));
    if (selects.length === 0) {
      return setMessage("info: Nothing selected.");
    }
    for (let r of selects) {
      if (r.id.startsWith("-")) {
        continue;
      }
      let response = await RequestLeague.deleteLeagueRankRequest(r.id);
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage("error: Deleting rank " + r.name);
      }
    }
    let ranks = league
      .getRanks()
      .filter((r) => !tableRef.current.selected.includes(r.id));
    league.getRanks = () => ranks;
    tableRef.current.setSelected([]);
    setMessage("success: Successfully deleted from server.");
  };

  const doAdd = () => {
    let ranks = [...league.getRanks(), { id: `-${new Date().getTime()}`, status: 'A' }];
    league.getRanks = () => ranks;
    reloadPage(setRefresh, () => tableRef.current.gotoLastPage());
  };

  const toolbarButtons = ref => (
    <ToolbarButtons ref={ref} doAdd={doAdd} doSave={save} doDelete={deleteSelected} />
  );
  return (
    <TableFormInput name="Ranks"
      isEditable
      onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
      onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
      toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
      toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
      ref={tableRef}
      headCells={headCells}
      data={(league.getRanks && league.getRanks()) || []}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <TableRow
            className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
            hover
            onClick={(event) => {
              if (event.target.tagName.toUpperCase() === "INPUT") {
                return handleClick(event, row.id);
              }
            }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div style={alignCenter}>
                <Checkbox
                  color="primary"
                  checked={isItemSelected ? true : false}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
                <span>{index + 1}</span>
              </div>
            </TableCell>
            <TableCell>
              <Tooltip title={row.name}>
                <Input 
                  defaultValue={row.name}
                  onChange={e => {
                    row.name = e.target.value;
                    checkCRC();
                }}/>
              </Tooltip>
              
            </TableCell>
            <TableCell>
              <Select value={row.status || ""} 
                onChange={e => {
                  row.status = e.target.value;
                  setRefresh(!refresh);
                  checkCRC();
                }} 
                onClick={e => e.stopPropagation()}>
                {includesBlankOption(STATUS).map((s, i) => <MenuItem key={i} value={s.value}>
                    {s.label}
                  </MenuItem>)}
              </Select>
            </TableCell>
            <TableCell>
              <CodeInput row={row} list={league.getRanks()} checkCRC={checkCRC}/>
            </TableCell>
            <TableCell>
              <MultiSelectFormInput
                multiple
                value={(row.gi_ranks || []).map(r => GI_LEVEL_OPTIONS.find(l => l.value === r))}
                name="gi_ranks"
                label="Allowed BJJ Belts"
                optionLabel="label"
                optionValue="value"
                fetchOptions={searchValue => {
                  searchValue = searchValue.toLowerCase();
                  return Promise.resolve(includesAllOption(GI_LEVEL_OPTIONS)
                    .filter(r => !searchValue || r.label.toLowerCase().includes(searchValue)));
                }}
                onChange={v => {
                  row.gi_ranks = v.map(v => v.value);
                  checkCRC();
                }}
              />
            </TableCell>
            <TableCell>
              <SelectGrapplingType row={row} setRefresh={() => setRefresh(!refresh)} checkCRC={checkCRC}/>
            </TableCell>
            <TableCell>
              <SelectGender row={row} setRefresh={() => setRefresh(!refresh)} checkCRC={checkCRC}/>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
};

export const getGrapplingType = (grappling_types) => {
  let types = grappling_types || [];
  if (types.length === 2) {
    return 'both';
  }
  if (types.length > 0) {
    return types[0];
  } 
  return GRAPPLING_TYPES[0].value;
}

export const GI_LEVEL_OPTIONS = [
  { value: "WHT", label: "White" },
  { value: "GRYw", label: "Grey-white" },
  { value: "GRY", label: "Grey" },
  { value: "GRYb", label: "Grey-black" },
  { value: "YELw", label: "Yellow-white" },
  { value: "YEL", label: "Yellow" },
  { value: "YELb", label: "Yellow-black" },
  { value: "ORGw", label: "Orange-white" },
  { value: "ORG", label: "Orange" },
  { value: "ORGb", label: "Orange-black" },
  { value: "GRNw", label: "Green-white" },
  { value: "GRN", label: "Green" },
  { value: "GRNb", label: "Green-black" },
  { value: "BLU", label: "Blue" },
  { value: "PUR", label: "Purple" },
  { value: "BRN", label: "Brown" },
  { value: "BLK", label: "Black" },
  { value: "REDb", label: "Red-black" },
];
