import "./Help.scss";
import { useEffect, useRef } from "react";
import { useStore } from '../../Store';
import { default as CloseIcon} from '@mui/icons-material/CloseOutlined';

const Help = ({}) => {
    const isHelp = useStore(state => state.isHelp);
    const setIsHelp = useStore(state => state.setIsHelp);
    const divRef = useRef();
    const isMouseDown = useRef(false);

    useEffect(() => {

    }, []);

    const mouseDown = e => {
        isMouseDown.current = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    const handleMouseUp = e => {
        isMouseDown.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }

    const handleMouseMove = e => {
        if (isMouseDown) {
            const {x, width} = divRef.current.getBoundingClientRect();
            let w = Math.floor((x - e.clientX) + width);
            console.log(w);
            divRef.current.style.width = `${w}px`;
        }
    }

    return !isHelp? '': 
        <div className="Help main" ref={divRef}>
            <div className="close" onClick={() => setIsHelp()}><CloseIcon /> Close</div>
            <div className="resize-divider" onMouseDown={mouseDown}/>
            <div className="body">
            <h5>Help Title</h5>  
            <div className="help-content">
                Content
            </div>
            </div>
        </div>;
}

export default Help;