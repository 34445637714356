import { IonButtons, IonIcon } from "@ionic/react";
import { paperPlaneOutline as sendMessageIcon } from "ionicons/icons";
import "./PageRequestButtons.scss";


export function PageRequestButtons({ session, onClick, register, claimProfile }) {

    return session && (
        <IonButtons className="PageRequestButtons">
            {onClick && <button className="button"
                onClick={onClick}>
                <IonIcon icon={sendMessageIcon} />
                Send Us a message
            </button>}
            {register}
            {claimProfile}
        </IonButtons>
    )
}