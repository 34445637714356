import {
  IonContent, IonPage,
  useIonViewWillEnter
} from "@ionic/react";
import LoginForm from "../../components/LoginForm/LoginForm";
import "./LoginPage.scss";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import { useHistory } from "react-router-dom";
import UserModel from "../../serverUtils/models/UserModel";
import { useStore } from "../../Store";
import { useEffect, useRef, useState } from "react";
import { Link } from "@mui/material";
import AlertPane from "../../components/FormInput/AlertPane";
import Utils from "../../serverUtils/Utils";
import { RequestUtils } from "../../serverUtils/requests";

export const MODE = {
  login: 0,
  sign_up: 1,
  reset_password: 2,
  email_confirmation: 3,
  password_recovery: 4,
}

const LoginPage = () => {
  const history = useHistory();
  const localServer = useStore((state) => state.local_server);
  const setSession = useStore((state) => state.setSession);
  const session = useStore((state) => state.session);
  const [mode, setMode] = useState(0);
  const [loginMode, setLoginMode] = useState();
  const [message, setMessage] = useState();
  const loginFormRef = useRef();

  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.forget_password) {
      setTimeout(() => loginFormRef.current.doForgetPassword(), 1000);
    }else if (params.sign_up) {
      setTimeout(() => signUp(), 1000);
    }else {
      setMode(MODE.login);
    }
  }, []);

  useEffect(() => {
    document.location.pathname.includes('/login') && session && history.push('/dashboard');
  }, [session]);

  useIonViewWillEnter(() => {
    if (document.location.pathname.includes("/email_confirmation")) {
      const confirmEmail = async () => {
        setMode(MODE.email_confirmation);
        let id = document.location.pathname.split('/');
        id = decodeURIComponent(id[id.length - 1]);
        let result = await UserModel.confirmValidationEmail(id);
        if (!result || result.error) {
          setMessage('error: Error confirming your email');
        }
      }
      return confirmEmail();
    }

    if (document.location.pathname.includes("/reset_password")) {
      const sendTemporaryPassword = async () => {
        setMode(MODE.reset_password);
        let id = document.location.pathname.split('/');
        id = decodeURIComponent(id[id.length - 1]);
        let result = await UserModel.sendTemporaryPassword(id);
        if (!result || result.error) {
          setMessage('error: Error resetting your password');
        }
      }
      return sendTemporaryPassword()
    }
    if (document.location.pathname.includes("/logout")) {
      Utils.deleteCookie('gih_session');
      history.push("/login");
      return UserModel.logout().then(() => {
        setSession(null);
      });
    }
    if (session) {
      if (!localServer) {
        history.push("/dashboard");
      } else {
        history.push("/tournaments");
      }
    } else if (document.location.pathname.includes("/login")) {
      setMode(MODE.login);
    }
  }, [session]);

  const signUp = () => {
    RequestUtils.insertURLParam('sign_up', true, history, true);
    setMode(MODE.sign_up);
  }

  return (
    <IonPage >
      {/*<IonHeader></IonHeader>*/}
      <IonContent className="LoginPage">
        <div className="page">
          {mode === MODE.email_confirmation &&
            <div className="login">
              <h1 className="title" id="login">Email Confirmation</h1>
              <AlertPane message={message || `success: Your email has been confirmed.`} />
              <button className="button" onClick={() => setMode(MODE.login)}>
                Login
              </button>
            </div>}
          {mode === MODE.reset_password &&
            <div>
              <h1 className="title" id="login">Reset Password</h1>
              <AlertPane message={message || `success: A temporary password has been send to your register email.`} />
              <button className="button" onClick={() => setMode(MODE.login)}>Login</button>
            </div>}
          {mode === MODE.login &&
            <div className="or-signup">
              <h1 className="title" id="login">
                {loginMode !== MODE.password_recovery? 'Login':'Password Recovery'}
              </h1>
              <LoginForm loginMode={m => setLoginMode(m)} ref={loginFormRef}/>
              {!localServer && <div>
                <p className="divider">Or</p>
                <div className="sign-up">
                  <Link style={{ cursor: 'pointer' }}
                    onClick={signUp}>Sign Up</Link>
                </div>
              </div>}
            </div>}
          {!localServer && mode === MODE.sign_up &&
            <div >
              <h1 className="title" id="signup">Sign Up</h1>
              <SignUpForm onCancel={() => {
                history.push('/login');
                setMode(MODE.login);
              }} />
            </div>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
