import { forwardRef, useImperativeHandle, useRef } from "react";
import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import { useState } from "react";
import ImageModel from "../../serverUtils/models/ImageModel";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { default as DeleteIcon } from "@mui/icons-material/RemoveCircle";
import { default as AddIcon } from "@mui/icons-material/CreateNewFolderOutlined";
import { default as ResetIcon } from "@mui/icons-material/BackspaceRounded";
import Utils from "../../serverUtils/Utils";

const ImageFormInput = forwardRef(({
  name,
  images,
  isHideButton,
  getFileName,
  onImage,
  readDataType,
  maxWidth = 100,
  size: scaleSize = 300,
  multiple = false,
}, ref) => {
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState(false);
  const fileInputRef = useRef();
  const imageListPaneRef = useRef();

  useImperativeHandle(ref, () => ({
    refresh: (imgs) => {
      imageListPaneRef.current.setImages(imgs);
    },
  }));

  const onFileChange = (e) => {
    setLoading(true);
    let fImages = [];
    const { files } = e.target;
    Array.from(files).forEach((file, i) => {
      let fileName = getFileName ? getFileName(file.name) : file.name;
      let reader = new FileReader();
      reader[readDataType || "readAsDataURL"](file);
      reader.addEventListener(
        "load",
        async () => {
          let resized = await ImageModel.resizeImage(
            reader.result,
            scaleSize,
            document.getElementById(fileName)
          );
          fImages.push({ data: [resized], fileName });
          if (i === files.length - 1) {
            if (!multiple) {
              Utils.resetArray(images);
            }
            fImages.forEach(i => images.push(i));
            if (onImage) {
              onImage(multiple ? images : fImages[0]);
            }
            setRefresh(!refresh);
          }
          setLoading(false);
        },
        false
      );
    });
  };

  return (
    <div className="FileFormInput">
      {!images || loading ? <LoadingScreen /> :
        <ImageListPane
          ref={imageListPaneRef}
          images={images}
          multiple={multiple}
          maxWidth={maxWidth}
          onImage={onImage}
        />
      }

      {!isHideButton && (
        <div>
          <Tooltip title="Add from local disk">
            <AddIcon
              onClick={() => {
                fileInputRef.current.click();
              }}
            />
          </Tooltip>
          <input
            ref={fileInputRef}
            hidden
            accept="image/*"
            multiple={multiple}
            type="file"
            id={name}
            onChange={onFileChange}
          />
          {images && images.length > 0 && (
            <Tooltip title="Clear all images">
              <ResetIcon
                style={{ marginLeft: 4, color: "red" }}
                onClick={() => {
                  let imgs = images.filter(i => i.id).map(i => i.delete = true)
                  onImage(imgs);
                  imageListPaneRef.current.setImages(imgs);
                }}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
})

const ImageListPane = forwardRef(({ images: _images, multiple, maxWidth, onImage }, ref) => {
  const [images, setImages] = useState(_images);
  useState(() => {
    setImages(_images);
  }, [_images]);
  useImperativeHandle(ref, () => ({ images, setImages }));

  const getImageKey = (img) => (img && img.id) || (img && img.fileName) || new Date().getTime();
  if (multiple) {
    return (
      <ImageList variant="quilted" cols={2}>
        {images && images
          .filter((img) => img && !img.delete)
          .map((img, i) => {
            return (
              <ImageListItem key={i}>
                <DeleteIcon
                  onClick={() => {
                    if (img.id) {
                      img.delete = true;
                    } else {
                      _images.splice(i, 1);
                    }
                    onImage(_images);
                    setImages([..._images]);
                  }}
                />
                <Tooltip title={img && img?.fileName}>
                  <img key={getImageKey(img)}
                    src={img && img.data && img.data.join && img.data.join("")}
                    id={img && img.fileName}
                    alt={img && img.fileName}
                    style={{ maxWidth, minWidth: maxWidth }}
                  />
                </Tooltip>
              </ImageListItem>
            );
          })}
      </ImageList>
    );
  }
  let img = images[0];
  let key = getImageKey(img);
  let src = img?.data?.join("");
  return (
    <img key={key}
      src={src}
      id={img?.fileName}
      alt={img?.fileName}
      style={{ maxWidth, minWidth: maxWidth }}
    />
  );
});

export default ImageFormInput;
