import { Avatar, Link, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider } from "@mui/material";
import Theme from "../../components/FormInput/Theme";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import "./Points.scss";

export default function Points({ tournament }) {
    let places = (tournament.getPoints && tournament.getPoints()) || [];
    return <ThemeProvider theme={Theme}>
        <div className="Points">
            <Table aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell>Place</TableCell>
                        <TableCell align="left">Team</TableCell>
                        <TableCell align="center">1st</TableCell>
                        <TableCell align="center">2nd</TableCell>
                        <TableCell align="center">3rd</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {places.filter(i => i.type === 'Teams').map((row, i) => {
                        return (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {TournamentModel.renderPlacement(row.place)}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="team">
                                        <Link href={`/teams/${row.id}`}>
                                            <Avatar src={row.getImage()}/>
                                        </Link>
                                        <div className="name">{row.name}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center">{row.firsts}</TableCell>
                                <TableCell align="center">{row.seconds}</TableCell>
                                <TableCell align="center">{row.thirds}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    </ThemeProvider>
}