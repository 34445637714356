import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import { useEffect, useState } from "react";
import LoadingList from "../../components/LoadingList/LoadingList";
import Utils from "../../serverUtils/Utils";
import Theme from "../../components/FormInput/Theme";
import UserModel from "../../serverUtils/models/UserModel";


export default function LeagueStandings({league}) {
    const [data, setData] = useState(null);
    const [standingSelected, setStandingSelected] = useState();
    const standingTypes = ['teams', 'gyms', 'individuals'];
    useEffect(() => {
        LeagueModel.getStandings(league.id)
            .then(data => {
                const { standings, memberships_doc } = data;
                data.standings = JSON.parse(standings);
                const { individuals } = data.standings;
                individuals.forEach(m => m.name = UserModel.getMembershipName(memberships_doc.find(md => md.id === m.id)));
                data.standings.individuals = individuals.filter(i => i.name);
                setStandingSelected(standingTypes[0]);
                setData(data);
            });
    }, [league]);

    return !data || !standingSelected ? <LoadingScreen /> :
        <ThemeProvider theme={Theme}>
            <div className="LeagueStandings" style={{ padding: Utils.isMobile() ? 2 : 10 }}>
                {standingTypes
                    .map((t, i) => <Chip
                        key={i}
                        className="filter"
                        label={Utils.capitalize(t)}
                        variant={t === standingSelected ? 'filled' : 'outlined'}
                        color={t === standingSelected ? 'primary' : undefined}
                        onClick={() => {
                            setStandingSelected(t);
                        }}
                    />)}
                <Standings data={data.standings[standingSelected]} />
            </div>
        </ThemeProvider>;
}

const Standings = ({ data }) => {
    const [divNames, setDivNames] = useState();
    const [divSelected, setDivSelected] = useState();
    const [standings, setStandings] = useState();

    useEffect(() => {
        if (divSelected) {
            let s = data[divSelected];
            if (Array.isArray(divSelected)) {
                s = data;
            }
            s.sort((a, b) => Utils.sorter(b, a, 'points'));
            setStandings(s);
        }
    }, [divSelected]);

    useEffect(() => {
        if (!data) {
            return;
        }
        if (Array.isArray(data)) {
            setDivNames([]);
            return setDivSelected(data);
        }

        let divs = Object.keys(data);
        setDivNames(divs);
        if (divs.length > 0) {
            setDivSelected(divs[0]);
        }
    }, [data]);

    return <div className="Standings" style={{ padding: Utils.isMobile() ? 4 : 20 }}>
        {divNames && divNames.map((d, i) => {
            return <Chip
                key={i}
                className="filter"
                label={d}
                variant={d === divSelected ? 'filled' : 'outlined'}
                color={d === divSelected ? 'primary' : undefined}
                onClick={() => {
                    setDivSelected(d);
                }}
            />;
        })}
        <StandingTable standings={standings} />
    </div>
}

const StandingTable = ({ standings }) => {
    if (!standings) {
        return <LoadingList />;
    }
    return <TableContainer component={Paper} className="StandingTable" style={{ marginTop: 20 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="center"><b>Rank</b></TableCell>
                    <TableCell><b>Name</b></TableCell>
                    <TableCell align="center"><b>Points</b></TableCell>
                    <TableCell align="center"><b>1st</b></TableCell>
                    <TableCell align="center"><b>2nd</b></TableCell>
                    <TableCell align="center"><b>3rd</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {standings.map((s, i) => {
                    return <TableRow key={i}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{s.name}</TableCell>
                        <TableCell align="center">{s.points}</TableCell>
                        <TableCell align="center">{s.firsts}</TableCell>
                        <TableCell align="center">{s.seconds}</TableCell>
                        <TableCell align="center">{s.thirds}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>;
}