import { RequestGym, RequestUser, RequestUtils, RequestStore, RequestImage, RequestCommon } from "../requests";
import Utils from "../Utils";
import { STATUS } from "../../components/Form/Form";
import { BAN } from "./TournamentModel";
import { IMAGE_TYPE } from "../../components/Form/Form";
import TournamentModel from "./TournamentModel";
import UserModel from "./UserModel";
import TeamModel from "./TeamModel";

export default class GymModel {
    static getGymsbyCountry = async (country) => {
        const response = await RequestGym.getGymsByCountry(country);
        return RequestUtils.getResponseData(response);
    };

    static mergeMemberships = async (tid, fid) => {
        const response = await RequestUser.mergeMemberships(tid, fid);
        return RequestUtils.getResponseData(response);
    }

    static getGyms = async ({page, page_size, search_string}) => {
        const response = await RequestGym.getGymsRequest({page, page_size, search_string, search_field: 'name'});
        return RequestUtils.getResponseData(response);
    };

    static async updateMembership(params) {
        let response = await RequestGym.updateGymMembership(params);
        return RequestUtils.getResponseData(response);
    }

    static async membershipRegistration(id, member_id) {
        let response = await RequestGym.registerGym(id, member_id);
        return RequestUtils.getResponseData(response);
    }

    static async getGymDisplay(ids) {
        let isMulti = Array.isArray(ids);
        let response = await RequestGym.getDisplayGyms(isMulti? [ids]:[ids]);
        let result = RequestUtils.getResponseData(response); 
        if (isMulti){
            return result;
        }
        return result[0];
    }

    static async addGym(params) {
        let response = await RequestGym.addGymRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async updateGym(params) {
        let response = await RequestGym.updateGymRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteGym(id) {
        let response = await RequestGym.deleteGymRequest(id);
        return RequestUtils.getResponseData(response);
    }
    static async getMemberships({id, search_string, search_field, page, page_size}) {
        let response = await RequestGym.getGymMembershipsRequest({id, search_string, search_field, page, page_size});
        return UserModel.formatResult(RequestUtils.getResponseData(response));
    }

    static async getStoreItems(id) {
        if (!id) {
            return [];
        }
        let response = await RequestStore.getStoreItemsRequest({ref: id, ref_type: 'gym'});
        let store = RequestUtils.getResponseData(response);
        const {store_items, images_doc} = store;
        return store_items.map(si => {
            si.getImages = () => images_doc.filter(i => i.ref === si.id);
            return si;
        });
    }

    static async getPhotos(params) {
        let response = await RequestImage.getImagesByRefRequest(
            {...params, ids: [params.id], image_type: IMAGE_TYPE.photo});
        return RequestUtils.getResponseData(response)
    }

    static async searchGym(search_string='') {
        const response = await RequestGym.searchGymRequest({search_string});
        let res = RequestUtils.getResponseData(response);
        return res.filter(r => r && r.display).map(r => {
            let words = r.display.split(',');
            return {id: r.id, display: words.map(w => w.trim()).filter(w => w).join(', ')};
        });
    }

    static formatResult(data) {
        let docs = {...data};
        let { gyms } = { ...data };
        gyms && gyms.forEach(gym => {
            gym.canRegister = session => session && session.gyms.find(g => g.id===gym.id);
            gym.getTransactions = () => docs.gym_transactions_doc;
            gym.getImage = () => docs.images_doc.find(image => image && image.id && image.ref === gym.id && image.image_type === IMAGE_TYPE.gym);
            gym.setImage = img => docs.images_doc.push(img);
            gym.getAdmins = () => TeamModel.getUsers(docs.memberships_doc, gym.admins, docs.images_doc);
            gym.getOwner = () => docs.memberships_doc.find(m => m.id===gym.owner);
            gym.getCreatedBy = () => docs.memberships_doc.find(m => m.id===gym.created_by);
            gym.getHeadCoaches = () => TeamModel.getUsers(docs.memberships_doc, gym.head_coaches, docs.images_doc);
            gym.getExecutives = () => TeamModel.getUsers(docs.memberships_doc, gym.executives, docs.images_doc);
            gym.getTeams = () => {
                let teamIds = gym.teams.filter(t => t.status === STATUS.Active).map(t => t.id);
                return docs.teams_doc.filter(t => teamIds.includes(t.id));
            };
            gym.getTeam = () => {
                if (gym.teams && gym.teams.length > 0) {
                    let actives = gym.teams.filter(t => t.status===STATUS.Active).map(t => t.id);
                    if (actives.length > 0) {
                        let team = docs.teams_doc.filter(t => actives.includes(t.id)).sort((a, b) => Utils.sorter(a, b, 'registered_on')).pop();
                        return team && docs.teams_doc.find(t => t.id === team.id);
                    }
                }
            };
            gym.getLeagues = () => {
                return docs.leagues_doc;
            }
            gym.getMessages = () => docs.messages_doc.filter(m => m.from === gym.id);
            gym.getBanMemberships = () => this._getBans(gym.id, docs.memberships_doc, docs.bans_doc, BAN.membership);
            gym.getFee = () => `${gym.currency_code} ${Utils.toFloatString(gym.registration_fee, 2)}`;
            gym.getCurrency = () => gym.currency_code;
        });
        return gyms;
    }

    static getGym = async (id) => {
        let response = await RequestGym.getGymRequest(id);
        let data = RequestUtils.getResponseData(response);
        if (data) {
            let gyms = GymModel.formatResult(data);
            if (gyms?.length > 0){
                return gyms[0];
            }
        }
        return {id, error: response? response.data.errors:'server error'};
    }

    static async getMessages(params) {
        const response = await RequestGym.getGymMessagesRequest(params);
        let data = RequestUtils.getResponseData(response);
        const { memberships_doc, messages, images_doc } = data;
        messages.forEach(message => {
            message.getFromDoc = () => {
                let membership = memberships_doc.find(m => m.id === message.from);
                if (membership) {
                    membership.getImage = () => images_doc.find(i => i && i.ref === membership.id);
                    return membership;
                }
            }
        });
        return messages;
    }

    static async sendMessage(params){
        delete params.id;
        params.message_type = 'gym';
        let response = await RequestGym.sendMessageRequest(params);
        if (response.status === 200 && !response.data.errors) {
            return response.data.data.addMessage;
        }
    }

    static async disassociateMembership(params) {
        params.registration_type = 'gyms';
        let response = await RequestUser.disassociateGymMembershipRegistrationRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async getGymInfo(id) {
        if (!id) {
            return;
        }
        const response = await RequestGym.getGymInfo(id);
        return RequestUtils.getResponseData(response);
    }
}
