

import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import { useEffect, useState } from "react";


export default function GymStandings({gym}) {
    const [data, setData] = useState(null);

    useEffect(() => {
        gym?.getTeam()?.league && LeagueModel.getStandings(gym?.getTeam()?.league)
            .then(standings => {
                setData(standings);
            });
    }, [gym]);

    return !data? <LoadingScreen /> : (
        <div>
            {JSON.stringify(data)}
        </div>
    )
}