import { useEffect, useState } from "react";
import { useStore } from "../../Store";
import { initStation } from "./LocalServer";
import { useHistory } from 'react-router-dom';
import LocalServerModel from "../../serverUtils/models/LocalServerModel";
import Utils from "../../serverUtils/Utils";

let sse;
export default function LocalStation({}) {
  const station = useStore(state => state.station);
  const setStation = useStore(state => state.setStation);
  const [redirect, setRedirect] = useState();
  const history = useHistory();
  const localServer = useStore((state) => state.local_server);
  useEffect(() => {
    if (station && station.type) {
      station.type === LocalServerModel.STATION_TYPE.not_set && initSSE();
      history.push(`/local/${station.type}`);
    }
  }, [station]);

  const initSSE = async () => {
    sse && sse.close();
    sse = initStation({
      localServer,
      receive: message => {
        message.tournament_id && Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament, message.tournament_id);
        if (station && message.to === station.station) {
          if (LocalServerModel.setCurrentStation(message, station, setStation, setRedirect)) {
            sse.close();
          }
        } else if (station && message.to === LocalServerModel.LOCAL_SERVER.ping) {
          LocalServerModel.sendMessage({
            from: station.station,
            label: station.label,
            type: station.type
          });
        }
      }
    });
  };

  return redirect? redirect :
    <div style={{ padding: 40 }}>
      <h1>Station is not assigned.  Waiting for Master station for assignment.</h1>
    </div>;
}
