import SelectFormInput from "../FormInput/SelectFormInput";
import TextFormInput from "../FormInput/TextFormInput";
import { RemoveSubFormIcon, SOCIAL_MEDIA_TYPE } from "./Form";
import "./SocialMedias.scss";

export default function SocialMedias({ data, register, errors, refresh, setValue }) {
  return data? <div className={`SocialMedias ${data.length===0? 'empty':''}`}>
    {(data || []).map((s, i) => (
      <div key={i} id={`social_medias_${i}`}>
        <div className="socialMediaWrapper">
          <SelectFormInput width={200}
            name={`social_medias.${i}.name`}
            value={s.name}
            label="Social Media"
            setValue={setValue}
            placeholder="Social Media"
            options={SOCIAL_MEDIA_TYPE}
            errors={errors && errors[i]?.name}
          />
          <TextFormInput
            name={`social_medias.${i}.url`}
            value={s.url}
            register={register} setValue={setValue}
            label="URL"
            errors={errors && errors[i]?.url}
          />
        </div>
        <RemoveSubFormIcon onClick={() => {
          data.splice(i, 1);
          refresh();
        }} label={`Social Media ${i + 1}`} />
      </div>
    ))}
  </div> : '';
}
