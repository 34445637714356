import { IonAvatar, IonChip, IonRouterLink } from "@ionic/react";
import { RequestUser, RequestUtils } from "../../serverUtils/requests";
import { useEffect, useState, useRef } from "react";
import { ThemeProvider } from "@emotion/react";
import Theme from "../FormInput/Theme";
import { useStore } from "../../Store";
import Utils from "../../serverUtils/Utils";
import { Tooltip } from "@mui/material";

export const getUserChip = async (userId) => {
    const response = await RequestUser.getUserRequest(userId);
    return RequestUtils.getResponseData(response)[0];
}

export const renderAvatar = (membership) => {
    if (membership && membership?.getImage && membership.getImage()?.data) {
        return <img
            src={membership.getImage().data.join('')}
            alt="user"
        />;
    }
    return <img
        alt="user"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"
    />;
}

const UserChip = ({ id, membership, onClick, onDoubleClick, title='', nameClass='' }) => {
    const session = useStore(state => state.session);
    const timer = useRef()
    const [data, setData] = useState(null);

    const getData = async (userId) => {
        const userChip = await getUserChip(userId);
        if (userChip){
            setData(userChip);
        }
    }

    useEffect (() => {
        id && getData(id);
    }, [id]);

    let m = data || membership;
    return m? <IonRouterLink href={onClick ? undefined : (m && `/users/${m.id}`)}>
        <ThemeProvider theme={Theme}>
            <IonChip
                onClick={e => {
                    e.stopPropagation();
                    if (onClick) {
                        clearTimeout(timer.current);
                        if (e.detail === 1) {
                            timer.current = setTimeout(onClick, 200);
                        } else if (e.detail === 2) {
                            onDoubleClick && onDoubleClick();
                        }
                    }
                }}
            >

                <IonAvatar style={{ minWidth: 24 }}>
                    {renderAvatar(m)}
                </IonAvatar>
                {m &&
                    <span id={m.id} className={nameClass}>
                        <Tooltip title={title}>
                            <span>{m.getName ? m.getName() : `${m.first_name} ${m.last_name}`}</span>
                        </Tooltip>
                    </span>} 
                    
                {m && session && session.is_super && 
                    <b style={{marginLeft: 10, color: 'blue'}}>
                        <Tooltip title={m.id||''}>
                            <div className="super-info">
                                {[m.status, Utils.formatDateTime(m.created_on, 'M/D/YYYY hh:mma')].filter(e => e).join('-')}
                            </div>
                        </Tooltip>
                    </b>}
            </IonChip>
        </ThemeProvider>
    </IonRouterLink> : '';
}

export default UserChip;