import { createTheme } from "@mui/material";


const Theme = createTheme({
    palette: {
        text: {
            primary: '#000000', // Set primary text color to white
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    minWidth: 'xs',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root': {
                        fontFamily: 'Inter, sans-serif',
                    },
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 10,
                    },
                    '& .MuiInputBase-input': {
                        color: 'black', // Change input text color here
                        fontFamily: 'Inter, sans-serif',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, sans-serif',
                    borderRadius: 10,
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, sans-serif',
                }
            }
        }
    },
});

export default Theme;
