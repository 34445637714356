import {useEffect, useState} from "react";
import ImageModel from "../../serverUtils/models/ImageModel";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {IonImg} from "@ionic/react";

export default function Image({image: _image, className, size}) {
    const [image, setImage] = useState(_image);
    useEffect(() => {
        if (_image) {
            ImageModel.getImageById(_image.id, size).then(setImage);
        } else {
            setImage(null);
        }
    }, [_image]);

    return !image? <LoadingScreen /> :
        <IonImg className={className} src={image && image?.data && image?.data.join('')}/>;
}