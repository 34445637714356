import {IonItem, IonItemGroup, IonList, IonSkeletonText, IonTitle} from "@ionic/react";
import styles from './LoadingList.module.scss';

const LoadingList = () => <IonList>
    <IonItem lines="none">
        <IonItemGroup>
            <IonSkeletonText animated className={styles.loadingImage}/>
        </IonItemGroup>
        <IonItemGroup>
            <IonTitle><IonSkeletonText animated className={styles.loadingName}/></IonTitle>
            <IonTitle><IonSkeletonText animated className={styles.loadingDate}/></IonTitle>
            <IonTitle><IonSkeletonText animated
                                       className={styles.loadingLocation}/></IonTitle>
        </IonItemGroup>
    </IonItem>
    <IonItem lines="none">
        <IonItemGroup>
            <IonSkeletonText animated className={styles.loadingImage}/>
        </IonItemGroup>
        <IonItemGroup>
            <IonTitle><IonSkeletonText animated className={styles.loadingName}/></IonTitle>
            <IonTitle><IonSkeletonText animated className={styles.loadingDate}/></IonTitle>
            <IonTitle><IonSkeletonText animated
                                       className={styles.loadingLocation}/></IonTitle>
        </IonItemGroup>
    </IonItem>
    <IonItem lines="none">
        <IonItemGroup>
            <IonSkeletonText animated className={styles.loadingImage}/>
        </IonItemGroup>
        <IonItemGroup>
            <IonTitle><IonSkeletonText animated className={styles.loadingName}/></IonTitle>
            <IonTitle><IonSkeletonText animated className={styles.loadingDate}/></IonTitle>
            <IonTitle><IonSkeletonText animated
                                       className={styles.loadingLocation}/></IonTitle>
        </IonItemGroup>
    </IonItem>
    <IonItem lines="none">
        <IonItemGroup>
            <IonSkeletonText animated className={styles.loadingImage}/>
        </IonItemGroup>
        <IonItemGroup>
            <IonTitle><IonSkeletonText animated className={styles.loadingName}/></IonTitle>
            <IonTitle><IonSkeletonText animated className={styles.loadingDate}/></IonTitle>
            <IonTitle><IonSkeletonText animated
                                       className={styles.loadingLocation}/></IonTitle>
        </IonItemGroup>
    </IonItem>
</IonList>;

export default LoadingList;