import { useState, useEffect, forwardRef } from "react";
import {
  IonContent, IonPage, useIonViewWillEnter
} from "@ionic/react";
import classNames from "classnames";
import pageStyles from "../Page.module.scss";
import TeamModel from "../../serverUtils/models/TeamModel";
import TeamInformationForm from "../../components/TeamForm/TeamInformationForm";
import UserModel from "../../serverUtils/models/UserModel";
import TeamStoreItems from "./TeamStoreItems";
import { RequestUtils } from "../../serverUtils/requests";
import { useStore } from "../../Store";
import { Link } from "@mui/material";
import { Tabs } from "../../components/Tabs/Tabs";
import { ENTITY } from "../../serverUtils/Models";
import TeamMemberships from "../TeamPage/TeamMemberships";

const TABS = [
  { html: "Information", url: 'information' },
  { html: "Memberships", url: 'memberships' },
  // { html: "Transactions", url: 'transactions' },
  { html: "Store Items", url: 'store_items' },
];

const TeamEditPage = forwardRef((props, ref) => {
  const session = useStore((state) => state.session);
  const [team, setTeam] = useState(null);
  const [tabs, setTabs] = useState(TABS);
  const [sectionIndex, setSectionIndex] = useState();

  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(TABS.findIndex(t => t.url === params.tab));
    }
  }, []);

  const getData = async (id) => {
    if (!id) {
      return;
    }
    if (id === "add") {
      setTabs(TABS.slice(0, 1));
      setSectionIndex(0);
      return setTeam(UserModel.addSessionAsAdmin(session));
    }
    let team = await TeamModel.getTeam(id);
    if (team) {
      UserModel.isAdmin(session, ENTITY.team, team.id) && setTeam(team);
    }
  };

  let urlId = RequestUtils.getURLId("teams");
  useEffect(() => {
    if (urlId === 'add') {
      setTeam(null);
    }
  }, [urlId]);

  useEffect(() => {
    if (session) {
      getData(urlId);
    }
  }, [session]);

  useIonViewWillEnter(() => {
    session && getData(RequestUtils.getURLId("teams"));
  }, [session]);

  return (
    <IonPage>
      {team && (
        <IonContent
          className={classNames(pageStyles.pageContent)}
        >
          <h1 id="team_page" className="display-font">
            {props.match.params.id ? "Edit" : "New"} Team
          </h1>
          {team && team.id && <Link href={`/teams/${props.match.params.id}`}>
            {team && team.name}
          </Link>}
          <Tabs
            index={sectionIndex}
            tabs={tabs}
            sections={[
              <TeamInformationForm team={team} />,
              <TeamMemberships team={team} />,
              // <div>Transactions</div>,
              <TeamStoreItems team={team} session={session} />
            ]}
          />
        </IonContent>
      )}
    </IonPage>
  );
});

export default TeamEditPage;
