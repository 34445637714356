import { IonButton, IonIcon, IonList } from "@ionic/react";
import { useEffect, useState } from "react";
import LoadingList from "../../components/LoadingList/LoadingList";
import TeamModel from "../../serverUtils/models/TeamModel";
import UserModel from "../../serverUtils/models/UserModel";
import pageStyles from "./../Page.module.scss";
import {
    addCircle as addIcon,
} from "ionicons/icons";
import AutocompleteTextFormInput from "../../components/FormInput/AutocompleteTextFormInput";
import UserChip from "../../components/UserChip/UserChip";
import MembershipInfo from "../../components/Form/MembershipInfo";
import moment from "moment";
import { STATUS } from "../../components/Bracket/Bracket";
import TextFormInput from "../../components/FormInput/TextFormInput";
import { useStore } from "../../Store";
import "./TeamMemberships.scss";
import AlertPane from "../../components/FormInput/AlertPane";
import { ENTITY } from "../../serverUtils/Models";
import AddEntityMember from "../../components/Form/AddEntityMember";
import { FilterInput } from "../../components/Form/Form";

export default function TeamMemberships({team}) {
    const session = useStore(state => state.session);
    const [newMember, setNewMember] = useState();
    const [memberships, setMemberships] = useState();
    const [isAdd, setIsAdd] = useState();
    const [message, setMessage] = useState();
    const [filter, setFilter] = useState('');
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        team && TeamModel.getMemberships({id:team.id, search_string: filter})
            .then(memberships => {
                setMemberships(memberships);
            });
        setIsEditable(document.location.href.includes('/edit'));
    }, [team, filter]);

    const doAdd = async () => {
        if (!newMember) {
            return;
        }
        const {membership_type} = team;
        let ms = membership_type && membership_type.length>0 && membership_type[0];
        let data = {
            registered_date: new Date().getTime(),
            update_by: session.id,
            membership_type: (ms && ms.membership_type) || '',
            period: {
                start_date: new Date().getTime(),
                end_date: moment().add((ms && ms.duration) || 1, (ms && ms.duration_type) || 'years'),
            },
            since: new Date().getTime(),
            id: team.id,
            note: `add by ${UserModel.getMembershipName(session)}`,
            status: STATUS.Active
        };
        let result = await TeamModel.updateMembership({id: team.id, member_id: newMember.id, data});
        if (result && result.id){
            let m = await UserModel.getUser(result.id);
            setMemberships([...memberships, m]);
            setIsAdd(false);
        }else {
            setMessage('Error updating membership.');
        }
    }

    let isAdmin = team && UserModel.isAdmin(session, ENTITY.team, team.id);
    return !memberships? <LoadingList /> : (
        <div className="TeamMemberships">
            <div className="filter">
                <AlertPane message={message} />
                {isAdd && 
                    <AddEntityMember
                        entity={team}
                        session={session} 
                        memberships={memberships}
                        newMember={newMember}
                        setNewMember={setNewMember}
                        setMemberships={setMemberships}
                        setIsAdd={setIsAdd}
                        model={TeamModel}/>}
                {isAdmin && 
                    (!isAdd && <button className="button" onClick={() => setIsAdd(true)}>
                        <IonIcon icon={addIcon}/>Find a member to add
                    </button>)
                }
            
            <FilterInput entity="team_memberships" label="Filter by name..." value={filter} onFilter={v => setFilter(v)} />
            </div>
            <IonList className={pageStyles.marginBottom}>
                <div className="memberships">
                    {memberships.map((m, i) => {
                        return <div key={i} className="membership">
                            <UserChip membership={m}/>
                            {isAdmin && isEditable &&
                                <MembershipInfo info={m.teams.find(l => l.id===team.id)} 
                                    onUpdate={data => {
                                        return TeamModel.updateMembership({id: team.id, member_id: m.id, data});
                                    }}
                                />}
                        </div> 
                    })}
                </div>
            </IonList>
        </div>
    )
}