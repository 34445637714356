import { MESSAGE_TYPE } from "../../components/Form/Form";
import { RequestMessage, RequestUtils } from "../requests";
import Utils from "../Utils";


export default class MessageModel {
    static setMessageFrom(messageObj) {
        const {messages, tournaments_doc, leagues_doc, gyms_doc, teams_doc, memberships_doc} = messageObj;
        messages.sort((a, b) => Utils.sorter(b, a, 'created_on')).forEach(m => {
            const setLinks = (docs, ltype) => {
            return docs.map(d => {
                d.link = `/${ltype}/${d.id}`;
                return d;
            });
            }
            m.getFrom = () => [
            ...setLinks(tournaments_doc, 'tournaments'), 
            ...setLinks(leagues_doc, 'leagues'), 
            ...setLinks(gyms_doc, 'gyms'), 
            ...setLinks(teams_doc, 'teams'), 
            ...setLinks(memberships_doc, 'users')
            ].find(doc => doc.id === m.from);
        });
        return messages;
    }

    static async searchMessageThreads(id, searchText) {
        let response = await RequestMessage.searchMessageThreads(id, searchText);
        return RequestUtils.getResponseData(response);
    }

    static async getMessageConversation(id1, id2) {
        let response = await RequestMessage.getMessageConversation(id1, id2);
        return RequestUtils.getResponseData(response);
    }

    static async sendBulkMessage(params) {
        let response = await RequestMessage.sendBulkMessage(params);
        return RequestUtils.getResponseData(response);
    }

    static async sendMessage(params) {
        let response = await RequestMessage.sendMessageRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async getMessageThread(id) {
        let response = await RequestMessage.getMessageThread(id);
        let data = RequestUtils.getResponseData(response);
        const {messages, 
               gyms_doc, 
               images_doc, 
               leagues_doc, 
               memberships_doc, 
               tournaments_doc,
               teams_doc
            } = data;
        return messages.map(m => {
            m.getFrom = () => {
                if (m.message_type === MESSAGE_TYPE.membership) {
                    memberships_doc.find(md => md.id === m.from);
                }else if (m.message_type === MESSAGE_TYPE.tournament) {
                    tournaments_doc.find(md => md.id === m.from);
                }else if (m.message_type === MESSAGE_TYPE.gym) {
                    gyms_doc.find(md => md.id === m.from);
                }else if (m.message_type === MESSAGE_TYPE.league) {
                    leagues_doc.find(md => md.id === m.from);
                }else if (m.message_type === MESSAGE_TYPE.team) {
                    teams_doc.find(md => md.id === m.from);
                }
            }
            return m;
        });
    }
}