import HomePage from "../../pages/HomePage/HomePage";
import LeaguesPage from "../../pages/LeaguesPage/LeaguesPage";
import TournamentsPage from "../../pages/TournamentsPage/TournamentsPage";
import TournamentPage from "../../pages/TournamentPage/TournamentPage";
import TournamentEditPage from "../../pages/TournamentEditPage/TournamentEditPage";
import GymsPage from "../../pages/GymsPage/GymsPage";
import TeamsPage from "../../pages/TeamsPage/TeamsPage";
import UsersPage from "../../pages/UsersPage/UsersPage";
import UserPage from "../../pages/UserPage/UserPage";
import SearchPage from "../../pages/SearchPage/SearchPage";
import LoginPage from "../../pages/LoginPage/LoginPage";
import LeagueEditPage from "../../pages/LeagueEditPage/LeagueEditPage";
import LeaguePage from "../../pages/LeaguePage/LeaguePage";
import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import MessagesPage from "../../pages/MessagesPage/MessagesPage";
import Profile from "../MembershipForm/Profile";
import GymEditPage from "../../pages/GymEditPage/GymEditPage";
import GymPage from "../../pages/GymPage/GymPage";
import TeamPage from "../../pages/TeamPage/TeamPage";
import TeamEditPage from "../../pages/TeamEditPage/TeamEditPage";
import UserEditPage from "../../pages/UserEditPage/UserEditPage";
import CartsPage from "../../pages/CartsPage/CartsPage";
import CartPage from "../../pages/CartsPage/CartPage";
import SupportPage from "../../pages/SupportPage/SupportPage";
import MessagePage from "../../pages/MessagesPage/MessagePage";
import LocalServer from "../../pages/LocalServer/LocalServer";
import { LocalScoreCard } from "../../pages/LocalServer/LocalScoreCard";
import LocalStation from '../../pages/LocalServer/LocalStation';
import { LocalWeightIn } from "../../pages/LocalServer/LocalWeightIn";
import { LocalSchedule } from "../../pages/LocalServer/LocalSchedule";
import { LocalCamera } from "../../pages/LocalServer/LocalCamera";
import LocalResults from "../../pages/LocalServer/LocalResults";

export const pages = [
  { name: 'Login', path: '/', component: LoginPage, icon: 'log-in', mainNav: true },

  { name: 'Dashboard', path: '/dashboard', component: DashboardPage, mainNav: true, session: true, icon: 'grid' },
  { name: 'Send Message', path: '/sendMessage/:send_type/:entity/:id', component: MessagePage },
  { name: 'Messages', path: '/messages', component: MessagesPage },

  { name: 'Edit League', path: '/leagues/:id/edit', component: LeagueEditPage },
  { name: 'League', path: '/leagues/:id', component: LeaguePage },
  { name: 'Add League', path: '/leagues/add', component: LeagueEditPage },
  { name: 'Leagues', path: '/leagues', component: LeaguesPage, icon: 'league', mainNav: true },

  { name: 'Edit Tournament', path: '/tournaments/:id/edit', component: TournamentEditPage },
  { name: 'Tournament', path: '/tournaments/:id', component: TournamentPage },
  { name: 'Add Tournament', path: '/tournaments/add', component: TournamentEditPage },
  {name: 'Tournaments', path: '/tournaments', component: TournamentsPage, icon: 'trophy', mainNav: true, localSever: true },


  { name: 'Edit Gym', path: '/gyms/:id/edit', component: GymEditPage },
  { name: 'Gym', path: '/gyms/:id', component: GymPage },
  { name: 'Add Gym', path: '/gyms/add', component: GymEditPage },
  { name: 'Gyms', path: '/gyms', component: GymsPage, icon: 'dumbbell', mainNav: true },

  { name: 'Edit Team', path: '/teams/:id/edit', component: TeamEditPage },
  { name: 'Team', path: '/teams/:id', component: TeamPage },
  { name: 'Add Team', path: '/teams/add', component: TeamEditPage },
  { name: 'Teams', path: '/teams', component: TeamsPage, icon: 'users', mainNav: true },

  { name: 'Edit User', path: '/users/:id/edit', component: UserEditPage },
  { name: 'User', path: '/users/:id', component: UserPage },
  { name: 'Add User', path: '/users/add', component: UserEditPage },
  { name: 'Users', path: '/users', component: UsersPage, icon: 'user', mainNav: true },

  { name: 'Support', path: '/support', component: SupportPage, icon: 'circle-help', mainNav: true },
  { name: 'Search', path: '/search', component: SearchPage },
  { name: 'Login', path: '/login', component: LoginPage, localSever: true, },
  { name: 'Logout', path: '/logout', component: LoginPage, localSever: true, },
  { name: 'Reset Password', path: '/reset_password/:id', component: LoginPage },
  { name: 'Email Confirmation', path: '/email_confirmation/:id', component: LoginPage },
  { name: 'Profile', path: '/profile', component: Profile },

  { name: 'Carts', path: '/carts', component: CartsPage },
  { name: 'Cart', path: '/carts/:id', component: CartPage },

  { name: 'Station', path: '/local', component: LocalServer, localSever: true, },
  { name: 'Not Assign', path: '/local/NA', component: LocalStation, localSever: true, },
  { name: 'Score Card', path: '/local/SC', component: LocalScoreCard, localSever: true, },
  { name: 'Camera', path: '/local/CA', component: LocalCamera, localSever: true, },
  { name: 'Weight In', path: '/local/WT', component: LocalWeightIn, localSever: true, },
  { name: 'Schedule', path: '/local/SE', component: LocalSchedule, localSever: true, },
  { name: 'Results', path: '/local/RS', component: LocalResults, localSever: true, },
  { name: 'Local Tournament', path: '/local/tournaments/:id', component: LocalServer, localSever: true, },
];
