import { Alert, ThemeProvider } from "@mui/material";
import Theme from "./Theme";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import "./AlertPane.scss";
import {default as CloseIcon} from "@mui/icons-material/CloseOutlined";

const AlertPane = React.forwardRef(({ 
  children='', 
  setMessage, 
  message, 
  isFloat, 
  isModal, 
  timeOut, 
  severity, 
  omitClose 
}, ref) => {
  const [refresh, setRefresh] = useState(false);
  const [refMessage, setRefMessage] = useState('');
  const timeoutRef = useRef();

  useImperativeHandle(ref, () => ({
    setRefMessage,
  }));

  useEffect(() => {
    if (message && timeOut && setMessage) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setMessage('');
      }, timeOut);
    }else if (setMessage) {
      setMessage(message || '');
    }
    setRefresh(!refresh);
  }, [timeOut, message]);
  let isString = typeof (refMessage || message) === 'string';
  const getSeverity = () => {
    if (severity && ["error", "info", "success", "warning"].includes(severity)) {
      return severity;
    }
    if (!isString) {
      return;
    }
    let msg = refMessage || message;
    if (msg.startsWith('error:')) {
      return 'error';
    }
    if (msg.startsWith('info:')) {
      return 'info';
    }
    if (msg.startsWith('warning:')) {
      return 'warning';
    }
    return 'success';
  }

  return message ?
    <ThemeProvider theme={Theme}>
      <div className={`Message ${isFloat? 'float':''} ${isModal? 'modal':''}`}>
        {(!omitClose && setMessage) && 
          <CloseIcon className="CloseIcon" 
            onClick={() => {
              setMessage && setMessage('');
            }}/>}
        <Alert variant="filled"
          severity={severity || getSeverity()} >
          <span className="text">
            {(isString ? (refMessage || message).split(': ')[1] : message)}
          </span>
        </Alert>
        {children}
      </div>
    </ThemeProvider> : '';
});

export default AlertPane;