import axios from "axios";
import { RequestTournament, RequestUtils } from "../requests";
import Utils, { traceMessages } from "../Utils";
import TournamentModel from "./TournamentModel";
import { setStationToCookie } from "../../LocalServerApp";
import { Redirect } from 'react-router-dom';

const serverPosts = {};
export default class LocalServerModel {
  static LOCAL_SERVER_URL = '';
  static blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  static async uploadFile(filepath, blob, cbStatus) {
    let arr = await this.blobToBase64(blob);
    const base64Header = "data:video/webm;base64,";
    arr = arr.slice(base64Header.length);
    let chunkSize = 5 * 1024;
    let lastChunk = arr.length % chunkSize;
    const post = async (start) => {
      let end = start + chunkSize;
      if (end > arr.length) {
        end = arr.length;
      }
      const data = new FormData();
      data.append('append', end < arr.length ? 'true' : 'false');
      data.append('filepath', filepath);
      data.append('base64', arr.slice(start, end));
      let r = await axios.post(LocalServerModel.LOCAL_SERVER_URL + `/upload`, data, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
        timeout: 30000,
      });
      if (r.status < 200 && r.status >= 300) {
        return 'Error uploading data';
      }
    }
    let chunks = Math.floor(arr.length / chunkSize);
    for (let i = 0; i < chunks; i++) {
      let start = i * chunkSize;
      let error = await post(start);
      if (error) {
        return { error }
      }
      cbStatus(i / chunks);
    }
    if (lastChunk > 0) {
      let error = await post(chunks * chunkSize);
      if (error) {
        return { error }
      }
      cbStatus(1);
    }
  }

  static postServer(path, params) {
    let key = `${path}-${JSON.stringify(params)}`;
    let call = serverPosts[key];
    if (call){
      return call;
    }
    call = axios
      .create(RequestUtils.requestHeaders)
      .post(LocalServerModel.LOCAL_SERVER_URL + path, params)
      .then(r => {
        delete serverPosts[key];
        path!=='/sendMessage' && console.log('postServer: ',
              Utils.formatDateTime(new Date().getTime(), 'hh:ss:mm'),
              '\npath:',
              path,
              '\nparams:',
              params? (params.length>80? params.substring(0, 80):params):'',
              '\nresponse:',
              r
            );
        if (r.status >= 200 && r.status < 300) {
          return r.data;
        }
      });
    serverPosts[key] = call;

    return call;
  }

  static STATION_TYPE_NAME = {
    SC: 'Score Card',
    WT: 'Weight Station',
    SE: 'Schedule',
    RS: 'Results',
    CA: 'Camera',
    MASTER: 'Master',
    NA: 'Not Set'
  };
  static STATION_TYPE = {
    score_card: 'SC',
    weight_in: 'WT',
    schedule: 'SE',
    results: 'RS',
    master: 'MASTER',
    camera: 'CA',
    not_set: 'NA'
  };

  static STATION_OPTIONS = Object.values(LocalServerModel.STATION_TYPE)
    .filter(s => s !== this.STATION_TYPE.master)
    .map(s => ({ value: s, label: LocalServerModel.STATION_TYPE_NAME[s] }));

  static LOCAL_SERVER = {
    local_tournament: 'local_tournament',
    gih_mode: 'gih_mode',
    localhost: 'localhost',
    local_station: 'local_station',
    local_station_type: 'local_station_type',
    ping: 'ping',
  };

  static setLocalServerURL(url) {
    LocalServerModel.LOCAL_SERVER_URL = url;
  }

  static async sendMessage(message) {
    console.log('sendMessage: ', message);
    console.trace();
    return await LocalServerModel.postServer('/sendMessage', message);
  }

  static async sendElectronjsMessage(message) {
    return await LocalServerModel.postServer('/sendElectronjsMessage', JSON.stringify(message));
  }

  static async downloadTournament(id) {
    let queries = [
      JSON.stringify({ query: RequestTournament.getTournamentQuery(id) }), 
      JSON.stringify({ query: RequestTournament.getBracketEntriesQuery(id) }), 
      JSON.stringify({ query: RequestTournament.getScheduleEntriesQuery(id) })
    ];
    let body = JSON.stringify({ id: id, queries });
    let r = await LocalServerModel.postServer('/downloadTournament', body);
    if (r !== undefined) {
      return r;
    }
  }

  static async getTournamentRaw(id) {
    let body = JSON.stringify({ name: `tournament.${id}` });
    let r = await LocalServerModel.postServer('/getData', body);
    if (r) {
      Utils.cookie('local_tournament', id);
      let tournament = JSON.parse(r.message);
      return tournament;
    }
  }

  static async getTournament(id) {
    Utils.cookie('local_tournament', id);
    let tournament = await LocalServerModel.getTournamentRaw(id);
    if (tournament) {
      return TournamentModel.formatResult(tournament.data.rs, true);
    }
  }

  static async getTournamentList() {
    let r = await LocalServerModel.postServer('/getTournamentList');
    if (r !== undefined) {
      return r;
    }
  }

  static async getBracketEntries(tourId) {
    let name = `tournament.${tourId}.bracketEntries`;
    let body = JSON.stringify({ name });
    let r = await LocalServerModel.postServer('/getData', body);
    if (r) {
      try {
        return {
          data : JSON.parse(r.message),
          status: 200
        };
      } catch (e) {
        console.log('getBracketEntries: ' + name + ': ', e);
      }
    }
  }

  static async getScheduleEntries(tourId) {
    let name = `tournament.${tourId}.schedules`;
    let body = JSON.stringify({ name });
    let r = await LocalServerModel.postServer('/getData', body);
    if (r) {
      try {
        return {
          data : JSON.parse(r.message),
          status: 200
        };
      } catch (e) {
        console.log('getSchedules: ' + name + ': ', e);
      }
    }
  }

  static async getStations(tourId) {
    let name = `tournament.${tourId}.stations`;
    let body = JSON.stringify({ name });
    let r = await LocalServerModel.postServer('/getData', body);
    if (r) {
      try {
        return JSON.parse(r.message);
      } catch (e) {
        console.log('getStations: ' + name + ': ', e);
      }
    }
  }

  static async getConfig() {
    let r = await LocalServerModel.postServer('/getConfig');
    if (r !== undefined) {
      return r;
    }
  }

  static async saveConfig(params) {
    let r = await LocalServerModel.postServer('/saveConfig', JSON.stringify(params));
    if (r !== undefined) {
      return r;
    }
  }

  static async saveStations(stations, tourId) {
    if (!tourId) {
      return;
    }
    let body = JSON.stringify({
      name: `tournament.${tourId}.stations`,
      data: JSON.stringify(stations),
    });
    let r = await LocalServerModel.postServer('/writeData', body);
    return r;
  }

  static async saveTournament(tournament, stations) {
    let tRaw = await LocalServerModel.getTournamentRaw(tournament.id);
    if (tRaw !== undefined) {
      let { registrations_doc, tournaments } = tRaw.data.rs;
      let tourRaw = tournaments.find(t => t.id === tournament.id);
      tourRaw.poolings = tournament.poolings;
      tRaw.data.rs.registrations_doc = tournament.getRegistrations();
      let body = JSON.stringify({
        name: `tournament.${tournament.id}`,
        data: JSON.stringify(tRaw),
      });
      let r = await LocalServerModel.postServer('/writeData', body);

      const saveCache = async (rs, name) => {
        let sRaw = { data: { rs }};
        body = JSON.stringify({
          name: `tournament.${tournament.id}.${name}`,
          data: JSON.stringify(sRaw),
        });
        return await LocalServerModel.postServer('/writeData', body);
      }
      r = saveCache(tournament.caches.scheduleEntries, 'schedules');
      r = saveCache(tournament.caches.bracketEntries, 'bracketEntries');
      if (stations && r.status >= 0) {
        r = await LocalServerModel.saveStations(stations, tournament.id);
      }
      return r;
    }
  }

  static hasStationTypeChanged(message, station) {
    return (station.type || '') !== (message.type || '');
  }

  static hasStationChanged(message={}, station={}) {
    return (station.label || '') !== (message.label || '') || LocalServerModel.hasStationTypeChanged(message, station);
  }

  static setCurrentStation(message, station, setStation, setRedirect) {
    let isUpdate = LocalServerModel.hasStationChanged(message, station);
    station.label = message.label;
    station.type = message.type;
    setStationToCookie(station);
    isUpdate && setStation({ ...station });
    let url = `/local/${station.type||LocalServerModel.STATION_TYPE.not_set}`;
    if (document.location.pathname !== url){
      console.log('Switching station mode: ', document.location.pathname, '-->', url);
      // setTimeout(() => history.push(url), 100);
      setRedirect(<Redirect to={url}/>);
      return true;
    }
  }
}