import { IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { RequestUtils } from "../../serverUtils/requests";
import { useStore } from "../../Store";

export const initSwipableTab = (sections, setSectionIndex) => {
  const { tab } = RequestUtils.getURLParameters();
  if (tab) {
    let i = sections.findIndex((s) => s.type === tab);
    if (i >= 0) {
      setSectionIndex(i);
    }
  }
};

const SwipableTabs = ({ sections, data, setData, props, index }) => {
  const session = useStore(state => state.session);
  const [activeSectionIndex, setActiveSectionIndex] = useState(index || 0);
  const [activeSectionComponent, setActiveSectionComponent] = useState(null);

  useEffect(() => {
    if (data) {
      setActiveSectionIndex(index || 0);
      setActiveSectionComponent(
        sections[index || 0]?.component(data, setData, session)
      );
    }
  }, [data, index, session]);

  const handleChange = (event, newValue) => {
    const newIndex = newValue;
    RequestUtils.insertURLParam("tab", sections[newIndex].type);
    setActiveSectionIndex(newIndex);
    setActiveSectionComponent(null);
    setTimeout(() => {
      setActiveSectionComponent(
        sections[newIndex]?.component(data, setData, session)
      );
    }, 1000);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <div className="swipable-tabs">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeSectionIndex}
          onChange={handleChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {sections.map((section, i) => (
            <Tab
              key={section.name}
              label={
                typeof section.name == "function" && data
                  ? section.name(data)
                  : section.name
              }
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </Box>
      {activeSectionComponent || <IonSpinner name="circles" className="spinner"/>}
    </div>
  );
};

export default SwipableTabs;
