import { useEffect, useState } from "react"
import LoadingList from "../../components/LoadingList/LoadingList";
import GymModel from "../../serverUtils/models/GymModel";

export default function GymStore({gym, session}) {
    const [items, setItems] = useState();
    const getStoreItems = async () => {
        let its = await GymModel.getStoreItems(gym.id);
        setItems(its);
    }
    useEffect(() => {
        !items && getStoreItems(); 
    }, [gym]);

    return !items? <LoadingList /> :
        <div>
            <h1>Store</h1>
            <span style={{color: 'white'}}>store items: {items.length}</span>
        </div>
}