import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFormInput, { IndexCell, ToolbarButtons } from "../FormInput/TableFormInput";
import Checkbox from "@mui/material/Checkbox";
import { IonButton, IonButtons } from "@ionic/react";
import { default as CancelIcon } from "@mui/icons-material/HighlightOff";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getDivisionDisplay, getGrapplingTypeLabel } from "../LeagueInformationForm/Divisions";
import SelectFormInput from "../FormInput/SelectFormInput";
import { BRACKET, BRACKET_TYPES } from "../Bracket/Bracket";
import { getBadge, hasTableFormChange, reloadPage } from "../Form/Form";
import React from "react";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { Link, Paper, TextField } from "@mui/material";
import AlertPane from "../FormInput/AlertPane";
import TransferList from "../FormInput/TransferList";
import "./TournamentDivisions.scss";
import Slider from '@mui/material/Slider';
import Utils from "../../serverUtils/Utils";
import { ThemeProvider } from "@emotion/react";
import Theme from "../FormInput/Theme";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import { TournamentSchema } from "../../serverUtils/Models";
import { useHistory } from 'react-router-dom';

const headCells = [
  {
    id: "division",
    disablePadding: false,
    label: "Division",
  },
  {
    id: "current_fee",
    disablePadding: false,
    label: "Current Fee",
  },
  {
    id: "reg_count",
    disablePadding: false,
    label: "# Regs",
  },
  {
    id: "grappling_type",
    disablePadding: false,
    label: "Grappling Type",
  },
  {
    id: "bracket_type",
    disablePadding: false,
    label: "Bracket Type",
  },
  {
    id: "max_reg",
    disablePadding: false,
    label: "Max Registrations",
  },
  {
    id: "is_third",
    disablePadding: false,
    label: "Has 3rd Place",
    disable_filter: true,
  },
  // {
  //   id: "is_bracket_by_seed",
  //   disablePadding: false,
  //   label: "Has Seed",
  // },
  {
    id: "is_block",
    disablePadding: false,
    label: "Is Block",
    disable_filter: true,
  },
];

const TournamentDivisions = forwardRef(({ tournament, tabsRef, tabObjRefs }, ref) => {
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [message, setMessage] = useState("");
  const [league, setLeague] = useState();
  const [isImport, setIsImport] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [isAlert, setIsAlert] = useState();

  const tableRef = React.useRef();
  const checkCRCMessageRef = useRef();
  const checkCRCMessageRef1 = useRef();
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const CRC_Fields = Utils.listObjectKeys(TournamentSchema().model.divisions);
  useEffect(() => {
    tournament.getDivisions && tournament.getDivisions().forEach(r => r.crc = Utils.getCRC(r, CRC_Fields));
  }, []);
  useEffect(() => {
    LeagueModel.getLeague(tournament.league).then((l) => setLeague(l));
  }, [tournament]);

  useImperativeHandle(ref, () => ({
    checkCRC,
    doSave,
  }));

  const checkCRC = () => {
    checkCRCMessageRef.current.innerHTML = '';
    checkCRCMessageRef1.current.innerHTML = '';
    let r = hasTableFormChange(tournament.getDivisions(), CRC_Fields)
    if (r) {
        checkCRCMessageRef.current.innerHTML = 'Data has changed';
        checkCRCMessageRef1.current.innerHTML = 'Data has changed';
        toolbarButtonsRef.current.setIndicator({save: 'yellow'});
        toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
    }else {
        toolbarButtonsRef.current.setIndicator({save: ''});
        toolbarButtonsRef1.current.setIndicator({save: ''});
    }
    return r;
}

  const doSave = async (skipTabRefCheck) => {
    !skipTabRefCheck && await tabObjRefs.current.save('tournamentDivisionsRef');
    let result = await TournamentModel.updateDivisions(tournament.id,[]);
    if (!result || result.error) {
      return setMessage("error: Error updating divisions");
    }
    const divisions = tournament
      .getDivisions()
      .map(d => ({
        id: d.id,
        bracket_type: d.bracket_type,
        is_bracket_by_seed: d.is_bracket_by_seed,
        is_block: d.is_block || d.is_blocked,
        max_reg: d.max_reg,
        is_third: d.is_third,
      }));
    for (let i = 0, chunk = 50; i < divisions.length; i+=chunk) {
      let divs = divisions.slice(i, i+chunk);
      result = await TournamentModel.updateDivisions(tournament.id, divs);
      if (!result || result.error) {
        setMessage("error: Error updating divisions");
        return false;
      }
    }
    tournament.getDivisions().forEach(r => r.crc = Utils.getCRC(r, CRC_Fields));
    setMessage("success: Successfully update divisions");
    checkCRC();
    return true;
  };

  const doAdd = () => {
    setIsAdd(true);
  };

  let divisionOptions = ((league && league.getDivisions()) || []).map((d) => ({
    value: d.id,
    label: getDivisionDisplay(d, true),
  }));

  let divIds =
    (tournament.getDivisions && tournament.getDivisions().map((d) => d.id)) ||
    [];
  let addAvailables = divisionOptions.filter(d => !divIds.includes(d.value));
  const importDivisions = (divisions) => {
    try{
      if (divisions.length === 0){
        tournament.divisions = [];
      }else {
        let updateDivisions = divisions.map(d => {
          let td = tournament.getDivisions().find(td => td.id === d)
          if (td) {
            return td;
          }
          let ld = league.getDivisions().find(ld => ld.id === d);
          if (ld) {
            return { ...ld,
              bracket_type: BRACKET.Single_Elimination,
            }
          }
        });
        tournament.getDivisions = () => updateDivisions;
        doSave().then(r => {
          r && reloadPage(setReload);
        })
      }
    }finally {
      setIsImport(false);
    }
    
  };

  const doDelete = async () => {
    if (tableRef.current.selected.length === 0) {
      return setMessage('info: Nothing is selected');
    }

    let deletes = tableRef.current.selected.filter(s => s && !s.startsWith('-'));
    let r = deletes.length === 0 ? { r: 'ok' } : await TournamentModel.deleteTournamentDivision(tournament.id, deletes);
    if (r && !r.error) {
      let divisions = tournament
        .getDivisions()
        .filter((d) => !tableRef.current.selected.includes(d.id));
      tournament.getDivisions = () => divisions;
      checkCRC()
      return reloadPage(setReload, () => {
        tableRef.current.setSelected([]);
        setMessage('success: Successfully update server');
        setTimeout(() => document.location.reload(), 2000);
      });
    }
    setMessage('error: Error deleting selected divisions from server');
  }

  const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
    let words = Utils.separateWords(filterString);
    if (headCell.id === "division") {
      return (filterData || tournament.getDivisions()).filter(d => {
        return isFilter(words, () => getDivisionDisplay(d, true));
      });
    } 
    if (headCell.id === "bracket_type") {
      return (filterData || tournament.getDivisions()).filter(d => {
        return isFilter(words, () => BRACKET_TYPES.find(b => b.value === d.bracket_type));
      });
    }
    if (headCell.id === "current_fee") {
      return (filterData || tournament.getDivisions()).filter(d => {
        let fee = d.getFee(true);
        return parseInt(filterString) === fee;
      });
    }
    if (headCell.id === "bracket_type") {
      return (filterData || tournament.getDivisions()).filter(d => {
        let btype =  BRACKET.getBracketName() || '';
        return btype.toLowerCase().includes(filterString.toLowerCase())
      });
    }
    if (headCell.id === "max_reg") {
      return (filterData || tournament.getDivisions()).filter(d => {
        let mr = d.max_reg;
        return parseInt(filterString) === mr;
      });
    }
  };

  const comparator = (a, b, orderBy) => {
    if (orderBy === "division") {
      return { a: getDivisionDisplay(a, true), b: getDivisionDisplay(b, true) };
    }
  };

  const getToolbarButtons = ref => {
    return <ToolbarButtons ref={ref}
      doAdd={addAvailables.length > 0 && doAdd} 
      doDelete={doDelete} 
      doSave={() => doSave()} 
      buttons={[<IonButtons key={0}>
      {hasMoreImport && !isImport &&
        getBadge(
          availCount || 0,
          <button className="button"
            onClick={() => setIsImport(true)}
          >
            Activate Divisions
          </button>
        )}
    </IonButtons>]}/>;
  }

  const DivisionForm = ({onDone}) => {
    const [divData, setDivData] = useState({label: '', value: ''});
    return <ThemeProvider theme={Theme}>
      <div className="DivisionForm">
        <MultiSelectFormInput
            multiple={false}
            value={divData}
            name="division"
            label="Select a division"
            optionLabel="label"
            optionValue="value"
            fetchOptions={searchVal => {
              let filters = !searchVal? addAvailables : addAvailables.filter(a => {
                let words = searchVal.toLowerCase().split(' ');
                return words.every(word => Utils.extractTextFromElement(a.label).toLowerCase().includes(word));
              });
              return Promise.resolve(filters);
            }}
            onChange={v => {
              if (v) {
                setDivData({...divData, ...v});
              }
            }}
        />
        <SelectFormInput
          value={divData.bracket_type}
          onChange={(v) => {
            divData.bracket_type = v;
            setDivData({...divData});
          }}
          name="bracket_type"
          label="Bracket Type"
          placeholder="Select bracket type"
          options={BRACKET_TYPES}
        />
        <TextField
          type="number"
          defaultValue={divData.max_reg || ""}
          label="Max Reg"
          onChange={(e) => {
            divData.max_reg = !e.target.value || isNaN(e.target.value)? '' : parseInt(e.target.value);
            setDivData({...divData});
          }}
          onClick={e => e.stopPropagation()}
        />
        <div className="checkbox">
          <Checkbox
            color="primary"
            defaultChecked={divData.is_third}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              divData.is_third = e.target.checked;
              setDivData({...divData});
            }}
          />Has Third?
          <Checkbox
            color="primary"
            defaultChecked={divData.is_block || divData.is_blocked}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              divData.is_blocked = e.target.checked;
              setDivData({...divData});
            }}
          />Is Block?
        </div>
        <div className="buttons">
          <button className="button"
            onClick={e => {
              e.stopPropagation();
              let divisions = [...tournament.getDivisions(), {...league.getDivisions().find(d => d.id === divData.value), ...divData}];
              tournament.getDivisions = () => divisions;
              setIsAdd(false);
              onDone();
            }}
          >Save Division</button>
          <button className="button"
            onClick={e => {
              e.stopPropagation();
              setIsAdd(false);
            }}
          >Cancel</button>
        </div>
        
      </div>
    </ThemeProvider>;
  }

  let hasMoreImport = league && tournament.getDivisions().length < league.getDivisions().length;
  let availCount = league && league.getDivisions().length - tournament.getDivisions().length;
  return (
    <div className="TournamentDivisions" >
      {hasMoreImport && isImport && (
        <ImportDivisions
          tournament={tournament}
          league={league}
          onDone={() => {
            setIsImport(false);
          }}
          onImport={importDivisions}
        />
      )}
      {/* <AlertPane message={message} setMessage={setMessage} isFloat/> */}
      <AlertPane message={message} setMessage={setMessage} timeOut={5000}/>
      <div className="TournamentDivisionsTable" style={{display: isImport? 'none':'block'}}>
        {divisionOptions.length === 0 ? 
          TournamentModel.getDivisionsMessage(tournament)
        : 
          isAdd? <DivisionForm onDone={() => {
              setTimeout(() => {
                tableRef.current.gotoLastPage();
                reloadPage(setReload, () => tableRef.current.gotoLastPage());
                checkCRC();
              }, 1200);
            }}/> :
            <TableFormInput name="TournamentDivisions"
              isEditable
              ref={tableRef}
              onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
              onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
              toolbarButtons={() => getToolbarButtons(toolbarButtonsRef)}
              toolbarButtons1={() => getToolbarButtons(toolbarButtonsRef1)}
              getFilterData={getFilterData}
              comparator={comparator}
              headCells={headCells}
              data={(tournament.getDivisions && tournament.getDivisions()) || []}
              renderTRow={({
                row,
                isSelected,
                index,
                handleClick,
              }) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index} ${row.id}`;
                const getCurrentFee = () => {
                  let r = row.getFee && row.getFee();
                  if (r === false) {
                    return 'Fee has expired';
                  }
                  return r;
                }

                const RegCount = () => {
                  const [count, setCount] = useState();
                  useEffect(() => {
                    setCount(row.getRegistrations && row.getRegistrations().length);
                  }, []);
                  return <span className="RegCount">
                    {count}
                  </span>
                }

                const updateBracketType = e => {
                  e.stopPropagation();
                  tournament.getBracketEntries().then(bes => {
                      tournament.caches.bracketEntries = bes.filter(be => be.division !== row.pool);
                      checkCRC();
                  });
                  setIsAlert(null);
                }

                return (
                  <TableRow
                    className={`TableRow`}
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {isAlert && isAlert.id === row.id && row.getRegistrations && row.getRegistrations().length>0 &&
                        <AlertPane message={'warning: This will reset the registrants of this division.'} isModal >
                            <IonButton onClick={updateBracketType} fill="solid">Continue</IonButton>
                            <IonButton onClick={e => {
                                e.stopPropagation();
                                row.bracket_type = isAlert.bracket_type;
                                setIsAlert(null);
                            }} fill="solid">Cancel</IonButton>
                        </AlertPane>}
                    <TableCell padding="checkbox">
                      <IndexCell labelId={labelId} isItemSelected={isItemSelected} index={index + 1} />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Link className="Link"
                        onClick={e => {
                          e.stopPropagation();
                          history.push(`/tournaments/${tournament.id}/edit?tab=brackets&id=${row.id}`);
                          tabsRef.current.setActiveTabByName('brackets');
                        }}
                      >
                        {getDivisionDisplay(row)}
                      </Link>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {getCurrentFee()}
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <RegCount />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      {getGrapplingTypeLabel(row.grappling_type)}
                    </TableCell>
                    <TableCell align="right" className="bracket-type">
                      <SelectFormInput
                        value={row.bracket_type}
                        onChange={v => {
                          setIsAlert({id: row.id, bracket_type: row.bracket_type});
                          row.bracket_type = v;
                        }}
                        name="bracket_type"
                        label="Bracket Type"
                        placeholder="Select bracket type"
                        options={BRACKET_TYPES}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        defaultValue={row.max_reg || ""}
                        label="Max Reg"
                        onChange={(e) => {
                          let d = tournament
                            .getDivisions()
                            .find((d) => d.id === row.id);
                          d.max_reg = !e.target.value || isNaN(e.target.value)? '' : parseInt(e.target.value);
                          checkCRC();
                        }}
                        onClick={e => e.stopPropagation()}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        defaultChecked={row.is_third}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          row.is_third = e.target.checked;
                          checkCRC();
                        }}
                      />
                    </TableCell>
                    {/* <TableCell align="center">
                      <Checkbox
                        color="primary"
                        defaultChecked={row.is_bracket_by_seed}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          row.is_bracket_by_seed = e.target.checked;
                        }}
                      />
                    </TableCell> */}
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        defaultChecked={row.is_block || row.is_blocked}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          row.is_blocked = row.is_block = e.target.checked;
                          checkCRC();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }}
            />
        }
      </div>
    </div>
  );
});

const FILTER_DIVISION_CATEGORY = {
  male: { value: "M", label: "Male" },
  female: { value: "F", label: "Female" },
};
function ImportDivisions({ tournament, league, onDone, onImport }) {
  const ageSize = [0, 65];
  const weightSize = [0, 300];
  const [divisions, setDivisions] = useState([]);
  useEffect(() => {
    let genders = Object.values(FILTER_DIVISION_CATEGORY).map(v => v.value);
    setDivisions(league.getDivisions());
  }, []);
  
  const AvailableDivisions = ({ onDone }) => {
    const [ageRange, setAgeRange] = useState(ageSize);
    const [weightRange, setWeightRange] = useState(weightSize);
    const [ageRangeSelected, setAgeRangeSelected] = useState(ageSize);
    const [weightRangeSelected, setWeightRangeSelected] = useState(weightSize);
    
    const [ageGroupFilter, setAgeGroupFilter] = useState();
    const [rankFilter, setRankFilter] = useState();
    const [categoryFilter, setCategoryFilter] = useState();

    const [ageGroupFilterSelected, setAgeGroupFilterSelected] = useState();
    const [rankFilterSelected, setRankFilterSelected] = useState();
    const [categoryFilterSelected, setCategoryFilterSelected] = useState();

    const [availables, setAvailables] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const [isfilterChange, setIsFilterChange] = useState();

    const transferListRef = useRef();
    const importButtonRef = useRef();

    useEffect(() => {
      importButtonRef.current.disabled = true;
      let selectIds = tournament.getDivisions().map(d => d.id);
      setAvailables([...divisions.filter(d => !selectIds.includes(d.id))]);
      setSelecteds([...tournament.getDivisions()]);
    }, [divisions]);

    const ListFilter = ({isSelect}) => {
      let ar = isSelect? ageRangeSelected : ageRange;
      let sar = isSelect? setAgeRangeSelected : setAgeRange;
      let wr = isSelect? weightRangeSelected : weightRange;
      
      let catf = isSelect? categoryFilterSelected : categoryFilter;
      let agf = isSelect? ageGroupFilterSelected : ageGroupFilter;
      let rkf = isSelect? rankFilterSelected : rankFilter;
      let scatf = isSelect? setCategoryFilterSelected : setCategoryFilter;
      let sagf = isSelect? setAgeGroupFilterSelected : setAgeGroupFilter;
      let srkf = isSelect? setRankFilterSelected : setRankFilter;

      let swr = isSelect? setWeightRangeSelected : setWeightRange;

      let cats = [{id: '', name: 'No Selection'}, ...Utils.uniqArrayByKey(divisions.filter(d => d.hashtags).map(d => d.hashtags.split(' ')).flat().map(h => ({id: h, name: h})), 'id')];
      let ags = [{id: '', name: 'No Selection'}, ...Utils.uniqArrayByKey(divisions.map(d => d.getAgeGroup()).filter(a => a), 'id')];
      let ranks = [{id: '', name: 'No Selection'}, ...Utils.uniqArrayByKey(divisions.map(d => d.getRank()).filter(r => r), 'id')];

      const setFilterChange = (func, v) => {
        func(v);
        setIsFilterChange(!isfilterChange);
      }

      return <ThemeProvider theme={Theme}>
        <div className="RangeFilter">
          {cats.length>0 && <MultiSelectFormInput value={catf}
            multiple
            name="hashtags"
            label="Select Categories"
            optionLabel="name"
            optionValue="id"
            fetchOptions={searchVal => {
              let filters = !searchVal? cats : cats.filter(c => {
                  let words = searchVal.toLowerCase().split(' ');
                  return words.every(word => c.id.toLowerCase().includes(word));
              });
              return Promise.resolve(filters);
            }}
            onChange={v => setFilterChange(scatf, v)}
          />}
          <div className="range">
            <RangeSlider label="Age Range" size={ageSize} range={ar} onChange={v => setFilterChange(sar, v)}/>
            <RangeSlider label="Weight Range" size={weightSize} range={wr} onChange={v => setFilterChange(swr, v)}/>
          </div>
          <div className="category">
            <SelectFormInput 
              value={agf}
              options={ags.map(a => ({value: a.id, label: a.name}))} 
              name="age_group_filter"
              label="Age Group Filter"
              onChange={v => setFilterChange(sagf, v)}
            />
            <SelectFormInput 
              label="Rank Filter" 
              value={rkf}
              options={ranks.map(a => ({value: a.id, label: a.name}))} 
              name="rank_filter"
              onChange={v => setFilterChange(srkf, v)}
            />
          </div>
        </div>
      </ThemeProvider>;
    }

    const getListFilter = (d, ar, wr) => {
      let ag = d.getAgeGroup && d.getAgeGroup();
      if (!ag) {
        return false;
      }
      try{
        let {age_min=ageSize[0], age_max=ageSize[1]} = ag;
        let {weight_min=weightSize[0], weight_max=weightSize[1]} = d.getWeightClass() || {};
        return Utils.hasRangeIntersection(weight_min, weight_max, wr[0], wr[1])
          && Utils.hasRangeIntersection(age_min, age_max, ar[0], ar[1]);
      }catch(e) {
        console.log(e);
        return false;
      }
    }
    const hasCategory = (d, f) => {
      let fvalues = f && f.map(f => f.id.toLowerCase());
      const hasIntersection = () => {
        return Utils.intersection((d.hashtags && d.hashtags.toLowerCase().split(' ') || []), fvalues).length > 0;
      }
      return !f || f.length===0 || hasIntersection();
    }
    
    let as = availables
      .filter(d => hasCategory(d, categoryFilter))
      .filter(d => getListFilter(d, ageRange, weightRange))
      .filter(d => !ageGroupFilter || d.getAgeGroup().id === ageGroupFilter)
      .filter(d => !rankFilter || d.getRank().id === rankFilter)
      .map(d => ({ value: d.id, label: getDivisionDisplay(d) }));
    let ss = selecteds
      .filter(d => hasCategory(d, categoryFilterSelected))
      .filter(d => getListFilter(d, ageRangeSelected, weightRangeSelected))
      .filter(d => !ageGroupFilterSelected || d.getAgeGroup().id === ageGroupFilterSelected)
      .filter(d => !rankFilterSelected || d.getRank().id === rankFilterSelected)
      .map(d => ({ value: d.id, label: getDivisionDisplay(d) }));

    return <TransferList ref={transferListRef}
      filterLabel="Name Filter"
      selectTitle="Active Divisions"
      unSelectTitle="Available for Activation"
      selectButtonTitle="Activate"
      unSelectButtonTitle= "Deactivate"
      availFilter={<ListFilter />}
      selectFilter={<ListFilter isSelect/>}
      availables={[...as]}
      selecteds={[...ss]}
      onFilterReset={isSelect => {
        if (isSelect) {
          setCategoryFilterSelected(null);
          setAgeGroupFilterSelected(null);
          setWeightRangeSelected(weightSize);
          setAgeRangeSelected(ageSize);
          setRankFilterSelected(null);
        }else {
          setCategoryFilter(null);
          setAgeGroupFilter(null);
          setWeightRange(weightSize);
          setAgeRange(ageSize);
          setRankFilter(null);
        }
      }}
      onSelect={(_sMoved, _aMoved) => {
        let avs, sls;
        if (_aMoved){
          avs = availables.filter(d => !_aMoved.includes(d.id));
          sls = [...selecteds, ..._aMoved.map(a => league.getDivisions().find(d => d.id === a))];
        }
        if (_sMoved){
          sls = selecteds.filter(d => !_sMoved.includes(d.id));
          avs = [...availables, ..._sMoved.map(s => league.getDivisions().find(d => d.id === s))];
        }
        
        setAvailables(avs);
        setSelecteds(sls);
        importButtonRef.current.disabled = sls.length === tournament.getDivisions().length;
      }}
      buttons={<div className="CustomButtons">
        <button key={2} className="button" ref={importButtonRef}
          onClick={() => {
            onImport(transferListRef.current.getSelected());
          }}
        >
          Save
        </button>
        <button key={3} className="button"
          onClick={onDone}
        >
          Cancel
        </button>
      </div>}
    />
  }

  let filters = Object.keys(FILTER_DIVISION_CATEGORY);
  return <ThemeProvider theme={Theme}>
    <div className="ImportDivisions">
      <Paper elevation={3} style={{ margin: 20 }} >
        <button className="button icon_button cancel" onClick={onDone}><CancelIcon /></button>
        <AvailableDivisions onDone={onDone}/>
      </Paper>
    </div>
  </ThemeProvider>;
}

export function RangeSlider({label, range, onChange, size}) {
  const [value, setValue] = React.useState(range);
  return <div className="RangeSlider">
    <span className="label">{label}</span>
    <Slider
      min={size[0]}
      max={size[1]}
      value={value}
      onChange={(e, v) => setValue(v)}
      onChangeCommitted={(e, v) => onChange(v)}
      valueLabelDisplay="on"
      getAriaValueText={() => `fuck you`}
    />
  </div>;
}

export default TournamentDivisions;