import { Alert } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import LocalServerModel from "../../serverUtils/models/LocalServerModel";
import Utils from "../../serverUtils/Utils";
import { useStore } from "../../Store";
import { duplicate_station_error, getStationName, initStation, isMaster } from "./LocalServer";
import { useHistory } from 'react-router-dom';
import Schedules from "../TournamentPage/Schedules";

let sse;
export const LocalSchedule = forwardRef(({ }, ref) => {
  const history = useHistory();
  const [isInit, setIsInit] = useState(false);
  const station = useStore(state => state.station);
  const setStation = useStore(state => state.setStation);
  const localServer = useStore((state) => state.local_server);
  const tournament = useStore(state => state.local_tournament);
  const setTournament = useStore(state => state.setLocalTournament);
  const [refresh, setRefresh] = useState(false);
  const [notification, setNotification] = useState();
  const [message, setMessage] = useState();
  const [redirect, setRedirect] = useState();
  const [schedules, setSchedules] = useState();
  const matScheduleRef = useRef();

  useImperativeHandle(ref, () => ({
    setNotification,
  }));

  useEffect(() => {
    if (tournament) {
      if (!schedules){
        tournament.getSchedules().then(ss => setSchedules(ss));
      }else {
        setSchedules(schedules);
      }
    }else {
      let tourId = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament);
      tourId && LocalServerModel.getTournament(tourId).then(tour => setTournament(tour));
    }
  }, [tournament, schedules]);

  useEffect(() => {
    station && station.type === LocalServerModel.STATION_TYPE.schedule && initSSE();
  }, [localServer, station]);

  const initSSE = async () => {
    sse && sse.close();
    setIsInit(true);
    sse = initStation({
      localServer,
      type: LocalServerModel.STATION_TYPE.schedule,
      receive: (message) => {
        receive(message);
      },
      history,
      forceUpdate: () => setRefresh(!refresh),
    });
  };

  const receive = (message) => {
    if (message.to !== LocalServerModel.LOCAL_SERVER.ping && message.to !== station.station) {
      return;
    }
    
    if (message === duplicate_station_error) {
      setMessage('Duplicate station: ' + Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station))
    }
    const { update, to, from, notification, raw, tournament_id, type } = message;
    if (from === station.station) {
      return;
    }
    if (to === LocalServerModel.LOCAL_SERVER.ping) {
      return LocalServerModel.sendMessage({
        tournament_id,
        from: station.station, to,
        label: station.label,
        type: LocalServerModel.STATION_TYPE.schedule,
      });
    }
    if (to && to !== station.station) {
      return;
    }
    console.log(
      '\nreceive:',
      Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'),
      '\nmessage:',
      message
    );
    Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament, tournament_id);
    if (type === LocalServerModel.STATION_TYPE.schedule && raw) {
      return setSchedules(JSON.parse(raw));
    }
    if (LocalServerModel.setCurrentStation(message, station, setStation, setRedirect)) {
      sse.close();
      return;
    };
    if (notification) {
      return setNotification(notification);
    }

  };

  const toggleFullScreen = () => {
    matScheduleRef.current.toggleFullScreen();
  }
  return redirect? redirect :
    <div className="LocalSchedule" style={{ position: 'relative' }}>
      <h1>Schedule Station {getStationName(station && station.label)}</h1>
      <Schedules
        ref={matScheduleRef}
        tournament={tournament}
        schedules={schedules}
        />
      {notification && <Alert severity='info'>{notification}</Alert>}
      {message && <Alert severity='error'>{message}</Alert>}
    </div>
  ;
});