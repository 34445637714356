import { useState } from "react";
import {
  IonContent,
  IonImg,
  IonItem,
  IonItemGroup,
  IonList,
  IonPage,
  IonThumbnail,
  IonTitle, IonButtons,
  IonIcon,
  useIonViewWillEnter
} from "@ionic/react";
import pageStyles from "./../Page.module.scss";
import LoadingList from "../../components/LoadingList/LoadingList";
import classNames from "classnames";
import { addCircleOutline as addIcon } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { Link, ThemeProvider } from "@mui/material";
import GymModel from "../../serverUtils/models/GymModel";
import { useStore } from "../../Store";
import Theme from "../../components/FormInput/Theme";
import { FilterInput } from "../../components/Form/Form";
import "./GymsPage.scss";

const GymsPage = () => {
  const history = useHistory();
  const session = useStore((state) => state.session);
  const [gyms, setGyms] = useState(null);
  const [searchString, setSearchString] = useState();
  const searchStrings = useStore(state => state.searchStrings);
  const setSearchStrings = useStore(state => state.setSearchStrings);

  const getData = async (search_string = searchString) => {
    const gyms = await GymModel.getGyms({ search_string });
    if (gyms) {
      setGyms(gyms);
    }
  };

  useIonViewWillEnter(() => {
    if (!gyms) {
      getData(searchStrings.gyms);
    }
  }, [gyms, searchString, session]);

  const getImageById = (id) => {
    const image = gyms.images_doc.find((image) => image && image.ref === id);
    if (image) {
      return <img alt="gym" src={image.data.join("")} />;
    }
    return (
      <img
        alt="user"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"
      />
    );
  };

  const doFilter = (s) => {
    setSearchStrings('gyms', s);
    getData(s);
  };

  return (
    <IonPage className={`${pageStyles.page} GymsPage`}>
      <ThemeProvider theme={Theme}>
        <IonContent>
          <div className={`page-header ${pageStyles.pageHeader}`}>
            <div
              className={pageStyles.pageHeaderImage}
            />
            <div className={pageStyles.pageHeaderTitleWrapper}>
              <h1
                id="gyms_page"
                className={classNames("display-font", pageStyles.pageHeaderTitle)}
              >
                Gyms
              </h1>
              <p className={pageStyles.pageHeaderSubtitle}>
                Find a gym and join a league
              </p>
              <FilterInput label="Search Gym" entity="gyms" onFilter={v => doFilter(v)} />
            </div>
          </div>
          <div className={pageStyles.marginBottom}>
            {gyms ? (
              <IonList >
                {gyms.gyms.map((gym, i) => (
                  <IonItem routerLink={`/gyms/${gym.id}`} lines="none" key={i}>
                    <IonItemGroup>
                      <IonThumbnail className={pageStyles.listImage}>
                        {getImageById(gym.id)}
                      </IonThumbnail>
                    </IonItemGroup>
                    <IonItemGroup>
                      <IonTitle>{gym.name}</IonTitle>
                    </IonItemGroup>
                  </IonItem>
                ))}
              </IonList>
            ) : (
              <LoadingList />
            )}
            {/*=======*/}

            {/*            {data ?*/}
            {/*                <IonList>*/}
            {/*                    {data?.gyms.map((gym, i) =>*/}
            {/*                        <IonItem routerLink={`/tournaments/${gym.id}`} lines="none" key={gym.id}>*/}
            {/*                            <IonItemGroup>*/}
            {/*                                <IonThumbnail className={pageStyles.listImage}>*/}
            {/*                                    {getImageById(gym.id)}*/}
            {/*                                </IonThumbnail>*/}
            {/*                            </IonItemGroup>*/}
            {/*                            <IonItemGroup className={pageStyles.listContent}>*/}
            {/*                                <h5>{gym.name}</h5>*/}
            {/*                                /!*<p className={styles.date}>{formatDate(tournament.dates.start_date)} - {formatDate(tournament.dates.end_date)}</p>*!/*/}
            {/*                                /!*{tournament.locations.map((location, i) => <p key={`location-${i}`}>{location.city + ','} {location.state + ','} {location.country}</p>)}*!/*/}
            {/*                            </IonItemGroup>*/}
            {/*                        </IonItem>*/}
            {/*                    )}*/}
            {/*                </IonList> :*/}
            {/*                <LoadingList/>*/}
            {/*            }*/}
            {/*>>>>>>> Stashed changes*/}
          </div>
        </IonContent>
      </ThemeProvider>
    </IonPage>
  );
};

export default GymsPage;
