import { IonButton, IonButtons, IonText } from "@ionic/react";
import { Component, createRef } from "react";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ImageFormInput from "../FormInput/ImageFormInput";
import UserModel from "../../serverUtils/models/UserModel";
import ImageModel from "../../serverUtils/models/ImageModel";
import { ImageListItem, Paper } from "@mui/material";
import { default as DeleteIcon } from "@mui/icons-material/RemoveCircle";
import styles from "../../components/Form/Form.module.scss";

export default class Photos extends Component{
    constructor(params) {
        super(params);
        const {entity, entity_type, session, model} = params;
        this.model = model;
        this.state = {
            images: [],
            newImages: [],
            message: '',
            entity,
            entity_type,
            session,
            ImageFormInputRef: createRef()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.session !== prevProps.session) {
            this.setState({ session: this.props.session });
        }
    }

    componentDidMount() {
        this.model.getPhotos({id: this.state.entity.id})
            .then(photos => {
                this.setState({images: photos});
            });
    }

    async doUpload() {
        const { newImages, entity, images } = this.state;
        const { uploads, errors } = await ImageModel.photosUpload(newImages, entity.id);
        this.setState({
            newImages: [],
            images: [...images, ...uploads],
            message: errors.length > 0 ? `error: Error uploading images: ${errors.join(', ')}` : 'success: Successfully upload images'
        }, () => this.state.ImageFormInputRef.current.refresh([]));
    }

    async doDelete(id) {
        const {images} = this.state;
        let result = await ImageModel.deleteImage(id);
        if (result && result.id) {
            let imgs = images.filter(i => i.id !== id);
            this.setState({images: imgs});
        }
    }

    render() {
        const { session, entity, entity_type, newImages, message, images, ImageFormInputRef } = this.state;
        let isAdmin = entity && UserModel.isAdmin(session, entity_type, entity.id);
        return  (
            <div className="Photos">
                {isAdmin && 
                    <div className="upload-image">
                        <ImageFormInput ref={ImageFormInputRef}
                            name="photos"
                            images={newImages}
                            onImage={imgs => {
                                this.setState({
                                    message: '',
                                    newImages: [...imgs]
                                });
                            }}
                            multiple
                        />
                        {newImages.length > 0 &&
                            <IonButtons style={{ marginBottom: 20 }}>
                                <IonButton onClick={this.doUpload.bind(this)} fill="solid" color="primary">Upload Image(s)</IonButton>
                            </IonButtons>
                        }
                        <Paper elevation={24}><IonText color="secondary">{message}</IonText></Paper>
                    </div>}
                {!images? <LoadingScreen /> : 
                    <div>
                        {images.map((img, i) => {
                            return <ImageListItem key={i}>
                                <img src={img?.data?.join('')} alt={`${i}`} style={{ maxWidth: 200 }} />
                                <DeleteIcon className={styles.delete_icon} onClick={() => this.doDelete(img.id)} />
                            </ImageListItem>
                        })}
                    </div>
                }
            </div>
        )
    }
}