import { useEffect, useState } from "react";
import { IMAGE_TYPE, STATUS } from "../../components/Form/Form";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import LoadingList from "../../components/LoadingList/LoadingList";
import ImageModel from "../../serverUtils/models/ImageModel";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import UserModel from "../../serverUtils/models/UserModel";
import { Link, Paper, ThemeProvider } from "@mui/material";
import Theme from "../../components/FormInput/Theme";
import { default as NoImageIcon } from "@mui/icons-material/ImageOutlined";
import { ENTITY } from "../../serverUtils/Models";

export default function LeagueTeams({league, session}) {
    const [teams, setTeams] = useState(league.getTeams() || []);
    
    useEffect(() => {
        setTeams(league.getTeams());
        league.getTeams().length > 0 && ImageModel.getImageByRefs({ids: league.getTeams().map(t => t.id), image_type: IMAGE_TYPE.team})
            .then(images => {
                teams.map(t => {
                    t.getImage = () => images.find(i => i.ref === t.id);
                    return t;
                });
                setTeams([...teams]);
            });
    }, [league]);

    const GetImage = ({ t }) => {
        if (t.getImage && t.getImage()) {
            return <img style={{ width: 300 }} src={t.getImage().data.join('')} alt={t.id} />;
        }
        return <NoImageIcon style={{ width: 200, height: 200 }} />;
    }
    return !teams? <LoadingList /> : (
        <ThemeProvider theme={Theme}>
            <div style={{ padding: 20 }}>
                {teams.map((t, i) => {
                    return <Paper className="team" key={i} style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                        <Link href={`/teams/${t.id}`}>
                            <GetImage t={t} />
                            <h5>{t.name}</h5>
                        </Link>
                        {league && UserModel.isAdmin(session, ENTITY.league, league.id) &&
                            <SelectFormInput options={STATUS} value={t.status} />}
                    </Paper>
                })}
            </div>
        </ThemeProvider>
    )
}