import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  useIonViewWillEnter
} from "@ionic/react";

import { useStore } from "../../Store";
import TournamentInformationForm from "../../components/TournamentForm/TournamentInformationForm";
import classNames from "classnames";
import pageStyles from "../Page.module.scss";
import styles from "../../components/Form/Form.module.scss";
import "./TournamentEditPage.scss";
import { useEffect, useState, useRef, createRef } from "react";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import Registrations from "../../components/TournamentForm/Registrations";
import Poolings from "../../components/TournamentForm/Poolings";
import Promos from "../../components/TournamentForm/Promos";
import TournamentDivisions from "../../components/TournamentForm/TournamentDivisions";
import TournamentSchedules from "../../components/TournamentForm/TournamentSchedules";
import TournamentStoreItems from "../../components/TournamentForm/TournamentStoreItems";
import TournamentCartReport from "../../components/TournamentForm/TournamentCartReport";
import { RequestUtils, setupSSE } from "../../serverUtils/requests";
import UserModel from "../../serverUtils/models/UserModel";
import {
  Badge, Link,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import TableFormInput, {
  IndexCell,
  ToolbarButtons,
} from "../../components/FormInput/TableFormInput";
import { default as DeleteIcon } from "@mui/icons-material/RemoveCircle";
import { default as AddIcon } from "@mui/icons-material/AddCircle";
import { default as EditIcon } from "@mui/icons-material/ModeEditOutline";
import TransferList from "../../components/FormInput/TransferList";
import Utils from "../../serverUtils/Utils";
import AlertPane from "../../components/FormInput/AlertPane";
import { Tabs } from "../../components/Tabs/Tabs";
import LoadingList from "../../components/LoadingList/LoadingList";
import BracketWrapper from "../../components/Bracket/BracketWrapper";
import { ENTITY } from "../../serverUtils/Models";
import DateFormInput1 from "../../components/FormInput/DateFormInput1";
import { getDivisionDisplay } from "../../components/LeagueInformationForm/Divisions";
import { hasTableFormChange } from "../../components/Form/Form";
import EntityMessagesPage from "../MessagesPage/EntityMessagesPage";

const TABS = [
  { html: <div>Information</div>, url: 'information' },
  { html: <div>Divisions</div>, url: 'divisions' },
  { html: <div>Pricing & Combinations</div>, url: 'pricing' },
  // { html: <div>Promos</div>, url: 'promos' },
  { html: <div>Competitors</div>, url: 'competitors' },
  { html: <div>Poolings</div>, url: 'poolings' },
  { html: <div>Brackets</div>, url: 'brackets' },
  // { html: <div>Schedules</div>, url: 'schedules' },
  // { html: <div>Store Items</div>, url: 'store_items' },
  // { html: <div>Payout</div>, url: 'payout' },
  { html: <div>Messages</div>, url: 'messages' },
  // { html: <div>Payouts</div>, url: 'payout' },
]

const TournamentEditPage = (props) => {
  const session = useStore(state => state.session);
  const setSession = useStore(state => state.setSession);
  const [data, setData] = useState(null);
  let isAdd = document.location.pathname.includes('/add');
  const [tabs, setTabs] = useState(isAdd? TABS.slice(0, 1) : TABS);
  const cache = useStore((state) => state.pushTournament);
  const [sectionIndex, setSectionIndex] = useState();
  const tabsRef = useRef();
  const registrationsRef = useRef();
  const poolingsRef = useRef();
  const bracketsRef = useRef();
  // const tournamentSchedulesRef = useRef();
  const tournamentDivisionsRef = useRef();
  const tabObjRefs = useRef({
    registrationsRef, 
    poolingsRef, 
    bracketsRef, 
    // tournamentSchedulesRef, 
    tournamentDivisionsRef,
    save: async currentRefName => {
      let refNames = Object.keys(tabObjRefs.current);
      for (let refName of refNames) {
        if ([currentRefName, 'save', 'checkCRC'].includes(refName)) {
          continue;
        }
        let ref = tabObjRefs.current[refName];
        if (ref.current.checkCRC()) {
          await ref.current.doSave(true);
        }
      }
    },
    checkCRC: currentRefName => {{
      Object.keys(tabObjRefs.current).forEach(refName => {
        let isSkip = [currentRefName, 'save', 'checkCRC'].includes(refName);
        if (!isSkip) {
          try {
            tabObjRefs.current[refName].current.checkCRC();
          }catch(e) {
            console.log(e);
          }
        } 
      })
    }}
  });

  const loadTabs = count => {
    let ts = tabs.filter(t => !t.url.includes('messages'));
    ts.push({
      html: <div>
        {!isNaN(count) && <Badge color="primary"
            badgeContent={count}>Messages
        </Badge>}
      </div>,
      url: 'messages'
    }) ;
    setTabs(ts);
  }

  let urlId = RequestUtils.getURLId("tournaments");
  useEffect(() => {
    loadTabs(0);
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(tabs.findIndex(t => t.url === params.tab));
      if (params.tab === 'messages') {
        return;
      }
    }
    getMessages();
  }, []);

  const getMessages = () => {
    TournamentModel.getMessages(urlId)
      .then(data => {
        if (data && data.messages) {
          let unreads = Utils.sumArray(data.messages.map(m => m.count));
          loadTabs(unreads);
        }
      });
  }

  useEffect(() => {
    if (urlId === 'add') {
      setTabs(tabs.slice(0, 1));
      setSectionIndex(0);
      setData(null);
    }
  }, [urlId]);

  useEffect(() => {
    if (session) {
      getData(urlId);
    }
  }, [session && session.id]);

  const getData = async (tournamentId) => {
    if (!tournamentId) {
      return;
    }
    if (tournamentId === "add") {
      setTabs(tabs.slice(0, 1));
      return setData(UserModel.addSessionAsAdmin(session));
    }
    let tournament; // = getCache[tournamentId];
    if (!tournament) {
      tournament = await TournamentModel.getTournament(tournamentId);
      cache(tournamentId, tournament);
    }
    if (tournament) {
      if (UserModel.isAdmin(session, ENTITY.tournament, tournament.id)) {
        setData({ ...tournament });
        setupSSE(tournament.id, messageObj => {
          if (messageObj.message === 'messages') {
            getMessages();
          }
        })
      }
    }
  };

  useIonViewWillEnter(() => {
    if (session) {
      getData(RequestUtils.getURLId("tournaments"));
    }
  }, [session]);

  let getSections = () => isAdd? 
    [<TournamentInformationForm data={data} />]
    :
    [
      <TournamentInformationForm data={data} />,
      <TournamentDivisions tournament={data} ref={tournamentDivisionsRef} tabsRef={tabsRef} tabObjRefs={tabObjRefs}/>,
      <Pricing tournament={data} />,
      // <Promos tournament={data} />,
      <Registrations ref={registrationsRef} tournament={data} isEditMode={true} tabsRef={tabsRef} tabObjRefs={tabObjRefs}/>,
      <Poolings ref={poolingsRef} tournament={data} tabsRef={tabsRef} tabObjRefs={tabObjRefs}/>,
      <BracketWrapper ref={bracketsRef} tournament={data} tabsRef={tabsRef} isEditMode tabObjRefs={tabObjRefs}/>,
      // <TournamentSchedules ref={tournamentSchedulesRef} tournament={data} isEditMode={true} tabsRef={tabsRef} tabObjRefs={tabObjRefs}/>,
      // <TournamentStoreItems tournament={data} />,
      // <TournamentCartReport tournament={data} />,
      <EntityMessagesPage entity={data} 
        getMessages={() => TournamentModel.getMessages(data.id)} 
        onMessageCount={(count, isUpdateHigher, thread) => {
          loadTabs(count);
          if (isUpdateHigher) {
            let tour = session.getAdminInfo().tournaments.find(t => t.id === data.id);
            if (tour && tour.getMessages) {
              tour.getMessages().messages.find(t => t.from === thread).count = 0;
              setSession({...session});
            }
          }
        }}/>,
    ];

  return (
    <IonPage>
      <IonHeader>
        {/*<IonToolbar>*/}
        {/*    <IonButtons slot="start">*/}
        {/*        <IonBackButton defaultHref="home" />*/}
        {/*    </IonButtons>*/}
        {/*    <History />*/}
        {/*</IonToolbar>*/}
      </IonHeader>
      <IonContent className={classNames('TournamentEditPage', pageStyles.pageContent)}>
        <h1 id="tournament_title" className="display-font">
          {props.match.params.id ? "Edit" : "New"} Tournament
        </h1>
        {data && data.id && (
          <div className="tournament-name">
            <div className="tournament">
              <Link href={`/tournaments/${props.match.params.id}`} className="tournament">
                {data && data.name}
              </Link>
              <span className="entity-type">{Utils.formatDate(data.dates.start_date)}</span>
            </div>
            <div className="league">
              <Link href={data && data.getLeague && `/leagues/${data.getLeague()?.id}`} >
                {data && data.getLeague && data?.getLeague()?.name}
              </Link>
              <span className="entity-type">@league</span>
            </div>
          </div>
        )}
        <div className={classNames('tabs-wrapper', pageStyles.pageContent)}>
          {/*<SwipableTabs*/}
          {/*  sections={data && data.id ? sections : [sections[0]]}*/}
          {/*  data={data} setData={setData}*/}
          {/*  index={sectionIndex}*/}
          {/*/>*/}
          {!data ? <LoadingList /> : 
            <Tabs ref={tabsRef}
              index={sectionIndex}
              tabs={tabs}
              sections={getSections()}
            />}
        </div>
      </IonContent>
    </IonPage>
  );
};

const headCells = [
  {
    id: "fee_name",
    disablePadding: false,
    label: "Name",
    align: "left",
    width: "15%",
  },
  {
    id: "period",
    disablePadding: false,
    label: "Periods",
    width: "36%",
  },
  {
    id: "divisions",
    disablePadding: false,
    label: "Divisions",
  },
];

function Pricing({ tournament }) {
  const tableRef = useRef();
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);

  const checkCRCMessageRef = useRef();
  const checkCRCMessageRef1 = useRef();
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const CRC_Fields = ['fee_name', 'fees', 'logic', 'period_name', 'amount', 'start_date', 'end_date', 'divisions'];
  const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
    let words = Utils.separateWords(filterString);
    if (headCell.id === "fee_name") {
      return (filterData || tournament.fee_info).filter(f => {
        return f.fee_name.toLowerCase().includes(filterString.toLowerCase());
      });
    } 
    if (headCell.id === "period_name") {
      return (filterData || tournament.fee_info).filter(f => {
        return f.fee_info.find(fi => fi.fee.find(fe => fe.fee_name.toLowerCase().includes(filterString.toLowerCase())));
      });
    } 
    if (headCell.id === "divisions") {
      return (filterData || tournament.fee_info).filter(f => {
        return f.logic.find(l => {
          return l.divisions.find(d => {
            let div = tournament.getDivisions().find(_d => _d.id === d);
            return isFilter(words, () => {
              return getDivisionDisplay(div, true);
            });
          });
        });
      });
    } 
  }

  const checkCRC = () => {
    checkCRCMessageRef.current.innerHTML = '';
    checkCRCMessageRef1.current.innerHTML = '';
    if (hasTableFormChange(tournament.fee_info, CRC_Fields)) {
        checkCRCMessageRef.current.innerHTML = 'Data has changed';
        checkCRCMessageRef1.current.innerHTML = 'Data has changed';
        toolbarButtonsRef.current.setIndicator({save: 'yellow'});
        toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
    }else {
        toolbarButtonsRef.current.setIndicator({save: ''});
        toolbarButtonsRef1.current.setIndicator({save: ''});
    }
  }

  useEffect(() => {
    if (tournament && tournament.fee_info) {
      tournament.fee_info.forEach(fe => {
        if (fe.fees) {
          fe.fees.forEach(f => {
            f.start_date = TournamentModel.convertTimeZoneToLocal(f.start_date_tz);
            f.end_date = TournamentModel.convertTimeZoneToLocal(f.end_date_tz);
          });
        }
        !fe.crc && (fe.crc = Utils.getCRC(fe, CRC_Fields));
      });
    }
  }, [tournament]);

  const doAdd = () => {
    tournament.fee_info.push({});
    checkCRC();
    setRefresh(!refresh);
    tableRef.current.gotoLastPage();
  };
  const doSave = async () => {
    setMessage("");
    let fee_info = Utils.deepCopy(tournament.fee_info || []);
    if (fee_info.filter(fi => fi.updated).length === 0) {
      return;
    }

    if (fee_info.find(s => !s.fee_name)) {
      return setMessage('info: Pricing name must be filled')
    }

    for (let fi of fee_info) {
      for (let fee of fi.fees) {
        fee.start_date = TournamentModel.convertToTimeZoneDate(
          fee.start_date,
          tournament.time_zone
        );
        fee.end_date = TournamentModel.convertToTimeZoneDate(
          fee.end_date,
          tournament.time_zone
        );
      }
      fi.logic = (fi.logic && fi.logic.filter(l => l.divisions.length > 0)) || [];
    }
    console.log(fee_info);
    let res = await TournamentModel.updateFeeInfo(tournament.id, fee_info);
    if (res && !res.error) {
      tournament.fee_info.forEach(fi => {
        delete fi.updated;
        for (let fee of fi.fees) {
          fee.start_date_tz = TournamentModel.convertToTimeZoneDate(fee.start_date, tournament.time_zone);
          fee.end_date_tz = TournamentModel.convertToTimeZoneDate(fee.end_date, tournament.time_zone);
        }
        fi.crc = Utils.getCRC(fi, CRC_Fields);
      });
      setMessage("success: Successfully update tournament fee information");
      return checkCRC();
    }
    setMessage("error: Error updating tournament fee information");
  };

  let fee_info = (tournament.fee_info || []).map((fi, i) => {
    fi.id = i;
    return fi;
  });
  
  const doDelete = async () => {
    let { selected } = tableRef.current;
    if (selected.length === 0) {
      return setMessage('info: Nothing was selected');
    }
    let sortIndex = [...selected].sort().reverse();
    for (let i of sortIndex) {
      tournament.fee_info.splice(i, 1);
    }
    tableRef.current.setSelected([]);
    checkCRC();
    setRefresh(!refresh);
  }

  return (
    <div className="TournamentPricing">
      <AlertPane message={message} setMessage={setMessage} timeOut={5000}/>
      <TableFormInput name="TournamentPricing"
        onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
        onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
        isEditable
        toolbarButtons={() => (
          <ToolbarButtons ref={toolbarButtonsRef} doAdd={doAdd} doDelete={doDelete} doSave={doSave} />
        )}
        toolbarButtons1={() => (
          <ToolbarButtons ref={toolbarButtonsRef1} doAdd={doAdd} doDelete={doDelete} doSave={doSave} />
        )}
        ref={tableRef}
        getFilterData={getFilterData}
        headCells={headCells}
        data={fee_info}
        renderTRow={({ row, isSelected, index, handleClick }) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              className={`TableRow ${!row.fee_name? 'is-add':''}`}
              hover
              onClick={(event) => {
                handleClick(event, row.id);
              }}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <IndexCell
                  isItemSelected={isItemSelected}
                  index={index + 1}
                  labelId={labelId}
                />
              </TableCell>
              <TableCell>
                <FeeName
                  name={row.fee_name}
                  onChange={v => {
                    row.fee_name = v;
                    row.updated = true;
                    checkCRC();
                  }}
                />
              </TableCell>
              <TableCell>
                <FeePeriods row={row} index={index} 
                  tournament={tournament} 
                  setReload={() => {
                    row.updated = true;
                    checkCRC();
                    setRefresh(!refresh);
                  }} />
              </TableCell>
              <TableCell>
                <DivisionLogics 
                  row={row} 
                  tournament={tournament} 
                  setReload={() => {
                    checkCRC();
                    setRefresh(!refresh);
                  }}/>
                <Tooltip title="Add divivion list"> 
                  <AddIcon
                    className={styles.add_icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!row.logic) {
                        row.logic = [];
                      }
                      row.logic.push({divisions: []});
                      checkCRC();
                      setRefresh(!refresh);
                    }}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        }}
      />
    </div>
  );
}

function FeePeriods({ row, index, setReload, tournament }) {
  const [message, setMessage] = useState();
  return <div className="FeePeriods">
    {message && <AlertPane message={message} setMessage={setMessage} timeOut={5000}/>}
    {(row.fees || []).map((f, i) => {
      const validate = () => {
        setMessage('');
        const { fees } = row;
        for (let f = 0; f < fees.length; f++) {
          let fee = fees[f];
          if (!fee) {
            continue;
          }
          if (f > 0 && fee.start_date < fees[f - 1].start_date) {
            return setMessage('error: Dates are out of order');
          }
        }
        setReload();
      }
      return <FeePeriod
        tournament={tournament}
        fee_info={row}
        fee={f}
        key={i}
        fiIndex={index}
        pindex={i}
        onDelete={() => {
          row.fees.splice(i, 1);
          setReload();
        }}
        validate={validate}
      />
    })}
    <Tooltip title="Add period"> 
      <AddIcon
        className={styles.add_icon}
        onClick={(e) => {
          e.stopPropagation();
          if (!row.fees) {
            row.fees = [];
          }
          row.fees.push({});
          setReload();
        }}
      />
    </Tooltip>
  </div>
}

const FeeName = ({ name: _name, onChange }) => {
  const [name, setName] = useState(_name);
  useEffect(() => setName(_name), [_name]);

  return <TextField
    onClick={(e) => e.stopPropagation()}
    value={name || ''}
    onChange={(e) => {
      let n = e.target.value
      setName(n);
      onChange(n);
    }}
    onMouseLeave={() => {
      // onChange(name);
    }}
  />
}

function FeePeriod({ fee, fiIndex, findex, onDelete, validate, tournament }) {
  const [refresh, setRefresh] = useState(false);
  const getMarksDates = () => {
    if (tournament?.dates?.start_date) {
      return [{
        id: 'mark',
        label: 'Tournament Date',
        ts: tournament?.dates?.start_date
      }];
    }
  }
  return (
    <Paper elevation={3} className="Paper">
      <div
        className="FeePeriod"
        onClick={(e) => e.stopPropagation()}
        style={{ marginBottom: 10 }}
      >
        <Tooltip title="Delete period">
          <DeleteIcon
            className={styles.delete_icon}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </Tooltip>
        <div className="period-name">
          <TextField className="name"
            value={fee.period_name || ''}
            label={"Period Name"}
            onChange={(e) => {
              fee.period_name = e.target.value;
              setRefresh(!refresh);
              validate();
            }}
          />
          <TextField error={isNaN(fee.amount)} className="fee"
            value={fee.amount}
            type="float"
            label={"Fee"}
            onChange={(e) => {
              let v = e.target.value;
              if (!isNaN(v)) {
                fee.amount = v;
                setRefresh(!refresh);
                validate();
              }
            }}
          />
        </div>
        <div className="period-dates">
          <DateFormInput1 
            name={`fee_info[${fiIndex}].fees[${findex}].start_date`}
            hasTime
            label="Start Date"
            value={fee.start_date || ""}
            endDate={fee.end_date}
            marks={getMarksDates()}
            onChange={v => {
              fee.start_date = new Date(v).getTime();
              if (!fee.end_date || fee.end_date < fee.start_date) {
                fee.end_date = fee.start_date;
              }
              setRefresh(!refresh);
              validate();
            }}/>
          <DateFormInput1 
            name={`fee_info[${fiIndex}].fees[${findex}].end_date`}
            hasTime
            label="End Date"
            value={fee.end_date || ""}
            startDate={fee.start_date}
            marks={getMarksDates()}
            onChange={v => {
              fee.end_date = new Date(v).getTime();
              if (fee.end_date < fee.start_date) {
                fee.start_date = fee.end_date;
              }
              setRefresh(!refresh);
              validate();
            }}/>
        </div>
      </div>
    </Paper>
  );
}

const FILTER_DIVISION_CATEGORY = {
  kid: { value: "kid", label: "1-17 yrs" },
  adult: { value: "adult", label: "18+ yrs" },
  male: { value: "M", label: "Male" },
  female: { value: "F", label: "Female" },
};

const DivisionLogics = ({row, tournament, setReload}) => {
  const [isEdit, setIsEdit] = useState(-1);
  return <div className="DivisionLogics">
    {(row.logic || []).map((l, i) => 
      <DivisionLogic key={i}
        index={i}
        isEdit={isEdit} setIsEdit={setIsEdit}
        logic={l}
        tournament={tournament}
        onDelete={() => {
          row.logic.splice(i, 1);
          row.updated = true;
          setReload();
        }}
        onChange={() => {
          row.updated = true;
          setReload();
        }}
      />
    )}
  </div>;
}

const DivisionLogic = ({ logic, tournament, onDelete, onChange, isEdit, setIsEdit, index }) => {
  const [divisions, setDivisions] = useState([]);
  const [availFilters, setAvailFilters] = useState([]);
  const [selectFilters, setSelectFilters] = useState([]);
  const ages = ['kid', 'adult'];
  const genders = ['male', 'female']; 
  useEffect(() => {
    setDivisions(logic.divisions);
  }, [logic]);

  const doSelect = (filter, isAvail) => {
    let filters = isAvail? availFilters : selectFilters;
    let setFilter = isAvail? setAvailFilters : setSelectFilters;
    if (filters.includes(filter)) {
      return setFilter([...filters.filter((s) => s !== filter)]);
    }
    if (ages.includes(filter)){
      filters = filters.filter(f => !ages.includes(f));
    }
    if (genders.includes(filter)){
      filters = filters.filter(f => !genders.includes(f));
    }
    setFilter([...filters, filter]);
  };
  
  const SelectDivisions = ({}) => {
    const DivisionFilter = ({isAvail}) => {
      return <IonButtons className="DivisionFilter">
        <div className="filter-chips">
          {Object.keys(FILTER_DIVISION_CATEGORY).map((f, i) => {
            return <IonButton key={i}
              fill="solid"
              style={{ marginLeft: 20 }}
              color={(isAvail? availFilters:selectFilters).includes(f) ? "tertiary":"light"}
              shape="round"
              onClick={() => doSelect(f, isAvail)}>
                {FILTER_DIVISION_CATEGORY[f].label}</IonButton>})}
        </div>
      </IonButtons>;
    }

    const getDivisionFilter = (isAvail) => {
      let filters = isAvail? availFilters : selectFilters;
      let divs = divisions;
      if (isAvail) {
        divs = tournament.getDivisions().filter(d => d && !divs.includes(d.id));
      }else {
        divs = divs.map(d =>  tournament.getDivisions().find(td => td.id === d));
      }
      let result = divs.filter(d => {
        if (!d) {
          return false;
        }

        if (Utils.intersection(ages, filters).length > 0) {
          const {age_min, age_max} = d.getAgeGroup();
          if (filters.includes(FILTER_DIVISION_CATEGORY.adult.value) && Utils.hasRangeIntersection(1, 17, age_min, age_max)) {
            return false;
          } 
          if (filters.includes(FILTER_DIVISION_CATEGORY.kid.value) && Utils.hasRangeIntersection(18, 1000, age_min, age_max)) {
            return false;
          }
        }
        return true;
      });

      result = result.filter(d => {
          if (Utils.intersection(genders, filters).length > 0) {
            return filters.map(f => FILTER_DIVISION_CATEGORY[f].value).includes(d.gender);
          }
          return true;
        });
            
      return result;
    }

    const getDivisionList = (isAvail) => {
      if (isAvail) {
        return getDivisionFilter(isAvail).filter(d => !divisions.includes(d.id))
          .map((d) => ({ value: d.id, label: getDivisionDisplay(d, true) }));
      }else {
        return getDivisionFilter(isAvail).filter(d => d && divisions.includes(d.id))
          .map((d) => ({ value: d.id, label: getDivisionDisplay(d, true) }));
      }
    }
    return <Paper elevation={3} className="SelectDivisions" onClick={(e) => e.stopPropagation()}>
      <TransferList 
        filterLabel="Name Filter"
        selectTitle="Selected Divisions"
        unSelectTitle="Available Divisions"
        availFilter={<DivisionFilter isAvail/>}
        selectFilter={<DivisionFilter/>}
        availables={getDivisionList(true)}
        selecteds={getDivisionList()}
        onDone={(divs) => {
          logic.divisions = divs;
          setIsEdit(-1);
          onChange();
        }}
        onSelect={(_sMoved, _aMoved) => {
          let sls;
          if (_aMoved){
            sls = [...divisions, ..._aMoved];
          }
          if (_sMoved){
            sls = divisions.filter(d => !_sMoved.includes(d));
          }
          setDivisions(sls);
        }}
        onFilterReset={isSelect => {
          if (isSelect) {
            setSelectFilters([]);
          }else {
            setAvailFilters([]);
          }
        }}
      />
    </Paper>;
  }

  return <Paper elevation={3} >
      {isEdit!==index ? 
        <div className="DivisionLogic" onClick={(e) => e.stopPropagation()}>
          <div className="header" >
            <Tooltip title="Delete division list"> 
              <DeleteIcon
                className={styles.delete_icon}
                onClick={(e) => {
                  onDelete();
                }}
              />
            </Tooltip>
            <Tooltip title="Edit division list"> 
              <EditIcon
                className={styles.edit_icon}
                onClick={(e) => {
                  setIsEdit(index);
                }}
              />
            </Tooltip>
            <Badge
              color="primary"
              max={2000}
              badgeContent={(divisions.length) || 0}
              style={{ marginLeft: 20 }}
            />
            </div>
          {isEdit === -1 && <ul
              className="DivisionLogic"
              style={{ maxHeight: 160, overflow: "auto" }}
            >
              {divisions.map(
                (d, i) => {
                  d = tournament.getDivisions().find((td) => td.id === d);
                  return d && <li key={i}>{getDivisionDisplay(d)}</li>;
                }
              ).filter(d => d)}
            </ul>}
        </div>
      : <SelectDivisions />}
    </Paper>
  ;
}

export default TournamentEditPage;
