import { RequestHelp, RequestUtils } from "../requests";

export default class HelpModel {
    static getHelps = async(isAll) => {
        let url = document.location.pathname;
        if (isAll){
            url = '';
        }
        let response = await RequestHelp.getHelps(url);
        return RequestUtils.getResponseData(response);
    }

    static addHelp = async(params) => {
        let response = await RequestHelp.addHelp(params);
        return RequestUtils.getResponseData(response);
    }

    static updateHelp = async(params) => {
        let response = await RequestHelp.updateHelp(params);
        return RequestUtils.getResponseData(response);
    }

    static deleteHelp = async(id) => {
        let response = await RequestHelp.deleteHelp(id);
        return RequestUtils.getResponseData(response);
    }
}