import { IonPage, IonContent, useIonViewWillEnter, IonButton, IonIcon, IonText } from "@ionic/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RequestMessage, RequestUtils } from "../../serverUtils/requests";
import styles from './../../components/Form/Form.module.scss';
import TextFormInput from "../../components/FormInput/TextFormInput";
import { Paper, TextareaAutosize } from "@mui/material";
import UserModel from "../../serverUtils/models/UserModel";
import { paperPlaneOutline as sendIcon } from "ionicons/icons";
import MessageModel from "../../serverUtils/models/MessageModel";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import MultiSelectFormInput from "../../components/FormInput/MultiSelectFormInput";
import { ENTITY } from "../../serverUtils/Models";
import { useStore } from "../../Store";
import { stopFormSubmitByEnter } from "../../components/Form/Form";

export default function MessagePage(props) {
    const [entityName, setEntityName] = useState();
    const [entity, setEntity] = useState();
    const [sendType, setSendType] = useState();
    const session = useStore(state => state.session);
    const {register, handleSubmit, formState: {errors}, control, setValue, reset} = useForm();
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState();
    const [messageThread, setMessageThread] = useState();
    const [id, setId] = useState();
    const clearNotification = () => {
        if (!props.match) {
            return;
        }
        let { id, entity: e, send_type } = props.match.params || {};
        let to;
        if (send_type !== 'bulk'){
            to = id;
        }
        setNotification({message_type: e, ref: id, subject: '', content: '', to});
        reset();
        setEntityName(RequestUtils.getURLParameters().name);
    }
    useIonViewWillEnter(() => {
        if (!props.match) {
            return;
        }
        let { id, entity: e, send_type } = props.match.params || {};
        setEntity(e);
        setId(id);
        setSendType(send_type);
        clearNotification();
        if (send_type !== 'bulk') {
            return MessageModel.getMessageThread(id)
                        .then(thread => setMessageThread(thread));
        }
    }, [session]);

    const onSubmit = async (d) => {
        setMessage('');
        if (!d.subject) {
            setMessage('Subject require.');
            return;
        }
        let message = {...notification,...d};
        let response = await RequestMessage.sendMessageRequest(message);
        let result = RequestUtils.getResponseData(response);
        if (result && result.id){
            message.id = result.id;
            setMessageThread([...messageThread, message]);
            clearNotification();
            return setMessage('Successfully send message.');    
        }
        setMessage('Failed to send message');
    }

    const reactHookProps = register('content', { required: 'This field is required.' });
    return <IonPage>
        <IonContent>
        <Paper elevation={24}><IonText color="secondary">{message}</IonText></Paper>
          {!session? <div>Login is require</div> : 
            <div className="MessagePage">
                <h4>{entityName} Message Thread</h4>
                <div>Message Thread....</div>
                <hr/>
                <h4>Send a message.</h4>
                {notification && 
                    <form className={styles.form} 
                    onKeyDown={stopFormSubmitByEnter}
                        onSubmit={handleSubmit(onSubmit)}>
                        {sendType==='bulk' && 
                            <div className="bulk">
                                <div className={`tos ${styles.fieldDescription}`}>
                                    <h3>To(s)</h3>
                                </div>
                                <div className="recipients">
                                    <MultiSelectFormInput control={control} 
                                        placeholder="Leave blank for all memberships"
                                        name="tos" label="Recipients"
                                        optionLabel="display" optionValue="id" 
                                        setValue={setValue}
                                        fetchOptions={(searchVal) => {
                                            if (entity === ENTITY.league) {
                                                return LeagueModel.searchMember(id, searchVal);
                                            }
                                            return UserModel.searchUser(searchVal);
                                        }}
                                    />
                                </div>
                            </div>}
                        <br/>
                        <div className="single">
                            <div className={styles.fieldDescription}>
                                <h3>Subject</h3>
                            </div>
                            <div className={styles.fields}>
                                <TextFormInput value={notification.subject} 
                                            name="subject" label="Subject" 
                                            isRequired 
                                            register={register} setValue={setValue}
                                            errors={errors}/>
                            </div>
                            <div className={styles.fieldDescription}>
                                <h3>Message</h3>
                            </div>
                            <div className={styles.fields}>
                                <TextareaAutosize label={'Message'}
                                    name="content"
                                    value={notification.value}
                                    minRows={3}
                                    placeholder={'Enter your message here...'}
                                    {...reactHookProps}
                                    style={{color: 'black'}}
                                />
                            </div>
                            <div className={styles.fieldDescription}>
                                <IonButton type="submit"><IonIcon icon={sendIcon} />Send Message</IonButton>
                            </div>
                        </div>
                    </form>}
            </div>}  
        </IonContent>
    </IonPage>
}