import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { calculateTotalEntriesByRound, calculateTotalRounds, createInitialRound, createRoundRobinRounds, BYE, WINS_BY, WINS_BY_OPTIONS, calculateTotalNumberOfCompetitors, TBD } from "./bracketUtils";
import BracketStyles from "./Bracket.module.scss";
import CompetitorStyles from "./Competitor.module.scss";
import "./BracketWrapper.scss";
import SelectFormInput from "../FormInput/SelectFormInput";
import { RequestUtils } from "../../serverUtils/requests";
import classNames from "classnames";
import FlagIcon from "../FlagIcon";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { BRACKET, BRACKET_TYPES, STATUS, STATUS_LABEL } from "./Bracket";
import Utils from "../../serverUtils/Utils";
import AlertPane from "../FormInput/AlertPane";
import { useHistory } from 'react-router-dom';
import FilterChips from "../Filters/FilterChips";
import { default as ZoomInIcon } from "@mui/icons-material/ZoomIn";
import { default as ZoomOutIcon } from "@mui/icons-material/ZoomOut";
import { default as ExportPDFIcon } from "@mui/icons-material/PictureAsPdfRounded";
import { default as EditIcon } from "@mui/icons-material/Edit";
import { default as CheckIcon} from "@mui/icons-material/CheckCircleOutline";
import { Checkbox, FormControlLabel, Link, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Theme from "../FormInput/Theme";
import UserChip from "../UserChip/UserChip";
import { useStore } from "../../Store";
import UserModel from "../../serverUtils/models/UserModel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DivisionFilter } from "../../pages/TournamentPage/TournamentPage";
import { BracketSchema, DivisionSchema, ENTITY } from "../../serverUtils/Models";
import Busy from "../Busy";
import { getDivisionTypeChips } from "../../pages/TournamentPage/DivisionRegistration";
import { openPDF } from "../../pages/MessagesPage/MessagesPage";
import { IonSpinner } from "@ionic/react";
import { default as CollapseIcon } from '@mui/icons-material/ArrowRight';
import { default as ExpandIcon } from '@mui/icons-material/ArrowDropDown';
import { GIHBanner } from "../../App";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import { divScroller } from "../Form/Form";

const urlParams = RequestUtils.getURLParameters();
const isBracketsTab = () => {
    return RequestUtils.getURLParameters().tab === 'brackets';
}

const CRC_Division_Fields = ["id", "bracket_type", "is_bracket_by_seed", "is_block", "max_reg", "is_third", "is_modify_round_robin"];
const BracketWrapper = React.forwardRef(({ tournament, tabsRef={}, isEditMode, tabObjRefs={} }, ref) => {
    const history = useHistory();
    const session = useStore(state => state.session);
    const [message, setMessage] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [isEditingBracket, setIsEditingBraket] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isExportingAll, setExportingAll] = useState();
    const [divisionFilter, setDivisionFilter] = useState();
    const [chip, setChip] = useState();
    const [currentDivision, setCurrentDivision] = useState(); 
    const divsionFilterRef = useRef();
    const bracketRef = useRef();
    const divRef = useRef();

    const initDivision = (curDiv = urlParams.id) => {
        if (curDiv) {
            curDiv = tournament.getAvailableDivisions().find(d => [d.id, d.pool].includes(curDiv));
            if (curDiv) {
                curDiv.getTournament = curDiv.getTournament || (() => tournament);
                setCurrentDivision(curDiv);
                divsionFilterRef.current && divsionFilterRef.current.setDivision(curDiv.id);
            }
        } 
        return curDiv;
    }

    let clientRec = divRef.current?.getBoundingClientRect && divRef.current.getBoundingClientRect();
    useEffect(() => {
        checkCRC();
    }, [clientRec]);
    useEffect(() => {
        if (tournament) {
            let df = getDivisionTypeChips(tournament);
            setDivisionFilter(df);
            initDivision();
            if (!tournament.bracket_entries_crc) {
                tournament.bracket_entries_crc = {};
            }
        }
    }, [tournament]);

    useEffect(() => {
        session && setIsEditable(UserModel.isAdmin(session, ENTITY.tournament, tournament.id));
    }, [session]);

    useEffect(() => {
        if (RequestUtils.getURLParameters().tab === 'brackets') {
            try{
                let {id, chip} = RequestUtils.getURLParameters();
                if (!chip && !id) {
                    return;
                }

                let df = divisionFilter;
                if (!df) {
                    df = getDivisionTypeChips(tournament);
                    setDivisionFilter(df);
                }
                if (!chip && id) {
                    divsionFilterRef.current && divsionFilterRef.current.setChipByDivision(id);
                }else if (chip) {
                    divsionFilterRef.current && divsionFilterRef.current.setSelected(chip);
                }
                
                if (id) {
                    return initDivision(id);
                }else {
                    setCurrentDivision(null);
                }
            }finally{
                checkCRC();
            }
        }
    }, [RequestUtils.getURLParameters().tab]);

    useEffect(() => {
        if (currentDivision && !currentDivision.crc) {
            currentDivision.crc = Utils.getCRC(currentDivision, CRC_Division_Fields);
        }
    }, [currentDivision]);

    useEffect(() => {
        // console.log('BracketWrapper');
        tournament.bracket_entries_crc = {};
        tournament.getBracketEntries().then(bracketEntries => {
            tournament.getAvailableDivisions().forEach(d => {
                tournament.bracket_entries_crc[d.id] = Utils.getCRC({bracketEntries: bracketEntries.filter(be => be.division === d.id)}, CRC_Bracket_Fields);
            })
        });
    }, []);

    useImperativeHandle(ref, () => ({
        forceUpdate: () => setRefresh(!refresh),
        checkCRC,
        doSave: async () => {
            let bracketEntries = await tournament.caches.bracketEntries;
            let divisions = tournament.getAvailableDivisions();
            let hasError = false;
            for (let d of divisions) {
                let currentCRC = Utils.getCRC({bracketEntries: bracketEntries.filter(be => be.division === d.id)}, CRC_Bracket_Fields);
                if (currentCRC !== tournament.bracket_entries_crc[d.id]) {
                    let entries = bracketEntries.filter(e => e.division === d.id);
                    let response = await TournamentModel.updateBracketEntries(entries, tournament.id, d.id, d.bracket_type);
                    if (!response || response.error) {
                        hasError = true;
                    }
                    tournament.bracket_entries_crc[d.id] = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields);
                }
            }
        },
    }));

    const checkCRC = () => {
        if (tournament.bracket_entries_crc === undefined || !currentDivision) {
            return;
        }
        let r;
        try {
            let curDivCrc = Utils.getCRC(currentDivision, CRC_Division_Fields);
            if (curDivCrc !== currentDivision.crc) {
                r = true;
            }else {
                let bracketEntries = tournament.caches.bracketEntries;
                r = bracketEntries && tournament.getAvailableDivisions().find(d => {
                    let entries = bracketEntries.filter(be => be && be.division === d.id);
                    let currentCRC = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields);
                    return currentCRC !== tournament.bracket_entries_crc[d.id];
                });
            }
            return r;
        }finally {
            setMessage(r? 'warning: Data has changed' : '');
        }
    }

    const ExportAll = () => {
        const Orientation = () => {
            const handleClick = (e, v) => {
                e.stopPropagation();
                GROUP_OF = v;
                setRefresh(!refresh);
            }
            return <div className="Orientation">
                <FormControlLabel control={<Radio checked={GROUP_OF===8} onClick={e => handleClick(e, 8)}/>} label="Landscape" />
                <FormControlLabel control={<Radio checked={GROUP_OF===16} onClick={e => handleClick(e, 16)}/>} label="Portrait" />
            </div>
        }

        const startExport = (groups) => {
            let group = groups.pop();
            let divisions = group.divisions.filter(d => d.getRegistrations().length > 0);
            const {initDivision, division} = divsionFilterRef.current;
            setExportingAll({groups, divisions});
            setChip(group.value);
            setTimeout(() => {
                if (division !== divisions[0].id) {
                    initDivision(divisions[0]);
                }else {
                    bracketRef.current.doExport();
                }
            }, 500);
        }

        const exportAll = () => {
            const {filters} = divsionFilterRef.current;
            let allFilters = [...filters];
            startExport(allFilters);
        }

        const exportGroup = () => {
            const {filters, selected} = divsionFilterRef.current;
            let filter = filters.find(f => f.value === selected);
            let regs = Utils.sumArray(filter.divisions.map(d => d.getRegistrations().length));
            if (filter.divisions.length === 0 || regs === 0) {
                return setMessage('info: There a no bracket with registrations');
            }
            startExport([filter]);
        }

        return !isEditingBracket && <div className="ExportAll">
            <button className="button export" onClick={exportAll}><ExportPDFIcon /> Export All</button>
            <button className="button export" onClick={exportGroup}><ExportPDFIcon /> Export Group</button>
            {/* <Orientation /> */}
        </div>
    }

    const bracketRenderComplete = () => {
        if (isExportingAll){
            bracketRef.current.doExport();
        }
    }

    const bracketExportComplete = () => {
        if (isExportingAll) {
            const {filters, setSelected, initDivision} = divsionFilterRef.current;
            let {groups, divisions, exportedDivisions=[]} = isExportingAll;
            exportedDivisions.push(currentDivision.id);
            divisions.shift();
            if (divisions.length === 0 && groups.length > 0){
                let g = groups.pop();
                setChip(g);
                setTimeout(() => {
                    divisions = g.divisions.filter(d => !exportedDivisions.includes(d.id));
                    setExportingAll({groups: [...groups], divisions, exportedDivisions});
                    divisions.length>0 && initDivision(divisions[0]);
                }, 500);
            }else if (divisions.length > 0){
                setExportingAll({groups: [...groups], divisions, exportedDivisions});
                initDivision(divisions[0]);
            }else {
                setExportingAll(null);
            }   
        }
    }

    return <div className="BracketWrapper" ref={divRef}>
        <AlertPane message={message} isFloat/>
        <ExportAll />
        <DivisionFilter ref={divsionFilterRef} isActiveTab={tabsRef.current && tabsRef.current.getActiveTabUrl() === 'brackets'}
            isEditMode={true}
            value={chip}
            division={currentDivision}
            tournament={tournament} 
            includeBracketTypeSize={true}
            onChip={c => {
                setChip(c);
                isBracketsTab() && RequestUtils.insertURLParam('chip', c, history);
            }}
            onSelect={f => {
                if (f) {
                    let div = tournament.getAvailableDivisions().find(d => [d.pool, d.id].includes(f));
                    setCurrentDivision(div? div:null);
                    // div && Utils.cookie('BracketWrapper', JSON.stringify({chip, id: div.id}));
                }
            }} />

        {!currentDivision? <AlertPane message={`warning: No registration found for this division.`}/> :
            <Bracket ref={bracketRef} tabObjRefs={tabObjRefs}
                checkCRC={checkCRC}
                tournament={tournament}
                currentDivision={currentDivision}
                isEditable={isEditMode && isEditable}
                setIsEditingBraket={(isEditing) => {
                    setIsEditingBraket(isEditing);
                }}
                tabsRef={tabsRef} 
                renderComplete={bracketRenderComplete}
                exportComplete={bracketExportComplete}
                isExportingAll={isExportingAll}
            />}
    </div>
});

export const CRC_Bracket_Fields = ["bracketEntries", ...Utils.listObjectKeys(BracketSchema().model)];
const Bracket = forwardRef(({ isExportingAll, tournament, currentDivision, isEditable, tabsRef, renderComplete, exportComplete, setIsEditingBraket, tabObjRefs, checkCRC }, ref) => {
    const history = useHistory();
    const localServer = useStore(state => state.local_server);
    const setLocalTournament = useStore(state => state.setLocalTournament);
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const [reload, setReload] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const [bracketEntries, setBracketEntries] = useState([]);
    const [hoveredCompetitor, setHoveredCompetitor] = useState(null);
    const [zoom, setZoom] = useState(1.0);
    const [isExporting, setIsExporting] = useState(false);
    const [isThird, setIsThird] = useState();
    const [isModifyRoundRobin, setModifyRoundRobin] = useState();
    const [registrations, setRegistrations] = useState([]);
    const [isExportRRSummary, setIsExportRRSummary] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const bracketTypeRef = useRef();
    const exportButtonRef = useRef();
    const bracketDivWrapperRef = useRef();
    const doExportCalback = useRef();

    useImperativeHandle(ref, () => ({
        doExport: (cb) => {
            doExportCalback.current = cb;
            exportButtonRef.current && exportButtonRef.current.click();
        },
        doSave: division => saveBracketEntries(division, true),
    }));

    useEffect(() => {
        tournament.getBracketEntries()
            .then(entries => {
                setBracketEntries(entries);
            });
    }, []);

    useEffect(() => {
        if (tournament && currentDivision) {
            currentDivision.getTournament = () => tournament;
            setIsThird(currentDivision.is_third);
            setModifyRoundRobin(currentDivision.is_modify_round_robin);
            tournament.getBracketEntries().then(bes => {
                setBracketEntries(bes);
                renderComplete && renderComplete();
            });
            const registrations = currentDivision.getRegistrations(true).filter(r => r.status===STATUS.Active);
            setRegistrations(registrations);
        }
    }, [currentDivision]);


    const saveBracketEntries = async (division=currentDivision, skipTabRefCheck) => {
        setServerMessage('');
        setIsDisabled(true);
        !skipTabRefCheck && await tabObjRefs.current.save('bracketsRef');
        let entries = bracketEntries.filter(e => e.division === division.id);
        let response = await TournamentModel.updateBracketEntries(entries, tournament.id, division.id, division.bracket_type);
        if (!response || response.error) {
            setIsDisabled(false);
            return setServerMessage('error: Error saving bracket');
        }
        let updateCRC = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields);
        tournament.bracket_entries_crc[division.id] = updateCRC;
        setIsDisabled(false);
        setServerMessage('success: Successfully update bracket');
        checkCRC();
    }

    const doReload = () => {
        let r = !reload;
        setReload(r);
        setTimeout(() => {
            setReload(!r);
        }, 300);
    }

    const updateTournamentBracketEntries = (entries) => {
        let bes = bracketEntries.filter(be => be.division !== currentDivision.id);
        bes.push(...entries);
        setBracketEntries([...bes]);
        tournament.caches.bracketEntries = bes;
        setIsBracketEntriesUpdate();
        checkCRC();
    }

    const renderBracket = (bracketType) => {
        if (reload) {
            return '';
        }
        if (currentDivision.getRegistrations(true).length === 0){
            return <AlertPane message={'warning: No registration found for this bracket'} />;
        }
        switch (bracketType) {
            case BRACKET.Single_Elimination:
                return <BracketSingleElimination tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    registrations={registrations}
                    isThird={isThird}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    checkCRC={() => setTimeout(checkCRC, 2000)}
                    tournament={tournament}
                />;
            case BRACKET.Round_Robin:
                return <BracketRoundRobin tabsRef={tabsRef} ref={bracketTypeRef}
                    isExportRRSummary={isExportRRSummary}
                    tournament={tournament}
                    zoom={zoom}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    checkCRC={() => setTimeout(checkCRC, 2000)}
                    isExporting={isExporting}
                />;
            case BRACKET.Double_Elimination_Loser_3RD:
            case BRACKET.Double_Elimination_Loser_2ND:
            case BRACKET.Double_Elimination_Loser_1ST:
                return <BracketDoubleElimination tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    tournament={tournament}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    checkCRC={() => setTimeout(checkCRC, 2000)}
                />;
            default:
                return <div>Not implemented</div>
        }
    }

    const clearBracket = (e) => {
        e && e.stopPropagation();
        let entries = bracketEntries.filter(e => ![currentDivision.id, currentDivision.pool].includes(e.division));
        setBracketEntries(entries);
        tournament.caches.bracketEntries = entries;
        // doReload();
        localServer && setLocalTournament({...tournament});
        checkCRC(entries);
    }

    const updateZoom = (isIn) => {
        const inc = 0.25;
        if (isIn) {
            let z = zoom + inc;
            z <= 1.0 && setZoom(z);
        } else {
            let z = zoom - inc;
            z >= 0.25 && setZoom(z);
        }
    }

    const NonPlacements = ({ }) => {
        if (!currentDivision) {
            return '';
        }
        let bracketEntryIds = [...new Set(bracketEntries.map(e => e.division === currentDivision.id && e.membership))];
        let nonPlaces = registrations.filter(r => !bracketEntryIds.includes(r.membership));
        return nonPlaces.length > 0 && <div className={classNames("NonPlacements", BracketStyles.Draw)}>
            <h6>Non Placements <span>[{nonPlaces.length}]</span></h6>
            {nonPlaces.map((n, i) => {
                return <UserChip key={i} membership={n.getMembership()} 
                    nameClass={`registrant ${n.status!==STATUS.Active? 'block':''}`}
                    title={`Current status: ${STATUS_LABEL(n.status)}`}
                    onClick={() => gotoCompetitor(n, tabsRef, history, tournament)}/>
            })}
        </div>;
    }

    const handleAutofill = (e) => {
        setBusy(true);
        e && e.stopPropagation();
        setTimeout(() => {
            clearBracket();
            console.log('handleAutofill:', currentDivision.bracket_type);
            let entries = currentDivision.bracket_type === BRACKET.Round_Robin ?
                createRoundRobinRounds(tournament, currentDivision) : createInitialRound(tournament, currentDivision);
            entries = [...tournament.caches.bracketEntries || [], ...entries.map(e => {
                if (!e.getDivision) {
                    let found = tournament.poolings.find(p => p.pool === e.division);
                    if (found) {
                        found.name = found.pool;
                        found.id = found.pool;
                        e.getDivision = () => found;
                    }
                }
                return e;
            })];
            setBracketEntries(entries);
            tournament.caches.bracketEntries = [...tournament.caches.bracketEntries.filter(be => be.division !== currentDivision.id), ...entries];
            // doReload();
            setIsBracketEntriesUpdate();
            localServer && setLocalTournament({...tournament});
            checkCRC(entries);
            setBusy(false);
        }, 1000);
    }

    const ExportSummary = () => {
        return currentDivision.bracket_type===BRACKET.Round_Robin?
            <CheckboxFormInput value={isExportRRSummary} onChange={setIsExportRRSummary} label={'Summary'}/> 
            : '';
    }
    let hasBracket = currentDivision && currentDivision.getRegistrations().length > 0;
    return <ThemeProvider theme={Theme}>
        <div className={`Bracket ${isExporting? 'export':''} ${currentDivision.bracket_type===BRACKET.Round_Robin? 'portrait':'landscape'}`}>
            <div className={`header-wrapper`}>
                <NonPlacements />
                <div className="header">
                    <h2 className={`${BracketStyles.divisionTitle} bracket-title`}>
                        <Link className="Link"
                            onClick={e => {
                                e.stopPropagation();
                                let isPool = currentDivision.id===currentDivision.pool;
                                history.push(`/tournaments/${tournament.id}${isEditable? '/edit':''}?tab=${isPool? 'poolings':'divisions'}&id=${currentDivision.id}`);
                                tabsRef.current.setActiveTabByName(isPool? 'poolings':'divisions');
                            }}
                        >
                            {currentDivision && (currentDivision.name || currentDivision.id)}
                            <span>[{BRACKET.getBracketName(currentDivision.getBracketType())} - {currentDivision.getRegistrations().length}]</span>
                        </Link>
                    </h2>
                    <AlertPane message={serverMessage} timeOut={5000} setMessage={setServerMessage}/>
                    <div className={`buttons controls ${BracketStyles.buttons}`} style={Utils.isMobile() ? { flexWrap: 'wrap' } : {}} >
                        {hasBracket && <button onClick={() => updateZoom(true)} className={classNames("button", "icon_button", BracketStyles.button)}>
                            <ZoomInIcon />
                        </button>}
                        {hasBracket && <button onClick={() => updateZoom()} className={classNames("button", "icon_button", BracketStyles.button)}>
                            <ZoomOutIcon />
                        </button>}
                        {!isEditing ? isEditable && hasBracket && 
                            <button className={classNames("button", "small_button", BracketStyles.button)} 
                                onClick={() => {
                                    setIsEditing(true);
                                    setIsDisabled(false);
                                    setIsEditingBraket(true);
                                }}><EditIcon /> Edit</button> :
                            <>
                                <button className="button alt_button" onClick={() => {
                                    setIsEditing(false);
                                    setIsDisabled(false);
                                    setIsEditingBraket(false);
                                }} disabled={isDisabled}>Done
                                </button>
                                {!localServer && 
                                    <button className="button" onClick={() => saveBracketEntries()} disabled={isDisabled}>
                                        Save {isDisabled && <IonSpinner name="circles" className="spinner"/>}
                                    </button>}
                            </>}
                        {!isEditing && hasBracket && 
                            <div className="export">
                                <button ref={exportButtonRef}
                                    className={classNames("ExportButton", "button", "small_button", BracketStyles.button)}
                                    onClick={() => {
                                        let pMsg = `Exporting bracket ${currentDivision.name || currentDivision.id} to PDF...`;
                                        setIsExporting(pMsg);
                                        bracketTypeRef.current.setIsPrinting(true);
                                        setTimeout(() => {
                                            bracketDivWrapperRef.current.style.pointerEvents = 'none'; 
                                            generatePDF(bracketTypeRef, currentDivision, 
                                                () => {
                                                    setIsExporting(false);
                                                    bracketTypeRef.current.setIsPrinting(false);
                                                    exportComplete && exportComplete();
                                                    bracketDivWrapperRef.current.style.pointerEvents = 'auto'; 
                                                    doExportCalback.current && doExportCalback.current();
                                                },
                                                (page=0, totalPage=1, printTitleRef) => {
                                                    let temp = printTitleRef;
                                                    try{
                                                        printTitleRef.current.setPage(`${page+1} of ${totalPage}`);
                                                        setIsExporting(`${pMsg} [ page ${page+1} ]`);
                                                    }catch(e) {
                                                        console.log(e, temp);
                                                    }
                                                    console.log(temp);
                                                },
                                                isExportingAll
                                            );
                                        }, 100);
                                        
                                    }}>
                                    <ExportPDFIcon />Export
                                </button>
                                <ExportSummary />
                            </div>}
                    </div>
                </div>
            </div>
            {isEditing && 
                <div className={`buttons edit ${BracketStyles.buttons}`}>
                    <button onClick={handleAutofill} className="button" disabled={isDisabled}>Autofill {isBusy && <IonSpinner name="circles" className="spinner"/>}</button>
                    <button className="button alt_button" onClick={clearBracket} disabled={isDisabled}>Clear
                    </button>
                    <SelectFormInput width="100%"
                        name="bracket_type"
                        label="Change Bracket Type"
                        value={currentDivision && currentDivision.bracket_type}
                        options={BRACKET_TYPES}
                        onChange={v => {
                            currentDivision.bracket_type = v;
                            currentDivision.getBracketType = () => v;
                            currentDivision.updated = true;
                            clearBracket();
                            tabObjRefs.current.checkCRC('bracketsRef');
                            localServer && setLocalTournament({...tournament});
                        }}
                    />

                    {currentDivision.bracket_type === BRACKET.Single_Elimination && currentDivision.getRegistrations(true).length > 3 &&
                        <FormControlLabel label="Has Third Place"
                            control={<Checkbox checked={currentDivision.is_third? true:false}
                                onChange={e => {
                                    let is_third = e.target.checked;
                                    currentDivision.is_third = is_third;
                                    currentDivision.updated = true;
                                    localServer && setLocalTournament({...tournament});
                                    let bes = [
                                        ...bracketEntries.filter(be => be.division !== currentDivision.id),
                                        ...bracketEntries.filter(be => be.division === currentDivision.id && !be.isThird)
                                    ];
                                    setBracketEntries(bes);
                                    setIsThird(currentDivision.getRegistrations(true).length > 3 && is_third);
                                    checkCRC();
                                }}
                            />}
                        />}

                    {currentDivision.bracket_type === BRACKET.Single_Elimination && currentDivision.getRegistrations(true).length === 3 &&
                        <FormControlLabel label="Modify Round Robin"
                            control={<Checkbox checked={isModifyRoundRobin? true:false}
                                onChange={e => {
                                    let is_modify_round_robin = e.target.checked;
                                    currentDivision.is_modify_round_robin = is_modify_round_robin;
                                    currentDivision.updated = true;
                                    localServer && setLocalTournament({...tournament});
                                    setModifyRoundRobin(currentDivision.getRegistrations(true).length === 3 && is_modify_round_robin);
                                    checkCRC();
                                }}
                            />}
                        />}
                </div>}
            {!isEditing && currentDivision.is_third? <span className="has-third"><CheckIcon /> Has Third Place</span>:''}  
            <div className="Bracket-div-wrapper" ref={bracketDivWrapperRef}>
                {currentDivision && renderBracket(currentDivision.bracket_type)}
            </div>
            <Busy message={isExporting}/>
        </div>
    </ThemeProvider>;
        
});

function getRoundName({ r, totalRounds, bracketType, isConsolidation, isThird }) {
    let isRR = bracketType === BRACKET.Round_Robin;
    if (isRR) {
        return;
    }
    if (!isConsolidation && bracketType !== BRACKET.Double_Elimination_Loser_1ST) {
        if (r === totalRounds - 2) {
            return ROUND_NAME.Final;
        }
        if (r === totalRounds - 3) {
            return ROUND_NAME.Semi;
        }
        if (r === totalRounds - 3) {
            return ROUND_NAME.Quarter;
        }
    }
    if (r === totalRounds - 1) {
        if (isConsolidation) {
            if (bracketType === BRACKET.Double_Elimination_Loser_3RD) {
                return ROUND_NAME.ThirdPlace;
            } else if (bracketType === BRACKET.Double_Elimination_Loser_2ND) {
                return ROUND_NAME.SecondPlace;
            } else if (bracketType === BRACKET.Double_Elimination_Loser_1ST) {
                return ROUND_NAME.DrawWinner;
            }
        }
        if (bracketType === BRACKET.Double_Elimination_Loser_1ST) {
            return ROUND_NAME.DrawWinner;
        }
        if (isThird) {
            return ROUND_NAME.ThirdPlace;
        }
        return ROUND_NAME.Winner;
    }
    if (isConsolidation && r === totalRounds - 2) {
        return `Round ${r + 1}`;
    }
}

const selectNodeId = (thisEl) => {
    let params = RequestUtils.getURLParameters();
    if (params.node) {
        let node = thisEl.querySelector(`.${params.node}`);
        if (node) {
            node.style.border = '2px solid blue';
            node.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
    }
}

const gotoCompetitor = (r, tabsRef, history, tournament) => {
    history.push(`/tournaments/${tournament.id}/edit?tab=competitors&id=${r.id}`);
    tabsRef.current.setActiveTabByName('competitors');
}

const BracketDoubleElimination = React.forwardRef(({
    zoom,
    registrations=[],
    currentDivision,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    checkCRC,
}, ref) => {
    const divConsolidationRef = useRef();
    const [filter, setFilter] = useState(0);
    const [drawFilter, setDrawFilter] = useState({ value: 0 });
    const bracketDoubleElimationDivRef = useRef();
    const consolidationRef = useRef();
    const mainBracketRef = useRef();
    const finalDoubleEliminationRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        bracketZoom(divConsolidationRef, zoom);
    }, [zoom, drawFilter.value]);

    useLayoutEffect(() => {
        let thisEl = divConsolidationRef.current;
        if (thisEl) {
            let columns = thisEl.children;
            const isWinnerRoundOfLoser3rd = (i) => currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD && i === columns.length - 1;
            let bottoms = [0];
            for (let c = 1; c < columns.length; c++) {
                let isOddColumn = c % 2 > 0;
                let column = columns[c];
                let prevColumn = columns[c - 1];
                if (prevColumn.classList.contains('blank')) {
                    bottoms.push(parseFloat(column.style.bottom || 0));
                    continue;
                }
                let prevChildLastNodes = prevColumn.lastChild.children;
                let nextPrevColumnLastNode = prevChildLastNodes[prevChildLastNodes.length - 2];
                let lastPrevColumnNode = prevChildLastNodes[prevChildLastNodes.length - 1];
                if (!lastPrevColumnNode || !nextPrevColumnLastNode) {
                    continue;
                }
                let bottom = bottoms[c - 1];
                if (isOddColumn && !isWinnerRoundOfLoser3rd(c)) {
                    let mid = (lastPrevColumnNode.getBoundingClientRect().top - nextPrevColumnLastNode.getBoundingClientRect().bottom) / 2;
                    bottom += mid + lastPrevColumnNode.getBoundingClientRect().height / 2;
                }
                column.style.bottom = `${bottom}px`;
                bottoms.push(bottom);
            }
            let offset = bottoms.pop()
            thisEl.style.paddingTop = `${offset}px`;
            let highestTop;
            for (let c = 0; c < columns.length; c++) {
                let column = columns[c];
                if (column.classList.contains('blank')) {
                    continue;
                }
                let firstNode = column.firstChild.firstChild;
                if (!firstNode) {
                    continue;
                }
                let b = firstNode.querySelector('b');
                if (!b) {
                    continue;
                }
                let top = firstNode.getBoundingClientRect().top - b.getBoundingClientRect().height;
                if (!highestTop || top < highestTop) {
                    highestTop = top;
                }
            }
            offset = offset - (highestTop - thisEl.getBoundingClientRect().top); 
            thisEl.style.paddingTop = `${offset + 40}px`;
            renderJoins(thisEl.children, thisEl.classList.contains('bracket_consolidation') && 1, currentDivision);
            selectNodeId(thisEl);
        }
    }, [divConsolidationRef.current, currentDivision.id, currentDivision.bracket_type, filter, drawFilter.value, zoom]);

    useImperativeHandle(ref, () => ({
        divRef: bracketDoubleElimationDivRef,
        setFilter,
        setDrawFilter,
        mainBracketRef, consolidationRef, finalDoubleEliminationRef,
        setIsPrinting: isPrinting => {
            mainBracketRef.current.setIsPrinting(isPrinting);
            setIsPrinting(isPrinting);
        }
    }));

    let totalRounds = calculateTotalRounds(registrations.length, BRACKET.Double_Elimination_Loser_3RD);
    let isLoserThird = currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD;
    if (isLoserThird) {
        totalRounds -= 2;
    } else {
        totalRounds -= 1;
    }
    const handleDrawFilterClick = fs => {
        fs = drawFilter.value != fs.value ? fs : {};
        setDrawFilter(fs);
    }

    const renderConsolidation = () => {
        return <ConsolidationBracket ref={consolidationRef}
            filter={filter}
            setFilter={setFilter} 
            isPrinting={isPrinting}
            setIsPrinting={setIsPrinting}
            totalRounds={totalRounds}
            currentDivision={currentDivision}
            getPrintTitle={getPrintTitle}
            divConsolidationRef={divConsolidationRef}
            registrations={registrations}
            isLoserThird={isLoserThird}
            bracketEntries={bracketEntries}
            isEditing={isEditing}
            isDisabled={isDisabled}
            hoveredCompetitor={hoveredCompetitor}
            setHoveredCompetitor={setHoveredCompetitor}
            setBracketEntries={setBracketEntries}
            checkCRC={checkCRC}
        />
    }

    const FinalDoubleElimination =  React.forwardRef(({}, ref) => {
        const [pair, setPair] = useState([]);
        const [pair1, setPair1] = useState();
        const divRef = useRef();
        const pairsRef = useRef();
        const printTitleRef = useRef();

        useImperativeHandle(ref, () => ({
            divRef, printTitleRef, setIsPrinting,
        }));

        useEffect(() => {
            if (isPrinting && isPrinting.cb) {
                isPrinting();
            }
        }, [isPrinting]);

        useEffect(() => {
            let bracketSize = calculateTotalNumberOfCompetitors(registrations.filter(r => r.status === STATUS.Active).length);
            let totalRound = calculateTotalRounds(bracketSize);
            let mainEntries = bracketEntries.filter(be => !be.isLoser && be.round === totalRound);
            let consoleTotalRound = totalRound>2? totalRound+1 : totalRound;
            let consolidateEntries = bracketEntries.filter(be => be.isLoser && be.round === consoleTotalRound);
            let finalBes = bracketEntries.filter(be => be.isFinal_1st_double);
            const setMatch0 = () => {
                let round = 0;
                let pr = finalBes.filter(be => be.round === round);
                let p0 = pr.find(be => be.index===0);
                let p1 = pr.find(be => be.index===1);
                if (!p0 && mainEntries.length > 0) {
                    p0 = {...mainEntries.find(be => be.round === totalRound && be.index === 0), 
                            isFinal_1st_double: true, 
                            round, index: 0,
                            isLoser: false,
                        };
                    bracketEntries.push(p0);
                }
                if (!p1 && consolidateEntries.length > 0) {
                    p1 = {...consolidateEntries.find(be => be.round === consoleTotalRound && be.index === 0),
                            isFinal_1st_double: true, 
                            round, index: 1,
                            isLoser: false,
                        };
                    bracketEntries.push(p1);
                } 
                pr = [p0, p1];
                setPair(pr);
                return pr;
            }
            let pr = setMatch0(0, setPair);
            let pr1 = finalBes.filter(be => be.round===1);
            if (pr1.length===0 && pr.find(be => be && be.result && be.index === 1)) {
                pr1 = [
                    {...pr[1], round: 1, result: '', index: 0}, 
                    {...pr[0], round: 1, result: '', index: 1}
                ];
                bracketEntries.push(...pr1);
            }

            pr1.filter(be => be).length===2 && setPair1(pr1);

            let finalEntries = bracketEntries.filter(be => be.isFinal_1st_double);
            finalEntries.forEach(be => be.place = '');
            if (finalEntries.find(be => be.result)) {
                const setPlacements = (fes) => {
                    let first = fes.find(be => be.result);
                    if (first) {
                        first.place = 1;
                    }
                    let second = fes.find(be => !be.result);
                    if (second) {
                        second.place = 2;
                    }
                }
                if (finalEntries.find(be => be.round === 1)) {
                    setPlacements(finalEntries.filter(be => be.round === 1));
                }else {
                    setPlacements(finalEntries.filter(be => be.round === 0));
                }
            }
        }, [bracketEntries]);

        const Pair = ({_pair=[], isBracketWinner, round}) => {
            const [refresh, setRefresh] = useState(false);
            return <div className="Pair">
                {[0, 1, 2].map(index => {
                    let entry = index<2 && _pair[index];
                    let winner = _pair.find(p => p && p.result) || {};
                    let node_id = createBracketNodeId({ round: entry? entry.round:index, index: index});
                    return <div className="entry" key={`competitor${index}_${isEditing}`} id={`competitor${index}_${isEditing}`}>
                        {index===2 && index === 2 && isBracketWinner && <b className="winner-label">Bracket Winner</b>}
                        {isEditing && index === 2? 
                            <EditingCompetitor
                                node_id={node_id}
                                checkCRC={checkCRC}
                                currentDivision={currentDivision}
                                round={round}
                                roundName={''}
                                mainBracketEntries={[]}
                                bracketEntries={bracketEntries}
                                index={index}
                                registrations={registrations.filter(r => [_pair[0] && _pair[0].membership, _pair[1] && _pair[1].membership].includes(r.membership))}
                                defaultValue={winner.membership}
                                defaultResult={winner.result}
                                isFinal_1st_double
                                onChange={v => {
                                    if (!v) {
                                        _pair[2] = {};
                                    }
                                    _pair.forEach((be, i) => {
                                        if (i < 2) {
                                            be.result = be.membership === v? WINS_BY.WIN.value : '';
                                        }
                                    });
                                    let bes = bracketEntries.filter(be => !be.isFinal_1st_double);
                                    if (round === 1) {
                                        bes = bracketEntries.filter(be => !(be.isFinal_1st_double && be.round === 1));
                                    }else {
                                        setPair1(null);
                                    }

                                    let entries = [...bes, _pair[0], _pair[1]];
                                    setBracketEntries(entries);
                                    checkCRC();
                                    // setTimeout(() => {
                                    //     bracketDoubleElimationDivRef.current.getElementsByClassName('FinalDoubleElimination')[0].scrollIntoView({ behavior: 'auto', block: 'center' });
                                    // }, 1000);
                                }}
                                onWinBy={v => {
                                    winner.result = v;
                                    setRefresh(!refresh);
                                    checkCRC();
                                }}
                            />
                        :
                            <Competitor 
                                isFinal_1st_double
                                roundName={`Round ${round + 1}`}
                                index={index}
                                node_id={node_id}
                                entry={index<2? entry:winner}
                                hoveredCompetitor={hoveredCompetitor}
                                setHoveredCompetitor={setHoveredCompetitor}
                                isPrinting={isPrinting}
                                bracketEntries={bracketEntries}
                                round={round}
                            />}
                    </div>
                })}
            </div>
        }

        return <div className={`FinalDoubleElimination ${isPrinting? 'export':''}`} ref={divRef}>
            <PrintTitle getPrintTitle={() => 'Final Draw'} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef}/>
            <h5>Final Double Elimination</h5>
            <div className="Pairs" ref={pairsRef} onMouseMove={mouseEvent => divScroller({divRef: pairsRef, mouseEvent})}>
               <Pair _pair={pair} isBracketWinner={!pair1} round={0}/>
                {pair1 && <Pair _pair={pair1} isBracketWinner round={1}/>} 
            </div>
        </div>;
    });

    const getPrintTitle = (isConsol) => {
        let ddDraw = ` - ${isConsol? 'Main':'Consolidation'} Draw`;
        let g = filter.toString().startsWith('group_');
        if (g) {
            let gr = filter.toString().split('group_')[1].split('_');
            let r = parseInt(gr[0]);
            g = parseInt(gr[1]);
            g = ` - Round${r+1} Group${g+1}`;
        } else {
            g = '';
        }
        return `${currentDivision.name || currentDivision.id}${ddDraw}${g}`;
    }

    return totalRounds <= 0? '' : 
        <div className="BracketDoubleElimation" ref={bracketDoubleElimationDivRef}>
            <div className={BracketStyles.DrawFilter}>
                <FilterChips 
                    filters={[{ value: 1, label: 'Main Draw' }, { value: 2, label: 'Consolation Draw' }]}
                    activeFilters={[drawFilter]}
                    onClick={handleDrawFilterClick}
                />
            </div>
            {(!drawFilter.value || drawFilter.value === 1) && 
            <div>
                <BracketSingleElimination ref={mainBracketRef}
                    isDoubleElimination
                    zoom={zoom}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => !e.isLoser && !e.isFinal_1st_double)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        setBracketEntries([
                            ...entries,
                            ...bracketEntries.filter(e => e.isLoser || e.isFinal_1st_double),
                        ]);
                    }}
                    checkCRC={checkCRC}
                />
            </div>}
            {(!drawFilter.value || drawFilter.value === 2) && renderConsolidation()}
            {currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_1ST && <FinalDoubleElimination ref={finalDoubleEliminationRef}/>}
        </div>;
});


const ConsolidationBracket = forwardRef(({
    filter, 
    setFilter, 
    isPrinting, 
    setIsPrinting, 
    totalRounds, 
    currentDivision, 
    getPrintTitle, 
    divConsolidationRef,
    registrations,
    isLoserThird,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    checkCRC
}, ref) => {

    const divRef = useRef();
    const printTitleRef = useRef();
    const roundFilterRef = useRef();
    useEffect(() => {
        console.log('BracketDoubleElimination: ConsolidationBracket ', filter, isPrinting);
        if (isPrinting && isPrinting.cb) {
            isPrinting();
        }
    }, [isPrinting]);
    useEffect(() => {
        console.log('ConsolidationBracket');
    
        const getLosersByRound = (round) => {
            const entries = bracketEntries.filter(be => 
                !be.isLoser && !be.isFinal_1st_double && be.round === round
            );
            const winners = entries.filter(be => be.result);
    
            return winners.map(w => {
                const loserIndex = (w.index % 2 === 0) ? w.index + 1 : w.index - 1;
                return entries.find(be => be.index === loserIndex && be.getMembership());
            }).filter(Boolean);
        };
    
        const findConsoleIndex = (cround, index) => {
            const isEven = index % 2 === 0;
            const first = isEven ? index : index - 1;
            const second = first + 1;
    
            if (cround === 0) {
                if (first % 2 !== 0 || second !== first + 1) {
                    return null; 
                }
                return first / 2; // Index is half of the first element
            }
            return second === first + 1 ? first : null;
        };
    
        let conEntries = bracketEntries.filter(be => be.isLoser);
        let hasChange = false;
        const totalRounds = calculateTotalRounds(currentDivision.getRegistrations().length) - 
                            (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_3RD ? 1 : 0);
    
        for (let round = 0; round < totalRounds; round++) {
            const losers = getLosersByRound(round);
    
            losers.forEach(l => {
                let cround = l.round >= 2 ? round + 1 : round;
                let cindex = findConsoleIndex(cround, l.index);
                let cfound = conEntries.find(be => be.round === cround && be.index === cindex);
    
                if (cfound && cfound.membership !== l.membership) {
                    conEntries.forEach(be => {
                        if (be.membership === cfound.membership) {
                            be.membership = l.membership;
                            be.getMembership = l.getMembership;
                        }
                    });
                    hasChange = true;
                } else if (!cfound) {
                    bracketEntries.push({
                        ...l, round: cround, index: cindex, isLoser: true,
                    });
                    hasChange = true;
                }
            });
    
            if (losers.length === 0) {
                const isDropDownNode = be => {
                    return be.round === 0 || (be.round > 0 && be.index%2===0);
                }
                bracketEntries = bracketEntries.filter(be => {
                    return !be.isLoser || !isDropDownNode(be) || (be.isLoser && be.round !== round);
                });
            }
        }
    
        if (hasChange || conEntries.length !== bracketEntries.filter(be => be.isLoser).length) {
            setBracketEntries([...bracketEntries]); // Avoid mutating state directly
        }
    }, [bracketEntries]);
    

    useImperativeHandle(ref, () => ({
        divRef, printTitleRef, roundFilterRef, 
        setIsPrinting: isP => {
            setIsPrinting(isP);
        },
    }));

    return <div className={`ConsolidationBracket ${BracketStyles.Draw}`}>
        <h5>Consolation Draw</h5>
        <RoundFilter ref={roundFilterRef}
            initFilter={filter}
            totalRounds={totalRounds}
            isConsolidation={true}
            currentDivision={currentDivision}
            onFilter={(f, isPrint) => {
                setFilter(f);
            }} />
        <div className={`bracket_main_wrapper`} ref={divRef}
            onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
            <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef} bracketTypeName="ConsolidationBracket"/>
            <div ref={divConsolidationRef} className={classNames('bracket_consolidation', BracketStyles.bracket_consolidation)}>
                {Array(totalRounds).fill(0).map((_, r) => {
                    let entriesCount = calculateTotalEntriesByRound(Math.floor(r / 2), registrations.length, BRACKET.Double_Elimination_Loser_3RD);
                    if (isLoserThird && r === totalRounds - 1) {
                        entriesCount = 1;
                    }
                    return isFilter(filter, r) ? 
                        <Round key={r}
                            round={r}
                            mainBracketEntries={bracketEntries.filter(e => !e.isLoser)}
                            bracketEntries={bracketEntries.filter(e => e.isLoser)}
                            isEditing={isEditing}
                            isDisabled={isDisabled}
                            registrations={registrations}
                            hoveredCompetitor={hoveredCompetitor}
                            setHoveredCompetitor={setHoveredCompetitor}
                            setBracketEntries={(entries) => {
                                entries.forEach(be => be.place = '');
                                if (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_2ND) {
                                    let second = entries.find(be => be.round===totalRounds-1);
                                    if (second) {
                                        second.place = 2;
                                        entries.filter(be => be.round===totalRounds-2).forEach(be => {
                                            if (be.membership !== second.membership) {
                                                be.place = 3;
                                            }
                                        });
                                    }
                                }else if (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_1ST) {
                                    let winner = entries.find(be => be.round===totalRounds-1);
                                    if (winner) {
                                        entries.filter(be => be.round===totalRounds-2).forEach(be => {
                                            if (be.membership !== winner.membership) {
                                                be.place = 3;
                                            }
                                        });
                                    }
                                }
                                setBracketEntries([
                                    ...bracketEntries.filter(e => !e.isLoser),
                                    ...entries
                                ]);
                            }}
                            currentDivision={currentDivision}
                            entriesCount={entriesCount}
                            roundName={getRoundName({ r, totalRounds, isConsolidation: true, bracketType: currentDivision.getBracketType() })}
                            isConsolidation={true}
                            filter={getEliminationGroupFilter({ filter, isConsolidation: true })}
                            isPrinting={isPrinting}
                            checkCRC={checkCRC}
                        /> : <div className="blank column" />;
                })}
            </div>
        </div>
    </div>;
});

const BracketRoundRobin = React.forwardRef(({
    zoom,
    registrations,
    currentDivision,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    checkCRC,
    tabsRef,
    tournament,
    isExportRRSummary,
    isExporting,
}, ref) => {
    const history = useHistory();
    const [filter, setFilter] = useState({ round: 'summary' });
    const [summary, setSummary] = useState([]);
    const [isExpandSummary, setIsExpandSummary] = useState(true);
    const bracketPoolRef = useRef();
    const roundFilterRef = useRef();
    const summaryTableRef = useRef();
    const summaryRef = useRef();
    const divRef = useRef();
    const printTitleRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    const printPageSize = 12;

    useEffect(() => {
        summaryTableRef.current.style.display = isExpandSummary? '':'none';
    }, [isExpandSummary]);

    useEffect(() => {
        console.log('BracketRoundRobin: ', filter);
        if (isPrinting && typeof isPrinting === 'function') {
            isPrinting();
        }else {
            bracketPoolRef.current.style.display = '';
            summaryRef.current.style.display = '';
        }
        
    }, [isPrinting]);

    useEffect(() => {
        bracketZoom(bracketPoolRef, zoom);
    }, [zoom]);
    const totalRounds = calculateTotalRounds(registrations.length, BRACKET.Round_Robin);
    
    const getRRPoints = () => {
        if (!currentDivision.getTournament) {
            return;
        }
        let rrPoints;
        let tournament = currentDivision.getTournament();
        if (tournament.use_round_robin_points) {
            rrPoints = {};
            tournament.getLeague().round_robin_points.forEach(rp => {
                rrPoints[rp.win_by] = rp.points;
            });
        }
        return rrPoints;
    }

    const getSummary = () => {
        let rrPoints = getRRPoints();
        let winners = [];
        let losers = [];
        bracketEntries.forEach(be => be.points = 0);
        Array(totalRounds).fill(0).map((_, r) => {
            let entries = bracketEntries.filter(e => e.round === r).sort((a, b) => Utils.sorter(a, b, 'index'));
            for (let i = 0; i < entries.length; i+=2) {
                let pair = [entries[i], entries[i+1]];
                let hasBye = pair.find(be => be.membership === BYE);
                if (!hasBye) {
                    let winner = pair.find(be => be.result);
                    if (winner) {
                        if (rrPoints){
                            let points = rrPoints[winner.result];
                            winner.points = points || 1;
                        }
                        winners.push(winner.membership);
                        let loser = pair.find(be => !be.result);
                        if (loser) {
                            losers.push(loser.membership);
                        }
                    }
                }
            }
        });
        let order = registrations.map(r => {
            r.wins = winners.filter(w => w === r.membership).length;
            r.losses = losers.filter(w => w === r.membership).length;
            r.points = rrPoints? Utils.sumArray(bracketEntries.filter(be => be.membership === r.membership).map(be => be.points)) 
                : r.wins - r.losses;
            return r;
        }).sort((a, b) => Utils.sorter(b, a, 'points'));
        setSummary(order);
    };

    useEffect(() => {
        getSummary();
        roundFilterRef.current = {
            toogleCSS: (isShow) => {
                let competitorWrapperEls = divRef.current.getElementsByClassName('competitor-name');
                for (let cEl of competitorWrapperEls) {
                    cEl.style.fontFamily = isShow? '':'';
                }
            },
            toggleDisplay: isShow => {
                roundFilterRef.current.toogleCSS();
                let matchEls = divRef.current.getElementsByClassName(`match`);
                for (let mEl of matchEls) {
                    mEl.style.display = isShow? '':'none';
                }
                let roundTitleEls = divRef.current.getElementsByClassName(`round-title`);
                for (let rEl of roundTitleEls) {
                    rEl.style.display = isShow? '':'none';
                }
                let winnerHeaderEls = divRef.current.getElementsByClassName(`winner-header`);
                for (let rEl of winnerHeaderEls) {
                    rEl.style.display = isShow? '':'none';
                }
            },
            getRoundFilters: () => {
                roundFilterRef.current.toogleCSS();
                let matches = Math.ceil(bracketEntries.length / 2);
                if (matches <= printPageSize) {
                    return isExportRRSummary? [{value: 'summary'}, {value: 0} ]:[];
                }
                let pages = Math.ceil(matches / printPageSize);
                let fts = new Array(pages).fill(0).map((_, index) => index).map(f => ({value: f})).reverse();
                if (isExportRRSummary){
                    fts.push({value: 'summary'});
                }
                return fts;
            },
            handleClick: (f, cb) => {
                let page = f.value;
                if (page === 'summary'){
                    summaryRef.current.style.display = '';
                    summaryTableRef.current.style.display = '';
                    bracketPoolRef.current.style.display = 'none';
                }else {
                    summaryRef.current.style.display = 'none';
                    summaryTableRef.current.style.display = 'none';
                    bracketPoolRef.current.style.display = '';
                    let rounds = Utils.groupBy(bracketEntries, ['round']);
                    let start = page * printPageSize;
                    let end = start + printPageSize;
                    let matchIndex = 0;
                    roundFilterRef.current.toggleDisplay();
                    Object.keys(rounds).forEach(r => {
                        let matches = toPairs(rounds[r]);
                        let roundTitleEl = divRef.current.getElementsByClassName(`t${r}`);
                        matches.forEach(m => {
                            if (start <= matchIndex && matchIndex < end) {
                                let matchCompEl = divRef.current.getElementsByClassName(`m${matchIndex}`);
                                matchCompEl.length>0 && (matchCompEl[0].style.display = '');
                                if (roundTitleEl.length>0) {
                                    roundTitleEl[0].style.display = '';
                                }
                            }
                            matchIndex++;
                        });
                    });
                }
                cb();
            },
        }
        
    }, [bracketEntries]);

    useImperativeHandle(ref, () => ({
        divRef,
        setFilter, filter,
        roundFilterRef,
        setIsPrinting,
        printTitleRef,
    }));

    function toPairs(arr) {
        let result = [];
        for (let i = 0; i < arr.length; i += 2) {
          result.push([arr[i], arr[i + 1], arr[i].result? arr[i] : (arr[i+1].result? arr[i+1] : {})]);
        }
        return result;
    }

    const RRPrint = () => {
        const divRef = useRef();
        let rounds = Utils.groupBy(bracketEntries, ['round']);
        let matchIndex = 0;
        return <div className="RRPrint" ref={divRef} onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
            {Object.keys(rounds).map((r, i) => {
                let pairs = toPairs(rounds[r]);
                return <div id={`r${r}`} className={`round r${r}`} key={i}>
                    <div className="round-header"><h4 className={`round-title t${r}`}>Round {i+1}</h4><b className="winner-header">Round Winner</b></div>
                    <div className="competitors">
                        {pairs.map((pair, index) => {
                            let isBye = pair.find(p => p.membership === BYE);
                            if (isBye) {
                                isBye.getMembership = () => null;
                            }
                            let winner = isBye || pair.find(p => p.result) || {};
                            return <div className={`match m${matchIndex++}`} key={index}>
                                <span>{matchIndex}</span>
                                {pair.map((entry, k) => {
                                    let node_id = createBracketNodeId({ round: entry.round, index: k});
                                    return <div className="entry" key={k}>
                                        {!isBye && isEditing && k===2?
                                            <EditingCompetitor
                                                rrPoints={getRRPoints()}
                                                isRR rrIndex={0}
                                                node_id={node_id}
                                                checkCRC={checkCRC}
                                                currentDivision={currentDivision}
                                                round={r}
                                                roundName={''}
                                                mainBracketEntries={[]}
                                                bracketEntries={bracketEntries}
                                                index={index}
                                                registrations={registrations.filter(r => [pair[0].membership, pair[1].membership].includes(r.membership))}
                                                defaultValue={winner.membership}
                                                defaultResult={winner.result}
                                                isDisabled={isDisabled}
                                                onChange={v => {
                                                    if (!v) {
                                                        pair[3] = {};
                                                    }
                                                    pair.forEach((be, i) => {
                                                        if (i < 2) {
                                                            be.result = be.membership === v? WINS_BY.WIN.value : '';
                                                            if (be.result) {
                                                                pair[2] = be;
                                                            }
                                                        }
                                                    });
                                                    setBracketEntries(bracketEntries);
                                                    checkCRC();
                                                }}
                                                onWinBy={v => {
                                                    winner.result = v;
                                                    getSummary();
                                                    checkCRC();
                                                }}
                                            />
                                        :
                                            <Competitor 
                                                isRR
                                                roundName={''}
                                                index={k}
                                                node_id={node_id}
                                                entry={k==2? winner : entry}
                                                hoveredCompetitor={hoveredCompetitor}
                                                setHoveredCompetitor={setHoveredCompetitor}
                                                isPrinting={isPrinting}
                                                bracketEntries={bracketEntries}
                                                round={r}
                                            />}
                                    </div>}
                                )}
                            </div>;
                        })}
                    </div>
                </div>;
            })}
        </div>
    }

    const getPrintTitle = () => {
        let g = filter.group;
        if (g) {
            g = ` - Group ${g+1}`;
        } else {
            g = '';
        }
        let r = filter.round;
        if (!isNaN(r)) {
            r = ` - Round ${r+1}`;
        } else {
            r = '';
        }
        return `${currentDivision.name || currentDivision.id}${r} ${g}`;
    }

    const Summary = () => {
        return <div className="Summary" style={{display: isPrinting? 'none':''}} ref={summaryRef}>
            <h4>{isExpandSummary? 
                <CollapseIcon onClick={e => {
                    e.stopPropagation();
                    setIsExpandSummary(false)
                }}/>
                :
                <ExpandIcon onClick={e => {
                    e.stopPropagation();
                    setIsExpandSummary(true);
                }}/>} Robin Summary</h4>
            <TableContainer component={Paper} className="TableContainer" ref={summaryTableRef} style={{display: isExpandSummary? '':'none'}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Competitor</TableCell>
                            <TableCell align="right">Placement</TableCell>
                            <TableCell align="right">Points</TableCell>
                            <TableCell align="right">Wins</TableCell>
                            <TableCell align="right">Losses</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summary.map((reg, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <UserChip membership={reg.getMembership()} onClick={() => gotoCompetitor(reg, tabsRef, history, tournament)}/>
                                </TableCell>
                                <TableCell align="right">{i + 1}</TableCell>
                                <TableCell align="right">{reg.points}</TableCell>
                                <TableCell align="right">{reg.wins}</TableCell>
                                <TableCell align="right">{reg.losses}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    }

    return <ThemeProvider theme={Theme}>
        <div ref={divRef} className={`BracketRoundRobin ${isExporting? 'export':''}`}>
            <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef}/>
            <Summary />
            <div ref={bracketPoolRef} className={BracketStyles.BracketRoundRobin}>
                <RRPrint />
            </div>
        </div>
    </ThemeProvider>
});

let GROUP_OF = 8;
const RoundFilter = React.forwardRef(({ initFilter, totalRounds, onFilter, isConsolidation, currentDivision }, ref) => {
    const isRR = currentDivision.getBracketType() === BRACKET.Round_Robin;
    const minRounds = 1;
    const [filter, setFilter] = useState({value: 0});
    useEffect(() => {
        // currentDivision && setFilter(initFilter);
    }, [currentDivision]);
    
    useEffect(() => {
        setFilter(!initFilter? {value: 0}:{value:initFilter});
    }, [initFilter]);

    React.useImperativeHandle(ref, () => ({
        getRoundFilters: () => {
            let roundFilters = getRoundFilters()
            return [...roundFilters, ...getRRGroupFilter(roundFilters.length)];
        },
        handleClick,
    }));

    const createGroups = (groups, r=0) => {
        return Array(groups).fill(0)
            .map((_, f) => {
                return { label: `Round ${r+1} Group ${f + 1}`, value: `group_${r}_${f}` };
            });
    }
    const getRoundFilters = () => {
        let roundFilters = Array(totalRounds).fill(0)
            .map((_, f) => {
                let roundName = getRoundName({ r: f, totalRounds, bracketType: currentDivision.getBracketType(), isConsolidation });
                return { label: roundName ? roundName : `Round ${f + 1}`, value: f };
            });
        if (!isRR && currentDivision.getRegistrations().length > GROUP_OF) {
            const skip = 3
            for (let r=0; r<totalRounds-skip; r+=skip) {
                let baseEntriesCount = calculateTotalEntriesByRound(r, currentDivision.getRegistrations(true).length, BRACKET.Single_Elimination);
                if (isConsolidation) {
                    baseEntriesCount /= 2;
                }
                if (baseEntriesCount > 0) {
                    let groupCount = Math.ceil(baseEntriesCount / GROUP_OF);
                    let groups = createGroups(groupCount, r);
                    roundFilters = [...roundFilters, ...groups];
                }
            }
        }
        return roundFilters;
    }

    const getRRGroupFilter = (rounds) => {
        if (isRR) {
            let rRrounds = [];
            let groups = Math.ceil(currentDivision.getRegistrations(true).length / GROUP_OF);
            for (let r=0; r<rounds; r ++) {
                if (groups > 1) {
                    rRrounds.push(createGroups(groups, r));
                }
            }
            return rRrounds.flat();
        }
        return [];
    }
    let rounds = getRoundFilters();
    let groupRRs = getRRGroupFilter(rounds.length);
    const handleClick = (f, isPrint) => {
        if (!f || Object.keys(f).length===0) {
            return;
        }
        try{
            if (isRR) {
                let value = f.value;
                if (f.group) {
                    f.round = f.round || filter.value || 0;
                } else if (f.value === filter.value) {
                    f.round = 'summary';
                    value = f.value = -1;
                } else if (isNaN(f.value)) {
                    let rg = f.value.split('_');
                    f = { round: parseInt(rg[1]), group: parseInt(rg[2])};
                } else {
                    f = {round: value};
                }
                setFilter({value});
                return onFilter(f, isPrint);
            }

            f = filter.value != f.value ? f : {};
            onFilter(f.value || -1, isPrint);
            setFilter(f);
            return true;
        }catch(e){
            console.log('handleClick', e);
        }
    }

    return totalRounds > minRounds && filter &&
        <div className={BracketStyles.RoundFilter}>
            <FilterChips
                filters={[...rounds, ...groupRRs]}
                activeFilters={[filter]}
                onClick={handleClick}
            />
        </div>;
});

function bracketZoom(divRef, zoom) {
    if (divRef && divRef.current) {
        divRef.current.style.zoom = zoom;
    }
}

function isFilter(filter, round) {
    if (!isNaN(filter)) {
        return filter <= round;
    }
    return true;
}

const PrintTitle = forwardRef(({getPrintTitle, currentDivision, isPrinting, bracketTypeName}, ref) => {
    const [page, setPage] = useState('1');
    const divRef = useRef();
    useImperativeHandle(ref, () => ({
        setPage,
        setStyleDisplay: isShow => {
            divRef.current.style.display = isShow? 'flex !important':'none !imporant';
        },
        divRef,
    }));
    useEffect(() => {
        // console.log('', currentDivision, isPrinting, bracketTypeName);
    }, [isPrinting]);

    return currentDivision.getTournament? 
        <div className={`PrintTitle ${isPrinting? 'export':''}`} ref={divRef}>
            <div className="division">
                <h1>{getPrintTitle()}</h1>
                <div className="tournament-name">{currentDivision.getTournament().name} - {Utils.formatDate(currentDivision.getTournament().dates.start_date)}</div>
            </div>
            <div className="banner">
                <GIHBanner />
                <div className="page">page {page}</div>
            </div>
        </div> : '';
});

const BracketSingleElimination = React.forwardRef(({
    isDoubleElimination,
    zoom,
    registrations,
    currentDivision, 
    isThird,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    tabsRef,
    checkCRC,
}, ref) => {
    const [filter, setFilter] = useState(0);
    const totalRounds = calculateTotalRounds(registrations.length) + 1;
    const divRef = useRef();
    const bracketThirdRef = useRef();
    const roundFilterRef = useRef();
    const printTitleRef = useRef();
    const divScrollerRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        // console.log('BracketSingleElimination: ', filter);
        if (isPrinting && typeof isPrinting === 'function') {
            isPrinting();
        }
    }, [isPrinting]);

    useEffect(() => {
        bracketZoom(divRef, zoom);
        bracketThirdRef && bracketThirdRef.current && bracketZoom(bracketThirdRef.current.thisRef, zoom);
    }, [zoom]);
    useEffect(() => {
        isThird && bracketZoom(bracketThirdRef.current.thisRef, zoom);
    }, [isThird]);

    useImperativeHandle(ref, () => ({
        divRef,
        setFilter,
        roundFilterRef,
        bracketThirdRef,
        setIsPrinting,
        printTitleRef,
    }));

    const getPrintTitle = () => {
        let ddDraw = isDoubleElimination ? ' - Main Draw' : '';
        let g = filter.toString().startsWith('group_');
        if (g) {
            let gr = filter.toString().split('group_')[1].split('_');
            let r = parseInt(gr[0]);
            g = parseInt(gr[1]);
            g = ` - Round${r+1} Group${g+1}`;
        } else {
            g = '';
        }
        return `${currentDivision.name || currentDivision.id}${ddDraw}${g}`;
    }
    return <div className={classNames("BracketSingleElimination")}>
        <div className={ BracketStyles.Draw}>
            {isDoubleElimination && <h5>Main Draw</h5>}
            <RoundFilter ref={roundFilterRef}
                totalRounds={totalRounds}
                currentDivision={currentDivision}
                onFilter={(f, isPrint) => {
                    setFilter(f);
                    setIsPrinting(isPrint);
                }}
            />
            <div ref={divRef} className={`bracket_main_wrapper`} 
                onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
                <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef}/>
                <div className={classNames('bracket_main', BracketStyles.bracket)} >
                    {Array(totalRounds).fill(0).map((_, r) => {
                        return isFilter(filter, r) ?
                            <Round key={r}
                                tabsRef={tabsRef}
                                filter={getEliminationGroupFilter({ filter })}
                                round={r}
                                bracketEntries={bracketEntries.filter(b => !b.isThird)}
                                isEditing={isEditing}
                                isDisabled={isDisabled}
                                registrations={registrations}
                                hoveredCompetitor={hoveredCompetitor}
                                setHoveredCompetitor={setHoveredCompetitor}
                                setBracketEntries={(entries) => {
                                    if (currentDivision.is_third){
                                        entries = [...entries, ...bracketEntries.filter(be => be.isThird)];
                                    }
                                    setBracketEntries(entries);
                                    // bracketThirdRef && bracketThirdRef.current && bracketThirdRef.current.refresh();
                                }}
                                currentDivision={currentDivision}
                                roundName={getRoundName({ r, totalRounds, bracketType: currentDivision.getBracketType() })}
                                isPrinting={isPrinting}
                                checkCRC={checkCRC}
                            /> : ''
                    }
                    )}
                </div>
            </div>
        </div>
        {isThird && 
            <ThirdPlace 
                semiRound={totalRounds-3}
                isPrinting={isPrinting}
                getPrintTitle={getPrintTitle}
                tabsRef={tabsRef}
                isEditing={isEditing}
                isDisabled={isDisabled}
                registrations={registrations}
                hoveredCompetitor={hoveredCompetitor}
                setHoveredCompetitor={setHoveredCompetitor}
                currentDivision={currentDivision}
                filter={filter}
                ref={bracketThirdRef}
                bracketEntries={bracketEntries}
                setBracketEntries={entries => {
                    setBracketEntries(entries);
                    bracketThirdRef && bracketThirdRef.current && bracketThirdRef.current.refresh();
                }} 
                checkCRC={checkCRC}
                />}
                
    </div>;
});

const ThirdPlace = React.forwardRef(({
    semiRound,
    isPrinting, 
    getPrintTitle, 
    tabsRef, 
    isEditing, 
    isDisabled, 
    registrations, 
    hoveredCompetitor, 
    setHoveredCompetitor,
    currentDivision,
    filter,
    bracketEntries,
    setBracketEntries,
    checkCRC,
    }, ref) => {
    
    const [refresh, setRefresh] = useState(false);
    const [entries, setEntries] = useState([]);
    const divRef = useRef();
    const thirdDivRef = useRef();
    const printTitleRef = useRef();

    useImperativeHandle(ref, () => ({
        refresh: () => setRefresh(!refresh),
        divRef,
        printTitleRef,
    }));

    useEffect(() => {
        let thirdEntries = bracketEntries.filter(b => b.isThird);
        if (!isEditing) {
            return setEntries(thirdEntries);
        }
        let semiLoserEntries = getSemiLoserEntries();
        let loserMemberIds = semiLoserEntries.map(be => be.membership);  
        thirdEntries = thirdEntries.filter(be => loserMemberIds.includes(be.membership)); //remove that is not in current semi losers
        let hasChange;
        let newIdIndex = bracketEntries.length;
        semiLoserEntries.forEach(s => {
            let found = thirdEntries.find(be => be.round===0 && be.membership === s.membership);
            if (!found) {
                hasChange = true;
                let newEntry = {...s, index: s.index<2? 0:1, isThird: true, round: 0, place: null, id: `-${newIdIndex++}`}
                thirdEntries.push(newEntry);
            }
        });
        let bes = bracketEntries.filter(be => !be.isThird); 
        if (hasChange) {
            thirdEntries = thirdEntries.filter(be => be.round !== 1); //reset 3rd winner  
            setBracketEntries([...bes, ...thirdEntries]);
        }else {
            setEntries(thirdEntries);
        }
    }, [bracketEntries]);

    const getSemiLoserEntries = () => {
        const semiEntries = bracketEntries.filter(be => 
            !be.isThird && be.round === semiRound
        );
    
        const losers = [0, 1].map(index => {
            const entries = semiEntries.filter(be => Math.floor(be.index / 2) === index);
            return entries.length === 2 && entries.filter(be => !be.result).length === 1 
                ? entries.filter(be => !be.result)[0] 
                : null;
        });
    
        return losers.filter(loser => loser);
    };

    const getRegistrations = () => {
        let membershipIds = getSemiLoserEntries().map(e => e.getMembership && e.getMembership() && e.getMembership().id).filter(e => e);
        return registrations.filter(r => {
            let rdivs = [r.division, ...r.pool.split('|')];
            return rdivs.includes(currentDivision.id) && membershipIds.includes(r.membership);
        });
    }

    const totalRounds = 2;
    return <div ref={divRef} className={`ThirdPlace ${BracketStyles.Draw} ${isPrinting? 'export':''}`} >
        <PrintTitle getPrintTitle={getPrintTitle} currentDivision={currentDivision} isPrinting={isPrinting} ref={printTitleRef}/>
        <h4 className="title">Third Place</h4>
        <div className={classNames('third_bracket_main', BracketStyles.bracket)} ref={thirdDivRef}
            onMouseMove={mouseEvent => divScroller({divRef: thirdDivRef, mouseEvent})}>
            {Array(totalRounds).fill(0).map((_, r) => {
                return <Round key={r}
                        tabsRef={tabsRef}
                        filter={getEliminationGroupFilter({ filter })}
                        round={r}
                        bracketEntries={entries}
                        isEditing={isEditing}
                        isDisabled={isDisabled}
                        registrations={getRegistrations()}
                        hoveredCompetitor={hoveredCompetitor}
                        setHoveredCompetitor={setHoveredCompetitor}
                        setBracketEntries={entries => {
                            setBracketEntries([...bracketEntries.filter(b => !b.isThird), ...entries]);
                        }}
                        currentDivision={currentDivision}
                        roundName={getRoundName({ r, totalRounds, bracketType: BRACKET.Single_Elimination, isThird: true })}
                        isPrinting={isPrinting}
                        isThird={true}
                        checkCRC={checkCRC}
                    /> 
            })}
        </div>
    </div>
});

function RoundHeader({ round, name, index, isConsolidation, isThird, isFinal_1st_double }) {
    const isSkip = () => {
        if (isFinal_1st_double) {
            return index!==0;
        }
        let r = round || 0;
        if (isConsolidation) {
            if (r % 2 !== 0) {
                r--;
            }
        }
        return index * Math.pow(2, r) % GROUP_OF !== 0;
    }
    if (isSkip()) {
        return '';
    }

    name = name!==undefined ? name : (isNaN(round) ? (isThird? ROUND_NAME.ThirdPlace:ROUND_NAME.Winner) : `Round ${round + 1}`);
    return <div className={classNames('round_header', CompetitorStyles.round_header)}>
        <b>{name===ROUND_NAME.Final && isThird? '':name}</b>
    </div>;
}

const ROUND_NAME = {
    Winner: 'Winner',
    Final: 'Final',
    Semi: 'Semi',
    Quarter: 'Quarter',
    ThirdPlace: '3rd Place',
    SecondPlace: '2nd Place',
    DrawWinner: 'Draw Winner'
}

function RenderNode({
    round,
    rrIndex,
    allEntries,
    index,
    registrations,
    isDisabled,
    currentDivision,
    setBracketEntries,
    hoveredCompetitor,
    isEditing,
    bracketEntries, mainBracketEntries,
    setHoveredCompetitor,
    roundName,
    isConsolidation,
    filter,
    isPrinting,
    isThird,
    checkCRC,
    isRR,
}) {
    const entry = allEntries.find(e => e?.index === index);
    const getMembership = (v) => {
        let found = registrations.find(r => r.membership === v);
        return found && found.getMembership();
    }
    const renderRoundNumber = () => {
        if (currentDivision.bracket_type === BRACKET.Round_Robin) {
            return index === 0 && rrIndex === 0 ? round : undefined;
        }
        return round
    }
    const getOpponentEntry = (membership) => {
        if (round === 0) {
            return;
        }
        let prevRound = round - 1;
        let prevIndex = Math.floor(index / 2);
        return bracketEntries.find(e => {
            return e.round === prevRound && [prevIndex, prevIndex + 1].includes(e.index) && e.membership !== membership;
        });
    }

    const render = () => {
        const getPrevFromBracketEntries = membership => {
            let prevs = bracketEntries
                .filter(r => {
                    const getIndex = () => {
                        if (isConsolidation && round%2 !== 0 && !Object.values(ROUND_NAME).includes(roundName)) {
                            return index - 1;
                        }
                        return index;
                    }
                    return r.round === round - 1 && Math.floor(r.index / 2) === getIndex();
                });
            if (membership){
                return prevs.find(be => be.membership === membership);
            }
            return prevs;
        }

        const setPlacement = (entry, oppEntry) => {
            delete entry.place;
            if (roundName === ROUND_NAME.Winner) {
                entry.place = 1;
                oppEntry && (oppEntry.place = 2);
                if (!currentDivision.is_third) {
                    let semiLoserEntries = bracketEntries.filter(be => be.round === round-2 && !be.result);
                    semiLoserEntries.forEach(be => {
                        if (!be.result && ![entry.membership, oppEntry && oppEntry.membership].filter(e => e).includes(be.membership)) {
                            be.place = 3;
                        }else {
                            be.place = '';
                        }
                    });
                }
            } else if (roundName === ROUND_NAME.ThirdPlace) {
                bracketEntries.forEach(be => be.place = null);
                entry.place = 3;
                oppEntry && (oppEntry.place = 4);
            } else {
                oppEntry && (delete oppEntry.place);
            }
        }

        let node_id = createBracketNodeId({ round: rrIndex || round, index, isConsolidation, isThird});
        if (isEditing) {
            if (!entry) {
                return <EmptyCompetitor key={index}
                    isRR={isRR} rrIndex={rrIndex}
                    node_id={node_id}
                    currentDivision={currentDivision}
                    isThird={isThird}
                    isConsolidation={isConsolidation}
                    round={renderRoundNumber()}
                    roundName={roundName}
                    mainBracketEntries={mainBracketEntries}
                    bracketEntries={bracketEntries}
                    index={index}
                    registrations={registrations}
                    isDisabled={isDisabled}
                    onChange={entry => {
                        entry.isThird = isThird;
                        entry.round = round;
                        entry.index = index;
                        entry.isLoser = isConsolidation;
                        entry.tournament = currentDivision.tournament;
                        entry.division = currentDivision.id;
                        entry.getMembership = () => getMembership(entry.membership);
                        entry.getDivision = () => currentDivision;
                        let prevBracketEntry = getPrevFromBracketEntries(entry.membership);
                        if (prevBracketEntry) {
                            if (hasByeInPrevRound(bracketEntries, round, index)) {
                                prevBracketEntry.result = WINS_BY.BYE.value;
                            }else {
                                prevBracketEntry.result = WINS_BY.WIN.value;
                            }
                        }
                        entry.crc = 'new entry';
                        let oppEntry = getOpponentEntry(entry.membership);
                        if (!oppEntry && prevBracketEntry) {
                            prevBracketEntry.result = WINS_BY.BYE.value;
                        }
                        setPlacement(entry, oppEntry);
                        if (currentDivision.is_modify_round_robin && round === 1 && index === 0) {
                            let thirdLoser = bracketEntries.find(be => be.round===0 && be.index===3);
                            thirdLoser.membership = oppEntry.membership;
                            thirdLoser.getMembership = oppEntry.getMembership;
                        }
                        bracketEntries.push(entry);
                        setBracketEntries(bracketEntries);
                        checkCRC();
                    }}
                />
            }
            return <EditingCompetitor key={index}
                isRR={isRR} rrIndex={rrIndex}
                node_id={node_id}
                checkCRC={checkCRC}
                currentDivision={currentDivision}
                isThird={isThird}
                isConsolidation={isConsolidation}
                round={renderRoundNumber()}
                roundName={roundName}
                mainBracketEntries={mainBracketEntries}
                bracketEntries={bracketEntries}
                index={index}
                registrations={registrations}
                entryId={entry?.id}
                defaultValue={entry.membership}
                defaultResult={entry.result}
                isDisabled={isDisabled}
                onChange={v => {
                    try{
                        let prevs = getPrevFromBracketEntries();
                        prevs.forEach(be => be.result = '');
                        if (!v && entry) {
                            let bes = bracketEntries.filter(be => be !== entry);
                            return setBracketEntries(bes);
                        }
                        delete entry.place;
                        let oppEntry = getOpponentEntry(v);
                        if (oppEntry) {
                            let prevBracketEntry = getPrevFromBracketEntries(v);
                            if (prevBracketEntry) {
                                prevBracketEntry.result = WINS_BY.WIN.value;
                            }
                            setPlacement(entry, oppEntry);
                        }
                        entry.isThird = isThird;
                        entry.isLoser = isConsolidation;
                        entry.membership = v;
                        entry.getMembership = () => getMembership(v);
                        if (currentDivision.is_modify_round_robin && round === 1 && index === 0) {
                            let thirdLoser = bracketEntries.find(be => be.round===0 && be.index===3);
                            thirdLoser.membership = oppEntry.membership;
                            thirdLoser.getMembership = oppEntry.getMembership;
                        }
                        setBracketEntries(bracketEntries);
                    }finally{
                        checkCRC();
                    }
                }}
                onWinBy={v => {
                    let prevBracketEntry = getPrevFromBracketEntries(entry.membership);
                    if (prevBracketEntry) {
                        prevBracketEntry.result = v;
                        let bOpp = prevBracketEntry.getOpponent && prevBracketEntry.getOpponent();
                        if (bOpp){
                            bOpp.opp.result = '';
                        }
                        setBracketEntries(bracketEntries);
                    }
                }}
            />;
        }
        return <Competitor key={index}
            isRR={isRR}
            isThird={isThird}
            index={index}
            round={renderRoundNumber()}
            roundName={roundName}
            bracketEntries={bracketEntries}
            node_id={node_id}
            entry={entry}
            hoveredCompetitor={hoveredCompetitor}
            setHoveredCompetitor={setHoveredCompetitor}
            isConsolidation={isConsolidation}
            isPrinting={isPrinting}
        />
    }

    const isScheduled = () => {
        if (isEditing || round === 0 || rrIndex === 0 || (isConsolidation && round % 2 !== 0 && index % 2 === 0)) {
            return;
        }
        if (rrIndex === 1) {
            return true;
        }
        let isOddRound = round % 2 !== 0;
        let prevIndex = index * 2;
        if (isConsolidation && isOddRound) {
            prevIndex = (index - 1) * 2;
        }
        let prevEntries = bracketEntries.filter(b => b.round === round - 1 && [prevIndex, prevIndex + 1].includes(b.index));
        let prevLength = prevEntries.length;
        if ([prevLength>0 && prevEntries[0].membership, prevLength>1 && prevEntries[1].membership].includes(BYE)) {
            return;
        }
        let bracketId1 = prevLength > 0 && TournamentModel.getBracketId(prevEntries[0]);
        let bracketId2 = prevLength > 1 && TournamentModel.getBracketId(prevEntries[1]);
        return bracketId1 || bracketId2;
    }
    let scheduled = isScheduled();
    let cName = classNames(
        `competitor_wrapper ${isPrinting? 'export':''} group_of_${GROUP_OF}`,
        BracketStyles.competitor_wrapper, 
        createBracketNodeId(round, index, isConsolidation, isThird));
    let isFilter = () => {
        const thirdPlaceFilter = ['node_R0_I0_C0_T1', 'node_R0_I1_C0_T1', 'node_R1_I0_C0_T1'];
        if (!isNaN(filter)) {
            return false;
        }
        let nodeId = createBracketNodeId({round, index, isConsolidation, isThird});
        if (thirdPlaceFilter.includes(nodeId)){
            return false;
        }
        return !filter.includes(nodeId);
    }
    const isJoinVisible = () => {
        if (isConsolidation && round%2!==0 && index%2===0 && !Object.values(ROUND_NAME).includes(roundName)) {
            return 'none';
        }
        return undefined;
    }
    const getJoinerId = () => [isConsolidation, round, index].join('_')
    return !isFilter() && 
        <div className={`${cName}`}>
            <div className={classNames('border_center', BracketStyles.border_center)} style={{border: isJoinVisible()}} id={getJoinerId()}>
                <div className={classNames('border_center_top', BracketStyles.border_center_top)} style={{border: isJoinVisible()}} id={getJoinerId()}/>
                <div className="border_center_bottom" style={{ position: 'relative' }}>
                    {/* {tabsRef && scheduled && !isPrinting &&
                        <Link>
                            <ScheduleIcon style={{ position: 'absolute', zIndex: 10, left: -16, cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(`/tournaments/${currentDivision.tournament}/edit?tab=schedules&id=${scheduled}`);
                                    tabsRef.current.setActiveTabByName('schedules');
                                }}
                            />
                        </Link>} */}
                </div>
            </div>
            <div className={classNames('border_join', BracketStyles.border_join)} style={{border: isJoinVisible()}} id={getJoinerId()}/>
            {render()}
        </div>;
}
export const createBracketNodeId = ({ round, index, isConsolidation, isThird=false }) => `node_R${round}_I${index}_C${isConsolidation ? 1:0}_T${isThird? 1:0}`;
const renderJoins = (columns, isConsolidation, currentDivision) => {
    if (!columns) {
        return;
    }
    const hideJoiner = (node, unHide) => {
        let join = node.querySelector('.border_join');
        let center = node.querySelector('.border_center .border_center_top');
        if (!join) {
            return;
        }
        if (!unHide) {
            join.style.visibility = 'hidden';
            center.style.visibility = 'hidden';
        } else {
            join.style.visibility = 'visible';
            center.style.visibility = 'visible';
        }
    }
    for (let ci = 1; ci < columns.length; ci++) {
        let column = columns[ci];
        let prevColumn = columns[ci - 1];
        if (prevColumn.classList.contains('blank')) {
            if (column.firstChild) {
                for (let n = 0; n < column.firstChild.children.length; n++) {
                    hideJoiner(column.firstChild.children[n]);
                }
            }
            continue;
        }
        try {
            let nodes = prevColumn.firstChild.children;
            if (nodes.length === 0) {
                continue;
            }
            let first = nodes[0];
            let second = nodes[1];
            let nodeHeight = first.getBoundingClientRect().height / 2;
            let firstMid = first.getBoundingClientRect().top + nodeHeight;
            let secondMid = second.getBoundingClientRect().top + nodeHeight;
            let height = secondMid - firstMid;
            const getCalculateOffsetNode = () => {
                let ciChildren = column.firstChild.children;
                for (let ci = 0; ci < ciChildren.length; ci++) {
                    if (ciChildren[ci].getBoundingClientRect().top > first.getBoundingClientRect().top) {
                        return ciChildren[ci];
                    }
                }
                return column.firstChild.firstChild;
            }
            let joinOffset = getCalculateOffsetNode();
            if (!joinOffset) {
                continue;
            }
            joinOffset = joinOffset.getBoundingClientRect().top - firstMid;
            for (let si = 0; si < column.children.length; si++) {
                let splitColumn = column.children[si];
                for (let ni = 0; ni < splitColumn.children.length; ni++) {
                    let node = splitColumn.children[ni];
                    let join = node.querySelector('.border_join');
                    if (join) {
                        let isLoser3rdFinalRound = currentDivision && currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD && ci === columns.length - 1;
                        if (isConsolidation === 1 && ci % 2 > 0 && ni % 2 === 0 && !isLoser3rdFinalRound) {
                            // hideJoiner(node);
                        } else {
                            hideJoiner(node, true);
                            join.style.height = `${height}px`;
                            join.style.marginTop = `-${joinOffset}px`;
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}

const getRoundRobinGroupFilter = (filter, regCount) => {
    if (isNaN(filter.group)) {
        return [];
    }
    let groupFilters = [];
    let group = filter.group;
    for (let round = 0; round <= 1; round++) {
        let groupOf = Math.floor(GROUP_OF / Math.pow(2, round))
        let startIndex = group * groupOf;
        let endIndex = startIndex + groupOf;
        for (let index = startIndex; index < endIndex && index < regCount; index++) {
            groupFilters.push(createBracketNodeId({ round, index }));
        }
    }
    return groupFilters;
}

const getEliminationGroupFilter = ({ filter, isConsolidation }) => {
    if (!filter || !filter.toString().startsWith('group_')) {
        return filter;
    }

    let group = filter.split('_');
    if (group.length !== 3) {
        return [];
    }
    let round = parseInt(group[1]);
    group = parseInt(group[2]);
    if (isNaN(group)) {
        return [];
    }
    let startIndex = group * GROUP_OF;
    let endIndex = startIndex + GROUP_OF;
    let groupFilter = [];
    const createFilter = () => {
        let rounds = round + calculateTotalRounds(GROUP_OF);
        for (let r = round; r < rounds; r++) {
            for (let index = startIndex; index < endIndex; index++) {
                groupFilter.push(createBracketNodeId({ round: r, index, isConsolidation }));
            }
            startIndex = Math.floor(startIndex / 2);
            endIndex = Math.floor(endIndex / 2);
        }
        return groupFilter;
    }

    const createConslidationFilter = () => {
        for (let round = 0; round <= 4; round++) {
            for (let index = startIndex; index < endIndex; index++) {
                groupFilter.push(createBracketNodeId({ round, index, isConsolidation }));
            }
            if (round % 2 !== 0) {
                startIndex /= 2;
                endIndex /= 2;
            }
        }
        return groupFilter;
    }

    if (!isConsolidation) {
        return createFilter();
    }
    return createConslidationFilter();
}

function RenderRound({
    rrIndex,
    round,
    allEntries,
    registrations,
    isDisabled,
    currentDivision,
    setBracketEntries,
    hoveredCompetitor,
    isEditing,
    bracketEntries, mainBracketEntries,
    setHoveredCompetitor,
    roundName,
    thisRef,
    isConsolidation,
    tabsRef,
    filter,
    isPrinting,
    isThird,
    checkCRC,
    isRR,
}) {
    const renderCompetitor = (i) => {
        return <RenderNode key={i}
            rrIndex={rrIndex}
            round={round}
            index={i}
            registrations={registrations}
            isDisabled={isDisabled}
            currentDivision={currentDivision}
            setBracketEntries={setBracketEntries}
            hoveredCompetitor={hoveredCompetitor}
            isEditing={isEditing}
            allEntries={allEntries}
            mainBracketEntries={mainBracketEntries}
            bracketEntries={bracketEntries}
            setHoveredCompetitor={setHoveredCompetitor}
            roundName={roundName}
            isConsolidation={isConsolidation}
            tabsRef={tabsRef} 
            filter={filter}
            isPrinting={isPrinting}
            isThird={isThird}
            checkCRC={checkCRC}
            isRR={isRR}
        />
    }

    return <div key={`round_${round}`} className={classNames('column', BracketStyles.column)} ref={thisRef}>
        {allEntries.length > 1 ?
            <div className={BracketStyles.topHalf}>
                {allEntries.map((_, i) => {
                    return renderCompetitor(i)
                })}
            </div>
            : <div className={BracketStyles.winner}>
                {renderCompetitor(0)}
            </div>}
    </div>
}

function Round({
    round,
    rrIndex,
    mainBracketEntries,
    bracketEntries,
    isEditing,
    isDisabled,
    registrations,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    currentDivision,
    entriesCount,
    roundName,
    isConsolidation,
    filter,
    tabsRef,
    isPrinting,
    isThird,
    checkCRC,
    isRR,
}) {
    const thisRef = useRef(null);
    useEffect(() => {
        renderJoins(thisRef.current.parentElement.children);
    }, [thisRef.current && thisRef.current.getClientRects()]); 
    
    useLayoutEffect(() => {
        thisRef.current && renderJoins(thisRef.current.parentElement.children);
        thisRef.current && selectNodeId(thisRef.current);
    }, [thisRef.current, currentDivision.id, filter]);

    let numberOfEntries = entriesCount || calculateTotalEntriesByRound(round, registrations.length);
    let allEntries = bracketEntries.filter(entry => entry.round === round);
    for (let i = allEntries.length; i < numberOfEntries; i++) {
        allEntries.push(null);
    }
    return <RenderRound
        tabsRef={tabsRef} 
        rrIndex={rrIndex}
        round={round}
        allEntries={allEntries}
        mainBracketEntries={mainBracketEntries}
        registrations={registrations}
        isDisabled={isDisabled}
        currentDivision={currentDivision}
        setBracketEntries={entries => {
            entries = entries.map(e => {
                e.tournament = currentDivision.getTournament().id;
                return e;
            })
            setBracketEntries(entries);
        }}
        hoveredCompetitor={hoveredCompetitor}
        isEditing={isEditing}
        bracketEntries={bracketEntries}
        setHoveredCompetitor={setHoveredCompetitor}
        roundName={roundName}
        thisRef={thisRef}
        isConsolidation={isConsolidation}
        filter={filter}
        isPrinting={isPrinting}
        isThird={isThird}
        checkCRC={checkCRC}
        isRR={isRR}
    />
}

const getWinBy = ({bracketEntries, round, index, membershiId, isConsolidation, roundName, isRR, isFinal_1st_double}) => {
    let preEntries = isRR || isFinal_1st_double? bracketEntries.filter(be => be.round === parseInt(round)) :
        getPrevBracketEntries(bracketEntries, round, isConsolidation&&round%2!==0 && !Object.values(ROUND_NAME).includes(roundName)? index-1:index);
    let found = isFinal_1st_double? preEntries.find(be => be.isFinal_1st_double && be.result) : preEntries.find(e => e.membership===membershiId);
    if (found && found.result) {
        let r = WINS_BY[found.result];
        if (r) {
            return r.label;
        }
    }
    return '';
}

function Competitor({
    isFinal_1st_double,
    isRR,
    entry,
    hoveredCompetitor,
    setHoveredCompetitor,
    round,
    roundName,
    node_id,
    index,
    isConsolidation, isThird,
    isPrinting,
    bracketEntries
}) {
    const [isHighLite, setIsHighLite] = useState(false);
    const divRef = useRef();
    useEffect(() => {
        let params = RequestUtils.getURLParameters();
        const doScrollToView = () => {
            if (params.highlite.split('|')[0] === entry.membership) { 
                    divRef.current.scrollIntoView({
                        behavior: 'smooth', 
                        block: 'center',     
                    });
                }
        }
        if (entry && (params.highlite||'').split('|').includes(entry.membership)){
            if (!params.round){
                doScrollToView();
                setIsHighLite('highlite');
            }else if (parseInt(params.round) === round) {
                doScrollToView();
                setIsHighLite('highlite');
            }else {
                setIsHighLite('');
            }
        }
    }, [entry]);

    const getName = () => {
        if (!entry || !entry.getMembership || !entry.membership || entry.membership === TBD) {
            return <span className="empty">TBD</span>;
        }
        if (entry.membership === BYE) {
            return <span className="empty">Bye</span>;
        }
        const membership = entry.getMembership();
        return `${membership?.first_name} ${membership?.last_name}`;
    }
    const RenderFlag = () => {
        if (!isPrinting && entry.getMembership && entry.getMembership()?.country) {
            return <FlagIcon className={CompetitorStyles.flagIcon} country={entry?.getMembership()?.country.toLowerCase()} />
        }
        return '';
    }
    const getGym = () => {
        if (!entry || !entry.getMembership) {
            return;
        }
        const membership = entry.getMembership();
        return(membership && membership.getGym && membership.getGym()?.name) || '';
    }
    if ([ROUND_NAME.Winner, ROUND_NAME.ThirdPlace].includes(roundName) && entry && ![TBD, BYE].includes(entry.membership)) {
        let isWinner = ROUND_NAME.Winner === roundName;
        entry.place = isWinner? 1:3;
        let second = bracketEntries.find(b => {
            return b.round === entry.round - 1 && b.membership !== entry.membership;
        });
        if (second) {
            second.place = isWinner? 2:4;
        }
    }
    
    const displayWinBy = () => {
        if (isRR || isFinal_1st_double) {
            return index===2;
        }
        return round>0;
    }
    return <div node_id={node_id} ref={divRef}
        index={entry?.index}
        key={entry?.id || 0}
        entry-id={entry?.id}
        className={classNames(
            `competitor ${isHighLite}`,
            CompetitorStyles.competitor,
            { [CompetitorStyles.hovered]: hoveredCompetitor === entry?.membership })}
        onMouseOver={() => {
            if (entry?.membership) {
                setHoveredCompetitor(entry?.membership);
            }
        }}
        onMouseOut={() => {
            setHoveredCompetitor(null);
        }}
    >
        <RoundHeader round={round} name={roundName} index={index} isConsolidation={isConsolidation} isThird={isThird} isFinal_1st_double={isFinal_1st_double}/>
        {entry ? <div className={`competitor-info`}>
            <div className={CompetitorStyles.competitorName}>
                <div className="competitor-name">
                    <Tooltip title={getName()}>
                        <Link color="inherit" href={`/users/${entry.membership}`}>
                            {getName()}
                        </Link>
                    </Tooltip>
                </div>
                <RenderFlag />
                <div className={CompetitorStyles.placement}>{!isRR && entry.place? TournamentModel.renderPlacement(entry.place) : ''}</div>
            </div>
            <div className={`${CompetitorStyles.competitorTeam} gym-name`}>{getGym()}</div>
            {displayWinBy() && <b className="win-by">{getWinBy({bracketEntries, round, index, membershiId: entry.membership, isConsolidation, roundName, isRR, isFinal_1st_double})}</b>}
        </div> : <div className={CompetitorStyles.competitorName}>TBD</div>}
    </div>
}

export const getPrevBracketEntries = (bracketEntries, round, index) => {
    return bracketEntries.filter(r => r.round === round - 1 && Math.floor(r.index / 2) === index);
}

const hasByeInPrevRound = (bracketEntries, round, index) => {
    let hasBye = getPrevBracketEntries(bracketEntries, round, index);
    if (hasBye.length!== 2 || hasBye.find(be => [TBD, BYE, '', undefined, null].includes(be.membership))) {
        return true;
    }
}
const getCompetitorOptions = ({
    rrIndex, 
    isRR, 
    isThird, 
    registrations, 
    round, 
    index, 
    bracketEntries, 
    isConsolidation, 
    mainBracketEntries, 
    roundName, 
    isFinal_1st_double 
}) => {
    let lastRoundComps = [];
    let isRoundOdd = round % 2 !== 0;
    let isIndexOdd = index % 2 !== 0;

    const getPrevFromMain = () => {
        const getMainIndex = (index) => {
            if (round > 0){
                return Math.floor(index/2);
            }
            return index;
        };

        const getRound = () => {
            let finalRound = calculateTotalRounds(registrations.length) - 1;
            if (round > finalRound){
                return finalRound;
            }
            return round;
        }
        let founds = mainBracketEntries.filter(r => {
            return r.round === getRound() 
                && Math.floor(r.index / 2) === (isConsolidation? getMainIndex(index):index);
        }).map(r => r.membership);
        let winner = mainBracketEntries.find(r => r.round === getRound() + 1 && founds.includes(r.membership));
        return winner ? founds.filter(f => f !== winner.membership) : founds;
    }
    const getPrevFromBracketEntries = () => getPrevBracketEntries(bracketEntries, round, index).map(r => r.membership);

    if (round === undefined) {
        lastRoundComps = mainBracketEntries.filter(r => Math.floor(r.index / 2) === index).map(r => r.membership);
    }else if (round > 0) {
        if (!Object.values(ROUND_NAME).includes(roundName) && isConsolidation) {
            if (isRoundOdd && !isIndexOdd) {
                lastRoundComps = getPrevFromMain();
            } else if (isConsolidation && isRoundOdd) {
                lastRoundComps = bracketEntries.filter(r => r.round === round - 1 && Math.floor(r.index / 2) === (index - 1)).map(r => r.membership);
            } else {
                lastRoundComps = getPrevFromBracketEntries();
            }
        } else {
            lastRoundComps = getPrevFromBracketEntries();
        }
    } else if (isConsolidation && round === 0) {
        lastRoundComps = getPrevFromMain();
    }

    const createOption = (r, isSuggest, isReg) => {
        if (r && r.id && r.membership) {
            return {
                value: r.membership,
                label: <span className={isSuggest? CompetitorStyles.competitorSelectable: ''}>
                    {r.first_name} {r.last_name} [<b>{r.getMembership() && r.getMembership().getGym() && r.getMembership().getGym().name}</b>]
                </span>,
                isReg,
                isSuggest
            };
        }
    }
    let suggests = registrations.filter(r => r && lastRoundComps.includes(r.membership)).map(r => {
        return createOption(r, true, true);
    }).filter(o => o);
    let nonSuggests = registrations.filter(r => r && !lastRoundComps.includes(r.membership)).map(r => {
        return createOption(r, null, true);
    }).filter(o => o);
    
    if ((isThird && round === 0) || isRR || isFinal_1st_double) {
        suggests = registrations.map(r => createOption(r, true, true));
        nonSuggests = [];
    }

    let byeOptions = [{ value: TBD, label: 'TBD' }, isRR? null : { value: BYE, label: 'Bye' }];
    let options = [...suggests, ...nonSuggests];
    if ((isRR && rrIndex > 0) || (!isRR && round > 0) || isFinal_1st_double){
        options = suggests;
        if (suggests.length > 1){
            byeOptions = [];
        }
    }
    return [(isFinal_1st_double || (!isRR && round>0)) && {value: '', label: <span>No Selection</span>}, ...byeOptions, ...options].filter(o => o);
}
function EditingCompetitor({
    currentDivision,
    bracketEntries, mainBracketEntries,
    index, rrIndex,
    registrations,
    defaultValue='',
    defaultResult='',
    isDisabled,
    onChange, onWinBy,
    round,
    roundName,
    isConsolidation, isThird,
    checkCRC,
    node_id,
    isRR,
    rrPoints,
    isFinal_1st_double,
}) {
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [refresh, setRefresh] = useState(false);
    const [isWinBy, setIsWinBy] = useState(false);
    const [result, setResult] = useState();
    const winnerSelectRef = useRef();
    const selectFormInputWinByRef = useRef();
    
    useEffect(() => {
        setResult(defaultResult);
    }, [defaultResult]);

    useEffect(() => {
        winnerSelectRef.current = defaultValue;
    }, [defaultValue]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [bracketEntries, mainBracketEntries, isBracketEntriesUpdate]);

    const getWinByRegName = () => {
        if (!winnerSelectRef.current) {
            return 'Select';
        }
        let reg = registrations.find(r => r.membership === winnerSelectRef.current);
        return reg? UserModel.getMembershipName(reg.getMembership()) : '';
    }
    const getWinByOptions = () => {
        const {winbys} = currentDivision.getTournament().getLeague();
        let options = WINS_BY_OPTIONS;
        if (winbys && winbys.length > 0) {
            options = winbys.map(wb => ({label: wb.name, value: wb.code}));
        }
        if (isRR && rrPoints) {
            let winBys = Object.keys(rrPoints); 
            return options.filter(wb => winBys.includes(wb.value)).map(wb => ({value: wb.value, label: `${wb.label} (${rrPoints[wb.value]} pts)`}));
        }
        return options;
    }

    let winBy = getWinBy({bracketEntries, round, index, membershiId: winnerSelectRef.current || defaultValue, isConsolidation, roundName, isRR, isFinal_1st_double});
    let options = getCompetitorOptions({
        rrIndex, isRR, isThird, 
        registrations, round, index, 
        bracketEntries, isConsolidation, 
        mainBracketEntries, roundName, 
        currentDivision, isFinal_1st_double });
    let hasNextRoundEntry = () => {
        if (isFinal_1st_double) {
            return false;
        }
        let nextRound = round + 1;
        return bracketEntries.find(be => {
            const getIndex = () => {
                if (isConsolidation) {
                    if (nextRound%2 > 0) {
                        return Math.floor(index/2) + 1;
                    }
                }
                return Math.floor(index/2);
            }
            return be.round===nextRound && getIndex()===be.index && be.membership;
        });
    };
    return <div className={classNames(node_id, 'EditingCompetitor', CompetitorStyles.competitor, { [CompetitorStyles.hovered]: false }, CompetitorStyles.edit)}>
        <ThemeProvider theme={Theme}>
            <RoundHeader round={round} name={roundName} index={index} isThird={isThird}/>
            {!isWinBy? <SelectFormInput width="100%" 
                disabled={hasNextRoundEntry() || isDisabled}
                name="competitor"
                label={<div className="select-competitor">
                        Select a competitor 
                        {(round>0 || isRR || isFinal_1st_double) && winnerSelectRef.current && 
                            <b className="win-by" 
                                onClick={e => {
                                    e.stopPropagation();
                                    if (currentDivision.bracket_type === BRACKET.Round_Robin) {
                                        let hasBye = bracketEntries.find(be => {
                                            let first = be.index;
                                            let second = be.index + 1;
                                            if (first%2 !== 0) {
                                                first = be.index - 1;
                                                second = be.index;
                                            }
                                            return be.round===round && [first, second].includes(be.index) && be.membership===BYE;
                                        });
                                        if (hasBye) {
                                            return;
                                        }
                                    }else if (hasByeInPrevRound(bracketEntries, round, index)) {
                                        return;
                                    }
                                    setIsWinBy(true);
                                }}>
                                ({winBy})
                            </b>}</div>}
                value={defaultValue}
                options={options}
                onChange={v => {
                    onChange(v);
                    winnerSelectRef.current = v;
                    checkCRC();
                }}
            />
            :
            <SelectFormInput width="100%" ref={selectFormInputWinByRef}
                disabled={isDisabled}
                name="winBy"
                label={<div className="select-winby"><b className="name">{getWinByRegName()}</b> win by</div>}
                value={result}
                options={[{}, {value: 'cancel', label: <b style={{color: 'red'}}>Cancel</b>}, ...getWinByOptions()]}
                onChange={v => {
                    try {
                        if (v === 'cancel'){
                            return;
                        }
                        onWinBy && onWinBy(v);
                        setResult(v);
                        checkCRC();
                    }finally {
                        setIsWinBy(false);
                    }
                }}
                onRenderComplete={() => {
                    console.log('onRenderComplete');
                    setTimeout(() => selectFormInputWinByRef.current.setOpen(true), 500);
                }}
                onOptionClick={v => {
                    if (v.value === result){
                        setIsWinBy(false);
                    }
                }}
            />}
        </ThemeProvider>
    </div>
}

function EmptyCompetitor({
    node_id,
    currentDivision,
    isConsolidation, isThird, isRR,
    index, rrIndex,
    round, 
    registrations,
    isDisabled,
    onChange,
    roundName,
    bracketEntries, mainBracketEntries }) {
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        setRefresh(!refresh);
    }, [bracketEntries, mainBracketEntries, isBracketEntriesUpdate]);

    let options = getCompetitorOptions({
        rrIndex, isRR, isThird, registrations, 
        round, index, bracketEntries, 
        isConsolidation, mainBracketEntries, 
        roundName, 
        currentDivision });

    const addEntry = value => {
        if (bracketEntries.find(be => be.index === index && be.round === round)) {
            return;
        }
        const tournament = currentDivision.getTournament();
        let bracketEntry = {
            membership: value, round, index, isThird,
            getMembership: () => tournament.getMemberships().find(m => m.id === value),
            getDivision: () => tournament.getAvailableDivisions().find(d => d.id === currentDivision.id)
        };
        onChange(bracketEntry);
    }

    return <div className={classNames(node_id, CompetitorStyles.competitor, { [CompetitorStyles.hovered]: false }, CompetitorStyles.edit)}>
        <RoundHeader round={round} name={roundName} index={index} isThird={isThird}/>
        <SelectFormInput width="100%"
            disabled={isDisabled}
            name="competitor"
            label={"Select a competitor"}
            value={''}
            options={options}
            onChange={value => {
                addEntry(value);
                // setRefresh(!refresh);
            }}
        />
    </div>
}

const downloadImage = (imag) => {
    // Create a temporary anchor element
    var link1 = document.createElement('a');
    link1.href = imag;
    // Set the download attribute to specify the filename
    link1.download = 'canvas-image.png';
    // Trigger a click event on the anchor element to start the download
    link1.click();
}
export const ORIENTATION = {
    landscape: 'landscape',
    portrait: 'portrait'
}
async function generatePDF(bracketTypeRef, currentDivision, cb, pcb, isExportingAll) {
    let isRR = currentDivision.getBracketType() === BRACKET.Round_Robin;
    let isDouble = currentDivision.getBracketType().startsWith('de');

    let hasExportThirdPlace = currentDivision.bracket_type=== BRACKET.Single_Elimination && currentDivision.is_third;
    let roundFilterRef = bracketTypeRef.current?.roundFilterRef?.current;
    const doGenerate = async (getContentDiv, getPrintTitleRef, isEnd, pdfName='') => {
        let contentDiv = getContentDiv();
        let printTitleRef = getPrintTitleRef();
        let orientation = GROUP_OF===16? ORIENTATION.portrait : ORIENTATION.landscape;
        if (isRR) {
            orientation = ORIENTATION.portrait;
        }
        
        const init = (hide) => {
            // contentDiv.style.width = hide ? contentDiv.scrollWidth + 'px' : '';
            // contentDiv.style.height = hide ? contentDiv.scollHeight + 'px' : '';
            // !hide && roundFilterRef.handleClick({}, false);
        }
        // Use html2canvas to capture the HTML element as an image
        if (contentDiv) {
            const maxWidth = 1053;
            const maxHeight = Math.ceil((11 * maxWidth)/ 8.5);
            const pdfHeight = orientation===ORIENTATION.landscape? maxHeight:maxWidth;
            const pdfWidth= orientation===ORIENTATION.landscape? maxWidth:maxHeight;
            let totalPages = [0];
            let rfilter =  roundFilterRef.getRoundFilters();
            let groupFilters = rfilter.filter(f => f.value.toString().startsWith('group_'));
            totalPages = groupFilters.map(gf => gf.value);
            let filters = rfilter;
            let final = filters.find(f => f.label === ROUND_NAME.Final);
            if (isRR) {
                totalPages = groupFilters.length>0? groupFilters.map(gf => gf.value) : filters.map(f => f.value);
            } else {
                totalPages.reverse();
            }
            
            if (groupFilters.length > 0 && final) {
                totalPages.unshift(final.value - 1); //semi
            } 

            // Create a new jsPDF instance
            const pdf = new jsPDF({
                orientation,
                unit: 'pt',
                format: [pdfWidth, pdfHeight]
            });

            let totalPage = totalPages.length;
            let total_pages = (totalPage||1) + (hasExportThirdPlace? 1 : 0);
            let p = 0;
            const doExport = async (page) => {
                pcb(p++, total_pages, printTitleRef);
                init(true);
                const pageExport = async () => {
                    const marginWidth = 20;
                    const marginHeight = 10;
                    // Capture the content segment using html2canvas
                    let contentDivWidth = contentDiv.scrollWidth;
                    let contentDivHeight = contentDiv.scrollHeight;
                    //max size for html2canvas is 1353 x 1062
                    const getRatio = () => {
                        return .9;
                    }
                    let canvas = await html2canvas(contentDiv, {
                        scale: getRatio(),
                        width: contentDivWidth + marginWidth,
                        height: contentDivHeight
                    });
                    
                    // Embed the captured content as an image in the PDF
                    const imgData = canvas.toDataURL("image/jpeg", 1.0);
                    // downloadImage(imgData);
                    let x = marginWidth;
                    let y = marginHeight;
                    pdf.addImage(imgData, "JPEG", x, y, canvas.width, canvas.height);

                    if (totalPages.length <= 0) {
                        if (hasExportThirdPlace){
                            hasExportThirdPlace = false;
                            pdf.addPage();
                            contentDiv = bracketTypeRef.current.bracketThirdRef.current.divRef.current;
                            printTitleRef = bracketTypeRef.current.bracketThirdRef.current.printTitleRef;
                            pcb(p, total_pages, printTitleRef);
                            await pageExport();
                        }else {
                            !isExportingAll && openPDF(pdf);
                            // Save the PDF with a filename
                            pdfName = pdfName? '_' + pdfName : '';
                            pdf.save(`${currentDivision.name || currentDivision.id}${pdfName}.pdf`);
                            init();
                            (!isDouble || (isDouble && isEnd)) && cb();
                            try{
                                isRR && roundFilterRef.toogleCSS(true);
                            }catch(e) {
                                console.log(e);
                            }
                        }
                    } else {
                        pdf.addPage();
                        await doExport(totalPages.pop());
                    }
                }
                if (page || page >= 0) {
                    console.log('exporting page: ', page);
                    roundFilterRef.handleClick({ value: page}, async () => {
                        setTimeout(async () => await pageExport(), 1000);
                    });
                }else {
                    await pageExport();
                }
            }

            await doExport(totalPages.pop());
            console.log('export exit');
        }
    }

    const timeout = 1000;
    if (isDouble){
        try{
            bracketTypeRef.current.setFilter([]);
            bracketTypeRef.current.setDrawFilter(1);

            roundFilterRef = bracketTypeRef.current.mainBracketRef.current.roundFilterRef.current;
            await doGenerate(
                () => bracketTypeRef.current.mainBracketRef.current.divRef.current, 
                () => bracketTypeRef.current.mainBracketRef.current.printTitleRef, 
                false,
                'main');
            roundFilterRef = bracketTypeRef.current.consolidationRef.current.roundFilterRef.current;
            let hasFinalRound = bracketTypeRef.current.finalDoubleEliminationRef.current? true:false
            await doGenerate(
                () => bracketTypeRef.current.consolidationRef.current.divRef.current, 
                () => bracketTypeRef.current.consolidationRef.current.printTitleRef, 
                !hasFinalRound, 
                'consolidate');

            if (hasFinalRound) {
                await doGenerate(
                    () => bracketTypeRef.current.finalDoubleEliminationRef.current.divRef.current, 
                    () => bracketTypeRef.current.finalDoubleEliminationRef.current.printTitleRef, 
                    true, 
                    'final');
            }
        }catch(e) {
            console.log(e);
            cb();
        }
    }else {
        setTimeout(async () => {
            await doGenerate(() => bracketTypeRef?.current.divRef?.current, () => bracketTypeRef?.current.printTitleRef);
        }, timeout);
    }
}

export default BracketWrapper;