import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, CircularProgress, FormControl, TextField, ThemeProvider, Tooltip } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Theme from './Theme';
import Utils from "../../serverUtils/Utils";
import "./MultiSelectFormInput.scss";
import { isKeyEnter } from "../Form/Form";

const SELECT_ALL = 'select_all';
const ALL_OPTION = {value: SELECT_ALL, label: <span className="all-option">Select All</span>};
export const includesAllOption = ops => [ALL_OPTION, ...ops];
const isSelectAll = selects => selects.find(i => i && i.value === SELECT_ALL);
const MultiSelectFormInput = ({
    name,
    label,
    value: _values,
    options,
    optionLabel = 'display',
    optionValue = 'id',
    fetchOptions,
    control,
    multiple = true,
    isRequired = false,
    onChange,
    placeholder = '',
    freeSolo,
    errorText, helperText,
    setValue,
    onKeyDown,
    onOpen,
    onBlur
}) => {
    const [open, setOpen] = useState(false);
    const [asyncOptions, setAsyncOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [values, setValues] = useState();
    const loading = open && asyncOptions.length === 0;
    const {control: _control} = useForm();
    const fetchTimeout = useRef();

    control = control || _control;

    useEffect(() => {
        if (multiple) {

        }else {
            setInputValue(_values? (_values[optionLabel] || ''): '');
        }
        !Utils.arraysEquals(values, _values) && setValues(_values);
        Object.keys(_values||{}).length>0 && setValue && setValue(name, _values, { shouldDirty: true });
    }, [_values, options]);

    useEffect(() => {
        let active = true;
        if (typeof fetchOptions !== 'function') {
            setAsyncOptions([...fetchOptions.sort((a, b) => Utils.sort(a[optionLabel], b[optionLabel]))]);
        } else if (inputValue && inputValue.length > 0) {
            fetchTimeout.current && clearTimeout(fetchTimeout.current);
            fetchTimeout.current = setTimeout(() => {
                (async () => {
                    const response = await fetchOptions(inputValue);
                    if (Array.isArray(response) && active && !options) {
                        onOpen && onOpen(response.length > 0);
                        setAsyncOptions([...response.sort((a, b) => Utils.sort(a[optionLabel], b[optionLabel]))]);
                    }
                })();
            }, 1200);
        }

        return () => {
            active = false;
        };
    }, [inputValue]);

    useEffect(() => {
        if (!open) {
            setAsyncOptions([]);
        } else if (Array.isArray(fetchOptions)) {
            setAsyncOptions([...fetchOptions.sort((a, b) => Utils.sort(a[optionLabel], b[optionLabel]))]);
        } else {
            (async () => {
                const response = await fetchOptions(inputValue);
                if (Array.isArray(response) && !options) {
                    setAsyncOptions([...response.sort((a, b) => Utils.sort(a[optionLabel], b[optionLabel]))]);
                }
            })();
        }
    }, [open]);

    return <div className={`MultiSelectFormInput ${name}`}>
        <ThemeProvider theme={Theme} >
            <FormControl fullWidth>
                <Controller
                    rules={{ required: isRequired && 'This field is required.' }}
                    render={({
                        field: { value = values, name, ref },
                    }) => {
                        // console.log('Autocomplete', name, value, asyncOptions)
                        return <Autocomplete value={value||(multiple? []:'')}
                            freeSolo={freeSolo}
                            id={name}
                            name={name}
                            fullWidth
                            multiple={multiple}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                                onOpen && onOpen(asyncOptions.length > 0);
                            }}
                            onClose={() => {
                                setOpen(false);
                                onOpen && onOpen(false);
                            }}
                            getOptionLabel={(option) => {
                                if (!option || !option[optionValue]) {
                                    return '';
                                }
                                if (option[optionValue] === -1){
                                    return option[optionLabel];
                                }
                                return option[optionLabel];
                            }}
                            options={asyncOptions}
                            // loading={loading}
                            filterSelectedOptions
                            filterOptions={x => {
                                return x;
                            }}
                            onClick={e => e.stopPropagation()}
                            onKeyDown={e => {
                                // console.log('onKeyDown', e);
                                if (isKeyEnter(e)) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpen(false);
                                    onOpen && onOpen(false);
                                }
                                onKeyDown && onKeyDown(e);
                            }}
                            onKeyUpCapture={e => {
                                // console.log('onKeyUpCapture', e);
                                if (freeSolo && e.target.value !== (values && values.display)){
                                    onChange && onChange({display: e.target.value, id: -1});
                                }else if (e.key === 'Backspace' && e.target.value.length === 1) {
                                    setInputValue('');
                                }
                            }}
                            onInputChange={(event, newInputValue, type) => {
                                // console.log('onInputChange', event, newInputValue, type);
                                if (event) {
                                    setInputValue(newInputValue);
                                }
                            }}
                            onChange={(e, value) => {
                                // console.log('onChange', e, value);
                                if (multiple && isSelectAll(value)) {
                                    value = [...asyncOptions.filter(o => o.value !== SELECT_ALL)];
                                }else {
                                   setInputValue((value && value[optionLabel] )|| ''); 
                                }
                                
                                setValues(value);
                                setValue && setValue(name, value);
                                onChange && onChange(value);
                            }}
                            isOptionEqualToValue={(o, v) => {
                                return o && v && o[optionValue] === v[optionValue];
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option[optionValue]}>
                                    {option[optionLabel]}
                                </li>
                            )}
                            renderInput={(params) => {
                                // params.id === 'league' && console.log('params.inputProps.value: ', params.inputProps.value, inputValue)
                                // params.inputProps.value = inputValue;
                                params.InputProps = {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }
                                params.inputProps.value = inputValue;
                                return <Tooltip title={inputValue}>
                                    <TextField
                                        {...params}
                                        error={isRequired && (multiple? !value || value.length === 0:!inputValue)}
                                        label={typeof label === 'string'? `${label} ${isRequired ? '*' : ''}`:label}
                                        helperText={helperText || errorText}
                                        placeholder={placeholder || 'type to search...'}
                                        onKeyDown={e => {
                                            if (isKeyEnter(e)) {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setOpen(false);
                                                onOpen && onOpen(false);
                                                if (freeSolo) {
                                                    onKeyDown && onKeyDown(e);
                                                }
                                            }
                                        }}
                                        onBlur={e => {
                                            onBlur && onBlur();
                                        }}
                                    />
                                </Tooltip>
                            }}
                        />
                    }}
                    onChange={([event, data]) => {
                        return data;
                    }}
                    name={name}
                    control={control}
                />
            </FormControl>
        </ThemeProvider>
    </div>
}

export default MultiSelectFormInput;