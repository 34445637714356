import create from 'zustand'
import Utils from './serverUtils/Utils';

const getNotifications = () => {
    let ns = localStorage.getItem(NOTIFICATIONS);
    if (!ns) {
        return [];
    }
    ns = JSON.parse(ns).filter(n => typeof n[1] === 'object');
    return ns;
}

const getBrowsingHistory = () => {
    let hist = localStorage.getItem(BROWSING_HISTORY);
    if (!hist) {
        return [];
    }
    hist = JSON.parse(hist);
    return hist;
}

const BROWSING_HISTORY = 'browsingHistory';
const NOTIFICATIONS = 'notifications';
const SEARCH_STRINGS = 'searchStrings';

const useStore = create((set) => {
    const notifications = getNotifications();
    const browsingHistory = getBrowsingHistory();
    return {
        isMobile: false,
        setIsMobile: e => set(state => {
            return state.isMobile = window.innerWidth <= 575;
        }),
        isHelp: false,
        setIsHelp: () => set(state => state.isHelp = !state.isHelp),
        tournament_messages_refresh: false,
        setTournamentMessagesRefresh: () => set(state => state.tournament_messages_refresh = !state.tournament_messages_refresh),
        sse_urls: [],
        setSseUrls: (url, isDelete) => set(state => {
            if (isDelete) {
                state.sse_urls = state.sse_urls.filter(u => u !== url);
            }else {
                state.sse_urls = [...state.sse_urls, url];
            }
            return state.sse_urls;
        }),
        searchStrings: JSON.parse(localStorage.getItem(SEARCH_STRINGS) || `{}`),
        setSearchStrings: (enitity, s) => set(state => {
            state.searchStrings = {...state.searchStrings, [enitity]: s};
            localStorage.setItem(SEARCH_STRINGS, JSON.stringify(state.searchStrings));
        }),
        currentTournament: null,
        setCurrentTournament: t => set(state => state.currentTournament = t),
        isBracketEntriesUpdate: false,
        setIsBracketEntriesUpdate: v => {
            set(state => {
                if (v === undefined) {
                    state.isBracketEntriesUpdate = !state.isBracketEntriesUpdate;
                }else {
                    state.isBracketEntriesUpdate = v;
                }
            });
        },
        global_messages: [],
        setGlobalMessage: message => {
            if (message) {
                set(state => {
                    const {global_messages} = state;
                    if (!global_messages.find(m => m.url === message.url && m.message === message.message)) {
                        let messages = global_messages.filter(m => m.url !== message.url);
                        if (message.message) {
                            state.global_messages = [...messages, message];
                        }
                    }
                });
            }
        },

        browsingHistory,
        pushBrowsingHistory: newUrl => set(state => {
            let history = [...state.browsingHistory, newUrl ].slice(-50);
            localStorage.setItem(BROWSING_HISTORY, JSON.stringify(history));
            return { browsingHistory: history};
        }),
        
        tournaments: {},
        pushTournament: (id, t) => set(state => state.tournaments[id] = t),
        removeTournament: id => set(state => delete state.tournaments[id]),

        local_tournament: null,
        setLocalTournament: (tournament) => {
            set(state => {
                state.local_tournament = tournament;
            });
        },

        leagues: {},
        pushLeague: (id, t) => set(state => state.leagues[id] = t),
        removeLeague: id => set(state => delete state.leagues[id]),

        gyms: {},
        pushGym: (id, t) => set(state => state.gyms[id] = t),
        removeGym: id => set(state => delete state.gyms[id]),

        teams: {},
        pushTeam: (id, t) => set(state => state.teams[id] = t),
        removeTeam: id => set(state => delete state.teams[id]),

        users: {},
        pushUser: (id, t) => set(state => state.users[id] = t),
        removeUser: id => set(state => delete state.users[id]),

        session: null,
        setSession: s => {
            set(state => {
                state.session = s;
            })
        },
        isGraphQLOnline: false,
        setIsGraphQLOnline: isOnline => {
            set(state => {
                state.isGraphQLOnline = isOnline;
            })
        },
        local_server : null,
        setLocalServer: props => {
            set(state => {
                state.local_server = props;
            })
        },

        station: null,
        setStation: station => set(state => {
            state.station = station;
        }),

        notifications,
        markNoficationsRead: () => {
            let ns = localStorage.getItem(NOTIFICATIONS);
            if (!ns){
                return;
            }
            ns = JSON.parse(ns);
            ns.forEach(n => {
                n[1].read = true;
            });
            set(state => {
                state.notifications = [...ns];
            });
            localStorage.setItem(NOTIFICATIONS, JSON.stringify(ns.filter(n => typeof n[1] === 'object')));
        },
        addNotification: (notification) => {
            let ns = localStorage.getItem(NOTIFICATIONS);
            if (!ns) {
                ns = [];
            } else {
                ns = JSON.parse(ns);
            }
            ns.push([Utils.formatDateTime(new Date().getTime(), 'M/DD h:mm a'), notification]);
            set(state => {
                state.notifications = [...ns];
            });
            localStorage.setItem(NOTIFICATIONS, JSON.stringify(ns.filter(n => typeof n[1] === 'object')));
        },
        clearNotifications: (from) => {
            let ns = localStorage.getItem(NOTIFICATIONS);
            if (!ns) {
                return;
            }
            ns = JSON.parse(ns);
            ns = ns.filter(n => ![n[1].from, n[1].to].includes(from));
            set(state => state.notifications = [...ns]);
            localStorage.setItem(NOTIFICATIONS, JSON.stringify(ns));
        },

        refresh: false,
        setRefresh: () => {
            set(state => state.refresh = !state.refresh);
        },
    }
});

export { useStore };