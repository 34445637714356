import SelectFormInput from "../FormInput/SelectFormInput";
import TextFormInput from "../FormInput/TextFormInput";
import { RemoveSubFormIcon } from "./Form";
import "./ContactInfos.scss";
import FormStyles from './../Form/Form.module.scss';
import { useState } from "react";

export default function ContactInfos({ data, register, useTitle, omitEmail, setValue, onChange }) {
  const [refresh, setRefresh] = useState(false);
  return <div style={{ marginBottom: '3em' }} className="ContactInfos">
    {(data || []).map((c, i) => {
      return <div key={i} className={FormStyles.fields} id={`contact_infos.${i}`} >
        <div className="name-label">Contact {i + 1}</div>
        <div className="contactInfoWrapper">
            <TextFormInput isRequired
              name={`contact_infos.${i}.name`}
              value={c.name}
              register={register} setValue={setValue} 
              label="Name"
              onChange={onChange}
            />
            <div>
              <TextFormInput isRequired
                name={`contact_infos.${i}.phone`}
                value={c.phone}
                register={register} setValue={setValue} 
                label="Phone"
                onChange={onChange}
              />
            </div>
            {!omitEmail && <div style={{ minWidth: 280 }}>
              <TextFormInput isRequired
                name={`contact_infos.${i}.email`}
                value={c.email}
                register={register} setValue={setValue} 
                label="Email"
                type="email"
                onChange={onChange}
              />
            </div>}
          <RemoveSubFormIcon onClick={() => {
            data.splice(i, 1);
            setValue(`contact_infos`, data);
            setRefresh(!refresh);
            onChange={onChange}
          }} label="Remove" />
        </div>
      </div>;
    })}
  </div>
}

export const USER_TITLE = [
  { value: '' },
  {value: 'Mr'},
  {value: 'Mrs'},
  {value: 'Ms' },
  {value: 'Miss'},
  {value: 'Dr'},
];

export const RELATION = [
  {value: 'Mother'}, 
  {value: 'Father'}, 
  {value: 'Sister'}, 
  {value: 'Brother'},  
  {value: 'Guardian'},  
  {value: 'Friend'}, 
  {value: 'Other'}, 
];