import { useEffect, useRef, useState } from "react";

import './DashboardPage.scss';
import Utils from "../../serverUtils/Utils";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import TableFormInput, { ToolbarButtons } from "../../components/FormInput/TableFormInput";
import { RequestCommon } from "../../serverUtils/requests";
import AlertPane from "../../components/FormInput/AlertPane";
import { default as ClearIcon } from "@mui/icons-material/Clear";
import { default as FileIcon } from "@mui/icons-material/FileOpen";
import Checkbox from '@mui/material/Checkbox';
import HelpModel from "../../serverUtils/models/HelpModel";
import TextFormInput from "../../components/FormInput/TextFormInput";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import ImageModel from "../../serverUtils/models/ImageModel";
import { hasTableFormChange, IMAGE_TYPE } from "../../components/Form/Form";
import { HelpSchema } from "../../serverUtils/Models";


const headCells = [
    {
        id: "url",
        label: "URL",
    },
    {
        id: "title",
        label: "Title",
    },
    {
        id: "file_name",
        label: "File Name",
    },
    {
        id: "location_type",
        label: "Type",
    },
    {
        id: "file_size",
        label: "Size",
    },
    {
        id: "last_date_modified",
        label: "Date",
    },
    {
        id: "images",
        label: "Images",
    },
]

const CRC_Fields = Utils.listObjectKeys(HelpSchema().model);
const HelpSetup = () => {
    const [helps, setHelps] = useState([]);
    const [message, setMessage] = useState();
    const tableRef = useRef();
    const checkCRCMessageRef = useRef();
    const checkCRCMessageRef1 = useRef();
    const toolbarButtonsRef = useRef();
    const toolbarButtonsRef1 = useRef();
    useEffect(() => {
        HelpModel.getHelps(true) 
            .then(hs => {
                const {helps, images_doc, files_doc} = hs;
                helps.forEach(h => {
                    h.getImages = () => {
                        let imgs = images_doc.filter(i => i.ref === h.id);
                        return imgs.map(img => {
                            img.getImage = () => images_doc.find(i => i.id === img.id);
                        });
                    };
                    h.getFile = () => files_doc.find(f => f.id === h.file);
                    h.crc = Utils.getCRC(h, CRC_Fields);
                });
                setHelps(helps);
            });
    }, []);

    const checkCRC = () => {
        checkCRCMessageRef.current.innerHTML = '';
        checkCRCMessageRef1.current.innerHTML = '';
        if (hasTableFormChange(helps, CRC_Fields)) {
          checkCRCMessageRef.current.innerHTML = 'Data has changed';
          checkCRCMessageRef1.current.innerHTML = 'Data has changed';
          toolbarButtonsRef.current.setIndicator({save: 'yellow'});
          toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
        }else {
          toolbarButtonsRef.current.setIndicator({save: ''});
          toolbarButtonsRef1.current.setIndicator({save: ''});
        }
    }

    const doAdd = () => {
        helps.push({
            id: `-${helps.length}`,
        });
        setHelps([...helps]);
    }

    const doSave = async () => {
        let error;
        try{
            for (let h of helps) {
                if (h.crc === Utils.getCRC(h, CRC_Fields)){
                    continue;
                }
                let file = h.file;
                let images = h.images;
                let id = h.id;
                delete h.file;
                delete h.images;
                if (id.startsWith('-')){
                    delete h.id;
                    let r = await HelpModel.addHelp(h);
                    if (!r || r.error) {
                        h.id = id;
                        return error=true;
                    }
                    h.id = r.id;
                }

                if (typeof file !== 'string') {
                    let data = await Utils.readLocalFile(file, 'text');
                    let r = await RequestCommon.uploadFile({
                        data, 
                        name: file.name, 
                        lastModifiedDate: file.lastModified, 
                        size: file.size,
                        image_type: IMAGE_TYPE.help
                    }, p => console.log(p));
                    if (!r || r.error) {
                        return error = true;
                    }
                    h.file = r.id;
                }else {
                    h.file = file;
                }
                for (let i=0; i<images.length; i++) {
                    let image = images[i];
                    if (typeof image !== 'string'){
                        let data = await Utils.readLocalFile(image);
                        let r = await ImageModel.addImage({
                            data, ref: h.id, image_type: 'help', length: image.size
                        }, p => console.log(p));
                        images[i] = r.id;
                    }else {
                        images[i] = i;
                    }
                }
                h.images = images;
                let r = await HelpModel.updateHelp(h);
                if (!r ||r.error) {
                    return error = true;
                }
                h.crc = Utils.getCRC(h, CRC_Fields);
            }
        }finally{
            setMessage(error? `error: Error uploading rule file.`:'success: Successfully save helps');
            setTimeout(() => document.location.reload(), 1000);
        }
    }

    const doDelete = () => {
        let error;
        tableRef.current.selected.forEach(async r => {
            let findex = helps.findIndex(h => h.id === r);
            if (!r.startsWith('-')){
                let result = await HelpModel.deleteHelp(r);
                if (!result || result.error) {
                    return error = true;
                }
            }
            helps.splice(findex, 1);
        });
        tableRef.current.setSelected([]);
        setHelps([...helps]);
        setMessage(error? 'error: Error deleting help':'success: Successfully deleted help');
    }

    const toolbarButtons = ref => (
        <ToolbarButtons ref={ref} doAdd={doAdd} doSave={doSave} doDelete={doDelete} />
    );
    return <div className="HelpSetup">
        <AlertPane message={message} setMessage={setMessage} timeOut={3000} />
        <TableFormInput ref={tableRef}
            onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
            onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
            toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
            toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
            name="HelpSetup"
            className="HelpSetup-table"
            isEditable
            headCells={headCells}
            data={helps}
            renderTRow={({ row, index, isSelected, handleClick }) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const getFileName = () => {
                    if (row.id.startsWith('-') || (row.file && typeof row.file === 'object')) {
                        return <span className="new-file">{(row.file && row.file.name) || ''}</span>;
                    }
                    return  <span>{(row.getFile() && row.getFile().name) || ''}</span>;
                }
                const getFileSize = () => {
                    if (typeof row.file === 'object') {
                        return (row.file && Utils.bytesToSize(row.file.size)) || '';
                    }
                    return (row.getFile() && Utils.bytesToSize(row.getFile().size)) || '';
                }
                const getFileDate = () => {
                    if (typeof row.file === 'object') {
                        return Utils.formatDateTime(row.file && row.file.lastModified) || '';
                    }
                    return (row.getFile() && Utils.formatDateTime(row.getFile().lastModifiedDate)) || '';
                }
                const getFileImageNames = () => {
                    const getImageFileName = (img) => {
                        if (img && typeof img === 'object') {
                            return <span className="new-file">{(img && img.name) || ''}</span>;
                        }
                        return  <span>{(img.getFile && img.getFile() && img.getFile().name) || ''}</span>;
                    }
                    const removeFile = f => {
                        row.images = row.images.filter(img => img != f);
                        updateHelps();
                    }

                    if (row.id.startsWith('-')) {
                        return <ul className="images">
                            {(row.images||[]).map((i, k) => {
                                return <div className="image-name" key={k}>
                                    {i.name}
                                    <ClearIcon onClick={() => removeFile(i)}/>
                                </div>
                            })}
                        </ul>
                    }
                    return <ul className="images">
                        {row.images.map((i, k) => 
                            <div className="image-name" key={k}>
                                {getImageFileName(i)}
                                <ClearIcon onClick={() => removeFile(i)}/>
                            </div>)}
                    </ul>;
                }
                const updateHelps = () => {
                    let found = helps.find(h => h.id === row.id);
                    if (found) {
                        Object.assign(found, row);
                        setHelps([...helps]);
                        checkCRC();
                    }
                }

                return <TableRow className="Help" key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={(event) => {
                        if (event.target.tagName.toUpperCase() === "INPUT") {
                            return handleClick(event, row.id);
                        }
                    }}
                >
                    <TableCell padding="checkbox">
                        <div className="index-cell">
                            <Checkbox 
                                color="primary"
                                checked={isItemSelected ? true : false}
                                inputProps={{
                                    "aria-labelledby": labelId,
                                }}
                            />
                            <span>{index + 1}</span>
                        </div>
                    </TableCell>
                    <TableCell >
                        <TextFormInput
                            label={`URL`}
                            name={`url`}
                            value={row.url || ''}
                            onChange={v => {
                                row.url = v;
                                updateHelps();
                            }}
                        />
                    </TableCell>
                    <TableCell >
                        <TextFormInput
                            label={`Title`}
                            name={`title`}
                            value={row.title || ''}
                            onChange={v => {
                                row.title = v;
                                updateHelps();
                            }}
                        />
                    </TableCell>
                    <TableCell >
                        <LocalFiles 
                            getFileName={getFileName}
                            accept=".html, .htm"
                            onChange={e => {
                                const { files } = e.target;
                                row.file = files[0];
                                updateHelps();
                            }}
                        />
                    </TableCell>
                    <TableCell >
                        <SelectFormInput 
                            options={[
                                {label: 'Main', value: 'main'},
                                {label: 'Book Mark', value: 'book_mark'}, 
                            ]}
                            value={row.location_type}
                            onChange={v => {
                                row.location_type = v;
                                updateHelps();
                            }} />
                    </TableCell>
                    <TableCell >{getFileSize()}</TableCell>
                    <TableCell >{getFileDate()}</TableCell>
                    <TableCell >
                        <LocalFiles 
                            multiple
                            getFileName={getFileImageNames}
                            accept="image/*"
                            onChange={e => {
                                const { files } = e.target;
                                row.images = [...(row.images||[]), ...files];
                                updateHelps();
                            }}
                        />
                    </TableCell>   
                </TableRow>
            }}
        />
    </div>
}

const LocalFiles = ({title, getFileName, multiple=false, accept='*', onChange}) => {
    const fileInputRef = useRef();

    return <div className="LocalFiles">
        <span className="filename">{getFileName()}</span>
        <Tooltip title={title || "Add file from local disk"}>
            <FileIcon
                onClick={() => {
                    fileInputRef.current.click();
                }}/>
        </Tooltip>
        <input
            accept={accept}
            multiple={multiple}
            ref={fileInputRef}
            hidden
            type="file"
            onChange={f => {
                onChange(f);
            }}
        />
    </div>
}

export default HelpSetup;