import styles from './FilterChips.module.scss';
import classNames from "classnames";

export default function FilterChips({isInline = false, filters = [], activeFilters = [], onClick}) {
    const isChecked = f => {
        return activeFilters.filter(af => af && af.value === f.value).length > 0;
    }
    return <div className={classNames(styles.filters, { [styles.inline]: isInline}, "FilterChips")}>
        {/*<Icon name="filter" />*/}
        {filters.map((filter, i) =>
            <div key={i}
                className={classNames(styles.filter, { [styles.activeFilter]: isChecked(filter) })} 
                onClick={e => {
                    e.stopPropagation();
                    onClick(filter);
                }}
            >
                <span>{filter.label}</span>
            </div>
        )}
    </div>;
}