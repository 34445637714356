import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { IonButtons, IonSpinner } from "@ionic/react";
import './LoginForm.scss';
import TextFormInput from "../FormInput/TextFormInput";
import UserModel from "../../serverUtils/models/UserModel";
import { useStore } from "../../Store";
import { Alert, Link } from "@mui/material";
import AlertPane from "../FormInput/AlertPane";
import { MODE } from "../../pages/LoginPage/LoginPage";
import { RequestUtils } from "../../serverUtils/requests";
import { stopFormSubmitByEnter } from "../Form/Form";

const LoginForm = forwardRef(({loginMode}, ref) => {
    const history = useHistory();
    const localServer = useStore(state => state.local_server);
    const setSession = useStore(state => state.setSession);
    const [message, setMessage] = useState();
    const [isSubmitting, setIsSubmitting] = useState();
    const [isForgetPassword, setIsForgetPassword] = useState();
    const { register, handleSubmit, formState: { errors }, getValues, setValue, setError } = useForm();

    useImperativeHandle(ref, () => ({
        doForgetPassword,
    }));
    useEffect(() => {
        if (document.location.pathname === '/'){
            history.push('/login');
        }
    }, []);
    const onSubmit = async (v) => {
        if (isForgetPassword) {
            return;
        }
        setMessage('');
        setIsSubmitting(true);
        try {
            let membership = await UserModel.login(v.user_name.trim(), v.password.trim());
            if (membership) {
                let session = await UserModel.getSession(setSession);
                if (session) {
                    return history.push(!localServer ? '/dashboard' : '/tournaments');
                }
            }
            setMessage(<Alert severity="error">Login information was not found. Please try again.</Alert>);
        } finally {
            setIsSubmitting(false);
        }
    }

    const forgetPassword = async () => {
        let email = getValues('user_name');
        if (!email) {
            return setMessage(
                <Alert severity="error">
                    Please enter your username or email. For an email with multiple users, please enter the <b>user name</b>.
                    You will receive an email for <b>reset confirmation</b>.
                    Once you <b>click the reset link</b>, you will receive a second email with a <b>temporary password</b>.
                </Alert>);
        }
        setMessage('');
        setIsSubmitting(true);
        let res = await UserModel.resetPassword(email);
        try{
            if (res && !res.error) {
                setTimeout(() => {
                    loginMode(null);
                    setIsForgetPassword(null);
                }, 3000);
                return setMessage(
                    <Alert severity="info">Your reset password has been
                        submitted, please check your email
                        inbox from < b >grappling.in.house@gmail.com</b > and
                        follow the reset password instructions
                        to complete this process.</Alert >);
            }
            setMessage(<Alert severity="error">
                Error resetting password.  The password or username you have entered may not exist in our system.  Please try again.
            </Alert>);
        }finally{
            setIsSubmitting(false);
        }
    }

    const doForgetPassword = () => {
        RequestUtils.insertURLParam('forget_password', true, history);
        loginMode(MODE.password_recovery);
        setIsForgetPassword(true);
    }

    return <div className="LoginForm">
        <form className="form" 
            // onKeyDown={stopFormSubmitByEnter}
            onSubmit={handleSubmit(onSubmit)}>
            <div className="user-info">
                <TextFormInput name="user_name" label="Email or Username"
                    register={register} setValue={setValue}
                    errors={errors} setError={setError} 
                    isRequired 
                    validateWithTrim
                    isLabelShrunk
                    />
                {!isForgetPassword && 
                <TextFormInput name="password" label="Password" type="password"
                    register={register} setValue={setValue}
                    errors={errors} setError={setError}
                    isRequired 
                    validateWithTrim
                    isLabelShrunk
                    />}
            </div>
            <div className="buttonWrapper">
                <IonButtons>
                    {!isForgetPassword && <button className="button" type="submit">
                        Sign-In {isSubmitting && <IonSpinner name="circles" className="spinner"/>}
                    </button>}
                    {isForgetPassword && 
                        <button className="button" onClick={forgetPassword}>
                            Recover Pwd {isSubmitting && <IonSpinner name="circles" className="spinner"/>}
                        </button>}
                    {!localServer && 
                        isForgetPassword ? <Link className="login-link"
                            onClick={() => {
                                history.push('/login');
                                setMessage('');
                                loginMode(null);
                                setIsForgetPassword(null);
                            }}>Login</Link>
                            :
                            <Link className="forget-password" 
                                onClick={doForgetPassword}
                                >Forget Password</Link>}
                </IonButtons>
                <AlertPane message={message} />
            </div>
        </form>
    </div>;
});

export default LoginForm;