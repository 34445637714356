import { useEffect, useRef, useState } from "react";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import TableFormInput from "../../components/FormInput/TableFormInput";
import { TableRow, TableCell, Link, Tooltip } from "@mui/material";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import LoadingList from "../../components/LoadingList/LoadingList";
import Utils from "../../serverUtils/Utils";
import { convertDate } from "../../serverUtils/Models";
import { IonButtons } from "@ionic/react";
import { getTournamentDates } from "../TournamentPage/TournamentPage";
import AddCircle from "@mui/icons-material/AddCircle";

const headCells = [
    {
        id: 'name',
        disablePadding: true,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'dates',
        disablePadding: true,
        label: 'Dates',
        align: 'left'
    },
    {
        id: 'location',
        disablePadding: true,
        label: 'Locations',
        align: 'left'
    },
    {
        id: 'contacts',
        disablePadding: true,
        label: 'Contacts',
        align: 'left'
    },
    {
        id: 'divisions',
        disablePadding: true,
        label: 'Divisions',
        align: 'left'
    },
    {
        id: 'competitors',
        disablePadding: true,
        label: 'Competitors',
        align: 'center'
    }
]

const tableComparator = (a, b, orderBy) => {
    if(orderBy==='dates'){
        return {
            a:`${Utils.formatDate(convertDate(a.dates.start_date))} - ${Utils.formatDate(convertDate(a.dates.end_date))}`,
            b:`${Utils.formatDate(convertDate(b.dates.start_date))} - ${Utils.formatDate(convertDate(b.dates.end_date))}`
        }
    }
    if(orderBy==='location'){
        return {
            a:`${TournamentModel.getLocations(a)} - ${TournamentModel.getLocations(a)}`,
            b:`${TournamentModel.getLocations(b)} - ${TournamentModel.getLocations(b)}`
        }
    }
    if(orderBy==='contacts'){
        return {
            a:`${TournamentModel.getContacts(a)} - ${TournamentModel.getContacts(a)}`,
            b:`${TournamentModel.getContacts(b)} - ${TournamentModel.getContacts(b)}`
        }
    }
}

export default function LeagueTournaments({league, session}) {
    const tableRef = useRef();
    const [tournaments, setTournaments] = useState();
    const [isEditable, setIsEditable] = useState(false);
    const [isAddTournament, setIsAddTournament] = useState();
    const [message, setMessage] = useState();

    useEffect(() => {
        if (document.location.pathname.includes('/edit')) {
            setIsEditable(true);
        }else {
            setIsEditable(false);
        }
        league && league?.id && LeagueModel.getTournaments(league.id)
            .then(async ts => {
                setTournaments(ts);
                let ids = ts.map(t => t.id);
                let regs = await TournamentModel.getCompetitors(ids);
                ts.forEach((t, i) => {
                    t.competitors = regs[i];
                });
                setTournaments([...ts]);
            });
    }, [league]);

    const addTournament = async (id) => {
        setMessage('');
        let result = await TournamentModel.updateTournament({id, league:league.id});
        if (result.id && !result.error){
             setMessage('Successfully add tournament to league.');
             setIsAddTournament(false);
             setTimeout(() => document.location.reload(), 1000);
        }
    }

    let tournamentFilter = (league && tournaments && tournaments.filter(t => t.league===league.id)) || [];
    const toolbarButtons = () => {
        return <div className="ToolBar">
            <IonButtons>
                <Link href={`/tournaments/add`}>
                    <Tooltip title="Add a tournament">
                        <button className="button icon_button">
                            <AddCircle />New Tournament
                        </button>
                    </Tooltip>
                </Link>
            </IonButtons>
        </div>;
    }
    return !tournaments? <LoadingList /> : (
        <TableFormInput name="LeagueTournaments"
            isEditable={isEditable}
            ref={tableRef}
            headCells={headCells}
            comparator={tableComparator}
            data={tournamentFilter}
            renderTRow={({row={}, isSelected, index, handleClick}) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return <TableRow
                    hover
                    onClick={(event) => {
                        handleClick(event, row.id);
                        event.stopPropagation();
                    }}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                >
                    <TableCell align="left"><span>{index + 1}.</span> <Link href={`/tournaments/${row.id}`}>{row.name}</Link></TableCell>
                    <TableCell align="center">{getTournamentDates(row.dates.start_date, row.dates.end_date)}</TableCell>
                    <TableCell align="left">{TournamentModel.getLocations(row)}</TableCell>
                    <TableCell align="left">{TournamentModel.getContacts(row)}</TableCell>
                    <TableCell align="center">{(row?.divisions?.length||0) + (row?.poolings?.length||0)}</TableCell>
                    <TableCell align="center">{row?.competitors?.length || ''}</TableCell>
                    
                </TableRow>
            }}
        />
    );
}