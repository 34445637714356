import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import { useEffect, useState } from "react";


export default function TeamStandings({team}) {
    const [data, setData] = useState(null);

    useEffect(() => {
        LeagueModel.getStandings(team.league)
        .then(standings => setData(standings));
    }, [team]);

    return !data? <LoadingScreen /> : (
        <div>
            {JSON.stringify(data)}
        </div>
    )
}