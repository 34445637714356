import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon, IonLabel,
  IonPage,
  IonSpinner,
  useIonViewWillEnter
} from "@ionic/react";
import pageStyles from "../Page.module.scss";
import { pencil, personOutline } from "ionicons/icons";
import { useStore } from "../../Store";
import { useEffect, useState } from "react";
import classNames from "classnames";
import "./TeamPage.scss";
import TeamModel from "../../serverUtils/models/TeamModel";
import { useHistory } from "react-router-dom";
import {
  homeOutline
} from "ionicons/icons";
import TeamMemberships from "./TeamMemberships";
import UserModel from "../../serverUtils/models/UserModel";
import { RequestUtils } from "../../serverUtils/requests";
import CartModel from "../../serverUtils/models/CartModel";
import { ENTITY } from "../../serverUtils/Models";
import Photos from "../../components/Form/Photos";
import AlertPane from "../../components/FormInput/AlertPane";
import { Tabs } from "../../components/Tabs/Tabs";
import { PageRequestButtons } from "../PageRequestButtons";
import { STORE_ITEM_TYPE } from "../../serverUtils/models/modelMethods";
import { getFlag } from "../UserPage/UserPage";
import TeamStandings from "./TeamStandings";
import { ClaimProfile } from "../GymPage/GymPage";
import { default as RegisteredIcon } from "@mui/icons-material/HowToReg";
import { Tooltip } from "@mui/material";
import { COUNTRIES } from "../../components/Form/AddressForm";
import UserChip from "../../components/UserChip/UserChip";

const TABS = [
  { html: "Members", url: "memberships" },
  { html: "Standings", url: "standings" },
  { html: "Photos", url: "photos" },
  { html: "Store", url: "store" },
];

const TeamPage = (props) => {
  const history = useHistory();
  const session = useStore((state) => state.session);
  const [team, setTeam] = useState();
  const [message, setMessage] = useState();
  const [sectionIndex, setSectionIndex] = useState();
  const [flag, setFlag] = useState();
  const [membershipStatus, setMembershipStatus] = useState();
  const [refresh, setRefresh] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const getData = async (id) => {
    let team = await TeamModel.getTeam(id);
    if (team) {
      let f = await getFlag(team.country);
      setFlag(f && <IonIcon className={`flag`} icon={f} />);
      setTeam(team);
    }
  };

  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(TABS.findIndex((t) => t.url === params.tab));
    }
  }, []);

  useEffect(() => {
    if (session && team) {
      getMembershipStatus(team);
    }
  }, [session, team]);

  useIonViewWillEnter(async () => {
    getData(RequestUtils.getURLId("teams"));
  }, [session]);

  const addToCart = async () => {
    await CartModel?.addEntityRegistrationToCart(
      CartModel.STORE_ITEM_TYPE.team_registration,
      CartModel.REF_TYPE.team,
      team,
      setMessage,
      history
    );
  };

  const getMembershipStatus = () => {
    if (!session) {
      return;
    }
    let id = RequestUtils.getURLId('teams');
    let status =
      UserModel.getMembershipStatus(
        session,
        "teams",
        id,
        STORE_ITEM_TYPE.team_registration,
      );
    if (status) {
      setIsRegistered(true);
    } 
    setMembershipStatus(status);
    return status;
  };

  const isClaimed = () => team && team.admins.filter(a => a.id).length > 0;
  let img = team && team?.getImage()?.data?.join('');
  return (
    <IonPage>
      {!team ? (
        <IonSpinner name="circles" className={`spinner`} />
      ) : (
        <IonContent>
          <div className="TeamPage">
            <div className={classNames(pageStyles.pageContent)}>
              <div className="page-title">
                <h1 className={classNames(`teamName`)}>
                  {img && <div className="image-icon"><img src={img}/></div>}
                  {team && UserModel.isAdmin(session, ENTITY.team, team.id) && (
                    <IonFab horizontal="end">
                      <IonFabButton className="entity-edit"
                        routerLink={`/teams/${props.match.params.id}/edit`}
                      >
                        <IonIcon icon={pencil} className="button"/><span>Edit</span>
                      </IonFabButton>
                    </IonFab>
                  )}
                  {team.name}
                  <span className="entity-type">@team</span>
                </h1>
              </div>
              <PageRequestButtons
                session={session}
                onClick={isClaimed() && (() =>
                  history.push(
                    `/messages?id=${team.id}&message_type=team&label=${team.name}`
                  ))
                }
                register={isRegistered? <Tooltip title="You are registered with this team">
                  <RegisteredIcon className="RegisteredIcon"/></Tooltip> :
                  isClaimed() && <button
                    className="button"
                    onClick={async () => {
                      await UserModel.membershipRegistration(
                        TeamModel,
                        team,
                        session.teams,
                        setMessage,
                        addToCart
                      );
                    }} 
                    fill="solid" color="primary">
                      Request for Association
                    </button>}
                claimProfile={session && session.status==='A' && !isClaimed() && 
                  <ClaimProfile infos={team.claim_ownership_infos} ref_id={team.id} ref_type="Team"
                    onDone={info => {
                      if (info) {
                        team.claim_ownership_infos = team.claim_ownership_infos || [];
                        team.claim_ownership_infos.push(info);
                      }
                      setRefresh(!refresh);
                    }}
                  />}
              />

              <ul className={'infoSection'}>
                <li className={pageStyles.pageContentInfoItem}>
                  <IonIcon
                    slot="icon-only"
                    icon={homeOutline}
                    className={pageStyles.pageContentInfoIcon}
                  />
                  <IonLabel className={pageStyles.pageContentInfoLabel}>
                    Location
                  </IonLabel>
                  <IonLabel className={`location ${pageStyles.pageContentInfoValue}`}>
                    {team.country && COUNTRIES.find(c => c.two_code === team.country)?.name} {flag}
                  </IonLabel>
                </li>
                <li className={pageStyles.pageContentInfoItem}>
                  <IonIcon
                    slot="icon-only"
                    icon={personOutline}
                    className={pageStyles.pageContentInfoIcon}
                  />
                  <IonLabel className={pageStyles.pageContentInfoLabel}>
                    Created By
                  </IonLabel>
                  <IonLabel className={pageStyles.pageContentInfoValue}>
                    <UserChip membership={team.getCreatedBy && team.getCreatedBy()}/>
                  </IonLabel>
                </li>
                <li className={pageStyles.pageContentInfoItem}>
                  <IonIcon
                    slot="icon-only"
                    icon={personOutline}
                    className={pageStyles.pageContentInfoIcon}
                  />
                  <IonLabel className={pageStyles.pageContentInfoLabel}>
                    Admins
                  </IonLabel>
                  <IonLabel className={pageStyles.pageContentInfoValue}>
                    {team.getAdmins().map(a => <UserChip membership={a}/>)}
                  </IonLabel>
                </li>
              </ul>
              <AlertPane message={message} />
              <Tabs
                index={sectionIndex}
                tabs={TABS}
                sections={[
                  <TeamMemberships team={team} />,
                  <TeamStandings team={team}/>,
                  <Photos
                    entity={team}
                    entity_type={ENTITY.team}
                    model={TeamModel}
                    session={session}
                  />,
                ]}
              />
            </div>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default TeamPage;
