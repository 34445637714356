import React, {Component} from 'react';
import Utils from "../../serverUtils/Utils";

export default class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {...props};
        try {
            let api_key = JSON.parse(decodeURIComponent(Utils.cookie('gih'))).google_api_key
            const {location} = props;
            if (location){
                let {address, city, state, zip, country, geo_location} = location;
                address = encodeURIComponent([address, city, state + ' ' + (zip||''), country].join(','));
                let src = `https://www.google.com/maps/embed/v1/place?key=${api_key}&q=${address}`;
                if (geo_location){
                    src = `https://www.google.com/maps/embed/v1/place?key=${api_key}&location=${geo_location.lat},${geo_location.long}`;
                }
                
                this.state = {
                    ...props,
                    src,
                    api_key
                };
            }
        }catch(e) {
            console.log(e);
        }
    }

    render() {
        let {src, width, height, location} = this.state;
        if (!location){
            return '';
        }
        return (
            <div>
                <h3>{location?.venue || ''}</h3>
                <iframe title="google-map"
                    width={width || '100%'}
                    height={height || 500}
                    frameBorder="0"
                    src={src} allowFullScreen />
            </div>
        );
    }
}