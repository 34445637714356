import TextFormInput from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Utils from "../../serverUtils/Utils";
import "./AddressForm.scss";

export const AddressForm = forwardRef(({ skey = '', name = '', address, register, isRequired=[], setValue, onChange }, ref) => {
    const [refresh, setRefresh] = useState(false);
    const countries = COUNTRIES.map(c => ({label: c.name, value:c.two_code}));
    const SELECTS = ['country', 'state', 'region'];
    const countryRef = useRef();

    useImperativeHandle(ref, () => ({
        countryRef: countryRef.current,
    }));

    useEffect(() => {
        Object.keys(address).forEach(f => {
            if (SELECTS.includes(f)) {
                if (f === 'country' && !address.country){
                    address.country = countries[0].value;
                }else if (f === 'state' && !address.state){
                    let state = STATES[getCountry(address.country)];
                    if (state) {
                        address.state = state[0].value;
                    }
                }else if (f === 'region' && !address.region){
                    let region = REGIONS[getCountry(address.country)];
                    if (region) {
                        address.region = region[0].value;
                    }
                }
            }
            setValue && setValue(`${name}${f}`, address[f]);
        });
        
        setRefresh(!refresh);
    }, [address]);

    const getCountry = (country) => {
        if (!country) {
            country = Utils.getBrowerCountry();
        }
        if (!country) {
            country = 'US';
        }
        return country;
    }

    let { address: addr, address1: addr1, address2: addr2, city, venue, state, country, region, suburb, zip } = address || {};
    return (
        <div className="AddressForm" key={skey}>
            {country !== undefined && <SelectFormInput ref={countryRef}
                name={`${name}country`}
                value={getCountry(country) || ''}
                onChange={(v) => {
                    address.country = v;
                    if (v !== 'US') {
                        if (suburb !== undefined) {
                            address.suburb = '';
                        }
                        if (region !== undefined) {
                            address.region = '';
                        }
                    } else if (state !== undefined){
                        address.state = '';
                    }
                    setRefresh(!refresh);
                    onChange && onChange(v);
                }}
                label='Country'
                setValue={setValue}
                placeholder="Select a country"
                options={countries}
            />}
            {venue !== undefined &&
                <TextFormInput isRequired={isRequired.includes('venue')}
                    name={`${name}venue`}
                    value={venue || ''}
                    register={register} setValue={setValue}
                    label="Venue"
                    onChange={onChange}
                />}
            {addr !== undefined &&
                <TextFormInput
                    isRequired={isRequired.includes('address')}
                    label={`Address`}
                    name={`${name}address`}
                    value={addr || ''}
                    register={register} setValue={setValue}
                    onChange={onChange}
                />}
            <div className="addresses">
                {addr1 !== undefined &&
                    <div>
                        <TextFormInput
                            isRequired={isRequired.includes('address1')}
                            label={`Address 1`}
                            name={`${name}address1`}
                            value={addr1 || ''}
                            register={register} setValue={setValue}
                            onChange={onChange}
                        />
                    </div>}
                {addr2 !== undefined &&
                    <div>
                        <TextFormInput
                            isRequired={isRequired.includes('address2')}
                            label={`Address 2`}
                            name={`${name}address2`}
                            value={addr2 || ''}
                            register={register} setValue={setValue}
                            onChange={onChange}
                        />
                    </div>}
            </div>
            {state !== undefined && STATES[getCountry(country)] &&
                <SelectFormInput name={`${name}state`} 
                    value={state || ''}
                    label={`${getCountry(country)} ${country==='CA'? 'Province':'State'}`}
                    setValue={setValue}
                    placeholder="US State"
                    options={STATES[getCountry(country)]}
                    onChange={onChange}
                /> }
            {region !== undefined && REGIONS[getCountry(country)] &&
                <SelectFormInput name={`${name}region`} 
                    value={region || ''}
                    label={`${getCountry(country)} Region`}
                    setValue={setValue}
                    placeholder="select a region"
                    options={REGIONS[getCountry(country)]}
                    onChange={onChange}
                />}
            {suburb !== undefined && address.suburb !== undefined && getCountry(country) !== 'US' &&
                <div>
                    <TextFormInput
                        isRequired={isRequired.includes('suburb')}
                        name={`${name}suburb`}
                        value={suburb || ''}
                        register={register} setValue={setValue}
                        label="Suburb"
                        onChange={onChange}
                    />
                </div>}
            {city !== undefined && 
                <TextFormInput
                    isRequired={isRequired.includes('city')}
                    name={`${name}city`}
                    value={city || ''}
                    register={register} setValue={setValue}
                    label="City"
                    onChange={onChange}
                />}
            {zip !== undefined && 
                <TextFormInput
                    isRequired={isRequired.includes('zip')}
                    name={`${name}zip`}
                    value={zip || ''}
                    register={register} setValue={setValue}
                    label="Postal Code"
                    onChange={onChange}
                />}
        </div>
    )
});

export const REGIONS = {
    NZ : [
        {value: "Northland"},
        {value: "Auckland"},
        {value: "Waikato"},	
        {value: "Bay of Plenty"},
        {value: "Gisborne"},
        {value: "Hawke's Bay"},
        {value: "Taranaki"},
        {value: "Manawatū-Whanganui"},
        {value: "Wellington"},
        {value: "Tasman"},
        {value: "Nelson"},
        {value: "Marlborough"},
        {value: "West Coast"},
        {value: "Canterbury"},
        {value: "Otago"},
        {value: "Southland"}
    ],
    GB: [
        {value: "EAS", label: "East of England"},
        {value: "EM", label: "East Midlands"},
        {value: "LON", label: "London"},
        {value: "NE", label: "North East"},
        {value: "NW", label: "North West"},
        {value: "SE", label: "South East"},
        {value: "SW", label: "South West"},
        {value: "WM", label: "West Midlands"},
        {value: "YH", label: "Yorkshire and the Humber"},
        {value: "SC", label: "Scotland"},
        {value: "WA", label: "Wales"},
        {value: "NI", label: "Northern Ireland"},
    ]
}

export const STATES = {
    US: [
        {value: "AL", label:"Alabama"},
        {value: "AK", label:"Alaska"},
        {value: "AS", label:"American Samoa"},
        {value: "AZ", label:"Arizona"},
        {value: "AR", label:"Arkansas"},
        {value: "CA", label:"California"},
        {value: "CO", label:"Colorado"},
        {value: "CT", label:"Connecticut"},
        {value: "DE", label:"Delaware"},
        {value: "DC", label:"District Of Columbia"},
        {value: "FM", label:"Federated States Of Micronesia"},
        {value: "FL", label:"Florida"},
        {value: "GA", label:"Georgia"},
        {value: "GU", label:"Guam"},
        {value: "HI", label:"Hawaii"},
        {value: "ID", label:"Idaho"},
        {value: "IL", label:"Illinois"},
        {value: "IN", label:"Indiana"},
        {value: "IA", label:"Iowa"},
        {value: "KS", label:"Kansas"},
        {value: "KY", label:"Kentucky"},
        {value: "LA", label:"Louisiana"},
        {value: "ME", label:"Maine"},
        {value: "MH", label:"Marshall Islands"},
        {value: "MD", label:"Maryland"},
        {value: "MA", label:"Massachusetts"},
        {value: "MI", label:"Michigan"},
        {value: "MN", label:"Minnesota"},
        {value: "MS", label:"Mississippi"},
        {value: "MO", label:"Missouri"},
        {value: "MT", label:"Montana"},
        {value: "NE", label:"Nebraska"},
        {value: "NV", label:"Nevada"},
        {value: "NH", label:"New Hampshire"},
        {value: "NJ", label:"New Jersey"},
        {value: "NM", label:"New Mexico"},
        {value: "NY", label:"New York"},
        {value: "NC", label:"North Carolina"},
        {value: "ND", label:"North Dakota"},
        {value: "MP", label:"Northern Mariana Islands"},
        {value: "OH", label:"Ohio"},
        {value: "OK", label:"Oklahoma"},
        {value: "OR", label:"Oregon"},
        {value: "PW", label:"Palau"},
        {value: "PA", label:"Pennsylvania"},
        {value: "PR", label:"Puerto Rico"},
        {value: "RI", label:"Rhode Island"},
        {value: "SC", label:"South Carolina"},
        {value: "SD", label:"South Dakota"},
        {value: "TN", label:"Tennessee"},
        {value: "TX", label:"Texas"},
        {value: "UT", label:"Utah"},
        {value: "VT", label:"Vermont"},
        {value: "VI", label:"Virgin Islands"},
        {value: "VA", label:"Virginia"},
        {value: "WA", label:"Washington"},
        {value: "WV", label:"West Virginia"},
        {value: "WI", label:"Wisconsin"},
        {value: "WY", label:"Wyoming"}
    ],
   AU: [
        {value: 'NSW', label: 'New South Wales'},
        {value: 'VIC', label: 'Victoria'},
        {value: 'QLD', label: 'Queensland'},
        {value: 'SA', label: 'South Australia'},
        {value: 'WA', label: 'Western Australia'},
        {value: 'TAS', label: 'Tasmania'},
        {value: 'ACT', label: 'Australian Capital Territory'},
        {value: 'NT', label: 'Northern Territory'},
   ],
   BR: [
    {value: "AC", label: "Acre"},
    {value: "AL", label: "Alagoas"},
    {value: "AP", label: "Amapá"},
    {value: "AM", label: "Amazonas"},
    {value: "BA", label: "Bahia"},
    {value: "CE", label: "Ceará"},
    {value: "DF", label: "Distrito Federal"},
    {value: "ES", label: "Espírito Santo"},
    {value: "GO", label: "Goiás"},
    {value: "MA", label: "Maranhão"},
    {value: "MT", label: "Mato Grosso"},
    {value: "MS", label: "Mato Grosso do Sul"},
    {value: "MG", label: "Minas Gerais"},
    {value: "PA", label: "Pará"},
    {value: "PB", label: "Paraíba"},
    {value: "PR", label: "Paraná"},
    {value: "PE", label: "Pernambuco"},
    {value: "PI", label: "Piauí"},
    {value: "RJ", label: "Rio de Janeiro"},
    {value: "RN", label: "Rio Grande do Norte"},
    {value: "RS", label: "Rio Grande do Sul"},
    {value: "RO", label: "Rondônia"},
    {value: "RR", label: "Roraima"},
    {value: "SC", label: "Santa Catarina"},
    {value: "SP", label: "São Paulo"},
    {value: "SE", label: "Sergipe"},
    {value: "TO", label: "Tocantins"}
   ],
   CA: [
    {value: "AB", label: "Alberta"},
    {value: "BC", label: "British Columbia"},
    {value: "MB", label: "Manitoba"},
    {value: "NB", label: "New Brunswick"},
    {value: "NL", label: "Newfoundland and Labrador"},
    {value: "NS", label: "Nova Scotia"},
    {value: "ON", label: "Ontario"},
    {value: "PE", label: "Prince Edward Island"},
    {value: "QC", label: "Quebec"},
    {value: "SK", label: "Saskatchewan"},
    {value: "NT", label: "Northwest Territories"},
    {value: "NU", label: "Nunavut"},
    {value: "YT", label: "Yukon"}
   ],

};

export const COUNTRIES = [
    // {name: "Afghanistan", two_code : "AF", three_code : "AFG",currency_code: "AFN", currency_symbol: "Af", currency_value: 971, currency_name: "Afghani"},
    // {name: "Albania", two_code : "AL", three_code : "ALB",currency_code: "ALL", currency_symbol: "L", currency_value: 8, currency_name: "Lek"},
    // {name: "Algeria", two_code : "DZ", three_code : "DZA",currency_code: "DZD", currency_symbol: "د.ج", currency_value: 12, currency_name: "Algerian Dinar"},
    // {name: "American Samoa", two_code : "AS", three_code : "ASM",currency_code: "", currency_symbol: "$", currency_value: 840, currency_name: "US Dollar"},
    // {name: "Andorra", two_code : "AD", three_code : "AND",currency_code: "EUR", currency_symbol: "€", currency_value: 979, currency_name: "Euro"},
    // {name: "Angola", two_code : "AO", three_code : "AGO",currency_code: "AOA", currency_symbol: "Kz", currency_value: 973, currency_name: "Kwanza"},
    // {name: "Anguilla", two_code : "AI", three_code : "AIA",currency_code: "XCD", currency_symbol: "$", currency_value: 951, currency_name: "East Caribbean Dollar"},
    // {name: "Antarctica", two_code : "AQ", three_code : "ATA",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Antigua and Barbuda", two_code : "AG", three_code : "ATG",currency_code: "XCD", currency_symbol: "$", currency_value: 952, currency_name: "East Caribbean Dollar"},
    // {name: "Argentina", two_code : "AR", three_code : "ARG",currency_code: "ARS", currency_symbol: "$", currency_value: 32, currency_name: "Argentine Peso"},
    // {name: "Armenia", two_code : "AM", three_code : "ARM",currency_code: "AMD", currency_symbol: "Դ", currency_value: 51, currency_name: "Armenian Dram"},
    // {name: "Aruba", two_code : "AW", three_code : "ABW",currency_code: "AWG", currency_symbol: "ƒ", currency_value: 533, currency_name: "Aruban Guilder/Florin"},
    {name: "Australia", two_code : "AU", three_code : "AUS",currency_code: "AUD", currency_symbol: "$", currency_value: 36, currency_name: "Australian Dollar"},
    // {name: "Austria", two_code : "AT", three_code : "AUT",currency_code: "EUR", currency_symbol: "€", currency_value: 980, currency_name: "Euro"},
    // {name: "Azerbaijan", two_code : "AZ", three_code : "AZE",currency_code: "AZN", currency_symbol: "ман", currency_value: 944, currency_name: "Azerbaijanian Manat"},
    // {name: "Bahamas", two_code : "BS", three_code : "BHS",currency_code: "BSD", currency_symbol: "$", currency_value: 44, currency_name: "Bahamian Dollar"},
    // {name: "Bahrain", two_code : "BH", three_code : "BHR",currency_code: "BHD", currency_symbol: "ب.د", currency_value: 48, currency_name: "Bahraini Dinar"},
    // {name: "Bangladesh", two_code : "BD", three_code : "BGD",currency_code: "BDT", currency_symbol: "৳", currency_value: 50, currency_name: "Taka"},
    // {name: "Barbados", two_code : "BB", three_code : "BRB",currency_code: "BBD", currency_symbol: "$", currency_value: 52, currency_name: "Barbados Dollar"},
    // {name: "Belarus", two_code : "BY", three_code : "BLR",currency_code: "BYN", currency_symbol: "Br", currency_value: 933, currency_name: "Belarusian Ruble"},
    // {name: "Belgium", two_code : "BE", three_code : "BEL",currency_code: "EUR", currency_symbol: "€", currency_value: 981, currency_name: "Euro"},
    // {name: "Belize", two_code : "BZ", three_code : "BLZ",currency_code: "BZD", currency_symbol: "$", currency_value: 84, currency_name: "Belize Dollar"},
    // {name: "Benin", two_code : "BJ", three_code : "BEN",currency_code: "XAF", currency_symbol: "₣", currency_value: 950, currency_name: "CFA Franc BCEAO"},
    // {name: "Bermuda", two_code : "BM", three_code : "BMU",currency_code: "BMD", currency_symbol: "$", currency_value: 60, currency_name: "Bermudian Dollar"},
    // {name: "Bhutan", two_code : "BT", three_code : "BTN",currency_code: "BTN", currency_symbol: "", currency_value: 64, currency_name: "Ngultrum"},
    // {name: "Bolivia (Plurinational State of)", two_code : "BO", three_code : "BOL",currency_code: "BOB", currency_symbol: "Bs.", currency_value: 68, currency_name: "Boliviano"},
    // {name: "Bonaire, Sint Eustatius and Saba", two_code : "BQ", three_code : "BES",currency_code: "", currency_symbol: "", currency_value: 0 , currency_name: ""},
    // {name: "Bosnia and Herzegovina", two_code : "BA", three_code : "BIH",currency_code: "BAM", currency_symbol: "КМ", currency_value: 977, currency_name: "Konvertibilna Marka"},
    // {name: "Botswana", two_code : "BW", three_code : "BWA",currency_code: "BWP", currency_symbol: "P", currency_value: 72, currency_name: "Pula"},
    // {name: "Bouvet Island", two_code : "BV", three_code : "BVT",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    {name: "Brazil", two_code : "BR", three_code : "BRA",currency_code: "BRL", currency_symbol: "R$", currency_value: 986, currency_name: "Brazilian Real"},
    // {name: "British Indian Ocean Territory", two_code : "IO", three_code : "IOT",currency_code: "GBP", currency_symbol: "£", currency_value: 827, currency_name: "Pound Sterling"},
    // {name: "Brunei Darussalam", two_code : "BN", three_code : "BRN",currency_code: "", currency_symbol: "$", currency_value: 841, currency_name: "US Dollar"},
    // {name: "Bulgaria", two_code : "BG", three_code : "BGR",currency_code: "BGN", currency_symbol: "лв", currency_value: 975, currency_name: "Bulgarian Lev"},
    // {name: "Burkina Faso", two_code : "BF", three_code : "BFA",currency_code: "XAF", currency_symbol: "₣", currency_value: 951, currency_name: "CFA Franc BCEAO"},
    // {name: "Burundi", two_code : "BI", three_code : "BDI",currency_code: "BIF", currency_symbol: "₣", currency_value: 108, currency_name: "Burundi Franc"},
    // {name: "Cabo Verde", two_code : "CV", three_code : "CPV",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Cambodia", two_code : "KH", three_code : "KHM",currency_code: "KHR", currency_symbol: "៛", currency_value: 116, currency_name: "Riel"},
    // {name: "Cameroon", two_code : "CM", three_code : "CMR",currency_code: "XAF", currency_symbol: "₣", currency_value: 952, currency_name: "CFA Franc BCEAO"},
    {name: "Canada", two_code : "CA", three_code : "CAN",currency_code: "CAD", currency_symbol: "$", currency_value: 124, currency_name: "Canadian Dollar"},
    // {name: "Cayman Islands", two_code : "KY", three_code : "CYM",currency_code: "KYD", currency_symbol: "$", currency_value: 136, currency_name: "Cayman Islands Dollar"},
    // {name: "Central African Republic", two_code : "CF", three_code : "CAF",currency_code: "XAF", currency_symbol: "₣", currency_value: 953, currency_name: "CFA Franc BCEAO"},
    // {name: "Chad", two_code : "TD", three_code : "TCD",currency_code: "XAF", currency_symbol: "₣", currency_value: 954, currency_name: "CFA Franc BCEAO"},
    // {name: "Chile", two_code : "CL", three_code : "CHL",currency_code: "CLP", currency_symbol: "$", currency_value: 152, currency_name: "Chilean Peso"},
    // {name: "China", two_code : "CN", three_code : "CHN",currency_code: "CNY", currency_symbol: "¥", currency_value: 156, currency_name: "Yuan"},
    // {name: "Christmas Island", two_code : "CX", three_code : "CXR",currency_code: "AUD", currency_symbol: "$", currency_value: 38, currency_name: "Australian Dollar"},
    // {name: "Cocos (Keeling) Islands", two_code : "CC", three_code : "CCK",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Colombia", two_code : "CO", three_code : "COL",currency_code: "COP", currency_symbol: "$", currency_value: 170, currency_name: "Colombian Peso"},
    // {name: "Comoros", two_code : "KM", three_code : "COM",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Congo (the Democratic Republic of the)", two_code : "CD", three_code : "COD",currency_code: "XAF", currency_symbol: "₣", currency_value: 955, currency_name: "CFA Franc BCEAO"},
    // {name: "Congo", two_code : "CG", three_code : "COG",currency_code: "CDF", currency_symbol: "₣", currency_value: 976, currency_name: "Congolese Franc"},
    // {name: "Costa Rica", two_code : "CR", three_code : "CRI",currency_code: "CRC", currency_symbol: "₡", currency_value: 188, currency_name: "Costa Rican Colon"},
    // {name: "Croatia", two_code : "HR", three_code : "HRV",currency_code: "HRK", currency_symbol: "Kn", currency_value: 191, currency_name: "Croatian Kuna"},
    // {name: "Cuba", two_code : "CU", three_code : "CUB",currency_code: "CUP", currency_symbol: "$", currency_value: 192, currency_name: "Cuban Peso"},
    // {name: "Curaçao", two_code : "CW", three_code : "CUW",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Cyprus", two_code : "CY", three_code : "CYP",currency_code: "EUR", currency_symbol: "€", currency_value: 982, currency_name: "Euro"},
    // {name: "Czechia", two_code : "CZ", three_code : "CZE",currency_code: "CZK", currency_symbol: "Kč", currency_value: 203, currency_name: "Czech Koruna"},
    // {name: "Côte d'Ivoire", two_code : "CI", three_code : "CIV",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Denmark", two_code : "DK", three_code : "DNK",currency_code: "DKK", currency_symbol: "kr", currency_value: 208, currency_name: "Danish Krone"},
    // {name: "Djibouti", two_code : "DJ", three_code : "DJI",currency_code: "DJF", currency_symbol: "₣", currency_value: 262, currency_name: "Djibouti Franc"},
    // {name: "Dominica", two_code : "DM", three_code : "DMA",currency_code: "XCD", currency_symbol: "$", currency_value: 953, currency_name: "East Caribbean Dollar"},
    // {name: "Dominican Republic", two_code : "DO", three_code : "DOM",currency_code: "DOP", currency_symbol: "$", currency_value: 214, currency_name: "Dominican Peso"},
    // {name: "Ecuador", two_code : "EC", three_code : "ECU",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Egypt", two_code : "EG", three_code : "EGY",currency_code: "EGP", currency_symbol: "£", currency_value: 818, currency_name: "Egyptian Pound"},
    // {name: "El Salvador", two_code : "SV", three_code : "SLV",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Equatorial Guinea", two_code : "GQ", three_code : "GNQ",currency_code: "XAF", currency_symbol: "₣", currency_value: 957, currency_name: "CFA Franc BCEAO"},
    // {name: "Eritrea", two_code : "ER", three_code : "ERI",currency_code: "ERN", currency_symbol: "Nfk", currency_value: 232, currency_name: "Nakfa"},
    // {name: "Estonia", two_code : "EE", three_code : "EST",currency_code: "EUR", currency_symbol: "€", currency_value: 983, currency_name: "Euro"},
    // {name: "Eswatini", two_code : "SZ", three_code : "SWZ",currency_code: "ETB", currency_symbol: "", currency_value: 230, currency_name: "Ethiopian Birr"},
    // {name: "Ethiopia", two_code : "ET", three_code : "ETH",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Falkland Islands [Malvinas]", two_code : "FK", three_code : "FLK",currency_code: "FKP", currency_symbol: "£", currency_value: 238, currency_name: "Falkland Islands Pound"},
    // {name: "Faroe Islands", two_code : "FO", three_code : "FRO",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Fiji", two_code : "FJ", three_code : "FJI",currency_code: "FJD", currency_symbol: "$", currency_value: 242, currency_name: "Fiji Dollar"},
    // {name: "Finland", two_code : "FI", three_code : "FIN",currency_code: "EUR", currency_symbol: "€", currency_value: 984, currency_name: "Euro"},
    // {name: "France", two_code : "FR", three_code : "FRA",currency_code: "EUR", currency_symbol: "€", currency_value: 985, currency_name: "Euro"},
    // {name: "French Guiana", two_code : "GF", three_code : "GUF",currency_code: "XPF", currency_symbol: "₣", currency_value: 953, currency_name: "CFP Franc"},
    // {name: "French Polynesia", two_code : "PF", three_code : "PYF",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "French Southern Territories", two_code : "TF", three_code : "ATF",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Gabon", two_code : "GA", three_code : "GAB",currency_code: "XAF", currency_symbol: "₣", currency_value: 958, currency_name: "CFA Franc BCEAO"},
    // {name: "Gambia", two_code : "GM", three_code : "GMB",currency_code: "GMD", currency_symbol: "D", currency_value: 270, currency_name: "Dalasi"},
    // {name: "Georgia", two_code : "GE", three_code : "GEO",currency_code: "GEL", currency_symbol: "ლ", currency_value: 981, currency_name: "Lari"},
    // {name: "Germany", two_code : "DE", three_code : "DEU",currency_code: "EUR", currency_symbol: "€", currency_value: 986, currency_name: "Euro"},
    // {name: "Ghana", two_code : "GH", three_code : "GHA",currency_code: "GHS", currency_symbol: "₵", currency_value: 936, currency_name: "Cedi"},
    // {name: "Gibraltar", two_code : "GI", three_code : "GIB",currency_code: "GIP", currency_symbol: "£", currency_value: 292, currency_name: "Gibraltar Pound"},
    // {name: "Greece", two_code : "GR", three_code : "GRC",currency_code: "EUR", currency_symbol: "€", currency_value: 987, currency_name: "Euro"},
    // {name: "Greenland", two_code : "GL", three_code : "GRL",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Grenada", two_code : "GD", three_code : "GRD",currency_code: "XCD", currency_symbol: "$", currency_value: 954, currency_name: "East Caribbean Dollar"},
    // {name: "Guadeloupe", two_code : "GP", three_code : "GLP",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Guam", two_code : "GU", three_code : "GUM",currency_code: "", currency_symbol: "$", currency_value: 843, currency_name: "US Dollar"},
    // {name: "Guatemala", two_code : "GT", three_code : "GTM",currency_code: "GTQ", currency_symbol: "Q", currency_value: 320, currency_name: "Quetzal"},
    // {name: "Guernsey", two_code : "GG", three_code : "GGY",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Guinea", two_code : "GN", three_code : "GIN",currency_code: "GNF", currency_symbol: "₣", currency_value: 324, currency_name: "Guinea Franc"},
    // {name: "Guinea-Bissau", two_code : "GW", three_code : "GNB",currency_code: "XAF", currency_symbol: "₣", currency_value: 959, currency_name: "CFA Franc BCEAO"},
    // {name: "Guyana", two_code : "GY", three_code : "GUY",currency_code: "GYD", currency_symbol: "$", currency_value: 328, currency_name: "Guyana Dollar"},
    // {name: "Haiti", two_code : "HT", three_code : "HTI",currency_code: "HTG", currency_symbol: "G", currency_value: 332, currency_name: "Gourde"},
    // {name: "Heard Island and McDonald Islands", two_code : "HM", three_code : "HMD",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Holy See", two_code : "VA", three_code : "VAT",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Honduras", two_code : "HN", three_code : "HND",currency_code: "HNL", currency_symbol: "L", currency_value: 340, currency_name: "Lempira"},
    // {name: "Hong Kong", two_code : "HK", three_code : "HKG",currency_code: "HKD", currency_symbol: "$", currency_value: 344, currency_name: "Hong Kong Dollar"},
    // {name: "Hungary", two_code : "HU", three_code : "HUN",currency_code: "HUF", currency_symbol: "Ft", currency_value: 348, currency_name: "Forint"},
    // {name: "Iceland", two_code : "IS", three_code : "ISL",currency_code: "ISK", currency_symbol: "Kr", currency_value: 352, currency_name: "Iceland Krona"},
    // {name: "India", two_code : "IN", three_code : "IND",currency_code: "INR", currency_symbol: "₹", currency_value: 357, currency_name: "Indian Rupee"},
    // {name: "Indonesia", two_code : "ID", three_code : "IDN",currency_code: "IDR", currency_symbol: "Rp", currency_value: 360, currency_name: "Rupiah"},
    // {name: "Iran (Islamic Republic of)", two_code : "IR", three_code : "IRN",currency_code: "IRR", currency_symbol: "﷼", currency_value: 364, currency_name: "Iranian Rial"},
    // {name: "Iraq", two_code : "IQ", three_code : "IRQ",currency_code: "IQD", currency_symbol: "ع.د", currency_value: 368, currency_name: "Iraqi Dinar"},
    // {name: "Ireland", two_code : "IE", three_code : "IRL",currency_code: "EUR", currency_symbol: "€", currency_value: 988, currency_name: "Euro"},
    // {name: "Isle of Man", two_code : "IM", three_code : "IMN",currency_code: "GBP", currency_symbol: "£", currency_value: 829, currency_name: "Pound Sterling"},
    // {name: "Israel", two_code : "IL", three_code : "ISR",currency_code: "ILS", currency_symbol: "₪", currency_value: 376, currency_name: "New Israeli Shekel"},
    // {name: "Italy", two_code : "IT", three_code : "ITA",currency_code: "EUR", currency_symbol: "€", currency_value: 989, currency_name: "Euro"},
    // {name: "Jamaica", two_code : "JM", three_code : "JAM",currency_code: "JMD", currency_symbol: "$", currency_value: 388, currency_name: "Jamaican Dollar"},
    // {name: "Japan", two_code : "JP", three_code : "JPN",currency_code: "JPY", currency_symbol: "¥", currency_value: 392, currency_name: "Yen"},
    // {name: "Jersey", two_code : "JE", three_code : "JEY",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Jordan", two_code : "JO", three_code : "JOR",currency_code: "JOD", currency_symbol: "د.ا", currency_value: 400, currency_name: "Jordanian Dinar"},
    // {name: "Kazakhstan", two_code : "KZ", three_code : "KAZ",currency_code: "KZT", currency_symbol: "〒", currency_value: 398, currency_name: "Tenge"},
    // {name: "Kenya", two_code : "KE", three_code : "KEN",currency_code: "KES", currency_symbol: "Sh", currency_value: 404, currency_name: "Kenyan Shilling"},
    // {name: "Kiribati", two_code : "KI", three_code : "KIR",currency_code: "AUD", currency_symbol: "$", currency_value: 37, currency_name: "Australian Dollar"},
    // {name: "Korea (the Democratic People's Republic of)", two_code : "KP", three_code : "PRK",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Korea (the Republic of)", two_code : "KR", three_code : "KOR",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Kuwait", two_code : "KW", three_code : "KWT",currency_code: "KWD", currency_symbol: "د.ك", currency_value: 414, currency_name: "Kuwaiti Dinar"},
    // {name: "Kyrgyzstan", two_code : "KG", three_code : "KGZ",currency_code: "KGS", currency_symbol: "", currency_value: 417, currency_name: "Som"},
    // {name: "Lao People's Democratic Republic", two_code : "LA", three_code : "LAO",currency_code: "LAK", currency_symbol: "₭", currency_value: 418, currency_name: "Kip"},
    // {name: "Latvia", two_code : "LV", three_code : "LVA",currency_code: "EUR", currency_symbol: "€", currency_value: 991, currency_name: "Euro"},
    // {name: "Lebanon", two_code : "LB", three_code : "LBN",currency_code: "LBP", currency_symbol: "ل.ل", currency_value: 422, currency_name: "Lebanese Pound"},
    // {name: "Lesotho", two_code : "LS", three_code : "LSO",currency_code: "LSL", currency_symbol: "L", currency_value: 426, currency_name: "Loti"},
    // {name: "Liberia", two_code : "LR", three_code : "LBR",currency_code: "LRD", currency_symbol: "$", currency_value: 430, currency_name: "Liberian Dollar"},
    // {name: "Libya", two_code : "LY", three_code : "LBY",currency_code: "LYD", currency_symbol: "ل.د", currency_value: 434, currency_name: "Libyan Dinar"},
    // {name: "Liechtenstein", two_code : "LI", three_code : "LIE",currency_code: "CHF", currency_symbol: "₣", currency_value: 756, currency_name: "Swiss Franc"},
    // {name: "Lithuania", two_code : "LT", three_code : "LTU",currency_code: "EUR", currency_symbol: "€", currency_value: 992, currency_name: "Euro"},
    // {name: "Luxembourg", two_code : "LU", three_code : "LUX",currency_code: "EUR", currency_symbol: "€", currency_value: 993, currency_name: "Euro"},
    // {name: "Macao", two_code : "MO", three_code : "MAC",currency_code: "MOP", currency_symbol: "P", currency_value: 446, currency_name: "Pataca"},
    // {name: "Madagascar", two_code : "MG", three_code : "MDG",currency_code: "MGA", currency_symbol: "", currency_value: 969, currency_name: "Malagasy Ariary"},
    // {name: "Malawi", two_code : "MW", three_code : "MWI",currency_code: "MWK", currency_symbol: "MK", currency_value: 454, currency_name: "Kwacha"},
    // {name: "Malaysia", two_code : "MY", three_code : "MYS",currency_code: "MYR", currency_symbol: "RM", currency_value: 458, currency_name: "Malaysian Ringgit"},
    // {name: "Maldives", two_code : "MV", three_code : "MDV",currency_code: "MVR", currency_symbol: "ރ.", currency_value: 462, currency_name: "Rufiyaa"},
    // {name: "Mali", two_code : "ML", three_code : "MLI",currency_code: "XAF", currency_symbol: "₣", currency_value: 960, currency_name: "CFA Franc BCEAO"},
    // {name: "Malta", two_code : "MT", three_code : "MLT",currency_code: "EUR", currency_symbol: "€", currency_value: 994, currency_name: "Euro"},
    // {name: "Marshall Islands", two_code : "MH", three_code : "MHL",currency_code: "", currency_symbol: "$", currency_value: 845, currency_name: "US Dollar"},
    // {name: "Martinique", two_code : "MQ", three_code : "MTQ",currency_code: "MRU", currency_symbol: "UM", currency_value: 929, currency_name: "Ouguiya"},
    // {name: "Mauritania", two_code : "MR", three_code : "MRT",currency_code: "MUR", currency_symbol: "₨", currency_value: 480, currency_name: "Mauritius Rupee"},
    // {name: "Mauritius", two_code : "MU", three_code : "MUS",currency_code: "MXN", currency_symbol: "$", currency_value: 484, currency_name: "Mexican Peso"},
    // {name: "Mayotte", two_code : "YT", three_code : "MYT",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Mexico", two_code : "MX", three_code : "MEX",currency_code: "MNX", currency_symbol: "$", currency_value: 846, currency_name: "Mexico Peso"},
    // {name: "Micronesia (Federated States of)", two_code : "FM", three_code : "FSM",currency_code: "MDL", currency_symbol: "L", currency_value: 498, currency_name: "Moldovan Leu"},
    // {name: "Moldova (the Republic of)", two_code : "MD", three_code : "MDA",currency_code: "EUR", currency_symbol: "€", currency_value: 995, currency_name: "Euro"},
    // {name: "Monaco", two_code : "MC", three_code : "MCO",currency_code: "MNT", currency_symbol: "₮", currency_value: 496, currency_name: "Tugrik"},
    // {name: "Mongolia", two_code : "MN", three_code : "MNG",currency_code: "EUR", currency_symbol: "€", currency_value: 996, currency_name: "Euro"},
    // {name: "Montenegro", two_code : "ME", three_code : "MNE",currency_code: "XCD", currency_symbol: "$", currency_value: 955, currency_name: "East Caribbean Dollar"},
    // {name: "Montserrat", two_code : "MS", three_code : "MSR",currency_code: "MAD", currency_symbol: "د.م.", currency_value: 504, currency_name: "Moroccan Dirham"},
    // {name: "Morocco", two_code : "MA", three_code : "MAR",currency_code: "MZN", currency_symbol: "MTn", currency_value: 943, currency_name: "Metical"},
    // {name: "Mozambique", two_code : "MZ", three_code : "MOZ",currency_code: "MMK", currency_symbol: "K", currency_value: 104, currency_name: "Kyat"},
    // {name: "Myanmar", two_code : "MM", three_code : "MMR",currency_code: "NAD", currency_symbol: "$", currency_value: 516, currency_name: "Namibia Dollar"},
    // {name: "Namibia", two_code : "NA", three_code : "NAM",currency_code: "ZAR", currency_symbol: "R", currency_value: 711, currency_name: "Rand"},
    // {name: "Nauru", two_code : "NR", three_code : "NRU",currency_code: "NPR", currency_symbol: "₨", currency_value: 524, currency_name: "Nepalese Rupee"},
    // {name: "Nepal", two_code : "NP", three_code : "NPL",currency_code: "EUR", currency_symbol: "€", currency_value: 997, currency_name: "Euro"},
    // {name: "Netherlands", two_code : "NL", three_code : "NLD",currency_code: "XPF", currency_symbol: "₣", currency_value: 954, currency_name: "CFP Franc"},
    {name: "New Zealand", two_code : "NZ", three_code : "NZL",currency_code: "NZD", currency_symbol: "$", currency_value: 558, currency_name: "New Zealand Dollar"},
    // {name: "Nicaragua", two_code : "NI", three_code : "NIC",currency_code: "XAF", currency_symbol: "₣", currency_value: 961, currency_name: "CFA Franc BCEAO"},
    // {name: "Nigeria", two_code : "NG", three_code : "NGA",currency_code: "NGN", currency_symbol: "₦", currency_value: 556, currency_name: "Nigeria Naira"},
    // {name: "Niue", two_code : "NU", three_code : "NIU",currency_code: "TRY", currency_symbol: "₤", currency_value: 949, currency_name: "Turkish Lira"},
    // {name: "Norfolk Island", two_code : "NF", three_code : "NFK",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Northern Mariana Islands", two_code : "MP", three_code : "MNP",currency_code: "NOK", currency_symbol: "kr", currency_value: 578, currency_name: "Norwegian Krone"},
    // {name: "Norway", two_code : "NO", three_code : "NOR",currency_code: "OMR", currency_symbol: "ر.ع.", currency_value: 512, currency_name: "Rial Omani"},
    // {name: "Oman", two_code : "OM", three_code : "OMN",currency_code: "OMR", currency_symbol: "﷼ ", currency_value: 848, currency_name: "Oman Rial"},
    // {name: "Pakistan", two_code : "PK", three_code : "PAK",currency_code: "PKR", currency_symbol: "₨", currency_value: 849, currency_name: "Pakistani Rupeer"},
    // {name: "Palau", two_code : "PW", three_code : "PLW",currency_code: "ILS", currency_symbol: "₪", currency_value: 377, currency_name: "New Israeli Shekel"},
    // {name: "Palestine, State of", two_code : "PS", three_code : "PSE",currency_code: "PAB", currency_symbol: "B/.", currency_value: 590, currency_name: "Balboa"},
    // {name: "Panama", two_code : "PA", three_code : "PAN",currency_code: "PAB", currency_symbol: "B/.", currency_value: 850, currency_name: "Panamanian Balboa"},
    // {name: "Papua New Guinea", two_code : "    PG ", three_code : "  PNG    ",currency_code: "PYG", currency_symbol: "₲", currency_value: 600, currency_name: "Guarani"},
    // {name: "Paraguay", two_code : "PY", three_code : "PRY",currency_code: "PEN", currency_symbol: "S/.", currency_value: 604, currency_name: "Nuevo Sol"},
    // {name: "Peru", two_code : "PE", three_code : "PER",currency_code: "PHP", currency_symbol: "₱", currency_value: 608, currency_name: "Philippine Peso"},
    // {name: "Philippines", two_code : "PH", three_code : "PHL",currency_code: "PHP", currency_symbol: "₱", currency_value: 557, currency_name: "Philippines Peso"},
    // {name: "Pitcairn", two_code : "PN", three_code : "PCN",currency_code: "PLN", currency_symbol: "zł", currency_value: 985, currency_name: "PZloty"},
    // {name: "Poland", two_code : "PL", three_code : "POL",currency_code: "EUR", currency_symbol: "€", currency_value: 998, currency_name: "Euro"},
    // {name: "Portugal", two_code : "PT", three_code : "PRT",currency_code: "", currency_symbol: "", currency_value: 851, currency_name: ""},
    // {name: "Puerto Rico", two_code : "PR", three_code : "PRI",currency_code: "QAR", currency_symbol: "ر.ق", currency_value: 634, currency_name: "Qatari Rial"},
    // {name: "Qatar", two_code : "QA", three_code : "QAT",currency_code: "RON", currency_symbol: "L", currency_value: 946, currency_name: "Leu"},
    // {name: "Republic of North Macedonia", two_code : "MK", three_code : "MKD",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Romania", two_code : "RO", three_code : "ROU",currency_code: "RUB", currency_symbol: "р.", currency_value: 643, currency_name: "Russian Ruble"},
    // {name: "Russian Federation", two_code : "RU", three_code : "RUS",currency_code: "RWF", currency_symbol: "₣", currency_value: 646, currency_name: "Rwanda Franc"},
    // {name: "Rwanda", two_code : "RW", three_code : "RWA",currency_code: "SHP", currency_symbol: "£", currency_value: 655, currency_name: "Saint Helena Pound"},
    // {name: "Réunion", two_code : "RE", three_code : "REU",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Saint Barthélemy", two_code : "BL", three_code : "BLM",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Saint Helena, Ascension and Tristan da Cunha", two_code : "SH", three_code : "SHN",currency_code: "XCD", currency_symbol: "$", currency_value: 956, currency_name: "East Caribbean Dollar"},
    // {name: "Saint Kitts and Nevis", two_code : "KN", three_code : "KNA",currency_code: "XCD", currency_symbol: "$", currency_value: 957, currency_name: "East Caribbean Dollar"},
    // {name: "Saint Lucia", two_code : "LC", three_code : "LCA",currency_code: "XCD", currency_symbol: "$", currency_value: 958, currency_name: "East Caribbean Dollar"},
    // {name: "Saint Martin (French part)", two_code : "MF", three_code : "MAF",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Saint Pierre and Miquelon", two_code : "PM", three_code : "SPM",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Saint Vincent and the Grenadines", two_code : "VC", three_code : "VCT",currency_code: "WST", currency_symbol: "T", currency_value: 882, currency_name: "Tala"},
    // {name: "Samoa", two_code : "WS", three_code : "WSM",currency_code: "EUR", currency_symbol: "€", currency_value: 999, currency_name: "Euro"},
    // {name: "San Marino", two_code : "SM", three_code : "SMR",currency_code: "STN", currency_symbol: "Db", currency_value: 930, currency_name: "Dobra"},
    // {name: "Sao Tome and Principe", two_code : "ST", three_code : "STP",currency_code: "SAR", currency_symbol: "ر.س", currency_value: 682, currency_name: "Saudi Riyal"},
    // {name: "Saudi Arabia", two_code : "SA", three_code : "SAU",currency_code: "XAF", currency_symbol: "₣", currency_value: 962, currency_name: "CFA Franc BCEAO"},
    // {name: "Senegal", two_code : "SN", three_code : "SEN",currency_code: "RSD", currency_symbol: "din", currency_value: 942, currency_name: "Serbian Dinar"},
    // {name: "Serbia", two_code : "RS", three_code : "SRB",currency_code: "SCR", currency_symbol: "₨", currency_value: 690, currency_name: "Seychelles Rupee"},
    // {name: "Seychelles", two_code : "SC", three_code : "SYC",currency_code: "SLL", currency_symbol: "Le", currency_value: 694, currency_name: "Leone"},
    // {name: "Sierra Leone", two_code : "SL", three_code : "SLE",currency_code: "BND", currency_symbol: "$", currency_value: 97, currency_name: "Brunei Dollar"},
    // {name: "Singapore", two_code : "SG", three_code : "SGP",currency_code: "SGD", currency_symbol: "$", currency_value: 703, currency_name: "Singapore Dollar"},
    // {name: "Sint Maarten (Dutch part)", two_code : "SX", three_code : "SXM",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Slovakia", two_code : "SK", three_code : "SVK",currency_code: "EUR", currency_symbol: "€", currency_value: 1001, currency_name: "Euro"},
    // {name: "Slovenia", two_code : "SI", three_code : "SVN",currency_code: "SBD", currency_symbol: "$", currency_value: 90, currency_name: "Solomon Islands Dollar"},
    // {name: "Solomon Islands", two_code : "SB", three_code : "SLB",currency_code: "SOS", currency_symbol: "Sh", currency_value: 706, currency_name: "Somali Shilling"},
    // {name: "Somalia", two_code : "SO", three_code : "SOM",currency_code: "ZAR", currency_symbol: "R", currency_value: 712, currency_name: "Rand"},
    // {name: "South Africa", two_code : "ZA", three_code : "ZAF",currency_code: "KRW", currency_symbol: "₩", currency_value: 410, currency_name: "South Korean Won"},
    // {name: "South Georgia and the South Sandwich Islands", two_code : "GS", three_code : "SGS",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "South Sudan", two_code : "SS", three_code : "SSD",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Spain", two_code : "ES", three_code : "ESP",currency_code: "LKR", currency_symbol: "Rs", currency_value: 144, currency_name: "Sri Lanka Rupee"},
    // {name: "Sri Lanka", two_code : "LK", three_code : "LKA",currency_code: "SDG", currency_symbol: "£", currency_value: 938, currency_name: "Sudanese Pound"},
    // {name: "Sudan", two_code : "SD", three_code : "SDN",currency_code: "SRD", currency_symbol: "$", currency_value: 968, currency_name: "Suriname Dollar"},
    // {name: "Suriname", two_code : "SR", three_code : "SUR",currency_code: "SZL", currency_symbol: "L", currency_value: 748, currency_name: "Lilangeni"},
    // {name: "Svalbard and Jan Mayen", two_code : "SJ", three_code : "SJM",currency_code: "SEK", currency_symbol: "kr", currency_value: 752, currency_name: "Swedish Krona"},
    // {name: "Sweden", two_code : "SE", three_code : "SWE",currency_code: "CHF", currency_symbol: "₣", currency_value: 757, currency_name: "Swiss Franc"},
    // {name: "Switzerland", two_code : "CH", three_code : "CHE",currency_code: "SYP", currency_symbol: "ل.س", currency_value: 760, currency_name: "Syrian Pound"},
    // {name: "Syrian Arab Republic", two_code : "SY", three_code : "SYR",currency_code: "TWD", currency_symbol: "$", currency_value: 901, currency_name: "Taiwan Dollar"},
    // {name: "Taiwan (Province of China)", two_code : "TW", three_code : "TWN",currency_code: "TJS", currency_symbol: "ЅМ", currency_value: 972, currency_name: "Somoni"},
    // {name: "Tajikistan", two_code : "TJ", three_code : "TJK",currency_code: "TZS", currency_symbol: "Sh", currency_value: 834, currency_name: "Tanzanian Shilling"},
    // {name: "Tanzania, United Republic of", two_code : "TZ", three_code : "TZA",currency_code: "THB", currency_symbol: "฿", currency_value: 764, currency_name: "Baht"},
    // {name: "Thailand", two_code : "TH", three_code : "THA",currency_code: "XAF", currency_symbol: "₣", currency_value: 963, currency_name: "CFA Franc BCEAO"},
    // {name: "Timor-Leste", two_code : "TL", three_code : "TLS",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Togo", two_code : "TG", three_code : "TGO",currency_code: "TOP", currency_symbol: "T$", currency_value: 776, currency_name: "Pa’anga"},
    // {name: "Tokelau", two_code : "TK", three_code : "TKL",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Tonga", two_code : "TO", three_code : "TON",currency_code: "TTD", currency_symbol: "$", currency_value: 780, currency_name: "Trinidad and Tobago Dollar"},
    // {name: "Trinidad and Tobago", two_code : "TT", three_code : "TTO",currency_code: "SHP", currency_symbol: "£", currency_value: 656, currency_name: "Saint Helena Pound"},
    // {name: "Tunisia", two_code : "TN", three_code : "TUN",currency_code: "TRY", currency_symbol: "₤", currency_value: 950, currency_name: "Turkish Lira"},
    // {name: "Turkey", two_code : "TR", three_code : "TUR",currency_code: "TMT", currency_symbol: "m", currency_value: 934, currency_name: "Manat"},
    // {name: "Turkmenistan", two_code : "TM", three_code : "TKM",currency_code: "TMT", currency_symbol: "", currency_value: 852, currency_name: "Turkmenistani Manat"},
    // {name: "Turks and Caicos Islands", two_code : "TC", three_code : "TCA",currency_code: "AUD", currency_symbol: "$", currency_value: 40, currency_name: "Australian Dollar"},
    // {name: "Tuvalu", two_code : "TV", three_code : "TUV",currency_code: "UGX", currency_symbol: "Sh", currency_value: 800, currency_name: "Uganda Shilling"},
    // {name: "Uganda", two_code : "UG", three_code : "UGA",currency_code: "UAH", currency_symbol: "₴", currency_value: 980, currency_name: "Hryvnia"},
    // {name: "Ukraine", two_code : "UA", three_code : "UKR",currency_code: "UAH", currency_symbol: "$", currency_value: 853, currency_name: "US Dollar"},
    // {name: "United Arab Emirates", two_code : "AE", three_code : "ARE",currency_code: "AED", currency_symbol: "د.إ", currency_value: 784, currency_name: "UAE Dirham"},
    {name: "United Kingdom", two_code : "GB", three_code : "GBR",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "United States Minor Outlying Islands", two_code : "UM", three_code : "UMI",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    {name: "United States", two_code : "US", three_code : "USA",currency_code: "USD", currency_symbol: "$", currency_value: 858, currency_name: "Peso Uruguayo"},
    // {name: "Uruguay", two_code : "UY", three_code : "URY",currency_code: "UYU", currency_symbol: "$", currency_value: 854, currency_name: "US Dollar"},
    // {name: "Uzbekistan", two_code : "UZ", three_code : "UZB",currency_code: "VUV", currency_symbol: "Vt", currency_value: 548, currency_name: "Vatu"},
    // {name: "Vanuatu", two_code : "VU", three_code : "VUT",currency_code: "EUR", currency_symbol: "€", currency_value: 1003, currency_name: "Euro"},
    // {name: "Venezuela (Bolivarian Republic of)", two_code : "VE", three_code : "VEN",currency_code: "VND", currency_symbol: "₫", currency_value: 704, currency_name: "Dong"},
    // {name: "Viet Nam", two_code : "VN", three_code : "VNM",currency_code: "XPF", currency_symbol: "₣", currency_value: 955, currency_name: "CFP Franc"},
    // {name: "Virgin Islands (British)", two_code : "VG", three_code : "VGB",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Virgin Islands (U.S.)", two_code : "VI", three_code : "VIR",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Wallis and Futuna", two_code : "WF", three_code : "WLF",currency_code: "YER", currency_symbol: "﷼", currency_value: 886, currency_name: "Yemeni Rial"},
    // {name: "Western Sahara", two_code : "EH", three_code : "ESH",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Yemen", two_code : "YE", three_code : "YEM",currency_code: "ZMW", currency_symbol: "ZK", currency_value: 967, currency_name: "Zambian Kwacha"},
    // {name: "Zambia", two_code : "ZM", three_code : "ZMB",currency_code: "ZWL", currency_symbol: "$", currency_value: 932, currency_name: "Zimbabwe Dollar"},
    // {name: "Zimbabwe", two_code : "ZW", three_code : "ZWE",currency_code: "", currency_symbol: "", currency_value: 0, currency_name: ""},
    // {name: "Åland Islands", two_code : "AX", three_code : "ALA",currency_code: "EUR", currency_symbol: "€", currency_value: 980, currency_name: "Euro"},
];

export const COUNTRY_OPTIONS = COUNTRIES.map(c => ({label: c.name, value:c.two_code}));
