import {Fragment, useEffect, useState} from 'react';
import { Autocomplete, CircularProgress, FormControl, TextField, ThemeProvider } from "@mui/material";
import Theme from './Theme';

export default function AutocompleteTextFormInput(params) {
    const [value, setValue] = useState();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState(params.options || []);
    const [reset, setReset] = useState();
    const loading = open && options.length === 0;

    useEffect(() => {
        setOptions((params && params.options) || []);
        if (!params.options || params.options.length === 0) {
            return;
        }
        let v = value && params.options.find(o => o.id === (value.id));
        if (v) {
            setValue({ ...v });
        }

    }, [params && params.options]);

    useEffect(() => {
        let o = params.options && params.options.find(o => o.id === params.value);
        setValue(o && { ...o });
    }, [params && params.value]);

    useEffect(() => {
        let active = true;
        (async () => {
            if (!params.optionsCallback) {
                return;
            }
            const response = await params.optionsCallback(search);
            if (active && response) {
                setOptions(response);
            }
        })();

        return () => {
            active = false;
        };

    }, [search]);

    // useEffect(() => {
    //     if (!open && value) {
    //         return setOptions([value]);
    //     }
    //     setOptions(params.options);
    // }, [open]);

    return <FormControl fullWidth margin="normal">
        <ThemeProvider theme={Theme}>
        {reset ? '' :
            <Autocomplete
                value={value || ''}
                getOptionLabel={option => {
                    return (option && option?.label) || '';
                }}
                    onChange={(_, newValue) => {
                    setValue(newValue);
                    params.onChange && params.onChange(newValue);
                }}
                onKeyUp={e => setSearch(e.target.value)}
                open={open}
                name={params.name}
                isOptionEqualToValue={(o, v) => {
                    if (!v) {
                        return params?.options && params.options.length > 0 && params.options[0].id === o.id;
                    }
                    return o && v && o.id === v.id;
                }}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                loading={loading}
                options={options}
                renderInput={(_params) => {
                    return <TextField
                        onChange={e => console.log(e.target.value)}
                        {..._params}
                        label={<div>{params.label}</div>}
                        InputProps={{
                            ..._params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {_params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                        error={!!params.errors?.message}
                        helpertext={params.errors?.message}
                        placeholder={params.placeholder || 'type to search...'}
                    />;
                }}
            />}
        </ThemeProvider>
    </FormControl>;
}