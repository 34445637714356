import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { TextField, Tooltip } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Theme from "./Theme";
import { default as CalendarIcon } from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateFormInput1.scss";
import Utils from "../../serverUtils/Utils";

export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = `hh:mm a`;
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const getDateFormat = (v, isTime) => {
    let ts = parseInt(v);
    if (!isNaN(ts)) {
        let dt = dayjs(ts).format(isTime ? TIME_FORMAT : DATE_FORMAT);
        return dt.toUpperCase();
    }
};

export const toMilliSeconds = (dt, hasTime) => {
    return moment(dt, hasTime ? DATE_TIME_FORMAT : DATE_FORMAT).toDate().getTime();
}

const DateFormInput1 = forwardRef(({
    name,
    label,
    value, onChange,
    setValue,
    hasTime, isRequired = false,
    endDate, startDate, 
    marks,
}, ref) => {
    const [date, setDate] = useState(new Date());
    const [dateInputValue, setDateInputValue] = useState('');
    const [timeInputValue, setTimeInputValue] = useState('');
    const [error, setError] = useState('');
    const [isDateWidget, setIsDateWidget] = useState(false);
    const [markDayTooltips, setMarkDayTooltips] = useState({});
    const divRef = useRef();

    useImperativeHandle(ref, () => ({
        setError, name,
    }));

    useEffect(() => {
        divRef.current && divRef.current.scrollIntoView({
            behavior: 'smooth', 
            block: 'center',     
        });
    }, [divRef.current]);

    useEffect(() => {
        let ms = {startDate: 'Start Date', endDate: 'End Date'};
        if (marks) {
            ms = {marks, ...ms};
        }
        setMarkDayTooltips(ms);
    }, [marks]);

    useEffect(() => {
        if (value) {
            let dt = new Date(parseInt(value));
            setDate(dt);
            setDateInputValue(getDateFormat(dt.getTime()));
            if (hasTime){
                setTimeInputValue(getDateFormat(dt.getTime(), true));
            }
            setValue && setValue(name, value);
        }
    }, [value]);

    useEffect(() => {
        if (isRequired) {
            return setError(!dateInputValue? 'This field is required':'');
        }
    }, [isRequired, dateInputValue]);

    const validateDate = (dateString) => {
        // Regular expression to validate date in MM/dd/yyyy format
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

        if (regex.test(dateString)) {
            setError('');
            return true;
        } else {
            setError('Invalid format [MM/dd/yyyy]');
            setValue && setValue(name, null);
            return false;
        }
    };

    const validateTime = (timeString) => {
        // Regular expression to validate time in hh:mm AM/PM format
        const regex = /^(0[1-9]|1[0-2]):[0-5][0-9]\s? (AM|PM)$/i;
    
        if (regex.test(timeString)) {
          setError('');
          return true;
        } else {
          setError('Invalid format [hh:mm AM/PM]');
          setValue && setValue(name, null);
          return false;
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        if (validateDate(value)) {
            let dt = new Date(value);
            setDate(dt);
            setValue && setValue(name, dt.getTime());
            onChange && onChange(dt.getTime());
        }
        setDateInputValue(value);
    };

    const handleTimeChange = (e) => {
        const { value } = e.target;
        if (validateTime(value)) {
            let dts = `${Utils.formatDate(date.getTime(), DATE_FORMAT)} ${value.toUpperCase()}`;
            let dt = new Date(dts);
            setDate(dt);
            setValue && setValue(name, dt.getTime());
            onChange && onChange(dt.getTime());
        }
        setTimeInputValue(value.toUpperCase());
      };

    const isThisDate = (dt, thisDate) => {
        if (thisDate) {
            let t = Utils.formatDate(thisDate, DATE_FORMAT);
            let c = Utils.formatDate(dt.getTime(), DATE_FORMAT);
            return t === c;
        }
    }

    const dayClassName = dt => {
        if (isThisDate(dt, endDate)) {
          return 'endDate';
        }else if (isThisDate(dt, startDate)) {
            return 'startDate';
        }
        if (markDayTooltips.marks) {
            let found = markDayTooltips.marks.find(m => isThisDate(dt, m.ts));
            if (found) {
                return found.id;
            }
        }

        return null;
    }

    const renderDayContents = (day, dt) => {
        let dateField = isThisDate(dt, startDate) && 'Start Date';
        if (!dateField) {
            dateField = isThisDate(dt, endDate) && 'End Date';
        }
        if (!dateField && markDayTooltips.marks) {
            let found = markDayTooltips.marks.find(m => isThisDate(dt, m.ts));
            if (found) {
                dateField = found.label;
            }
        }
       
        return (
            <Tooltip title={dateField? dateField : ''}>
                <span>{day}</span>
            </Tooltip>
        );
    };

    const getEndDate = () => {
        if (!endDate && !startDate) {
            return;
        }
        if (startDate) {
            return date;
        }
        return !isNaN(endDate) && new Date(parseInt(endDate));
    }

    const getStartDate = () => {
        if (!endDate && !startDate) {
            return;
        }
        if (endDate) {
            return date;
        }
        return !isNaN(startDate) && new Date(parseInt(startDate));
    }
    return <ThemeProvider theme={Theme}>
        <div className={`DateFormInput1 ${name}`}>
            <div className="datetime">
               <div className="date">
                    <TextField 
                        error={error? true:false}
                        className="TextField"
                        label={label}
                        value={dateInputValue}
                        onChange={handleChange}
                        placeholder="MM/dd/yyyy"
                        onClick={e => e.stopPropagation()}
                    />
                    <CalendarIcon onClick={() => {
                        setIsDateWidget(true);

                    }} />
                </div>
                <div className="time">
                    <TextField className="TextField"
                        error={error? true:false}
                        label={<span>Time <span className="pattern">00:00 AM</span></span>}
                        value={timeInputValue}
                        onChange={handleTimeChange}
                        placeholder="hh:mm AM/PM"
                        onClick={e => {
                            e.stopPropagation();
                            if (!timeInputValue){
                                setTimeInputValue('12:00 AM');
                            }
                        }}
                    /> 
                </div> 
            </div>
            
            <span className="error">{error}</span>

            {isDateWidget &&
                <div className="DatePicker-wrapper" ref={divRef}
                    onMouseLeave={() => setIsDateWidget(false)}
                    >
                    {label && <span className="title">{label}</span>}
                    <DatePicker inline 
                        selected={date}
                        startDate={getStartDate()}
                        endDate={getEndDate()}
                        renderDayContents={renderDayContents}
                        dayClassName={dayClassName}
                        selectsRange
                        onSelect={d => {
                            setDate(d);
                            setDateInputValue(getDateFormat(d.getTime()));
                            setError('');
                            setIsDateWidget(false);
                            setValue && setValue(name, d.getTime());
                            onChange && onChange(d.getTime())
                        }}
                        className="custom-datepicker"
                    />
                </div>}
        </div>
    </ThemeProvider>;
});

export default DateFormInput1;