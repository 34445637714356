import Utils from "../Utils";
import {convertDate} from "../Models";
import {RequestLeague, RequestMessage, RequestUser, RequestUtils, RequestStore, RequestCart} from "../requests";
import { IMAGE_TYPE } from "../../components/Form/Form";
import ImageModel from "./ImageModel";
import UserModel from "./UserModel";

class LeagueModel {
    static async adjustLeagueTransactionCredits(registrationId, leagueId, status) {
        let response = await RequestLeague.adjustLeagueTransactionCredits(registrationId, leagueId, status);
        return RequestUtils.getResponseData(response);
    }
    static async getTransactionCreditBalance(leagueId) {
        let response = await RequestLeague.getTransactionCreditBalance(leagueId);
        return RequestUtils.getResponseData(response);
    }
    static async updateLeagueTransactionCredits(params) {
        let response = await RequestLeague.updateLeagueTransactionCredits(params);
        return RequestUtils.getResponseData(response);
    }
    static async addDivision(params) {
        let response = await RequestLeague.addLeagueDivisionRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async updateDivision(params) {
        let response = await RequestLeague.updateLeagueDivisionRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteDivision(id) {
        let response = await RequestLeague.deleteLeagueDivisionRequest(id);
        return RequestUtils.getResponseData(response);
    }

    static async addToCart(cart) {
        let response = await RequestCart.addCart(cart); 
        return RequestUtils.getResponseData(response);
    }

    static async membershipRegistration(id, member_id) {
        let response = await RequestLeague.registerLeague(id, member_id);
        return RequestUtils.getResponseData(response);
    }

    static async searchMember(id, search_string) {
        let response = await RequestUser.searchLeagueMembership(id, search_string);
        return RequestUtils.getResponseData(response);
    }

    static async deleteLeague(id) {
        let response = await RequestLeague.deleteLeague(id);
        return RequestUtils.getResponseData(response);
    }

    static async searchLeague(search_string='') {
        let response = await RequestLeague.searchLeague({search_string});
        return RequestUtils.getResponseData(response);
    }

    static async updateMembership(params) {
        let response = await RequestLeague.updateLeagueMembership(params);
        return RequestUtils.getResponseData(response);
    }

    static async cloneLeague(id) {
        let response = await RequestLeague.cloneLeague(id);
        return RequestUtils.getResponseData(response);
    }

    static async updateLeague(params) {
        let response = await RequestLeague.updateLeague(params, params.id? 'updateLeague':'addLeague');
        return RequestUtils.getResponseData(response);
    }

    static async getStoreItems(id) {
        if (!id) {
            return [];
        }
        let response = await RequestStore.getStoreItemsRequest({ref: id, ref_type: 'league'});
        let store = RequestUtils.getResponseData(response);
        const {store_items=[], images_doc=[]} = store || {};
        return store_items.map(si => {
            si.getImages = () => images_doc.filter(i => i.ref === si.id);
            return si;
        });
    }

    static async getPhotos({id}) {
        return ImageModel.getImageByRefs({ids: [id], image_size: 400, image_type: IMAGE_TYPE.photo});
    }

    static async getMemberships({id, search_string, search_field, page, page_size}) {
        let response = await RequestLeague.getLeagueMembershipsRequest({id, search_string, search_field, page, page_size});
        return UserModel.formatResult(RequestUtils.getResponseData(response));
    }

    static async getStandings(id) {
        let response = await RequestLeague.getStandings(id);
        return RequestUtils.getResponseData(response);
    }

    static async getTournaments(id, search_string) {
        let response = await RequestLeague.getTournaments(id, search_string);
        const {tournaments, images_doc} = RequestUtils.getResponseData(response);
        tournaments.forEach(t => t.getImage = () => images_doc.find(i => i && i.ref===t.id));
        return tournaments;
    }

    static async addTransactionCredit(params) {
        let response = await RequestLeague.addTransactionCredit(params);
        return RequestUtils.getResponseData(response);
    }

    static async getTransactionCredits(leagueId) {
        let response = await RequestLeague.getTransactionCredits(leagueId);
        let data = RequestUtils.getResponseData(response);
        let {transaction_credits, memberships_doc} = data;
        transaction_credits.forEach(tc => {
            tc.getPurchaseBy = () => memberships_doc.find(m => m.id === tc.purchase_by);
        })
        return transaction_credits;
    }

    static async getLeague(id) {
        if (!id) {
            return;
        }

        const response = await RequestLeague.getLeagueRequest(id);
        let data = RequestUtils.getResponseData(response);
        return LeagueModel.formatResult(data)[0];
    }

    static async getLeagues({page, search_string}) {
        const response = await RequestLeague.getLeaguesRequest({page, search_string});
        return RequestUtils.getResponseData(response);
    }

    static formatResult(data) {
        let docs = {...data};
        docs.tournaments_doc && docs.tournaments_doc.forEach(t => {
            t.dates = {
                        start_date: convertDate(t.dates.start_date),
                        end_date: convertDate(t.dates.end_date),
                        close_date: convertDate(t.dates.close_date),
                    };
        });
        let {leagues=[]} = data || {};
        leagues.forEach(league => {
            league.CRCs ={};
            league.getLastTransactionCreditPurchase = () => {
                let lpurchase = docs.transaction_credit_doc;
                if (lpurchase) {
                    lpurchase.getPurchaser = () => docs.memberships_doc.find(m => m.id === lpurchase.purchase_by);
                    return lpurchase;
                }
            }
            league.getData = () => data;
            league.getCurrency = () => league.currency; 
            league.setImagesDoc = images => Utils.updateArray(docs.images_doc, images, true);
            league.getTransactions = () => docs.league_transactions_doc;
            league.updateTransaction = t => Object.assign(docs.divisions_doc.find(i => i.id === t.id) || {}, t);
            league.addTransaction = trans => {
                //docs.league_transactions_doc.push(trans)  just a stub since transactions get is not part of get league
            };
            league.deleteTransaction = id => {
                // Utils.removeArrayItem(docs.league_transactions_doc, {id}); just a stub since transactions get is not part of get league
            }
            league.getImage = () => docs.images_doc.find(image => image && image.id && image.ref === league.id && image.image_type === IMAGE_TYPE.league);
            league.setImage = image => docs.images_doc.push(image);
            league.getIcon = () => docs.images_doc.find(image => image && image.id && image.ref === league.id && image.image_type === IMAGE_TYPE.league_icon);
            league.getAdmins = () => docs.memberships_doc
                                        .filter(m => league.admins.find(a => a.id === m.id))
                                        .map(e => {
                                            e.getMembership = () => docs.memberships_doc.find(m => m.id === e.id); 
                                            return e;
                                        });
            league.getExecutives = () => {
                    return docs.memberships_doc
                                .filter(m => league.executives.find(e => e.id === m.id))
                                .map(e => {
                                    e.getMembership = () => docs.memberships_doc.find(m => m.id === e.id); 
                                    return e;
                                })};
            league.getTeams = () => docs.teams_doc.filter(t => t.leagues.map(l => l.id).includes(league.id));
            league.getGyms = () => docs.gyms_doc;
            league.getMessages = () => docs.messages_doc.filter(m => m.from === league.id);
            league.getTournaments = () => {
                let ts = docs.tournaments_doc.filter(m => m.league === league.id);
                for (let t of ts){
                    t.getImage = () => {
                        return docs.images_doc.find(i => i.ref === t.id);
                    };
                }
                return ts;
            };
            league.getAgeGroups = () => {
                let r = docs.age_groups_doc.filter(m => m.league === league.id);
                league.CRCs.getAgeGroups = Utils.getCRC(r);
                return r;
            };
            league.updateAgeGroup = ag => Object.assign(docs.age_groups_doc.find(i => i.id === ag.id) || {}, ag);
            league.addAgeGroup = ag => docs.age_groups_doc.push(ag);
            league.deleteAgeGroup = id => Utils.removeArrayItem(docs.age_groups_doc, {id});
            league.getBrackets = () => docs.brackets_doc.filter(m => m.league === league.id);
            league.getDivisions = () => {
                return docs.divisions_doc.filter(m => m.league === league.id)
                                    .map(d => {
                                        d.getRank = () => docs?.ranks_doc?.find(r => r.id === d.rank);
                                        d.getAgeGroup = () => docs?.age_groups_doc?.find(a => a.id === d.age_group);
                                        d.getWeightClass = () => docs?.weight_classes_doc?.find(w => w.id === d.weight_class);
                                        return d;
                                    });
            };
            league.updateDivision = d => Object.assign(docs.divisions_doc.find(i => i.id === d.id) || {}, d);
            league.addDivision = d => docs.divisions_doc.push(d);
            league.deleteDivision = id => Utils.removeArrayItem(docs.divisions_doc, {id});
            league.getDivisionCombos = () => docs.division_combos_doc.filter(m => m.league === league.id);
            league.addDivisionCombo = dc => docs.division_combos_doc.push(dc);
            league.deleteDivisionCombo = id => Utils.removeArrayItem(docs.division_combos_doc, {id});
            league.getDivisionTags = () => docs.division_tags_doc.filter(m => m.league === league.id);
            league.getGroupScores = () => docs.group_scores_doc.filter(m => m.league === league.id);
            league.updateGroupScore = gs => Object.assign(docs.group_scores_doc.find(i => i.id === gs.id) || {}, gs);
            league.addGroupScore = gs => docs.group_scores_doc.push(gs);
            league.deleteGroupScore = id => Utils.removeArrayItem(docs.group_scores_doc, {id});
            league.getRanks = () => docs.ranks_doc.filter(m => m.league === league.id);
            league.updateRank = r => Object.assign(docs.ranks_doc.find(i => i.id === r.id) || {}, r);
            league.addRank = rank => docs.ranks_doc.push(rank);
            league.deleteRank = id => Utils.removeArrayItem(docs.ranks_doc, {id});
            league.getWeightClasses = () => docs.weight_classes_doc.filter(m => m.league === league.id);
            league.updateWeightClass = wc => Object.assign(docs.weight_classes_doc.find(i => i.id === wc.id) || {}, wc);
            league.addWeightClass = wc => docs.weight_classes_doc.push(wc);
            league.deleteWeightClass = id => Utils.removeArrayItem(docs.weight_classes_doc, {id});
            league.getBanMemberships = () =>docs.memberships_doc.filter(m => (docs.bans_doc||[]).find(b => b.id===m.id));
            league.getBanTeams = () => docs.teams_doc.filter(t => docs.bans_doc.find(b => b.id===t.id));
            league.canRegister = session => {
                if (league.getBanMemberships().find(m => m.id === session.id))
                    return false;
                return session.leagues.find(l => l.id===league.id);
            };
        });
        return leagues;
    }

    static async getMessages(params) {
        const response = await RequestLeague.getLeagueMessagesRequest(params);
        let data = RequestUtils.getResponseData(response);
        const { memberships_doc, messages, images_doc } = data;
        messages.forEach(message => {
            message.getFromDoc = () => {
                let membership = memberships_doc.find(m => m.id === message.from);
                if (membership) {
                    membership.getImage = () => images_doc.find(i => i && i.ref === membership.id);
                    return membership;
                }
            }
        });
        return messages;
    }

    static async sendMessage(params){
        delete params.id;
        params.message_type = 'league';
        let response = await RequestMessage.sendMessageRequest(params);
        let data = RequestUtils.getResponseData(response);
        return data;
    }

    static async disassociateMembership(params) {
        params.registration_type = 'leagues';
        let response = await RequestUser.disassociateGymMembershipRegistrationRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async getLeagueInfo(id) {
        if (!id) {
            return;
        }
        const response = await RequestLeague.getLeagueInfo(id);
        return RequestUtils.getResponseData(response);
    }
}   

export default LeagueModel;