import {
    IonContent, IonPage, useIonViewWillEnter
} from "@ionic/react";
import { useStore } from "../../Store";
import classNames from "classnames";
import pageStyles from "../Page.module.scss";
import { useEffect, useState } from "react";
import LeagueInformationForm from "../../components/LeagueInformationForm/LeagueInformationForm";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import Ranks from "../../components/LeagueInformationForm/Ranks";
import AgeGroups from "../../components/LeagueInformationForm/AgeGroups";
import WeightClasses from "../../components/LeagueInformationForm/WeightClasses";
import Divisions from "../../components/LeagueInformationForm/Divisions";
import GroupScores from "../../components/LeagueInformationForm/GroupScores";
import UserModel from "../../serverUtils/models/UserModel";
import LeagueStoreItems from "./LeagueStoreItems";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { RequestUtils } from "../../serverUtils/requests";
import { Link } from "@mui/material";
import { Tabs } from "../../components/Tabs/Tabs";
import { ENTITY } from "../../serverUtils/Models";

const TABS = [
    { html: <div>Information</div>, url: "information" },
    { html: <div>Age Groups</div>, url: "age_group" },
    { html: <div>Ranks</div>, url: "ranks" },
    { html: <div>Weight Classes</div>, url: "weight_classes" },
    { html: <div>Divisions</div>, url: "divisions" },
    { html: <div>Point System</div>, url: "point_system" },
    { html: <div>Store Items</div>, url: "store_items" }
];

const LeagueEditPage = (props) => {
    const session = useStore(state => state.session);
    const [data, setData] = useState(null);
    const [tabs, setTabs] = useState(TABS);
    const [sectionIndex, setSectionIndex] = useState();

    const getData = async (leagueId) => {
        if (!leagueId) {
            return;
        }
        if (leagueId === 'add') {
            setTabs(TABS.slice(0, 1));
            setSectionIndex(0);
            return setData(UserModel.addSessionAsAdmin(session));
        }
        let league = await LeagueModel.getLeague(leagueId); 
        if (league) {
            UserModel.isAdmin(session, ENTITY.league, league.id) &&
                setData({ ...league });
        }
    }

    useEffect(() => {
        let params = RequestUtils.getURLParameters();
        if (params.tab) {
            setSectionIndex(TABS.findIndex(t => t.url === params.tab));
        }
    }, []);

    let urlId = RequestUtils.getURLId("leagues");
    useEffect(() => {
        if (urlId === 'add') {
            setData(null);
        }
    }, [urlId]);

    useEffect(() => {
        if (session){
            getData(urlId);
        }
    }, [session]);

    useIonViewWillEnter(() => {
        session && getData(RequestUtils.getURLId("leagues"));
    }, [session]);

    return <IonPage>
        <IonContent className={classNames(pageStyles.pageContent)}>
            {!data? <LoadingScreen /> : 
                <div id="edit_league_panel">
                    <h1 id="league_title" className="display-font">
                        {props.match.params.id? `Edit`:'New'} League
                    </h1>
                    {data && data.id && <Link href={`/leagues/${props.match.params.id}`}>
                        {data.name}</Link>}
                    <div className={classNames(pageStyles.pageContent)}>
                        <Tabs
                            index={sectionIndex}
                            tabs={tabs}
                            sections={[
                                <LeagueInformationForm data={data} />,
                                <AgeGroups league={data} />,
                                <Ranks league={data} />,
                                <WeightClasses league={data} />,
                                <Divisions league={data} />,
                                <GroupScores league={data} />,
                                <LeagueStoreItems league={data} session={session} />,
                            ]}
                        />
                    </div>
                </div>}
        </IonContent>
    </IonPage>
}

export default LeagueEditPage;