import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon, IonLabel,
  IonPage,
  useIonViewWillEnter
} from "@ionic/react";
import PageStyles from "../Page.module.scss";
import "./LeaguePage.scss";
import {
  pencil,
  peopleOutline,
  personOutline,
  trophyOutline
} from "ionicons/icons";
import { useState, useEffect } from "react";
import UserChip from "../../components/UserChip/UserChip";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useStore } from "../../Store";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import UserModel from "../../serverUtils/models/UserModel";
import LeagueTournaments from "./LeagueTournaments";
import { useHistory } from "react-router-dom";
import Photos from "../../components/Form/Photos";
import LeagueMemberships from "./LeagueMemberships";
import LeagueStandings from "./LeagueStandings";
import LeagueTeams from "./LeagueTeams";
import CartModel from "../../serverUtils/models/CartModel";
import { RequestUtils } from "../../serverUtils/requests";
import { ENTITY } from "../../serverUtils/Models";
import AlertPane from "../../components/FormInput/AlertPane";
import { Tabs } from "../../components/Tabs/Tabs";
import { PageRequestButtons } from "../PageRequestButtons";
import { STORE_ITEM_TYPE } from "../../serverUtils/models/modelMethods";
import { getFlag } from "../UserPage/UserPage";

const TABS = [
  {
    html: "Tournaments",
    url: "tournaments",
  },
  {
    html: "Standings",
    url: "standings",
  },
  {
    html: "Members",
    url: "members",
  },
  {
    html: "Teams",
    url: "teams",
  },
  {
    html: "Photos",
    url: "photos",
  },
  {
    html: "Store",
    url: "store",
  },
];
const LeaguePage = (props) => {
  const history = useHistory();
  const session = useStore((state) => state.session);
  const setSession = useStore((state) => state.setSession);
  const [league, setLeague] = useState();
  const [message, setMessage] = useState();
  const [sectionIndex, setSectionIndex] = useState();
  const setGlobalMessage = useStore(state => state.setGlobalMessage);
  const [flag, setFlag] = useState();
  const [membershipStatus, setMembershipStatus] = useState();

  const getData = async (id) => {
    let league = await LeagueModel.getLeague(id);
    if (league) {
      let f = await getFlag(league.country);
      setFlag(f && <IonIcon className={`flag`} icon={f} />);
      setLeague(league);
    }
  };

  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(TABS.findIndex((t) => t.url === params.tab));
    }
  }, []);
  
  useEffect(() => {
    if (session && league) {
      getMembershipStatus(league);
    }
  }, [session, league]);

  useIonViewWillEnter(async () => {
    getData(RequestUtils.getURLId("leagues"));
  }, [session]);

  const addToCart = async () => {
    setMessage("");
    let items = [
      {
        sku: league?.id,
        description: `${league?.name} league membership registration`,
        quantity: 0,
        unit_price: league?.registration_fee,
        item_type: STORE_ITEM_TYPE.league_registration,
      },
    ];
    let result = await CartModel.addToCart(
      items,
      session,
      league,
      CartModel.REF_TYPE.league,
      setSession
    );
    if (result && result.id) {
      setTimeout(() => {
        setMessage("");
        history.push("/carts");
      }, 2000);
    } else {
      setMessage("Error updating cart");
    }
  };

  const getMembershipStatus = () => {
    let id = RequestUtils.getURLId('leagues');
    let status = id &&
      UserModel.getMembershipStatus(
        session,
        "leagues",
        id,
        STORE_ITEM_TYPE.league_registration
      );
    if (status) {
      setGlobalMessage({message: status, url: document.location.pathname});
    } 
    setMembershipStatus(status);

    return status;
  };

  let img = league && league?.getImage()?.data?.join('');
  return (
    <IonPage>
      {league ? (
        <IonContent>
          <div className="LeaguePage">
            <div className="page-title">
              <h1 id="league_page">
                {img && <div className="image-icon"><img src={img} /></div>}
                {league && UserModel.isAdmin(session, ENTITY.league, league?.id) && (
                  <IonFab horizontal="end">
                    <IonFabButton className="entity-edit"
                      routerLink={`/leagues/${props.match.params.id}/edit`}
                    >
                      <IonIcon icon={pencil} className="button"/><span>Edit</span>
                    </IonFabButton>
                  </IonFab>
                )}
                {league?.name}
                <span className="entity-type">@league</span>
              </h1>
              <AlertPane message={message} />
            </div>
            <PageRequestButtons
              session={session}
              onClick={() => {
                league &&
                  history.push(
                    `/messages?id=${league.id}&message_type=league&label=${league.name}`
                  );
              }}
              register={!membershipStatus && 
                <button
                  className="button"
                  onClick={async () => {
                    await UserModel.membershipRegistration(
                      LeagueModel,
                      league,
                      session.leagues,
                      setMessage,
                      addToCart
                    );
                  }}
                  fill="solid" color="primary">
                  Request for Membership
                </button>}
            />

            <ul className={'infoSection'}>
              <li className={PageStyles.pageContentInfoItem}>
                <IonIcon
                  slot="icon-only"
                  icon={peopleOutline}
                  className={PageStyles.pageContentInfoIcon}
                />
                <IonLabel className={PageStyles.pageContentInfoLabel}>
                  Country
                </IonLabel>
                <IonLabel></IonLabel>
                <IonLabel className={PageStyles.pageContentInfoValue}>
                  {league?.country}
                  {flag}
                </IonLabel>
              </li>
              <li className={PageStyles.pageContentInfoItem}>
                <IonIcon
                  slot="icon-only"
                  icon={trophyOutline}
                  className={PageStyles.pageContentInfoIcon}
                />
                <IonLabel className={PageStyles.pageContentInfoLabel}>
                  Executives
                </IonLabel>
                <IonLabel></IonLabel>
                <IonLabel className={PageStyles.pageContentInfoValue}>
                  {league &&
                    league.getExecutives &&
                    league
                      .getExecutives()
                      ?.map((ex, i) => (
                        <UserChip key={i} membership={ex.getMembership()} />
                      ))}
                </IonLabel>
              </li>
              <li className={PageStyles.pageContentInfoItem}>
                <IonIcon
                  slot="icon-only"
                  icon={personOutline}
                  className={PageStyles.pageContentInfoIcon}
                />
                <IonLabel className={PageStyles.pageContentInfoLabel}>
                  Admins
                </IonLabel>
                <IonLabel></IonLabel>
                <IonLabel>
                  {league &&
                    league
                      .getAdmins()
                      .map((a, i) => (
                        <UserChip key={i} membership={a.getMembership()} />
                      ))}
                </IonLabel>
              </li>
            </ul>
            <Tabs
              index={sectionIndex}
              tabs={TABS}
              sections={[
                <LeagueTournaments league={league} session={session} />,
                <LeagueStandings league={league} />,
                <LeagueMemberships league={league} />,
                <LeagueTeams league={league} />,
                <Photos
                  entity={league}
                  entity_type={ENTITY.league}
                  model={LeagueModel}
                  session={session}
                />,
                <div className={`section`}>Store</div>,
              ]}
            />
          </div>
        </IonContent>
      ) : (
        <LoadingScreen />
      )}
    </IonPage>
  );
};

export default LeaguePage;
