import { STATUS, hasTableFormChange, includesBlankOption, reloadPage } from "../Form/Form";
import { useEffect, useRef, useState } from "react";
import "./../Form/Form.scss";
import {
  Checkbox,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { RequestLeague, RequestUtils } from "../../serverUtils/requests";
import TableFormInput, { ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/AlertPane";
import { SelectGender, SelectGrapplingType } from "./WeightClasses";
import { validateUniqueCode } from "./Divisions";
import Utils from "../../serverUtils/Utils";
import { AgeGroupSchema } from "../../serverUtils/Models";
export default function AgeGroups({ league }) {
  const [message, setMessage] = useState("");
  return (
    <div className={`AgeGroups sub_form`}>
      <AlertPane message={message} setMessage={setMessage} timeOut={2000}/>
      <AgeGroupTable league={league} setMessage={setMessage} />
    </div>
  );
}

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Age Group",
    align: "left",
    crc: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
    crc: true,
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    align: "left",
    crc: true,
  },
  {
    id: "age_min",
    disablePadding: false,
    label: "Age Minimum",
    align: "left",
    crc: true,
  },
  {
    id: "age_max",
    disablePadding: false,
    label: "Age Maximum",
    align: "left",
    crc: true,
  },
  {
    id: "grappling_types",
    disablePadding: false,
    label: "Grappling Types",
    crc: true,
  },
  {
    id: "genders",
    disablePadding: false,
    label: "Genders",
    crc: true,
  },
];

const alignCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const CRC_Fields = Utils.listObjectKeys(AgeGroupSchema().model);
const AgeGroupTable = ({ league, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const checkCRCMessageRef = useRef();
  const checkCRCMessageRef1 = useRef();
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const checkCRC = () => {
    checkCRCMessageRef.current.innerHTML = '';
    checkCRCMessageRef1.current.innerHTML = '';
    if (hasTableFormChange(league.getAgeGroups(), CRC_Fields)) {
      checkCRCMessageRef.current.innerHTML = 'Data has changed';
      checkCRCMessageRef1.current.innerHTML = 'Data has changed';
      toolbarButtonsRef.current.setIndicator({save: 'yellow'});
      toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
    }else {
      toolbarButtonsRef.current.setIndicator({save: ''});
      toolbarButtonsRef1.current.setIndicator({save: ''});
    }
  }
  useEffect(() => league.getAgeGroups && league.getAgeGroups().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields))), []);
  const save = async () => {
    setMessage("");
    let selects = league.getAgeGroups();
    if (selects.length === 0) {
      return setMessage("info: Nothing selected.");
    }
    if (
      selects.find(
        (s) => !s.name || !s.code || !s.status || !s.age_min || !s.age_max
      )
    ) {
      return setMessage(
        "error: Age group, code, age min, age max and status must be filled."
      );
    }
    for (let r of selects) {
      r.status = r.status || 'A';
      r.grappling_types = !r.grappling_types || r.grappling_types.length===0? ['gi']:r.grappling_types;
      r.genders = !r.genders || r.genders.length===0? ['M']:r.genders;
      let response;
      if (!r.id.startsWith("-1")) {
        response = await RequestLeague.updateLeagueAgeGroupRequest(r);
      } else {
        r.league = league.id;
        response = await RequestLeague.addLeagueAgeGroupRequest(r);
      }
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage("error: Updating age group " + r.name);
      } else if (!r.id) {
        r.id = response.id;
      }
      r.crc = Utils.getCRC(r, CRC_Fields);
    }
    tableRef.current.setSelected([]);
    setMessage("success: Successfully updated server.");
    checkCRC();
  };

  const deleteSelected = async () => {
    setMessage("");
    let selects = league
      .getAgeGroups()
      .filter((r) => tableRef.current.selected.includes(r.id));
    if (selects.length === 0) {
      return setMessage("info: Nothing selected.");
    }
    for (let r of selects) {
      if (r.id.startsWith("-")) {
        continue;
      }
      let response = await RequestLeague.deleteLeagueAgeGroupRequest(r.id);
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage("error: Deleting age group " + r.name);
      }
    }
    let ageGroups = league
      .getAgeGroups()
      .filter((r) => !tableRef.current.selected.includes(r.id));
    league.getAgeGroups = () => ageGroups;
    tableRef.current.setSelected([]);
    setMessage("success: Successfully deleted from server.");
  };

  const doAdd = () => {
    let ageGroups = [
      ...league.getAgeGroups(),
      { id: `-${new Date().getTime()}`, name: "", league: league.id, status: 'A' },
    ];
    league.getAgeGroups = () => ageGroups;
    reloadPage(setRefresh, () => tableRef.current.gotoLastPage());
  };

  const toolbarButtons = ref => (
    <ToolbarButtons ref={ref} doAdd={doAdd} doSave={save} doDelete={deleteSelected} />
  );
  return (
    <TableFormInput name="AgeGroups"
      onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
      onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
      toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
      toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
      isEditable
      ref={tableRef}
      headCells={headCells}
      data={(league.getAgeGroups && league.getAgeGroups()) || []}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <TableRow
            className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
            hover
            onClick={event => {
              event.stopPropagation();
              if (event.target.tagName.toUpperCase() === "INPUT") {
                return handleClick(event, row.id);
              }
            }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div style={alignCenter}>
                <Checkbox 
                  color="primary"
                  checked={isItemSelected ? true : false}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
                <span>{index + 1}</span>
              </div>
            </TableCell>
            <TableCell>
              <Tooltip title={row.name}>
                <Input defaultValue={row.name} 
                  onChange={e => {
                    row.name = e.target.value;
                    checkCRC();
                  }}
                  onClick={e => e.stopPropagation()}/>
              </Tooltip>
              
            </TableCell>
            <TableCell>
              <Select value={row.status || ""} 
                onChange={e => {
                  row.status = e.target.value;
                  checkCRC();
                  setRefresh(!refresh);
                }}
                onClick={e => e.stopPropagation()}>
                {includesBlankOption(STATUS).map((s, i) => (
                    <MenuItem key={i} value={s.value}>
                      {s.label}
                    </MenuItem>
                  ))}
                  ;
              </Select>
            </TableCell>
            <TableCell>
              <CodeInput row={row} list={league.getAgeGroups()} checkCRC={checkCRC}/>
            </TableCell>
            <TableCell>
              <Input onClick={e => e.stopPropagation()}
                onChange={e => {
                  row.age_min = e.target.value;
                  checkCRC();
                }}
                type="number"
                defaultValue={row.age_min}
              />
            </TableCell>
            <TableCell>
              <Input onClick={e => e.stopPropagation()}
                onChange={e => {
                  row.age_max = e.target.value;
                  checkCRC();
                }}
                type="number"
                defaultValue={row.age_max}
              />
            </TableCell>
            <TableCell>
              <SelectGrapplingType row={row} 
                setRefresh={() => {
                  setRefresh(!refresh);
                  checkCRC();
                }}/>
            </TableCell>
            <TableCell>
              <SelectGender row={row} 
                setRefresh={() => {
                  setRefresh(!refresh);
                }}
                checkCRC={checkCRC}
              />
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
};

export const getAgeGroupDisplay = (ag) => {
  if (!ag) {
    return "";
  }
  return `[${ag.age_min || 0}-${ag.age_max || 0} yrs]`;
};

export const CodeInput = ({row, list, checkCRC}) => {
  const [message, setMessage] = useState('');
  return <div className="CodeInput">
    <Tooltip title={row.code}>
      <Input defaultValue={row.code} className="capitalize"
        onChange={e => {
          row.code = e.target.value.toUpperCase();
          if (!validateUniqueCode(list.map(r => r.code))) {
            setMessage('duplicated');
          }else {
            setMessage('');
          }
          checkCRC();
        }}
        onClick={e => e.stopPropagation()}/>
    </Tooltip>
    <span className="warning">{message}</span>
  </div>;
}
