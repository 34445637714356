
export const STORE_ITEM_TYPE = {
    tournament_combo_pricing: 'tournament_combo_pricing',
    tournament_registration: 'tournament_registration',
    league_registration: 'league_registration',
    gym_registration: 'gym_registration',
    team_registration: 'team_registration',
    store_item: 'store_item',
    league: 'league',
    gym: 'gym',
    team: 'team',
    tournament: 'tournament'
};

export const PROMO_RULE_TYPES = {
    no_selection: "",
    age_range: "age_range",
    weight_range: "weight_range",
    fee_range: "fee_range",
    gender: "gender",
    ranks: "ranks",
    memberships: "memberships",
    divisions: "divisions",
    teams: "teams",
    registered_date_range: "registered_date_range",
}
export const DISCOUNT_TYPES = [{ value: "$" }, { value: "%" }];

const sumArray = (numbers) => {
    return numbers.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);
}

const intersection = (arrA, arrB) => {
    return arrA.filter(x => arrB.includes(x));
}

export function validatePromo({ cart, session, promo }) {
    let logics = [];
    let regItems = cart.items.filter(i => i.item_type === STORE_ITEM_TYPE.tournament_registration);
    const validate = () => {
        for (let r of promo.rules) {
            let v = decodeURIComponent(r.value);
            if (r.rule_type === PROMO_RULE_TYPES.age_range) {
                let value = JSON.parse(v);
                logics.push({ value: value.start <= session.age && session.age <= value.end, logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.fee_range) {
                let total = sumArray(regItems.map(i => i.unit_price));
                let value = JSON.parse(v);
                logics.push({ value: value.start <= total && total <= value.end, logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.weight_range) {
                let value = JSON.parse(v);
                logics.push({ value: value.start <= session.weight && session.weight <= value.end, logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.registered_date_range) {
                let ts = new Date().getTime();
                let value = JSON.parse(v);
                logics.push({ value: value.start <= ts && ts <= value.end, logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.divisions) {
                let value = JSON.parse(v);
                logics.push({ value: intersection(value, regItems(i => i.sku)).length > 0, logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.gender) {
                logics.push({ value: v === session.gender, logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.memberships) {
                let value = JSON.parse(v);
                logics.push({ value: value.map(v => v.value).includes(session.id), logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.ranks) {
                let value = JSON.parse(v);
                logics.push({ value: value.includes(session.gi_level), logic: r.logic });
            } else if (r.rule_type === PROMO_RULE_TYPES.teams) {
                let value = JSON.parse(v);
                logics.push({ value: value.includes(session.getTeam() && session.getTeam().id), logic: r.logic });
            }
        }

        if (logics.length === 0) {
            return;
        }
        let c = logics.shift();
        if (logics.length === 0) {
            return c.value;
        }
        let result;
        for (let l of logics) {
            result = eval(`${c.value} ${c.logic === 'AND' ? '&&' : '||'} ${l.value}`);
            c = l;
        }
        return result;
    }
    return validate();
}

