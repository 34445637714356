import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormStyles from "./../Form/Form.module.scss";
import { default as AddIcon } from "@mui/icons-material/AddCircle";

import TextFormInput from "../FormInput/TextFormInput";
import ImageFormInput from "../FormInput/ImageFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import {
  CURRENCIES,
  FormActionButtons,
  handleSubListAdd,
  IMAGE_TYPE,
  isEmailFormatValid,
  STATUS,
  stopFormSubmitByEnter
} from "../Form/Form";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import UserModel from "../../serverUtils/models/UserModel";
import GymModel from "../../serverUtils/models/GymModel";
import { useHistory } from "react-router-dom";
import ContactInfos from "../Form/ContactInfos";
import SocialMedias from "../Form/SocialMedias";
import ImageModel from "../../serverUtils/models/ImageModel";
import MembershipTypes from "../Form/MembershipTypes";
import PaymentInfos, { GIHTransactionFee } from "../Form/PaymentInfos";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import { useStore } from "../../Store";
import { AddressForm } from "../Form/AddressForm";
import "./GymInformationForm.scss";

export default function GymInformationForm({ gym }) {
  const session = useStore((state) => state.session);
  const setSession = useStore((state) => state.setSession);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
    getValues,
    getFieldState,
  } = useForm();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState(gym || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (gym) {
      setValue("admins", gym.admins);
      setValue("head_coaches", gym.head_coaches);
      setValue("executives", gym.executives);
      setValue("contact_infos", gym.contact_infos);
      setValue("social_medias", gym.social_medias);
      setValue("name", gym.name);
      setValue("city", gym.city);
      setValue("email", gym.email);
      setFormData({ ...gym });
    }
  }, [gym]);

  const onSubmit = async (_d) => {
    if (isSubmitting) {
      return;
    }
    setMessage("");
    setIsSubmitting(true);
    try {
      console.log(_d);
      let d = { ..._d, id: formData.id };
      let banner = d.banner;
      delete d.banner;
      let result = await GymModel[d.id ? "updateGym" : "addGym"](d);
      if (result && result.id) {
        d.id = result.id;
        if (banner) {
          await ImageModel.addEntityImage(banner, d, IMAGE_TYPE.gym);
          formData.getImage &&
            formData.getImage()?.id &&
            (await ImageModel.deleteImage(formData.getImage().id));
          setFormData({ ...d });
        }
        UserModel.updateAdminInfo(session, setSession, "gyms", {
          id: result.id,
          name: formData.name,
        });
        setMessage(
          `success: Successfully ${formData.id ? "updated" : "added"} gym to server.`
        );

        return !formData.id && setTimeout(() => history.push(`/gyms/${result.id}`), 2000);
      }
      setMessage(`error: Error ${formData.id ? "updating" : "adding"} gym to server.`);
    } finally {
      setIsSubmitting(false);
    }
  };
  const doDelete = async () => {
    if (isSubmitting) {
      return;
    }
    try {
      let result = await GymModel.deleteGym(formData.id);
      if (result && result.id) {
        setMessage("success: Successfully deleted gym.");
        return setTimeout(() => {
          document.location.href = "/gyms";
        }, 1000);
      }
      setMessage("error: Error deleting gym.");
    }finally {
      setIsSubmitting(false);
    }
  };

  const onImage = (d) => {
    console.log('GymInformation')
    setValue("banner", d, { shouldDirty: true });
  }
  return formData && 
    <div className="GymInformationForm">
      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        {session && session.is_super && <GIHTransactionFee register={register} formData={formData} />}
        <div className={FormStyles.fieldDescription}>
          <h3>Banner</h3>
        </div>
        <div className={FormStyles.fields}>
          <ImageFormInput
            name="banner"
            images={ImageModel.setImageFormInput(
              IMAGE_TYPE.gym,
              getValues,
              getFieldState,
              formData,
              "banner"
            )}
            size={600} maxWidth={200}
            onImage={onImage}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>General Information</h3>
          <p>This is the name of the tournament.</p>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="Gym Name"
            isRequired
            register={register} setValue={setValue}
          />
          <SelectFormInput
            value={formData?.status || ''}
            name="status"
            label="Gym Status"
            isRequired
            setValue={setValue}
            placeholder="Select status"
            options={STATUS}
          />
          <TextFormInput
            value={formData?.email}
            type="email"
            name="email"
            label="Email"
            register={register} setValue={setValue}
            isRequired validateWithTrim
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>Address</h3>
        </div>
        <AddressForm
          address={{
            country: formData.country,
            state: formData.state,
            address: formData.address,
            city: formData.city,
            zip: formData.zip,
            suburb: formData.suburb,
            region: formData.region,
          }}
          isRequired={['city']}
          register={register} setValue={setValue}
        />
        <div className={FormStyles.fieldDescription}>
          <h3>Executives</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            value={formData.executives || []}
            name="executives"
            label="Executives"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Head Coaches</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            value={getValues("head_coaches") || formData.head_coaches}
            name="head_coaches"
            label="Head Coaches"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => UserModel.searchUser(searchVal)}
            setValue={setValue}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Admins</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            isRequired
            control={control}
            value={getValues("admins") || formData.admins}
            name="admins"
            label="Admins"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => UserModel.searchUser(searchVal)}
            setValue={setValue}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>
            Contacts
            <AddIcon
              id="add_contacts"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "contact_infos";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Set contact information for this league</p>
        </div>
        <div className={FormStyles.fields}>
          <ContactInfos
            data={getValues("contact_infos") || formData.contact_infos}
            register={register} setValue={setValue}
            errors={errors} setError={setError}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Social Medias
            <AddIcon
              id="add_social_medias"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "social_medias";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Set social medias of this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <SocialMedias
            data={getValues("social_medias") || formData.social_medias}
            register={register} setValue={setValue}
            errors={errors.social_medias}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Membership Type
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() =>
                handleSubListAdd(setFormData, formData, "membership_type")
              }
            />
          </h3>
        </div>
        <div className={FormStyles.fields}>
          <MembershipTypes
            errors={errors?.membership_type}
            setFormData={setFormData}
            membership_type={formData.membership_type}
            register={register}
            setValue={setValue}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Registration Fee</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={gym?.registration_fee}
            name="registration_fee"
            label="Registration Fee"
            register={register} setValue={setValue}
            type="float"
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Currency</h3>
        </div>
        <div className={FormStyles.fields}>
          <SelectFormInput width={200}
            name="currency"
            label="Currency"
            isRequired
            value={gym?.currency || ''}
            setValue={setValue}
            placeholder="Currency"
            options={CURRENCIES}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Payment Information
            <AddIcon
              id="add_payment_info"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "payment_info";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Enter your payment gateway information</p>
        </div>
        <div className={FormStyles.fields}>
          <PaymentInfos
            data={getValues("payment_info") || formData.payment_info}
            register={register} setValue={setValue}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}></div>
        <div className={FormStyles.fields}>
          <CheckboxFormInput
            label="Auto Membership Approve"
            name="is_auto_approve"
            value={formData.is_auto_approve}
            register={register}
            setValue={setValue}
            onChange={v => formData.is_auto_approve = v}
          />
        </div>
        <div className={FormStyles.fieldDescription}/>
        <FormActionButtons doDelete={doDelete} message={message} id={formData.id} setMessage={setMessage}/>
      </form>
    </div>;
}
