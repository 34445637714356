import { Alert } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Registrations from "../../components/TournamentForm/Registrations";
import LocalServerModel from "../../serverUtils/models/LocalServerModel";
import Utils from "../../serverUtils/Utils";
import { useStore } from "../../Store";
import { duplicate_station_error, EmptyStation, getStationName, initStation, isMaster } from "./LocalServer";
import './LocalServer.scss';
import { useHistory } from 'react-router-dom';

let sse;
export const LocalWeightIn = forwardRef(({ }, ref) => {
  const history = useHistory();
  const [isInit, setIsInit] = useState(false);
  const station = useStore(state => state.station);
  const setStation = useStore(state => state.setStation);
  const [message, setMessage] = useState();
  const localServer = useStore(state => state.local_server);
  const tournament = useStore(state => state.local_tournament);
  const setTournament = useStore(state => state.setLocalTournament);
  const [refresh, setRefresh] = useState(false);
  const [notification, setNotification] = useState();
  const [redirect, setRedirect] = useState();

  useImperativeHandle(ref, () => ({
    setNotification,
    getData: () => tournament.getRegistrations(),
    updateData,
  }));

  const updateData = async (registrations, tid) => {
    let t = tournament;
    if (!t) {
      t = await LocalServerModel.getTournament(tid);
    }
    if (Array.isArray(registrations)) {
      let regs = [];
      for (let reg of registrations) {
        let found = t.getRegistrations().find(r => r.id === reg.id);
        regs.push({ ...found, ...reg });
      }
      t.getRegistrations = () => regs;
    } else {
      let found = t.getRegistrations().find(e => e.id === registrations.id);
      Object.assign(found, registrations);
    }
    setTournament(t);
  }

  const receive = (message) => {
    if (message.to !== LocalServerModel.LOCAL_SERVER.ping && message.to !== station.station) {
      return;
    }

    if (message === duplicate_station_error) {
      setMessage('Duplicate station: ' + Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station))
    }
    const { update, to, from, notification, raw, tournament_id, type } = message;
    if (from === station.station) {
      return;
    }
    if (to === LocalServerModel.LOCAL_SERVER.ping) {
      return LocalServerModel.sendMessage({
        tournament_id,
        from: station.station, to,
        type: LocalServerModel.STATION_TYPE.weight_in,
      });
    }
    if (to && to !== station.station) {
      return;
    }
    console.log(
      '\nreceive:',
      Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'),
      '\nmessage:',
      message
    );
    Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament, tournament_id);
    if (type === LocalServerModel.STATION_TYPE.weight_in && raw) {
      return updateData(JSON.parse(raw), tournament_id);
    }
    if (LocalServerModel.setCurrentStation(message, station, setStation, setRedirect)) {
      sse.close();
      return;
    };
    if (notification) {
      return setNotification(notification);
    }

  };

  const initSSE = async () => {
    setIsInit(true);
    sse && sse.close();
    sse = initStation({
      localServer,
      station_type: LocalServerModel.STATION_TYPE.weight_in,
      receive,
      history,
      forceUpdate: () => setRefresh(!refresh)
    });
  };

  useEffect(() => {
    station && station.type === LocalServerModel.STATION_TYPE.weight_in && initSSE();
  }, [localServer, station]);

  return redirect? redirect :
    <div className="LocalWeightIn">
      <h1>Weight In Station {getStationName(station && station.label)}</h1>
      {!(
        localServer &&
        station && !isMaster() &&
        tournament
      ) ?
        <EmptyStation
          type={LocalServerModel.STATION_TYPE.weight_in}
          setStation={(v) => {
            setStation(v);
          }}
          station={station}
        /> :
        <div className="Registration-wrapper">
          <Registrations isWeighInStation={true}
            tournament={tournament}
            isEditMode={false}
            isDense={true}
            onUpdate={row => {
              LocalServerModel.sendMessage({
                tournament_id: tournament.id,
                from: station.station,
                type: LocalServerModel.STATION_TYPE.weight_in,
                raw: JSON.stringify(Utils.removeObjectUnsetFields(row))
              });
            }} />
        </div>
      }
      {notification && <Alert severity='info'>{notification}</Alert>}
    </div>
  ;
});