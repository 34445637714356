import { useState, useRef, useEffect } from 'react';
import { StoreItems, STORE_ITEMS_HEAD_CELLS } from '../../pages/LeagueEditPage/LeagueStoreItems';
import CartModel from '../../serverUtils/models/CartModel';
import GymModel from '../../serverUtils/models/GymModel';
import TableFormInput, { ToolbarButtons } from '../../components/FormInput/TableFormInput';
import LoadingList from '../../components/LoadingList/LoadingList';
import AlertPane from '../../components/FormInput/AlertPane';

export default function GymStoreItems({ gym }) {
    const [data, setData] = useState();
    const [message, setMessage] = useState('');
    const getData = async () => {
        let storeItems = await GymModel.getStoreItems(gym.id);
        setData(storeItems?.filter(r => r));
    }
    useEffect(() => {
        gym && getData();
    }, [gym]);

    const tableRef = useRef();

    const doSave = async () => {
        return CartModel.storeItemsSave(setMessage, data, tableRef);
    }

    const doNew = () => {
        CartModel.newStoreItem(data, setData, 'gym', gym.id);
    };

    const doDelete = async () => {
        return CartModel.storeItemDelete(setMessage, data, tableRef, setData);
    }

    const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
        
    }

    const tableComparator = (a, b, orderBy) => {
        let _a, _b;
        if (orderBy === 'images') {
            return 0;
        } else {
            _a = a[orderBy];
            _b = b[orderBy];
        }

        _a = _a ? _a.toString() : '';
        _b = _b ? _b.toString() : '';
        if (_b < _a) {
            return -1;
        }
        if (_b > _a) {
            return 1;
        }
        return 0;
    }


    const toolbarButtons = () => <ToolbarButtons doAdd={doNew} doDelete={doDelete} doSave={doSave} />
    return !data ? <LoadingList /> :
        <div className="GymStoreItems">
            <AlertPane message={message} />
            <TableFormInput name="GymStoreItems"
                isEditable
                ref={tableRef}
                headCells={STORE_ITEMS_HEAD_CELLS}
                data={data}
                getFilterData={getFilterData}
                comparator={tableComparator}
                toolbarButtons={toolbarButtons}
                renderTRow={({
                    row,
                    isSelected,
                    index,
                    handleClick,
                }) => {
                    return <StoreItems row={row} key={index}
                        isSelected={isSelected}
                        index={index}
                        handleClick={handleClick}
                        data={data} />
                }}
            />
        </div>
}