import { IonButton, IonIcon } from "@ionic/react";
import Tooltip from "@mui/material/Tooltip";

export const createButton = ({ onClick, icon, title, isHide }) => {
  return (
    !isHide && (
      <Tooltip title={title || ""}>
        <IonButton onClick={onClick}>
          <IonIcon slot="icon-only" icon={icon} />
        </IonButton>
      </Tooltip>
    )
  );
};

export const isMobile = () => {
  return navigator.userAgent.match(
    /(Mobile)|(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );
};


export const STATUS = {
  Active: "A",
  Pending: "P",
  Delete: "D",
  Suspended: "S",
  Register: "R",
};

export const STATUS_OPTIONS = Object.keys(STATUS).map(k => ({
  value: STATUS[k],
  label: k,
}));

export const STATUS_LABEL = s => {
  let o = STATUS_OPTIONS.find(o => o.value === s);
  return (o && o.label) || '';
}

export const BRACKET = {
  Round_Robin: "rr",
  Single_Elimination: "se",
  Double_Elimination_Loser_3RD: "de",
  Double_Elimination_Loser_2ND: "de_l2",
  Double_Elimination_Loser_1ST: "de_l1",
};
export const BRACKET_TYPES = Object.keys(BRACKET).map((k) => ({
  value: BRACKET[k],
  label: k.replace(/_/g, " "),
}));

BRACKET.getBracketName = (code) => {
  return BRACKET_TYPES.find((b) => b.value === code)?.label;
};

const MAX_DISPLAY_LEVEL = 2;
const GROUP_SIZE = 8;
const WORD_EXPORT = {
  tournament: "TOUR_TITLEXXXXX",
  division: "DIVISION_HEADER_NAMEXXXXX",
  position: "XXXXX",
  place: "XXXXX",
};
export const mapDivisionsToPoolings = (poolings, getRegistrations) => {
  return poolings
    .filter((p) => p.pool)
    .map((p) => ({
      id: p.pool,
      name: p.pool,
      bracket_type: p.bracket_type,
      getRegistrations: () =>
        getRegistrations().filter((r) => r.pool.split("|").includes(p.pool)),
      getSchedules: () => p.schedules || [],
      getBracketType: () => p.bracket_type,
    }));
};

