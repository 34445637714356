import TournamentPageStyles from './TournamentPage.module.scss';
import './Schedules.scss';
import { useEffect, useRef, useState } from "react";
import {useHistory} from "react-router-dom";
import Utils from "../../serverUtils/Utils";
import UserModel from "../../serverUtils/models/UserModel";
import Theme from "../../components/FormInput/Theme";
import { renderAvatar } from "../../components/UserChip/UserChip";
import { default as LikeIcon } from "@mui/icons-material/FavoriteBorderOutlined";
import { default as FullScreenIcon } from '@mui/icons-material/Fullscreen';
import { DivisionFilter } from "./TournamentPage";
import Icon from "../../components/Icon";
import { ThemeProvider } from "@mui/material";
import { getPrevBracketEntries } from "../../components/Bracket/BracketWrapper";

export default function Schedules({ tournament, schedules, tabsRef }) {
    const history = useHistory();
    const [filter, setFilter] = useState();
    const [tournamentSchedules, setTournamentSchedules] = useState();
    const [bracketEntries, setBracketEntries] = useState();
    const [filterSchedules, setFilterSchedules] = useState([]);
    const fullscreenRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState();

    useEffect(() => {
        if (tournament){
            tournament.getBracketEntries().then(bes => setBracketEntries(bes));
            tournament.getSchedules().then(ss => setTournamentSchedules(ss));
        }
    }, [tournament]);
    useEffect(() => {
        if (filter && tournamentSchedules) {
            let fss = tournamentSchedules.filter(s => {
                return s.getDivision().id === filter;
            });
            setFilterSchedules(fss);
        }
    }, [filter, tournamentSchedules]);

    const RenderCompetitor = ({ membership }) => {
        const getGymName = () => {
            let g = membership && membership.getGym();
            return g? g.name : '';
        }
        return !membership? '':
            <div className={TournamentPageStyles.avatar}>
                <div>{renderAvatar(membership)}</div>
                <span>{UserModel.getMembershipName(membership)}</span>
                <span className="object-tag">{getGymName()}</span>
            </div>;
    }

    const fullscreen = () => {
        if (fullscreenRef.current) {
            setIsFullscreen(true);
            Utils.toggleFullscreen(fullscreenRef.current, () => setIsFullscreen(false));
        }
    }

    const getDivName = () => {
        let d = tournament && tournament.getAvailableDivisions().find(d => d.id === filter);
        return d && d.name;
    }
    return <ThemeProvider theme={Theme} >
        <div className="Schedules">
            <DivisionFilter tournament={tournament} onSelect={f => setFilter(f)} />
            <div className="fullscreen" ref={fullscreenRef}>
                <div className="title">
                    <FullScreenIcon className="FullScreenIcon" onClick={fullscreen}/>
                    <h3>{getDivName()}</h3>
                    <button className="button icon_button" 
                        onClick={() => {
                            history.push(`/tournaments/${tournament.id}?tab=brackets&id=${filter}`);
                            tabsRef.current.setActiveTabByName('brackets');
                        }}><Icon name="bracket" /></button>
                </div>
                <div className={`results`}>
                    {filterSchedules.filter(s => s.bracket_entry1 && s.bracket_entry2).map((s, i) => {
                        const { getBracketEntry1, getBracketEntry2, status, division } = s;
                        try{
                            let b = getBracketEntry1().getMembership();
                            let o = getBracketEntry2().getMembership();
                            let winner = getBracketEntry1().result ? b : (getBracketEntry2().result ? o:null);
                            if (getBracketEntry1().result && getBracketEntry2().result) {
                                winner = bracketEntries.filter(be => be.division===s.division)
                                    .find(be => be.round===getBracketEntry1().round+1 && [b.id, o.id].includes(be.membership));
                            }
                            return <div key={i} className={`result`}>
                                <div className={`scheduleMat`}>
                                    <b>Mat {s.mat || ''}</b>
                                    <LikeIcon />
                                </div>
                                <div className={`resultHeader`}>
                                    <RenderCompetitor membership={b} />
                                    <b>{status}</b>
                                    <RenderCompetitor membership={o} />
                                </div>
                                <div className="round">
                                    Round: <b>{getBracketEntry1().round + 1}</b>
                                </div>
                                <div className={`winner`}>
                                    Winner: <b>{UserModel.getMembershipName(winner)}</b>
                                </div>
                                <span className={`time`}>
                                    Time: <b>{s.start_date && Utils.formatDateTime(s.start_date)}</b></span>
                                <span className={`duration`}>
                                    Time: <b>{s.duration || ''}</b></span>
                            </div>
                        } catch (e) {
                            console.log(e)
                        }
                    })}
                </div>
            </div>
        </div>
    </ThemeProvider>
}