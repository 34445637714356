import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import Theme from "./Theme";
import Utils from "../../serverUtils/Utils";

const SelectFormInput = forwardRef(({
  value,
  name,
  label,
  setValue,
  options,
  onChange,
  onMouseLeave,
  disabled = false,
  width,
  isSortOption,
  onRenderComplete,
  onOptionClick,
}, ref) => {
  const [inputValue, setInputValue] = useState();
  const [open, setOpen] = useState(false);
  const selectRef = useRef();

  useImperativeHandle(ref, () => ({
    selectRef: selectRef.current,
    setInputValue,
    setOpen,
  }));

  useEffect(() => {
    inputValue && setValue && setValue(name, inputValue||'');
    onRenderComplete && onRenderComplete();
  }, [inputValue]);

  useEffect(() => {
    let v = value;
    if ((!v || !inOptions(v)) && !inputValue && options.length > 0){
      v = options[0].value;
    }else if (v && !inOptions(v) && options.length > 0){
      v = options[0].value;
    }
    setInputValue(v || '');
    // onChange && onChange(v);
  }, [value, options]);
  
  const inOptions = (v) => {
    return options.length>0 && options.find(o => o.value === v);
  }

  const handleChange = (e) => {
    let v = e.target.value;
    setInputValue(v);
    setValue && setValue(name, v || '');
    onChange && onChange(v);
    handleClose();
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  let _options = options || [];
  if (isSortOption) {
    _options = _options.sort((a, b) => Utils.sort(a.label, b.value));
  }
  // console.log(name, inputValue);
  return <div className={`SelectFormInput ${(name||'').split('.').pop()}`} >
    <ThemeProvider theme={Theme}>
    <FormControl margin="normal" disabled={disabled? true:false} style={{ width: (Utils.isMobile() || !width) ? '100%' : width }}>
        <InputLabel>
          {label||''}
        </InputLabel>
      <Select 
        // {..._register}
        labelId={name}
        label={label||''}
        name={name}
        value={inOptions(inputValue)? inputValue : ''}
        open={open}
        onClose={!disabled? handleClose:undefined}
        onOpen={!disabled? handleOpen:undefined}
        onChange={!disabled? handleChange:undefined}
        onClick={(e) => {
          e.stopPropagation();
          if (!open) {
            !disabled && handleOpen();
          }
        }}
        onMouseLeave={onMouseLeave}
        ref={selectRef}
      >
        {_options?.map((option, i) => (
          <MenuItem key={i} value={option.value} 
            style={{ whiteSpace: 'normal' }} 
            onClick={e => {
              e.stopPropagation();
              onOptionClick && onOptionClick(option);
            }}>
            <span className="menu-item">{option.label || option.value}</span>
          </MenuItem>
        ))}
      </Select>
      </FormControl>
    </ThemeProvider>
  </div>;
});

export default SelectFormInput;
