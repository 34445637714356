import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IonSpinner
} from "@ionic/react";
import { FormActionButtons, IMAGE_TYPE, MONTH_OPTIONS, RemoveSubFormIcon, hasTableFormChange, stopFormSubmitByEnter } from "../Form/Form";
import FormStyles from "./../Form/Form.module.scss";
import TextFormInput from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import { STATUS, CURRENCIES } from "../Form/Form";
import Utils from "../../serverUtils/Utils";
import PaymentInfos, { GIHTransactionFee } from "../Form/PaymentInfos";
import { AddressForm } from "../Form/AddressForm";
import ImageFormInput from "../FormInput/ImageFormInput";
import SocialMedias from "../Form/SocialMedias";
import ContactInfos from "../Form/ContactInfos";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import { useHistory } from "react-router-dom";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import UserModel from "../../serverUtils/models/UserModel";
import ImageModel from "../../serverUtils/models/ImageModel";
import MembershipTypes from "../Form/MembershipTypes";
import { useStore } from "../../Store";
import { default as AddIcon } from '@mui/icons-material/AddCircleOutline';
import "./LeagueInformationForm.scss";
import { PayPal } from "../../pages/CartsPage/CartPage";
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LeagueSchema } from "../../serverUtils/Models";
import AlertPane from "../FormInput/AlertPane";
import { ThemeProvider } from "@emotion/react";
import Theme from "../FormInput/Theme";
import { BYE, WINS_BY_OPTIONS } from "../Bracket/bracketUtils";
import { default as CollapseIcon } from '@mui/icons-material/ArrowRight';
import { default as ExpandIcon } from '@mui/icons-material/ArrowDropDown';
import CheckboxFormInput from "../FormInput/CheckboxFormInput";

const CRC_Fields = Utils.listObjectKeys(LeagueSchema().model);
export default function LeagueInformationForm({ data }) {
  const session = useStore(state => state.session);
  const setSession = useStore((state) => state.setSession);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
    getValues,
    getFieldState,
  } = useForm();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState(data || {});
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [isBuyCredits, setIsBuyCredits] = useState(false);
  const checkCRCTimeout = useRef();
  const [hasChange, setHasChange] = useState(false);
  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...data, ...getValues()};
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }
  

  const onSubmit = async (_d) => {
    if (isSubmitting) {
      return;
    }
    setMessage("");
    setIsSubmitting(true);
    try {
      console.log("onSubmit:", _d);
      let d = { ..._d, id: formData.id };
      let banner = d.banner;
      delete d.banner;
      let isAdd = !d.id;
      let result = await LeagueModel.updateLeague(d);
      if (result && result.id) {
        d.id = result.id;
        if (banner) {
          await ImageModel.addEntityImage(banner, d, IMAGE_TYPE.league);
          formData.getImage &&
          formData.getImage()?.id &&
            (await ImageModel.deleteImage(formData.getImage().id));
          setFormData({ ...d });
        }
        UserModel.updateAdminInfo(session, setSession, "leagues", {
          id: result.id,
          name: formData.name,
        });
        setMessage(`success: Successfully ${isAdd? 'added':'updated'} league to server.`);
        return setTimeout(() => history.push(`/leagues/${result.id}`), 2000);
      }
      setMessage(`error: Error ${isAdd? 'adding':'updating'} league to server.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const doCopy = async () => {
    if (isSubmitting) {
      return;
    }

    try{
      setMessage("");
      setIsSubmitting("clone");
      let result = await LeagueModel.cloneLeague(data.id);
      if (result && !result.error && result.id){
        setMessage("success: Successfully clone league.");
        setTimeout(
          () => history.push(`/leagues/${result.id}/edit`),
          2000
        );
      }else {
        setMessage("error: Error cloning league.");
      }
    }finally {
      setIsSubmitting(null);
    }
  }

  const doDelete = async () => {
    if (isSubmitting) {
      return;
    }
    setMessage('');
    if (formData.getDivisions().length > 0 || 
        formData.getRanks().length > 0 || 
        formData.getWeightClasses().length > 0 || 
        formData.getGroupScores().length > 0){
      return setMessage('error: All league resources such as division, rank, and weight class must be clear');
    }

    setIsSubmitting('delete');
    try{
      let result = await LeagueModel.deleteLeague(formData.id);
      if (result && result.id) {
        setMessage("success: Successfully deleted league.");
        return setTimeout(() => {
          document.location.href = "/leagues";
        }, 1000);
      }
      setMessage("error: Error deleting league.");
    }finally {
      setIsSubmitting(null)
    }
  };

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      setValue("admins", data.admins);
      setValue("executives", data.executives);
      setValue("contact_infos", data.contact_infos);
      setValue("social_medias", data.social_medias);
      setFormData(data);
      data.crc = Utils.getCRC(data, CRC_Fields)
    }
  }, [data]);

  const onImage = (d) => {
    console.log('LeagueInformation')
    setValue("banner", d, { shouldDirty: true });
    checkCRC();
  }

  const BuyTransactionCredits = ({onDone}) => {
    const CREDIT_OPTIONS = [10, 25, 50, 75, 100, 125, 150, 300];
    const DEFAULT_CREDITS = CREDIT_OPTIONS[0];
    const DEFAULT_TRANS_FEE = 1.0;
    const [transFee, setTransFee] = useState(DEFAULT_TRANS_FEE);
    const [isPayPal, setIsPayPal] = useState(false);
    const [totalAmount, setTotalAmount] = useState(DEFAULT_CREDITS * DEFAULT_TRANS_FEE);
    const [paypalAccount, setPaypalAccount] = useState();
    const [paypalClientId, setPaypalClientId] = useState();
    const [credits, setCredits] = useState(DEFAULT_CREDITS);
    const getCart = () => ({
      getBusiness: () => ({
        payment_info: [{
          payment_type: 'paypal',
          account: paypalAccount,
          clientId: paypalClientId
        }]
      }),
      items: [{
        sku: 'TRANSACTION_CREDIT',
        name: 'Gateway Credits',
        item_type: 'TRANSACTION_CREDIT',
        unit_price: totalAmount
      }]
    });

    useEffect(() => {
      if (formData.trans_fee) {
        setTransFee(formData.trans_fee);
        setTotalAmount(formData.trans_fee & 25);
      }
      let gih = JSON.parse(decodeURIComponent(Utils.cookie('gih')));
      setPaypalAccount(gih.gih_paypal_account);
      setPaypalClientId(gih.gih_paypal_clientId);
    }, [formData]);

    return <div className="BuyTransactionCredits">
      <div className="calculate">
        <SelectFormInput
          name="buy_credit"
          label="Select credit amount"
          options={CREDIT_OPTIONS.map(i => ({value: i, label: `${i} credits`}))}
          onChange={v => {
            setCredits(parseInt(v));
            setTotalAmount(parseInt(v) * transFee);
          }}
          /> * {transFee.toFixed(2)} = <span className="total_amount">USD {totalAmount.toFixed(2)}</span>
      </div>
      <div className="buttons">
        <button className="button" 
          onClick={e => {
            e.stopPropagation();
            setIsPayPal(true);
          }}
        >Continue</button>
        <button className="button"
          onClick={e => {
            e.stopPropagation();
            onDone();
          }}
        >Cancel</button> 
      </div>
      {isPayPal && 
        <PayPal cart={getCart()}
          onSubmit={details => {
            LeagueModel.addTransactionCredit({
              purchase_date: new Date().getTime(),
              credit_purchase: credits,
              purchase_by: session.id,
              league: formData.id,
              paid_amount: totalAmount,
              transaction_details: JSON.stringify(details)
            }).then(r => {
              if (r && !r.error) {
                details.id = r.id;
                details.getPurchaser = () => session;
                formData.getLastTransactionCreditPurchase = () => details;
                setIsBuyCredits(false);
                return setMessage(`success: Successfully purchase ${credits} credits`);
              }
              setMessage('error: Error purchasing credits')
            })
          }}/>}
    </div>;
  }

  const TransactionCredits = () => {
    const [showHistory, setShowHistory] = useState(false);

    const LastTransactionPurchase = () => {
      if (!formData.getLastTransactionCreditPurchase) {
        return '';
      }
      let lastPurchase = formData.getLastTransactionCreditPurchase();
      return !lastPurchase? '':
        <div className="LastTransactionPurchase">
          <div className="row"><label>Last Purchase Date: </label> <span className="date">{Utils.formatDateTime(lastPurchase.purchase_date)}</span></div>
          <div className="row"><label>Credits Purchased: </label> <span className="p">{lastPurchase.credit_purchase}</span></div>
          <div className="row"><label>Purchased By: </label> <span className="credits">{UserModel.getMembershipName(lastPurchase.getPurchaser())}</span></div>
        </div>;
    }

    const TransactionCreditPurchaseHistory = () => {
      const [transPurHistory, setTransPurHistory] = useState([])
      useEffect(() => {
        LeagueModel.getTransactionCredits(formData.id)
          .then(transPurHistory => {
            setTransPurHistory(transPurHistory);
          });
      }, []);

      return <ThemeProvider theme={Theme}>
        <TableContainer component={Paper} className="TransactionCreditPurchaseHistory">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Purchase Date</TableCell>
                <TableCell align="right">Credit Purchase</TableCell>
                <TableCell align="right">Purchase By</TableCell>
                <TableCell align="right">Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transPurHistory.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {Utils.formatDateTime(row.purchase_date)}
                  </TableCell>
                  <TableCell align="right">{row.credit_purchase}</TableCell>
                  <TableCell align="right">{UserModel.getMembershipName(row.getPurchaseBy())}</TableCell>
                  <TableCell align="right">USD {row.paid_amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>;
    }

    const UpdateCredits = () => {
      const [credits, setCredits] = useState(formData?.transaction_credit?.credits || 0);
      const [message, setMessage] = useState();
      return <div className="UpdateCredits">
        <div className="fields">
          <TextFormInput name="transaction_credit" value={credits} onChange={setCredits}/>
          <button className="button" 
            onClick={() => {
              LeagueModel.updateLeagueTransactionCredits({id: formData.id, credits})
                .then(r => {
                  if (r && r.id){
                    return setMessage('success: Successfully update credits');
                  }
                  setMessage('error: Failed to update credits');
                });
            }}>Update</button>
          <button className="button"
            onClick={() => {
              LeagueModel.getTransactionCreditBalance(formData.id)
                .then(r => {
                  let status = JSON.parse(r.error);
                  setMessage(<div className="Status">
                    <div><b>Calculated Balance: </b><span>{r.id}</span></div>
                    <div><b>Purchase: </b><span>{status.purchase}</span></div>
                    <div><b>Used: </b><span>{status.used}</span></div>
                    <b>History: </b>
                    {status.history.map((h, i) => <div className="history" key={i}>
                      <div><b>Date: </b><span>{new Date(h.last_update).toLocaleString()}</span></div>
                      <div><b>By: </b><span>{h.by_name}</span></div>
                      <div><b>Credits: </b><span>{h.credits}</span></div>
                    </div>)}
                  </div>);
                });
            }}
          >Status</button>
        </div>
        
        <AlertPane message={message} setMessage={setMessage}/>
      </div>;
    }

    return <div className={`TransactionCredits`}>
      {session.is_super && <GIHTransactionFee register={register} formData={formData} />}
      <div className={FormStyles.fieldDescription}>
        <h3>Gateway Credits</h3>
      </div>
      <div className={`${FormStyles.fields} summary`}>
        <div className="transaction-credit">
          <div className="row">
            <label>Total Credits: </label>
            {!session.is_super? 
              <span className="credits">{formData?.transaction_credit?.credits || 0}</span>
              :
              <UpdateCredits />}
          </div>
          <LastTransactionPurchase />
          <Link className="history"
            onClick={e => {
              e.stopPropagation();
              setShowHistory(!showHistory);
            }}
            >{showHistory? <ExpandIcon />:<CollapseIcon/>} Purchase History</Link>
            {showHistory && <TransactionCreditPurchaseHistory />}
        </div>
        {!isBuyCredits && <button className="button" 
          onClick={e => {
            e.stopPropagation();
            setIsBuyCredits(true);
          }}>Buy Credits</button>}
        {isBuyCredits && <BuyTransactionCredits 
          onDone={v => {
            setIsBuyCredits(false);
          }}/>}
      </div>
    </div>;
  }

  return isLoading ?
    <IonSpinner name="circles" className="spinner" /> : 
    <div className="LeagueInformationForm">
      <TransactionCredits />
      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        <div className={FormStyles.fieldDescription}/>
        <div className={`${FormStyles.fields} hr`} />
        <div className={FormStyles.fieldDescription}>
          <h3>Banner</h3>
        </div>
        <div className={FormStyles.fields}>
        <ImageFormInput
            onImage={onImage}
            name="banner"
            images={ImageModel.setImageFormInput(
              IMAGE_TYPE.league,
              getValues,
              getFieldState,
              formData,
              "banner"
            )}
            size={600} maxWidth={200}
        />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>General Information</h3>
        </div>
        <div className={`${FormStyles.fields} general`}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="League Name"
            isRequired
            register={register} setValue={setValue}
            onChange={checkCRC}
            errors={errors} />
          <SelectFormInput
            name="status"
            label="Status"
            setValue={setValue}
            placeholder="Status"
            options={STATUS}
            control={control}
            onChange={checkCRC}
            value={formData?.status} />
          <TextFormInput
            type="email"
            value={formData?.email}
            name="email"
            label="Email"
            register={register} setValue={setValue}
            onChange={checkCRC}
            isRequired validateWithTrim
          />
          <TextFormInput
            value={formData?.website}
            name="website"
            label="Web Site"
            onChange={checkCRC}
            register={register} setValue={setValue}
          />
          <AddressForm
            onChange={checkCRC}
            address={{
              country: getValues()?.country || formData.country,
              state: getValues()?.state || formData.state,
              address: getValues()?.address || formData.address,
              city: getValues()?.city || formData.city,
              zip: getValues()?.zip || formData.zip,
              suburb: getValues()?.suburb || formData.suburb,
              region: getValues()?.region || formData.region,
            }}
            register={register} setValue={setValue}
          />
          <TextFormInput
            onChange={checkCRC}
            value={formData?.weight_accuracy}
            name="weight_accuracy"
            label="Weight Accuracy"
            register={register} setValue={setValue}
            errors={errors}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>Executives</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            onChange={checkCRC}
            value={getValues("executives") || formData.executives}
            name="executives"
            label="Executives"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>Season</h3>
          <p>Set league season period</p>
        </div>
        <div className={FormStyles.fields}>
          <div className={FormStyles.fieldDescription}>
          <h6>Start Date</h6>
        </div>
          <div className="season">
            <SelectFormInput
              onChange={checkCRC}
              value={formData?.tournament_season?.start_date?.month || 1}
              name="tournament_season.start_date.month"
              label="Month"
              setValue={setValue}
              placeholder="Month"
              options={MONTH_OPTIONS}
              control={control}
            />
            <SelectFormInput
              value={formData?.tournament_season?.start_date?.day || 1}
              onChange={checkCRC}
              name="tournament_season.start_date.day"
              label="Day"
              setValue={setValue}
              placeholder="Select day"
              options={Utils.range(1, 31).map((o) => ({ value: o }))}
              control={control}
            />
          </div>
          <div className={FormStyles.fieldDescription}>
          <h6>End Date</h6>
        </div>
          <div className="season">
            <SelectFormInput
              value={formData?.tournament_season?.end_date?.month || 1}
              onChange={checkCRC}
              name="tournament_season.end_date.month"
              label="Month"
              setValue={setValue}
              placeholder="Select month"
              options={MONTH_OPTIONS}
              control={control}
            />
            <SelectFormInput
              value={formData?.tournament_season?.end_date?.day || 1}
              onChange={checkCRC}
              name="tournament_season.end_date.day"
              label="Day"
              setValue={setValue}
              placeholder="Select day"
              options={Utils.range(1, 31).map((o) => ({ value: o }))}
              control={control}
            />
          </div>
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Expiration Notification</h3>
          <p>Set the expiration notification</p>
        </div>
        <div className={FormStyles.fields}>
          <div className="expiration-notification">
            <SelectFormInput
              value={formData?.expiration_notification?.period_type}
              onChange={checkCRC}
              name="expiration_notification.period_type"
              label="Period"
              setValue={setValue}
              placeholder="Select type"
              options={[{ value: "Days" }, { value: "Weeks" }, { value: "Months" }]}
              control={control}
            />
            <TextFormInput
              value={formData?.expiration_notification?.amount}
              onChange={checkCRC}
              name="expiration_notification.amount"
              label="Units"
              register={register} setValue={setValue}
              type="number"
            />
          </div>
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Registration Fee</h3>
          <p>Set the league membership fee</p>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            onChange={checkCRC}
            value={formData?.registration_fee}
            name="registration_fee"
            label="League Membership Fee"
            register={register} setValue={setValue}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Currency</h3>
        </div>
        <div className={FormStyles.fields}>
          <SelectFormInput width={300}
            value={formData?.currency}
            onChange={checkCRC}
            name="currency"
            label="Currency"
            setValue={setValue}
            placeholder="Select currency"
            options={CURRENCIES}
            control={control}/>
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Admins</h3>
          <p>Important dates for the tournament.</p>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            onChange={checkCRC}
            value={getValues("admins") || formData?.admins}
            name="admins"
            label="Admins"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
            isRequired />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Payment Information
              <AddIcon id="add_payment_info"
                className={FormStyles.add_icon}
                onClick={() => {
                  let field = "payment_info";
                  let list = getValues(field) || [];
                  list.push({});
                  setValue(field, list, { shouldDirty: true });
                  setRefresh(!refresh);
                }}
            />
          </h3>
          <p>Enter your payment gateway information</p>
        </div>
        <div className={FormStyles.fields}>
          <PaymentInfos
            onChange={checkCRC}
            data={getValues("payment_info") || formData.payment_info}
            register={register} setValue={setValue}
            refresh={() => setRefresh(!refresh)}
              errors={errors?.payment_info}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Membership Types
              <AddIcon id="add_membership_type"
                className={FormStyles.add_icon}
                onClick={() => {
                  let field = "membership_type";
                  let list = getValues(field) || [];
                  list.push({});
                  setValue(field, list, { shouldDirty: true });
                  setRefresh(!refresh);
                }}
            />
          </h3>
          <p>Enter league membership type</p>
        </div>
        <div className={FormStyles.fields}>
          <MembershipTypes
            errors={errors?.membership_type}
            membership_type={getValues("membership_type") || formData.membership_type}
            register={register} setValue={setValue}
            refresh={() => setRefresh(!refresh)}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Social Medias
              <AddIcon id="add_social_medias"
                className={FormStyles.add_icon}
                onClick={() => {
                  let field = "social_medias";
                  let list = getValues(field) || [];
                  list.push({});
                  setValue(field, list, { shouldDirty: true });
                  setRefresh(!refresh);
                }}
              />
          </h3>
          <p>Set social medias of this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <SocialMedias
            data={getValues("social_medias") || formData.social_medias}
            register={register} setValue={setValue}
            errors={errors.social_medias}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Contacts
              <AddIcon id="add_contacts"
                className={FormStyles.add_icon}
                onClick={() => {
                  let field = "contact_infos";
                  let list = getValues(field) || [];
                  list.push({});
                  setValue(field, list, { shouldDirty: true });
                  setRefresh(!refresh);
                }}
              />
          </h3>
          <p>Set contact information for this league</p>
        </div>
        <div className={FormStyles.fields}>
          <ContactInfos
            data={getValues("contact_infos") || formData.contact_infos}
            register={register} setValue={setValue}
            errors={errors} setError={setError}
            refresh={() => setRefresh(!refresh)}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Win By
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "winbys";
                let list = getValues(field) || formData.winbys || [];
                list.push({});
                formData.round_robin_points  = list;
                setValue(field, list);
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Set win by types</p>
        </div>
        <div className={FormStyles.fields}>
          <WinBys 
            setValue={setValue}
            winbys={formData.winbys} 
            onChange={entries => {
              formData.winbys  = entries;
              setValue('winbys', [...entries]);
              checkCRC();
            }}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Round Robin Points
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "round_robin_points";
                let list = getValues(field) || formData.round_robin_points || [];
                let hasSet = list.map(w => w.code);
                if (list.length === (getValues('winbys')||formData.winbys).length) {
                  return;
                }
                list.push({});
                formData.round_robin_points  = list;
                setValue(field, list);
                setRefresh(!refresh);
              }}
            />
          </h3>
          <p>Set round robin point</p>
        </div>
        
        <div className={FormStyles.fields}>
              <RoundRobinPoints 
                winbys={getValues('winbys') || formData.winbys}
                round_robin_points={getValues('round_robin_points') || formData.round_robin_points} 
                onChange={entries => {
                  formData.round_robin_points  = entries;
                  setValue('round_robin_points', entries);
                  checkCRC();
                }}
              />
        </div>

        <div className={FormStyles.fieldDescription}/>
        <div className={FormStyles.fields}>
          <FormActionButtons 
            doCopy={data&&data.id? doCopy:undefined} 
            doDelete={doDelete} id={formData.id} 
            message={message} 
            setMessage={setMessage}/>
        </div>
      </form>
      {hasChange && <AlertPane isFloat message={'warning: Data has changed'} setMessage={() => setHasChange(false)}/>}
    </div>;
}

const WinBys = ({winbys, onChange}) => {
  return <div className="WinBys">
    {WINS_BY_OPTIONS.map((wo, i) => {
      let checked = !winbys || wo.value === 'BYE' || winbys.find(wb => wb.code === wo.value);
      return <CheckboxFormInput isReadOnly={wo.value === 'BYE'} key={i}
        label={wo.label} 
        name={wo.value} 
        value={checked? true:false}
        onChange={v => {
          let ws = winbys;
          if (v) {
            ws.push({name: wo.label, code: wo.value})
          }else {
            ws = ws.filter(w => w.code !== wo.value);
          }
          onChange(ws);
        }}
      />
    })}
  </div>;
}

const RoundRobinPoints = ({winbys, round_robin_points=[], onChange}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let list = !winbys? WINS_BY_OPTIONS : winbys.map(w => ({value: w.code, label: w.name}));
    let listValues = list.map(l => l.value);
    let removes = round_robin_points.filter(l => !listValues.includes(l.win_by));
    if (removes.length > 0){
      onChange(round_robin_points.filter(r => !removes.includes(r)));
    }
    setOptions(list);
  }, [winbys]);

  let hasSet = round_robin_points.map(rp => rp.win_by && rp.win_by);
  return <div className="RoundRobinPoints">
    {(round_robin_points||[]).map((e, i) => {
      let _options = options;
      if (!e.win_by){
        _options = options.filter(l => {
          return !hasSet.includes(l.value);
        });
      }
      return <div className="entry" key={i}>
        <SelectFormInput 
          value={e.win_by}
          name="win_by"
          label="Win By"
          placeholder="Select win by"
          options={_options}
          onChange={v => {
            round_robin_points[i].win_by = v;
            onChange(round_robin_points);
          }}
        />
        <TextFormInput type="number"
          value={e.points}
          name="points"
          label="Points"
          onChange={v => {
            round_robin_points[i].points = isNaN(v)? 0 : parseInt(v);
            onChange(round_robin_points);
          }}
        />
        <RemoveSubFormIcon
          right
          onClick={() => {
            round_robin_points.splice(i, 1);
            onChange(round_robin_points);
          }}
          label={`Points ${i + 1}`}
        />
      </div>
    })}
  </div>;
}