import { MESSAGE_TYPE } from "../../components/Form/Form";
import { RequestCart, RequestUtils } from "../requests";
import ImageModel from "./ImageModel";
import TournamentModel from "./TournamentModel";

export default class CartModel {
  static sendConfirmation = async (cartId, payment_status) => {
    let response = await RequestCart.sendConfirmation({ cartId, payment_status });
    return RequestUtils.getResponseData(response);
  }

  static getCart = async (id) => {
    let response = await RequestCart.getCart({ id });
    return RequestUtils.getResponseData(response);
  }

  static getCartsByRefMembership = async (ref) => {
    let response = await RequestCart.getCartsByRefMembership(ref);
    return RequestUtils.getResponseData(response);
  };

  static getCarts = async (ref, ref_type) => {
    let response = await RequestCart.getMerchantCartRequest({ id: ref, ref_type });
    return RequestUtils.getResponseData(response);
  };

  static newStoreItem = (data, setData, ref_type, ref) => {
    setData([
      ...data,
      {
        id: `-${new Date().getTime()}`,
        name: "new store item...",
        ref_type,
        ref,
        getImages: () => [],
        status: 'A',
      },
    ]);
  };

  static storeItemDelete = async (setMessage, data, tableRef, setData) => {
    setMessage("");
    if (tableRef.current.selected.length === 0) {
      return setMessage("info: Nothing is selected");
    }
    let fails = [],
      deletes = [];
    let hasServerDelete = false;
    for (let s of data.filter((d) =>
      tableRef.current.selected.includes(d.id)
    )) {
      if (s.id.startsWith("-")) {
        deletes.push(s.id);
        continue;
      }
      let result = await TournamentModel.deleteStoreItem(s.id);
      hasServerDelete = true;
      if (!result || result.error) {
        fails.push(s);
      } else {
        for (let img of s.getImages() || []) {
          if (img.id && !img.id.startsWith("-")) {
            await ImageModel.deleteImage(img.id);
          }
        }
        deletes.push(s.id);
      }
    }
    setData(data.filter((d) => !deletes.includes(d.id)));

    if (fails.length > 0) {
      return setMessage(`error: Error deleting items: ${fails.join(", ")}`);
    }

    setMessage(`success: Successfully delete ${hasServerDelete? 'server':''} `);
    setTimeout(() => {
      tableRef.current.gotoPage(0);
      tableRef.current.setSelected([]);
    }, 500);
  };

  static storeItemsSave = async (setMessage, data, tableRef) => {
    setMessage("");
    console.log(data);
    if (tableRef.current.selected.length === 0) {
      return setMessage("info: No item is selected.");
    }
    if (data.find((s) => !s.name || !s.sku || !s.status)) {
      return setMessage(
        "error: Store item name, SKU and status must be filled."
      );
    }
    let selected = data.filter((s) => tableRef.current.selected.includes(s.id));
    let fails = [];
    for (let si of selected) {
      let status;
      if (si.id.startsWith("-")) {
        status = await TournamentModel.addStoreItem(si);
      } else {
        status = await TournamentModel.updateStoreItem(si);
      }
      if (status && status.id) {
        si.id = status.id;
        let deleteds = [];
        for (let img of si.getImages()) {
          if (!img.id) {
            img.ref = status.id;
            let fileName = img.fileName;
            delete img.fileName;
            let result = await ImageModel.addImage(img, (p) =>
              setMessage(`info: Uploading ${fileName}: ${p}%`)
            );
            if (result && result.id) {
              si.id = result.id;
            }
          } else if (img.delete) {
            let result = await ImageModel.deleteImage(img.id);
            if (!result || result.error) {
              setMessage("error: Deleting image: " + img.id);
            } else {
              deleteds.push(img.id);
            }
          }
        }
        if (deleteds.length > 0) {
          let images = si.getImages().filter((i) => !deleteds.includes(i.id));
          si.getImages = () => images;
        }
      } else {
        fails.push(si.name);
      }
    }
    if (fails.length > 0) {
      return setMessage(
        `error: Error updating store item(s): ${fails.join(", ")}`
      );
    }
    setMessage("success: Successfully update server.");
    tableRef.current.setSelected([]);
  };

  static addEntityRegistrationToCart = async (
    item_type,
    ref_type,
    entity,
    setMessage,
    history,
    session
  ) => {
    setMessage("");
    let items = [
      {
        sku: entity.id,
        description: `${entity.name} ${ref_type} membership registration`,
        quantity: 0,
        unit_price: entity.registration_fee,
        item_type,
      },
    ];
    let result = await CartModel.addToCart(items, session, entity, ref_type);
    if (result && result.id) {
      setTimeout(() => {
        setMessage("");
        history.push("/carts");
      }, 2000);
    } else {
      setMessage("Error updating cart");
    }
  };

  static REF_TYPE = { ...MESSAGE_TYPE };

  static async addToCart(newItems, session, entity, ref_type, setSession) {
    if (!Array.isArray(newItems)) {
      newItems = [newItems];
    }
    if (!session) {
      return;
    }
    if (session) {
      let { getCarts } = session;
      let cart = getCarts()?.find((c) => c.ref === entity?.id) || {
        status: "A",
        items: [],
        membership: session.id,
        ref: entity.id,
        ref_type,
        ref_display: entity.name,
        payment_info: entity.payment_info,
        currency: entity.getCurrency(),
      };

      const { items = [] } = cart;
      for (let ni of newItems) {
        let _item = items.find((i) => i.sku === ni.sku);
        if (_item) {
          _item.quantity++;
        } else {
          items.push(ni);
        }
      }

      let response;
      if (!cart.id) {
        response = await RequestCart.addCart(cart);
        let carts = [...session.getCarts(), cart];
        session.getCarts = () => carts;
      } else {
        response = await RequestCart.updateCart(cart);
      }
      let result = RequestUtils.getResponseData(response);
      if (result && result.id) {
        cart.id = result.id;
      }
      // setSession({ ...session });
      return result;
    }
  }

  static async addCart(cart) {
    let response = await RequestCart.addCart(cart);
    return RequestUtils.getResponseData(response);
  }

  static async updateCart(cart) {
    let response = await RequestCart.updateCart(cart);
    return RequestUtils.getResponseData(response);
  }

  static async submitCart(cart) {
    let response = await RequestCart.submitCart(cart);
    return RequestUtils.getResponseData(response);
  }

  static async deleteCart(id) {
    let response = await RequestCart.deleteCart(id);
    return RequestUtils.getResponseData(response);
  }
}
