import { useEffect, useState } from "react";
import { useStore } from "../../Store";
import { duplicate_station_error, getStationName, initStation } from "./LocalServer";
import { useHistory } from 'react-router-dom';
import LocalServerModel from "../../serverUtils/models/LocalServerModel";
import Utils from "../../serverUtils/Utils";
import Rankings from "../TournamentPage/Rankings";
import AlertPane from "../../components/FormInput/AlertPane";

let sse;
export default function LocalResults({}) {
  const station = useStore(state => state.station);
  const setStation = useStore(state => state.setStation);
  const [redirect, setRedirect] = useState();
  const history = useHistory();
  const localServer = useStore((state) => state.local_server);
  const tournament = useStore(state => state.local_tournament);
  const setTournament = useStore(state => state.setLocalTournament);
  const [message, setMessage] = useState();
  const [notification, setNotification] = useState();

  useEffect(() => {
    if (station && station.type === LocalServerModel.STATION_TYPE.results) {
        initSSE();
    } 
  }, [station]);

  const updateData = async (placements, tid) => {
    let t = tournament;
    if (!t) {
      t = await LocalServerModel.getTournament(tid);
    }
    console.log(placements)
    setTournament(t);
  }

  const receive = (message) => {
    if (message.to !== LocalServerModel.LOCAL_SERVER.ping && message.to !== station.station) {
        return;
    }
    
    if (message === duplicate_station_error) {
        setMessage('Duplicate station: ' + Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station))
    }
    const { update, to, from, notification, raw, tournament_id, type } = message;
    if (from === station.station) {
      return;
    }
    if (to === LocalServerModel.LOCAL_SERVER.ping) {
      return LocalServerModel.sendMessage({
        tournament_id,
        from: station.station, to,
        label: station.label,
        type: LocalServerModel.STATION_TYPE.results,
      });
    }
    if (to && to !== station.station) {
      return;
    }
    console.log(
      '\nreceive:',
      Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'),
      '\nmessage:',
      message
    );
    Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament, tournament_id);
    if (type === LocalServerModel.STATION_TYPE.results && raw) {
      return updateData(JSON.parse(raw), tournament_id);
    }
    if (LocalServerModel.setCurrentStation(message, station, setStation, setRedirect)) {
      sse.close();
      return;
    };
    if (notification) {
      return setNotification(notification);
    }
  }
  const initSSE = async () => {
    sse && sse.close();
    sse = initStation({
      localServer,
      receive
    });
  };

  return redirect? redirect :
    <div style={{ padding: 40 }}>
        <h1>Result Station {getStationName(station && station.label)}</h1>
        <AlertPane message={message} />
        {tournament && <Rankings tournament={tournament}/>}
    </div>;
}
